/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BasicTable from "components/Tables/BasicTable";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
// Data
//import { columnsData1 } from "variables/columnsData";
//import tableData1 from "variables/tableData1.json";

function DataTables() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [twilio, setTwilio] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState('');

  const handleEdit = (originalRow) => {
    history.push('/administrator/publishers/users/' + originalRow._id + '/edit');
  };

  const columnsData = [
    // {
    //   Header: "Target ID",
    //   accessor: "id",
    // },
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Name",
      accessor: "client.name",
    },
    {
      Header: "Email",
      accessor: "client.email",
    },
    {
      Header: "Account ID",
      accessor: "accountId",
    },
    {
      Header: "Security Key",
      accessor: "securityKey",
    }
  ];

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, [refersh]);

  const getData = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: '32' })
    };
    const response = await fetch(apiUrl + '/api/admin/client/twilio/lists', requestOptions);
    const result = await response.json();
    if(result.statusCode == 200) {
      setTwilio(result.data);
      setLoadingData(false);
    } else {
      toast.error('Twilio Details not Found!');
    }
    toast.dismiss(loadToast);
  }

  return (
    <Flex className='addpub' direction='column'>
      <Card p='20px'>
        <CardHeader mb='24px'>
          <Flex direction='column'>
            <Text fontSize='lg' mb='5px' color='#fff'>
              Twilio Integrations
            </Text>

          </Flex>
        </CardHeader>

        <CardBody>
          {loadingData ? (
            <p>Loading Please wait...</p>
          ) : (
            <SearchTable1 tableData={twilio} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTables;
