/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState, useEffect, useMemo } from "react";

// Chakra imports
import {
  Avatar,
  Badge,
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  Select,
  Stack,
  Switch,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  LightMode,
  DarkMode,
  useDisclosure,
} from "@chakra-ui/react";
import toast, { Toaster } from "react-hot-toast";
import { langToLang } from "language-name-to-language-name";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { HSeparator } from "components/Separator/Separator";
import { Element, Link } from "react-scroll";

import AccountNav from 'components/Navbars/Account';
import { commonColors } from 'variables/general';
import CTModal from "components/Modal";

import { BsArrowRight } from "react-icons/bs";
import { GiSmartphone } from "react-icons/gi";
import { RiComputerLine } from "react-icons/ri";
import { MdOutlineEdit } from "react-icons/md";
import axios from "axios";
import moment from "moment";

function Settings() {
  const user = JSON.parse(localStorage?.getItem("user"));
  const languages = useMemo(() => Object.values(langToLang("en")).reverse(), []);
  const [toggle, setToggle] = useState(false);
  const [formDisabled, setFormDisabled] = useState(true);
  const [profile, setProfile] = useState({});
  const [sessions, setSessions] = useState([]);
  const [currentSession, setCurrentSession] = useState({});
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [passwordOject, setpasswordOject] = useState({
    oldP: "",
    newP: "",
    confirmP: "",
  });

  useEffect(() => {
    getProfile();
    getSessions();
  }, []);

  const getProfile = () => {
    try {
      axios.post("/api/users/publisher/fetch", { id: user.id })
        .then(({status, data}) => {
          if (data.success) {
            setProfile(data.user);
          } else {
            toast.error(data.error);
          }
        }).catch(error => {
          toast.error(error.message);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const updatePassword =() => {
    const { confirmP, oldP, newP } = passwordOject;
    if (confirmP !== newP) {
      toast.error("Passwords don't match.");
      return;
    }

    if (confirmP.trim() === "" || oldP.trim() === "" || newP.trim() === "") {
      toast.error("All password fields must be filled.");
      return;
    }

    if (oldP.trim() === newP.trim()) {
      toast.error("New and old passwords must be different.");
      return;
    }

    try {
      axios.post("/api/users/publisher/update", { password: oldP, newPassword: newP, id: user.id })
        .then(({ status, data}) => {
          if (data.success) {
            toast.success("Password successfully updated.");
            setpasswordOject({ oldP: "", newP: "", confirmP: "" });
          } else {
            toast.error(data.error);
          }
        }).catch(error => {
          toast.error(error.message);
        });
    } catch(error) {
      toast.error(error.message);
    }
  };

  const updateProfile = () => {
    const postData = {};
    if (
      profile.name.trim() === "" || 
      profile.email1?.trim() === "" || 
      (user.email !== profile.email1?.trim() && profile.email2?.trim() === "")
    ) {
      toast.error("Name and Email fields cannot be empty");
      return;
    }

    if (user.name !== profile.name.trim()) {
      postData.name =  profile.name;
    }

    if (profile.email2?.trim() !== "" && profile.email1?.trim() !== profile.email2?.trim()) {
      toast.error("Email fields have to match");
      return;
    } else if ( profile.email2 && profile.email1?.trim() === profile.email2?.trim()) {
      postData.email =  profile.email1;
    }

    const postDataFields = ["dob", "gender", "location", "language", "phone"];
    postDataFields.forEach(field => {
      if(profile[field] && profile[field]?.trim() !== "") {
        if (field === "dob") {
          postData[field] = moment(profile[field]).format("MM/DD/YYYY");
        } else {
          postData[field] = profile[field];
        }
      }
    });

    if (Object.keys(postData) === 0) {
      return;
    }

    try {
      axios.put("/api/users/profile/update", { id: user.id, postData: postData })
        .then(({ status, data}) => {
          if (data.success) {
            toast.success("Profile successfully updated.");
            getProfile();
            setFormDisabled(true);
          } else {
            toast.error(data.error);
          }
        }).catch(error => {
          toast.error(error.message);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const getSessions = () => {
    try {
      axios.get("/api/users/sessions", { params: { id: user.id } })
        .then(({ status, data }) => {
          if (data.success) {
            setSessions(data.userSessions);
          } else {
            toast.error(data.error);
          }
        }).catch(error => {
          toast.error(error.message);
        });
    } catch (error) {
      toast.error(error.message);
    }
  };

  const setFormData = (e) => {
    setProfile({
      ...profile,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Flex direction='column' className='settingsWrapper'>
      <Stack
        direction='column'
        spacing='24px'
        align={{ lg: "flex-end" }}
        justify={{ lg: "flex-end" }}
        w='100%'>
        <Card
          w="100%"
          p='20px'
          alignSelf='flex-start'
          justifySelf='flex-start'>
          <CardBody style={{ display: 'block' }}>
            <AccountNav current="/administrator/accounts/profile" userRole={user.role} />
          </CardBody>
          <Element id='info' name='info'>
            <CardBody
              bg={commonColors.darkNavBg}
              borderRadius='10px'
              p='50px'
            >
              <Stack direction='column' spacing='20px' w='100%'>
              <Flex
                direction="column"
                justify='flex-start'
                align='center'
                w='100%'>
                <Flex align='center' alignSelf='flex-start'>
                  <Avatar
                    w='80px'
                    h='80px'
                    me='22px'
                    borderRadius='15px'
                  />
                  <Flex direction='column'>
                    <Flex flexDirection={"row"} gap={3} alignItems={"baseline"}>
                      <Text color='#fff' fontSize='3xl'>
                        {profile.name} 
                      </Text>
                      <MdOutlineEdit style={{color: "#fff", cursor: "pointer"}} title="edit profile" onClick={() => setFormDisabled(!formDisabled)}/>
                    </Flex>
                    <Text color='gray.400' fontSize='sm'>
                      {profile.email}
                    </Text>
                  </Flex>
                </Flex>
                {/* <Flex
                  align='center'
                  alignSelf={{ sm: "flex-start", lg: null }}
                  mt={{ sm: "16px", lg: null }}
                  ms={{ sm: "6px", lg: null }}>
                  <Text color='#fff' me='14px' fontSize='xs'>
                    Switch to {toggle ? "invisible" : "visible"}
                  </Text>
                  <DarkMode>
                    <Switch onChange={() => setToggle(!toggle)} />
                  </DarkMode>
                </Flex> */}
              </Flex>
                <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Name
                    </FormLabel>
                    <Input
                      _hover='none'
                      color='#fff'
                      placeholder='eg. Michael'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      name="name"
                      value={profile.name}
                      onChange={setFormData}
                    />
                  </FormControl>
                  {/* <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Last Name
                    </FormLabel>
                    <Input
                      _hover='none'
                      color='#fff'
                      placeholder='eg. Jackson'
                      fontSize='xs'
                      value={profile.name}
                      onChange={setFormData}
                    />
                  </FormControl> */}
                </Stack>
                <Stack
                  direction={{ sm: "column", lg: "row" }}
                  spacing={{ sm: "24px", lg: "30px" }}>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      I am
                    </FormLabel>
                    <Select
                      _hover='none'
                      color='#fff'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      name="gender"
                      value={profile.gender}
                      onChange={setFormData}
                    >
                      <option value='Female'>Female</option>
                      <option value='Male'>Male</option>
                    </Select>
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      DOB
                    </FormLabel>
                    <Input
                      type="date"
                      _hover='none'
                      color='#fff'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      name="dob"
                      value={moment(profile.dob).format("yyyy-MM-DD")}
                      onChange={setFormData}
                    />
                  </FormControl>
                </Stack>
                <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Email Address
                    </FormLabel>
                    <Input
                      _hover='none'
                      color='#fff'
                      placeholder='eg. esthera@address.com'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      type="email"
                      name="email1"
                      value={profile.email}
                      onChange={setFormData}
                    />
                  </FormControl>
                  {!formDisabled &&
                    <FormControl>
                      <FormLabel
                        fontWeight='bold'
                        color='#fff'
                        fontSize='xs'
                        mb='10px'>
                        Confirmation Email
                      </FormLabel>
                      <Input
                        _hover='none'
                        color='#fff'
                        placeholder='eg. esthera@address.com'
                        fontSize={formDisabled ? 'md' : 'xs'}
                        type="email"
                        disabled={formDisabled}
                        name="email2"
                        onChange={setFormData}
                      />
                    </FormControl>
                  }
                </Stack>
                <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Your Location
                    </FormLabel>
                    <Input
                      _hover='none'
                      color='#fff'
                      placeholder='eg. Bucharest'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      name="location"
                      value={profile.location}
                      onChange={setFormData}
                    />
                  </FormControl>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Phone Number
                    </FormLabel>
                    <Input
                      _hover='none'
                      color='#fff'
                      placeholder='eg. +40 941 353 292'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      name="phone"
                      value={profile.phone}
                      onChange={setFormData}
                    />
                  </FormControl>
                </Stack>
                <Stack
                  direction={{ sm: "column", lg: "row" }}
                  spacing={{ sm: "24px", lg: "30px" }}
                  width='49%'>
                  <FormControl>
                    <FormLabel
                      fontWeight='bold'
                      color='#fff'
                      fontSize='xs'
                      mb='10px'>
                      Language
                    </FormLabel>
                    <Select
                      _hover='none'
                      color='#fff'
                      placeholder='English'
                      fontSize={formDisabled ? 'md' : 'xs'}
                      disabled={formDisabled}
                      name="language"
                      value={profile.language}
                      onChange={setFormData}
                    >
                      {languages.map(lang => {
                        return (<option key={lang.name} value={lang.name}>{lang.name}</option>);
                      })}
                    </Select>
                  </FormControl>
                </Stack>
                {!formDisabled &&
                  <Button
                    className='contextSwitchButton'
                    w='100px'
                    color={"white"}
                    style={{backgroundColor: commonColors.highlightBlue}}
                    onClick={updateProfile}
                  >
                    Save
                  </Button>
                }
              </Stack>
            </CardBody>
          </Element>
        {/* Change Password Card */}
        <CardBody
          bg={commonColors.darkNavBg}
          borderRadius='10px'
          p='50px'
          mt='20px'
        >
          <Element id='change-password' name='change-password' style={{width: "100%"}}>
            <CardHeader mb='20px'>
              <Text color='#fff' fontSize='xl'>
                Change Password
              </Text>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='20px' w='100%'>
                <FormControl w='49%'>
                  <FormLabel
                    fontWeight='bold'
                    color='#fff'
                    fontSize='xs'
                    mb='10px'>
                    Current Password
                  </FormLabel>
                  <Input
                    _hover='none'
                    color='#fff'
                    placeholder='Current Password'
                    fontSize='xs'
                    type="password"
                    name="oldP"
                    value={passwordOject.oldP}
                    onChange={(e) => setpasswordOject({
                      ...passwordOject,
                      [e.target.name]: e.target.value
                    })}
                  />
                </FormControl>
                <Flex direction='row'>
                <FormControl mr='30px'>
                  <FormLabel
                    fontWeight='bold'
                    color='#fff'
                    fontSize='xs'
                    mb='10px'>
                    New Password
                  </FormLabel>
                  <Input
                    _hover='none'
                    color='#fff'
                    placeholder='New Password'
                    fontSize='xs'
                    type="password"
                    name="newP"
                    value={passwordOject.newP}
                    onChange={(e) => setpasswordOject({
                      ...passwordOject,
                      [e.target.name]: e.target.value
                    })}
                  />
                </FormControl>
                <FormControl>
                  <FormLabel
                    fontWeight='bold'
                    color='#fff'
                    fontSize='xs'
                    mb='10px'>
                    Confirm New Password
                  </FormLabel>
                  <Input
                    _hover='none'
                    color='#fff'
                    placeholder='Confirm New Password'
                    fontSize='xs'
                    type="password"
                    name="confirmP"
                    value={passwordOject.confirmP}
                    onChange={(e) => setpasswordOject({
                      ...passwordOject,
                      [e.target.name]: e.target.value
                    })}
                  />
                </FormControl>
                </Flex>
                  <Button
                    className='contextSwitchButton'
                    w='250px'
                    style={{backgroundColor: commonColors.highlightBlue}}
                    onClick={updatePassword}
                  >
                    <Text fontSize="16px" color="#fff">
                      Update Password
                    </Text>
                  </Button>
              </Stack>
            </CardBody>
          </Element>
        </CardBody>
        {/* Notifications Card */}
        {false &&
        <CardBody
          bg={commonColors.darkNavBg}
          borderRadius='10px'
          p='50px'
          mt='20px'
        >
          <Element to='notifications' name='notifications' style={{width: '100%'}}>
            <CardHeader mb='20px'>
              <Flex direction='column'>
                <Text color='#fff' fontSize='xl' mb='4px'>
                  Notifications
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Table>
                <Thead>
                  <Tr>
                    <Th
                      color='gray.400'
                      fontSize='sm'
                      borderColor='gray.500'
                      fontWeight='500'
                      textTransform='none'
                      textAlign='center'
                      pl='4px'>
                      Activity
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='sm'
                      borderColor='gray.500'
                      fontWeight='500'
                      textTransform='none'
                      textAlign='center'
                      >
                      Email
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='sm'
                      borderColor='gray.500'
                      fontWeight='500'
                      textTransform='none'
                      textAlign='center'
                      >
                      Push
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='sm'
                      borderColor='gray.500'
                      fontWeight='500'
                      textTransform='none'
                      textAlign='center'
                      >
                      SMS
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  <Tr>
                    <Td borderColor='gray.500' ps='0px' minW={{ sm: "300px" }}>
                      <Flex direction='column'>
                        <Text
                          color='#fff'
                          fontWeight='500'
                          fontSize='sm'
                          mb='4px'>
                          Mentions
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderColor='gray.500' ps='0px' minW={{ sm: "300px" }}>
                      <Flex direction='column'>
                        <Text
                          color='#fff'
                          fontWeight='500'
                          fontSize='sm'
                          mb='4px'>
                          Comments
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch />
                      </DarkMode>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td borderColor='gray.500' ps='0px' minW={{ sm: "300px" }}>
                      <Flex direction='column'>
                        <Text
                          color='#fff'
                          fontWeight='500'
                          fontSize='sm'
                          mb='4px'>
                          Follows
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                  </Tr>
                  <Tr>
                    <Td
                      borderColor='gray.500'
                      border='none'
                      ps='0px'
                      py='24px'
                      minW={{ sm: "300px" }}>
                      <Flex direction='column'>
                        <Text
                          color='#fff'
                          fontWeight='500'
                          fontSize='sm'
                          mb='4px'>
                          Log in from new device
                        </Text>
                      </Flex>
                    </Td>
                    <Td borderColor='gray.500' border='none'>
                      <DarkMode>
                        <Switch />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500' border='none'>
                      <DarkMode>
                        <Switch defaultIsChecked />
                      </DarkMode>
                    </Td>
                    <Td borderColor='gray.500' border='none'>
                      <DarkMode>
                        <Switch />
                      </DarkMode>
                    </Td>
                  </Tr>
                </Tbody>
              </Table>
            </CardBody>
          </Element>
        </CardBody>
        }
        {/* Sessions Card */}
        <CardBody
          bg={commonColors.darkNavBg}
          borderRadius='10px'
          p='50px'
          mt='20px'
        >
          <Element id='sessions' name='sessions' style={{width: '100%'}}>
            <CardHeader mb='20px'>
              <Flex direction='column'>
                <Text
                  color='#fff'
                  fontSize='xl'
                  mb='4px'>
                  Sessions
                </Text>
              </Flex>
            </CardHeader>
            <CardBody>
              <Stack direction='column' spacing='18px' w='100%'>
              {sessions.length > 0 && sessions.map((session, i) => {
                return (
                  <Box key={session._id}> 
                    <Flex
                      direction={{ sm: "column", md: "row" }}
                      justify='space-between'
                      w='100%'>
                      <Flex align='center'>
                        <Icon
                          as={RiComputerLine}
                          me='30px'
                          w='28px'
                          h='28px'
                          color='gray.400'
                        />
                        <Flex direction='column' mb={{ sm: "8px", lg: null }}>
                          {i === 0 &&
                            <>
                              <Text color='#fff' fontWeight='500' fontSize='sm'>
                                {session.city?.toUpperCase() ?? "" } {session.ip}
                              </Text>
                              <Text color='gray.400' fontSize='xs' fontWeight='500'>
                                Your current session
                              </Text>
                            </>
                          }
                          {i > 0 &&
                            <Text color='#fff' fontWeight='500' fontSize='sm'>
                              {session.browser} on  {session.os}
                            </Text>
                          }
                        </Flex>
                      </Flex>
                      <Stack
                        direction='row'
                        spacing='24px'
                        align='center'
                        alignSelf={{ sm: "flex-end", lg: null }}
                        minW={{ lg: "280px" }}>
                        {i === 0 &&
                          <LightMode>
                            <Badge bg='#01B574' color='#fff' borderRadius='15px' px='20px' py='8px'>
                              Active
                            </Badge>
                          </LightMode>
                        }
                        {i > 0 &&
                          <Text color='gray.400' fontSize='xs' fontWeight='500'>
                            {moment(session.dateEnded).format("LLL")}
                          </Text>
                        }
                        <Text color='gray.400' fontSize='md' fontWeight='500'>
                          {session.country}
                        </Text>
                        <Button variant='transparent-with-icon' color='brand.200' onClick={() => {
                          setCurrentSession(session);
                          onOpen();
                        }}>
                          <Flex
                            align='center'
                            color='brand.200'
                            w='100%'
                            fontSize='sm'>
                            <Text
                              me='6px'
                              transition='all .3s ease'
                              _hover={{ me: "14px" }}
                              fontSize='xs'
                              color='#01B574'>
                              See more
                            </Text>
                            <Icon
                              as={BsArrowRight}
                              color='#01B574'
                              w='12px'
                              h='12px'
                              transition='all .3s ease'
                              _hover={{ transform: "translateX(50%)" }}
                            />
                          </Flex>
                        </Button>
                      </Stack>
                    </Flex>
                    {i < sessions.length - 1 &&
                      <HSeparator />
                    }
                    </Box>
                  )
                })}
                {sessions.length === 0 && 
                  <Text color='#fff' fontWeight='500' fontSize='sm'>No session details available.</Text>
                }
              </Stack>
            </CardBody>
          </Element>
        </CardBody>
      </Card>
      </Stack>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={"Session Info"}
      >
        <SessionInfoBox Session={currentSession}></SessionInfoBox>
      </CTModal>
    </Flex>
  );
}

export default Settings;

const SessionInfoBox = (prop) => {
  const { Session } = prop;
  const sessionDict = {
    // sessionId: "Session ID",
    ip: "IP Address",
    createdAt: "Session started",
    dateEnded: "Session ended",
    country: "Country",
    city: "City",
    device: "Device",
    os: "OS",
  };
  const sessionKeys = Object.keys(sessionDict);

  return (
    <Box>
      {sessionKeys.map(key => {
        if (Session[key]) {
          let value = Session[key];
          if (key == "dateEnded" || key == "createdAt") {
            value = moment(value).format("LLLL");
          }
          return (
            <Flex flexDirection={"row"} gap={2} mb={3}>
              <Text flexBasis={"50%"}>{sessionDict[key]}:</Text>
              <Text flexBasis={"50%"}>{value}</Text>
            </Flex>
          );
        }
      })}
    </Box>
  );
}
