/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button, Switch, useDisclosure } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BasicTable from "components/Tables/BasicTable";
import SearchTable1 from "components/Tables/SearchTable1";
import RoutingNav from "components/Navbars/Routing";
import CTModal from "components/Modal";
import NewMedia from "./NewMedia.js";
import axios from "axios";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import packageJson from "../../appConfig.js";
import Loading from 'components/Loading';

// Data
//import { columnsData1 } from "variables/columnsData";
//import tableData1 from "variables/tableData1.json";

function MediaList() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [media, setMedia] = useState([]);
  const [load, setLoad] = useState(false);
  const [loadingData, setLoadingData] = useState(true);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = JSON.parse(localStorage?.getItem("user"));
  const [refresh, setRefresh] = useState(0);


  const handleEdit = (originalRow) => {
    history.push("/administrator/buyers/users/" + originalRow._id + "/edit");
  };

  const addMedia = async (type) => {
    onOpen();
  };

  const getMedia = async () => {
    axios.get(apiUrl + '/api/ivrflow/files', { params: { userId: user.id } })
      .then(({ data, status }) => {
        if (data.error) {
          console.log(error);
        } else {
            setMedia(data.media);
        }
      })
      .catch(error => {
        console.log(error);
      });
  };

  const mediaColumns = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Creation Date",
      accessor: row => row.createdAt.split("T")[0],
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
    },
    // {
    //   Header: 'Action',
    //   accessor: (originalRow, rowIndex) => (
    //     <div>
    //       <a href="#" onClick={e => { e.preventDefault(); props.onEdit(originalRow); }} ><i className="fa-solid fa-pen-to-square"></i></a> | <a href="#" onClick={e => { e.preventDefault(); deletePublisher(originalRow); }} ><i className="fa-solid fa-trash"></i></a>
    //     </div>
    //   ),
    //   id: 'action',
    // }
  ];

  useEffect(() => {
    getMedia();
  }, [refresh]);

  const refreshPage = () => {
    onClose();
    setRefresh(Math.random());
  }

  return (
      <Flex
        className="addpub"
        direction="column"
        gap={10}
      >
        {/* Datatable with search */}
        <Card>
          <RoutingNav current="/administrator/callflows/manage/media"/>
          <Button
            variant="outlineWhite"
            onClick={addMedia}
            maxW={"200px"}
          >
            + Add Media File
          </Button>
          <CardBody>
            {media.length > 0 &&
              <SearchTable1 tableData={media} columnsData={mediaColumns} />
            }
          </CardBody>
        </Card>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={"Add File"}
      >
        <NewMedia onClose={refreshPage}/>
      </CTModal>
    </Flex>
  );
}

export default MediaList;
