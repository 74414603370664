/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Select,
} from "@chakra-ui/react";

// Custom components
import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

const NewNumber = (props) => {
  const apiUrl = packageJson.apiurl;
  const [publishers, setPublishers] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [loadingPublisher, setloadingPublisher] = useState([]);
  const [loadingCampaign, setloadingCampaign] = useState([]);
  const [refersh, refershSet] = React.useState('');
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [edit, setEdit] = useState(false);
  const [number, setNumber] = useState({
    name: "",
    phone: "",
    campaign: "",
    publisher: "",
    by: userDetails.id,
  });

  useEffect(() => {
    if (loadingCampaign) {
      getCampaigns();
    }
    if (loadingPublisher) {
      getPublishers();
    }
    if (props.number) {
      setNumber({
        ...number,
        ...props.number,
      })
      setEdit(true);
    }
  }, [refersh]);

  const getCampaigns = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      id: userDetails.id,
    };
    const result = await axios.post(apiUrl + "/api/admin/list-campaign", requestOptions);
    setCampaigns(result.data.data);
    setloadingCampaign(false);
    toast.dismiss(loadToast);
  }

  const getPublishers = async () => {
    const loadToast = toast.loading('Loading...');
    const result = await axios.get(apiUrl + "/api/users/publishers", { params: { id: userDetails.id } });
    if (!result.error) {
      setPublishers(result.data);
      setloadingPublisher(false);
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      toast.error("Could not fetch publisher list.");
    }
  }

  const buyTwilioNumber = async (e) => {
    if (!confirm("Do You want to Buy New Twilio Number?")) {
      return false;
    }

    const loadToast = toast.loading('Please Wait....');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: userDetails.id })
    };
    const response = await fetch(apiUrl + '/api/twilio/phonenumber/buy', requestOptions);
    const result = await response.json();
    if (result.status == "success") {
      setNumber({
        ...number,
        phone: result.phoneNumber
      });
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      console.log(response);
      toast.error("Number Assignment to Publisher failed");
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setNumber({
      ...number,
      [name]: value
    })
  }

  const editNumber = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, phone, campaign, publisher } = number;
    if ((name == "") || (phone == "") || (campaign == "") || (publisher == "")) {
      toast.dismiss(loadToast);
      toast.error("Please fill the required filled..");
    } else {
      let config = {
        method: 'post',
        url: apiUrl + '/api/number/update',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { number }
      };
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          toast.success("Number updated successfully.");
          props.onClose();
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  const createNumber = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, phone, campaign, publisher } = number;
    if ((name == "") || (phone == "") || (campaign == "") || (publisher == "")) {
      toast.dismiss(loadToast);
      toast.error("Please fill all required fields.");
    } else {
      number.by = userDetails.id;
      let config = {
        method: 'post',
        url: apiUrl + '/api/number/create',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { number, token: token }
      };
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          toast.success("Number created successfully.");
          props.onClose();
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  };

  return (
    <Flex flexDirection='column' gap={5}>
      <FormControl>
        <FormLabel variant="ct-label">
          Name
        </FormLabel>
        <Input
          type='text'
          variant="borderless"
          name="name"
          value={number.name}
          onChange={handleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Number
        </FormLabel>
        <Flex className="add_number">
          <Input
            type='text'
            variant="borderless"
            name="phone"
            value={number.phone}
            onChange={handleChange}
            disabled={edit}
          />
          {!edit &&
            <i className="fa-solid fa-plus" onClick={buyTwilioNumber}></i>
          }
        </Flex>
      </FormControl>
      <FormControl>
        <FormLabel
          fontWeight='bold'
          color={"#fff"}
          fontSize='xs'>
          Choose Campaign
        </FormLabel>
        <Select
          onChange={handleChange}
          name="campaign"
          variant="borderless"
          value={number.campaign}
        >
          <option value=''>Choose Campaign</option>
          {campaigns.length > 0 ? campaigns.map((item, index) =>
            <option value={item._id} key={index}>{item.name}</option>
          )
            : <option value=''>Not Found</option>
          }
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel
          fontWeight='bold'
          color={"#fff"}
          fontSize='xs'>
          Choose Publisher
        </FormLabel>
        <Select
          onChange={handleChange}
          name="publisher"
          variant="borderless"
          value={number.publisher}
        >
          <option value=''>Choose Publisher</option>
          {publishers.length > 0 ? publishers.map((item, index) =>
            <option value={item._id} key={index}>{item.name}</option>
          )
            : <option value=''>Not Found</option>
          }
        </Select>
      </FormControl>
      <Button
        variant='formButton'
        onClick={edit ? editNumber : createNumber}
      >
        { edit ? "Update" : "Create"}
      </Button>
  </Flex>
  );
};

export default NewNumber;
