/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState, useMemo } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Select,
  Text,
} from "@chakra-ui/react";

// Custom components//
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
import SearchTable1 from 'components/Tables/SearchTable1';
import toast from "react-hot-toast";
import axios from 'axios';
import packageJson from "../../../appConfig.js";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import ExportToExcelButton from "components/ExportExcel/ExportToExcelButton";
import ContextSwitcher from 'components/Navbars/ContextSwitcher';
import Loading from 'components/Loading';
import { caseInsensitiveSort } from "variables/general.js";

export default function Default() {
  const dateinfo = new Date();
  dateinfo.setDate(dateinfo.getDate() - 14); // Go back 14 days from the current date

  const apiUrl = packageJson.apiurl;

  const [publishers, setPublishers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [timezoneFilter, setTimezoneFilter] = useState([]);
  const [timezoneFilterLoading, setTimezoneFilterLoading] = useState(true);
  const [selectedTimezone, setSelectedTimezone] = useState('0');
  const [callDetails, setCallDetails] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [dateDetails, setDateDetails] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState("");
  const [barChartDataCharts1, setBarChartDataCharts1] = useState();
  const [barChartOptionsCharts1, setBarChartOptionsCharts1] = useState();
  const [showCalander, setShowCalander] = useState("hide");
  const [showCalanderBtnTxt, setShowCalanderBtnTxt] = useState("Click for Date Filter");
  const [startDate, setStartDate] = useState(dateinfo);
  const [endDate, setEndDate] = useState(new Date());
  const [ activeTab, setActiveTab ] = useState(1);

  useEffect(() => {
    if (timezoneFilterLoading) {
      getTimezoneFilterData();
    }
    getChartDetails();
    getPublisherDetails();
    getCampaignDetails();
    let intervalid = setInterval(() => {
      getPublisherDetails();
      getCampaignDetails();
    }, 15000);
    getBuyerDetails();
    getNumberDetails();
    getCallDetails();
    getDateDetails();
    return function cleanup() {
      clearInterval(intervalid);
    };
  }, [refresh]);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
    color: "#3d91ff",
  };

  const handleSelect = (date) => {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  };

  const handleTimezoneChange = (e) => {
    setSelectedTimezone(e.target.value);
    setRefresh(Math.random());
  };

  const getTimezoneFilterData = async () => {
    const result = (await axios.get(apiUrl + "/api/report/timezone/lists")).data;
    setTimezoneFilter(result.data);
    setTimezoneFilterLoading(false);
  };

  const getChartDetails = async () => {
    const loadToast = toast.loading("Loading chart data...");
    const result = (await axios.post(
      apiUrl + "/api/report/call/timeline",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;
    toast.dismiss(loadToast);
    const barChartColumn = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        y: {
          formatter: value => Math.round(value),
        },
        theme: "dark",
      },
      xaxis: {
        categories: result.column,
        show: true,
        labels: {
          show: true,
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        color: "black",
        labels: {
          show: true,
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
          formatter: value => Math.round(value),
        },
      },
      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "solid",
        colors: ["#582CFF"],
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: "40px",
        },
      },
    };

    let chartDetails = [
      {
        name: "Timeline",
        data: result.calls,
      },
    ];
    setBarChartOptionsCharts1(barChartColumn);
    setBarChartDataCharts1(chartDetails);
    setLoadingData(false);
  };

  const standardTotals = {
    incoming: 0,
    live: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    rpc: 0,
    revenue: 0,
    payout: 0,
    profit: 0,
    totalCost: 0,
    isTotalRow: true,
  };

  const calcTotals = (totalRow, items) => {
    items.forEach((item) => {
      totalRow.incoming += item.incoming;
      totalRow.live += item.live;
      totalRow.connected += item.connected;
      totalRow.paid += item.paid;
      totalRow.converted += item.converted;
      totalRow.noConnect += item.noConnect;
      totalRow.duplicate += item.duplicate;
      totalRow.blocked += item.blocked;
      totalRow.rpc += item.rpc;
      totalRow.revenue += item.revenue;
      totalRow.payout += item.payout;
      totalRow.profit += item.profit;
      totalRow.totalCost += item.totalCost;
    });

    // Calculate the total profit as a percentage
    totalRow.profit = (totalRow.profit / items.length).toFixed(2);
    if (isNaN(totalRow.profit)) {
      totalRow.profit = 0.00;
    }

    return totalRow;
  };

  const getPublisherDetails = async () => {
    const result = (await axios.post(
      apiUrl + "/api/report/publisher/call/details",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;

    const totalRow = {
      publisher: "Total",
      ...standardTotals,
    };

    result.push(calcTotals(totalRow, result));
    setPublishers(result);
  };

  const getBuyerDetails = async () => {
    const result = (await axios.post(
      apiUrl + "/api/report/target/call/details",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;

    const totalRowTarget = {
      buyer: "Total",
      ...standardTotals,
    };

    result.push(calcTotals(totalRowTarget, result));
    setBuyers(result);
  };

  const getCampaignDetails = async () => {
    const result = (await axios.post(
      apiUrl + "/api/report/campaign/call/details",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;

    const totalRowCampaign = {
      campaign: "Total",
      ...standardTotals,
    };

    result.push(calcTotals(totalRowCampaign, result));
    setCampaign(result);
  };

  const getNumberDetails = async () => {
    const result = (await axios.post(
      apiUrl + "/api/report/number/details",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;

    const totalRowNumber = {
      twilioNumber: "Total",
      ...standardTotals,
    };

    result.push(calcTotals(totalRowNumber, result));
    setNumbers(result);
  };

  const getDateDetails = async () => {
    const result = (await axios.post(
      apiUrl + "/api/report/date/details",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;

    const totalRowDate = {
      date: "Total",
      ...standardTotals,
    };

    result.push(calcTotals(totalRowDate, result));

    setDateDetails(result);
  };

  const getCallDetails = async () => {
    const loadToast = toast.loading("Loading calls...");
    const result = (await axios.post(
      apiUrl + "/api/report/call/summary",
      { startDate, endDate, timezoneOffset: selectedTimezone }
    )).data;
    setCallDetails(result);
    toast.dismiss(loadToast);
  };

  const viewCalanderHandle = (e) => {
    if (showCalander == "hide") {
      setShowCalander("show");
    } else {
      setShowCalander("hide");
    }
  };

  const handleApply = (e) => {
    setShowCalanderBtnTxt(
      startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
    );
    setShowCalander("hide");
    setLoadingData(true);
    setRefresh(Math.random());
  };

  const handleCancel = (e) => {
    setShowCalander("hide");
  };

  const usd = new Intl.NumberFormat('en-US', { style: 'currency', currency: 'USD' });

  const standardColumns = [
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    {
      Header: "RPC",
      accessor: "rpc",
    },
    {
      Header: "REVENUE",
      accessor: "revenue",
      Cell: c => <>{usd.format(c.value)}</>,
    },
    {
      Header: "PAYOUT",
      accessor: "payout",
      Cell: c => <>{usd.format(c.value)}</>,
    },
    {
      Header: "PROFIT",
      accessor: "profit",
      Cell: c => <>{c.value}%</>,
    },
    {
      Header: "TOTALCOST",
      accessor: "totalCost",
      Cell: c => <>{usd.format(c.value)}</>,
    },
  ];

  // for publisher
  const publisher_column = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "PUBLISHER",
      accessor: "publisher",
      sortType: (p, c, i) => {
        return caseInsensitiveSort(p, c, i);
      },
    },
    ...standardColumns,
  ];

  const publisherHead = useMemo(() => publisher_column, []);
  const data1 = useMemo(() => publishers, [publishers]);

  const publisherTable = useTable(
    {
      columns: publisherHead,
      data: data1,
    },
    useSortBy
  );
  const row1 = publisherTable.rows;

  // buyers table
  const buyers_column = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "TARGET",
      accessor: "buyer",
      sortType: (p, c, i) => {
        return caseInsensitiveSort(p, c, i);
      },
    },
    ...standardColumns,
  ];
  const buyerHead = useMemo(() => buyers_column, []);
  const data2 = useMemo(() => buyers, [buyers]);

  const buyerTable = useTable(
    {
      columns: buyerHead,
      data: data2,
    },
    useSortBy
  );
  const row2 = buyerTable.rows;

  // for campaign table
  const campaign_column = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "CAMPAIGN",
      accessor: "campaign",
      sortType: (p, c, i) => {
        return caseInsensitiveSort(p, c, i);
      },
    },
    ...standardColumns,
  ];
  const campaignHead = useMemo(() => campaign_column, []);
  const data3 = useMemo(() => campaign, [campaign]);

  const campaignTable = useTable(
    {
      columns: campaignHead,
      data: data3,
    },
    useSortBy
  );
  const row3 = campaignTable.rows;

  // For Number Tab
  const number_column = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Number",
      accessor: "twilioNumber",
    },
    ...standardColumns,
  ];
  const numberHead = useMemo(() => number_column, []);
  const data4 = useMemo(() => numbers, [numbers]);
  const numberTable = useTable(
    {
      columns: numberHead,
      data: data4,
    },
    useSortBy
  );
  const row4 = numberTable.rows;

  // for Date table
  const date_column = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "DATE",
      accessor: "date",
    },
    ...standardColumns,
  ];
  const dateHead = useMemo(() => date_column, []);
  const data5 = useMemo(() => dateDetails, [dateDetails]);
  const dateTable = useTable(
    {
      columns: dateHead,
      data: data5,
    },
    useSortBy
  );
  const row5 = dateTable.rows;

  // calldetails
  const callDetails_column = [
    {
      Header: "CALL DATES",
      accessor: "callDate",
      Cell: ({ value }) => {
        const date = new Date(value);
        const month = date.toLocaleString("en-US", { month: "short" });
        const day = date.toLocaleString("en-US", { day: "2-digit" });
        const time = date.toLocaleString("en-US", {
          hour: "numeric",
          minute: "numeric",
          second: "numeric",
          hour12: true,
        });
        return <span>{`${month} ${day}  ${time}`}</span>;
      },
    },
    {
      Header: "CAMPAIGN",
      accessor: "campaign",
      sortType: (p, c, i) => {
        return caseInsensitiveSort(p, c, i);
      },
    },
    {
      Header: "PUBLISHER",
      accessor: "publisher",
      sortType: (p, c, i) => {
        return caseInsensitiveSort(p, c, i);
      },
    },
    {
      Header: "CALLER ID",
      accessor: "callerId",
    },
    {
      Header: "DIALED #",
      accessor: "dialed",
    },
    {
      Header: "Target No.",
      accessor: "targetNo",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "TARGET",
      accessor: "target",
    },
    {
      Header: "DURATION",
      accessor: "callDurations",
    },
  ];

  const callDetailsHead = useMemo(() => callDetails_column, []);
  const callDetailsData = useMemo(() => callDetails || [], [callDetails]);
  const callDetailsTable = useTable(
    {
      columns: callDetailsHead,
      data: callDetailsData,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps: getTablePropsCallDetails,
    getTableBodyProps: getTableBodyPropsCallDetails,
    headerGroups: headerGroupsCallDetails,
    rows: rowCallDetails,
    prepareRow: prepareRowCallDetails,
    page,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = callDetailsTable;

  return (
    <Flex flexDirection="column">
    <Card>
      <CardHeader>
      <Text color="#fff" fontSize="xl" mb="5px" width="50%">
        All Reports & Charts
      </Text>
      <Flex
        className="topSelector"
        style={{ background: "transparent" }}
      >
        <Select visibility={timezoneFilterLoading ? 'hidden' : 'visible'} size="sm" sx={{ position: 'relative', right: '35px' }}
          maxWidth="300px" variant="brand" borderRadius="0" onChange={handleTimezoneChange}
        >
          {timezoneFilter.map((item, index) => (
            <option value={item.offset} key={index}>{item.text}</option>
          ))}
        </Select>
        <div style={{ position: "relative" }}>
          <div className="dropdown customDrop" style={{ border: "none" }}>
            <a
              href=""
              className="js-link"
              onClick={(e) => {
                e.preventDefault();
                viewCalanderHandle();
              }}
            >
              {showCalanderBtnTxt} <i className="fa fa-chevron-down" style={{ color: 'black' }}></i>
            </a>
          </div>
          <div className={`datePick ${showCalander}`}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
              // locale={enGb}
            />
            <div className="applyBtn">
              <button className="cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button onClick={handleApply}>Apply</button>
            </div>
          </div>
        </div>
      </Flex>
      </CardHeader>
        <CardHeader ml="15px">
          <Text color="#fff" fontSize="xs">
            Timeline
          </Text>
        </CardHeader>
        <CardBody h="100%">
          <Box w="100%" h="300px">
            {loadingData ? (
              <Loading />
            ) : (
              <BarChart
                chartData={barChartDataCharts1}
                chartOptions={barChartOptionsCharts1}
              />
            )}
          </Box>
        </CardBody>
        <CardHeader mt="45px">
          <Flex
            direction="column"
            alignSelf="flex-start"
          >
            <Text fontSize="lg" color="#fff">
              Report Summary
            </Text>
          </Flex>
        </CardHeader>
      <CardBody>

      <div className="tabs_new" style={{ width: "100%" }}>
        <ContextSwitcher
          onClick={setActiveTab}
          activeTab={activeTab}
          options={[
            { label: 'Campaign' },
            { label: 'Publishers' },
            { label: 'Targets' },
            { label: 'Numbers' },
            { label: 'Date' },
          ]}
        />
        <div className="wapr_tab-black">
          {activeTab === 1 && <div className={`w3-container city`}>
            {/* Campaign Summary Reports*/}
              <CardHeader
                mb="10px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <ExportToExcelButton
                    columns={campaign_column}
                    data={row3}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <SearchTable1 tableData={campaign} columnsData={campaign_column} />
              </CardBody>
          </div>}

          {activeTab === 2 && <div className={`w3-container city`}>
            {/* Publisher Summary Reports*/}
              <CardHeader
                mb="10px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <ExportToExcelButton
                    columns={publisher_column}
                    data={row1}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <SearchTable1 tableData={publishers} columnsData={publisher_column} />
              </CardBody>
          </div>}

          {activeTab === 3 && <div id="tab-3" className={`w3-container city`}>
            {/* Targets Summary Reports*/}
              <CardHeader
                mb="10px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <ExportToExcelButton
                    columns={buyers_column}
                    data={row2}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <SearchTable1 tableData={buyers} columnsData={buyers_column} />
              </CardBody>
          </div>}

          {activeTab === 4 && <div className={`w3-container city`}>
            {/* Number Summary Reports*/}
              <CardHeader
                mb="10px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <ExportToExcelButton
                    columns={number_column}
                    data={row4}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <SearchTable1 tableData={numbers} columnsData={number_column} />
              </CardBody>
          </div>}
          {activeTab === 5 && <div className={`w3-container city`}>
              <CardHeader
                mb="10px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <div>
                  <ExportToExcelButton
                    columns={date_column}
                    data={row5}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <SearchTable1 tableData={dateDetails} columnsData={date_column} />
              </CardBody>
          </div>}
        </div>
      </div>
      </CardBody>

        <CardHeader mb="20px" w="100%" style={{justifyContent: 'space-between'}}>
          <Text fontSize="lg" color="#fff" pt='65px' width="50%">
            Call Logs
          </Text>
        </CardHeader>

        <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
          <SearchTable1 tableData={callDetails} columnsData={callDetails_column} />
        </CardBody>
      </Card>
    </Flex>
  );
}
