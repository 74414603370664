/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState, useMemo } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Grid,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  useColorModeValue,
  Button,
  Input,
} from "@chakra-ui/react";

// Custom components//
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
//import Globe from "components/Globe/Globe";
import IconBox from "components/Icons/IconBox";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import packageJson from "../../../appConfig.js";
import { DateRangePicker } from "react-date-range";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import ExportToExcelButton from "components/ExportExcel/ExportToExcelButton";
import { format } from "date-fns";

const token = localStorage?.getItem("token");
const user = JSON.parse(localStorage?.getItem("user"));
export default function Default() {
  const dateinfo = new Date();
  //var firstDay = new Date(dateinfo.getFullYear(), dateinfo.getMonth(), 1);
  //var lastDay = new Date(dateinfo.getFullYear(), dateinfo.getMonth() + 1, 0);
  // const first = dateinfo.getDate() - dateinfo.getDay(); // First day is the day of the month - the day of the week
  // const last = first + 6; // last day is the first day + 6
  // const last = dateinfo.getDate();
  // const first = last - 6;
  // const dateinfo = new Date(); // Get the current date
  dateinfo.setDate(dateinfo.getDate() - 7); // Go back 7 days from the current date
  const first = dateinfo.getDate() - dateinfo.getDay(); // First day is the day of the month - the day of the week
  const last = first + 6; // Last day is the first day + 6
  const apiUrl = packageJson.apiurl;
  const [publishers, setPublishers] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [numbers, setNumbers] = useState([]);
  const [directForward, setDirectForward] = useState([]);
  const [campaign, setCampaign] = useState([]);
  const [userFilter, setUserFilter] = useState([]);
  const [dateDetails,setDateDetails] = useState([]);
  const [timezoneFilter, setTimezoneFilter] = useState([]); //
  const [timezoneFilterLoading, setTimezoneFilterLoading] = useState(true);
  const [userFilterValue, setUserFilterValue] = useState("");
  const [userFilterLoading, setUserFilterLoading] = useState(true);
  const [callDetails, setCallDetails] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState("");
  const [barChartDataCharts1, setBarChartDataCharts1] = useState();
  const [barChartOptionsCharts1, setBarChartOptionsCharts1] = useState();
  const [showCalander, setShowCalander] = useState("hide");
  const [showCalanderBtnTxt, setShowCalanderBtnTxt] = useState(
    "Click for Date Filter"
  );

  const endDate1 = new Date(); // Current date
  const startDate1 = new Date(endDate1);
  startDate1.setDate(endDate1.getDate() - 7); // 7 days ago
  
  const [startDate, setStartDate] = useState(startDate1);
  const [endDate, setEndDate] = useState(endDate1);
  const [tab1, setTab1] = useState("show");
  const [tab2, setTab2] = useState("hide");
  const [tab3, setTab3] = useState("hide");
  const [tab4, setTab4] = useState("hide");
  const [tab5, setTab5] = useState("hide");
  const [tabMenu1, setTabMenu1] = useState("active");
  const [tabMenu2, setTabMenu2] = useState("");
  const [tabMenu3, setTabMenu3] = useState("");
  const [tabMenu4, setTabMenu4] = useState("");
  const [tabMenu5, setTabMenu5] = useState("");
  useEffect(() => {
    if (userFilterLoading) {
      getUserFilterData();
    }
    if (timezoneFilterLoading) {
      getTimezoneFilterData();
    }
    if (loadingData) {
      getChartDetails();
    }
    getPublisherDetails();
    getCampaignDetails();
    let intervalid = setInterval(() => {
      getPublisherDetails();
      getCampaignDetails();
    }, 3000);
    getBuyerDetails();
    // getDirectForwardDetails();
    getCallDetails();
    getNumberDetails();
    getDateDetails();

    return function cleanup() {
      clearInterval(intervalid);
    };
  }, [refersh]);

  const selectionRange = {
    startDate: startDate,
    endDate: endDate,
    key: "selection",
    color: "#3d91ff",
  };
  const handleSelect = (date) => {
    setStartDate(date.selection.startDate);
    setEndDate(date.selection.endDate);
  };

  const getUserFilterData = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    let result = await fetch(
      apiUrl + "/api/admin/report/users/filter/lists",
      requestOptions
    );
    result = await result.json();
    setUserFilter(result);
    setUserFilterLoading(false);
  };

  const getTimezoneFilterData = async () => {
    let result = await fetch(apiUrl + "/api/report/timezone/lists");
    result = await result.json();
    setTimezoneFilter(result.data);
    setTimezoneFilterLoading(false);
  };

  const userFilterChnage = (e) => {
    const { name, value } = e.target;
    setUserFilterValue(value);
    setLoadingData(true);
    refershSet(Math.random());
  };

  const getChartDetails = async () => {
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        by: user.id,
        userFilter: userFilterValue,
        startDate: startDate,
        endDate: endDate,
      }),
    };
    let result = await fetch(
      apiUrl + "/api/admin/report/call/timeline",
      requestOptions
    );
    result = await result.json();
    toast.dismiss(loadToast);
    const barChartColumn = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      tooltip: {
        style: {
          fontSize: "12px",
          fontFamily: undefined,
        },
        onDatasetHover: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
        },
        theme: "dark",
      },
      xaxis: {
        categories: result.column,
        show: true,
        labels: {
          show: true,
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
      yaxis: {
        show: true,
        color: "black",
        labels: {
          show: true,
          style: {
            colors: "#A0AEC0",
            fontSize: "10px",
          },
        },
      },
      grid: {
        strokeDashArray: 5,
        borderColor: "#56577A",
        yaxis: {
          lines: {
            show: true,
          },
        },
        xaxis: {
          lines: {
            show: false,
          },
        },
      },
      fill: {
        type: "solid",
        colors: ["#582CFF"],
      },
      dataLabels: {
        enabled: false,
      },
      plotOptions: {
        bar: {
          borderRadius: 8,
          columnWidth: "40px",
        },
      },
    };

    let chartDetails = [
      {
        name: "Timeline",
        data: result.calls,
      },
    ];
    setBarChartOptionsCharts1(barChartColumn);
    setBarChartDataCharts1(chartDetails);
    setLoadingData(false);
  };

  const getPublisherDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: user.id,
        startDate: startDate,
        endDate: endDate,
      }),
    };

    let result = await fetch(
      apiUrl + "/api/admin/report/publisher/call/details",
      requestOptions
    );
    result = await result.json();
    setPublishers(result);
  }; //
  const getBuyerDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: user.id,
        startDate: startDate,
        endDate: endDate,
      }),
    };

    let result = await fetch(
      apiUrl + "/api/admin/report/target/call/details",
      requestOptions
    );
    result = await result.json();
    setBuyers(result);
  };

  const getCampaignDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: user.id,
        startDate: startDate,
        endDate: endDate,
      }),
    };
    let result = await fetch(
      apiUrl + "/api/admin/report/campaign/call/details",
      requestOptions
    );
    result = await result.json();
    setCampaign(result);
  };
  
  const getDirectForwardDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };

    let result = await fetch(
      apiUrl + "/api/admin/report/direct/forward",
      requestOptions
    );
    result = await result.json();
    setDirectForward(result);
  };

  const getCallDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        userFilter: userFilterValue,
        startDate: startDate,
        endDate: endDate,
        id: user.id,
      }),
    };
    let result = await fetch(
      apiUrl + "/api/admin/report/call/summary",
      requestOptions
    );
    result = await result.json();
    console.log(result+"sdsd");
    setCallDetails(result);
  };
  const getNumberDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: user.id,
        startDate: startDate,
        endDate: endDate,
      }),
    };

    let result = await fetch(
      apiUrl + "/api/admin/report/number/details",
      requestOptions
    );
    result = await result.json();
    setNumbers(result);
  };

  const getDateDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        id: user.id,
        startDate: startDate,
        endDate: endDate,
      }),
    };

    let result = await fetch(
      apiUrl + "/api/admin/report/date/details",
      requestOptions
    );
    result = await result.json();
    setDateDetails(result);
  };


  const viewCalanderHandle = (e) => {
    if (showCalander == "hide") {
      setShowCalander("show");
    } else {
      setShowCalander("hide");
    }
  };

  const handleApply = (e) => {
    setShowCalanderBtnTxt(
      startDate.toLocaleDateString() + "-" + endDate.toLocaleDateString()
    );
    setShowCalander("hide");
    setLoadingData(true);
    refershSet(Math.random());
  };

  const handleCancel = (e) => {
    setShowCalander("hide");
  };

  const tabHandle = (e) => {
    console.log(e.target.getAttribute("data-tab"));
    let tab = e.target.getAttribute("data-tab");
    if (tab == "tab-1") {
      setTab1("show");
      setTab2("hide");
      setTab3("hide");
      setTab4("hide");
      setTab5("hide");
      setTabMenu1("active");
      setTabMenu2("");
      setTabMenu3("");
      setTabMenu4("");
      setTabMenu5("");
    } else if (tab == "tab-2") {
      setTab1("hide");
      setTab2("show");
      setTab3("hide");
      setTab4("hide");
      setTab5("hide");
      setTabMenu1("");
      setTabMenu2("active");
      setTabMenu3("");
      setTabMenu4("");
      setTabMenu5("");
    } else if (tab == "tab-3") {
      setTab1("hide");
      setTab2("hide");
      setTab3("show");
      setTab4("hide");
      setTab5("hide");
      setTabMenu1("");
      setTabMenu2("");
      setTabMenu3("active");
      setTabMenu4("");
      setTabMenu5("");
    } else if (tab == "tab-4") {
      setTab1("hide");
      setTab2("hide");
      setTab3("hide");
      setTab4("show");
      setTab5("hide");
      setTabMenu1("");
      setTabMenu2("");
      setTabMenu3("");
      setTabMenu4("active");
      setTabMenu5("");
    } else {
      setTab1("hide");
      setTab2("hide");
      setTab3("hide");
      setTab4("hide");
      setTab5("show");
      setTabMenu1("");
      setTabMenu2("");
      setTabMenu3("");
      setTabMenu4("");
      setTabMenu5("active");
    }
  };
  // new changes
  const formatTimeToACLAndTCL = (timeString) => {
    if (timeString) {
      const timeParts = timeString.split(':');
      if (timeParts.length === 3) {
        // Convert the time to seconds
        const seconds = parseInt(timeParts[0]) * 3600 + parseInt(timeParts[1]) * 60 + parseFloat(timeParts[2]);
        // Format the time to "00:00:00"
        const formattedTime = new Date(seconds * 1000).toISOString().substr(11, 8);
        return formattedTime;
      }
    }
    return timeString;
  };
  // for publisher
  const totalRow = {
    publisher: "Total",
    incoming: 0,
    live: 0,
    // completed: 0,
    // ended: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    //rpc: 0,
    //revenue: 0,
    //payout: 0,
    //profit: 0,
    // margin: 0,
    // totalCost: 0,
  };
  publishers.forEach((item) => {
    totalRow.incoming += item.incoming;
    totalRow.live += item.live;
    // totalRow.completed += item.completed;
    // totalRow.ended += item.ended;
    totalRow.connected += item.connected;
    totalRow.paid += item.paid;
    totalRow.converted += item.converted;
    totalRow.noConnect += item.noConnect;
    totalRow.duplicate += item.duplicate;
    totalRow.blocked += item.blocked;
    // totalRow.rpc += item.rpc;
    // totalRow.revenue += item.revenue;
    // totalRow.payout += item.payout;
    // totalRow.profit += item.profit;
    // totalRow.margin += item.margin;
    // totalRow.totalCost += item.totalCost;
  });

  // Calculate the total profit as a percentage
  // totalRow.profit = (totalRow.profit / publishers.length).toFixed(2);

  // Calculate the total margin as a percentage
  // totalRow.margin = (totalRow.margin / publishers.length).toFixed(2);
  publishers.push(totalRow);

  const publisher_column = [
    {
      Header: "PUBLISHER",
      accessor: "publisher",
    },
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    // {
    //   Header: "COMPLETED",
    //   accessor: "completed",
    // },
    // {
    //   Header: "ENDED",
    //   accessor: "ended",
    // },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    // {
    //   Header: "RPC",
    //   accessor: "rpc",
    // },
    // {
    //   Header: "REVENUE",
    //   accessor: "revenue",
    // },
    // {
    //   Header: "PAYOUT",
    //   accessor: "payout",
    // },
    // {
    //   Header: "PROFIT",
    //   accessor: "profit",
    // },
    // {
    //   Header: "MARGIN",
    //   accessor: "margin",
    // },
    {
      Header: "TCL",
      accessor: (row) => formatTimeToACLAndTCL(row.tcl),
    },
    {
      Header: "ACL",
      accessor: (row) => formatTimeToACLAndTCL(row.acl),
    },
    // {
    //   Header: "TOTALCOST",
    //   accessor: "totalCost",
    // },
  ];
  const PublisherHead = useMemo(() => publisher_column, []);
  const data1 = useMemo(() => publishers, [publishers]);

  const PublisherTable = useTable(
    {
      columns: PublisherHead,
      data: data1,
    },
    useSortBy
  );
  const {
    getTableProps: getTableProps1,
    getTableBodyProps: getTableBodyProps1,
    headerGroups: headerGroups1,
    rows: row1,
    prepareRow: prepareRow1,
  } = PublisherTable;

  // for target
  const totalRowTarget = {
    buyer: "Total",
    incoming: 0,
    live: 0,
    // completed: 0,
    // ended: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    // rpc: 0,
    // revenue: 0,
    // payout: 0,
    // profit: 0,
    // margin: 0,
    // totalCost: 0,
  };

  buyers.forEach((item) => {
    totalRowTarget.incoming += item.incoming;
    totalRowTarget.live += item.live;
    // totalRowTarget.completed += item.completed;
    // totalRowTarget.ended += item.ended;
    totalRowTarget.connected += item.connected;
    totalRowTarget.paid += item.paid;
    totalRowTarget.converted += item.converted;
    totalRowTarget.noConnect += item.noConnect;
    totalRowTarget.duplicate += item.duplicate;
    totalRowTarget.blocked += item.blocked;
    // totalRowTarget.rpc += item.rpc;
    // totalRowTarget.revenue += item.revenue;
    // totalRowTarget.payout += item.payout;
    // totalRowTarget.profit += item.profit;
    // totalRowTarget.margin += item.margin;
    // totalRowTarget.totalCost += item.totalCost;
  });

  // Calculate the total profit as a percentage
  // totalRowTarget.profit = (totalRowTarget.profit / buyers.length).toFixed(2);

  // Calculate the total margin as a percentage
  // totalRowTarget.margin = (totalRowTarget.margin / buyers.length).toFixed(2);

  buyers.push(totalRowTarget);

  const buyers_column = [
    {
      Header: "TARGET",
      accessor: "buyer",
    },
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    // {
    //   Header: "COMPLETED",
    //   accessor: "completed",
    // },
    // {
    //   Header: "ENDED",
    //   accessor: "ended",
    // },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    // {
    //   Header: "RPC",
    //   accessor: "rpc",
    // },
    // {
    //   Header: "REVENUE",
    //   accessor: "revenue",
    // },
    // {
    //   Header: "PAYOUT",
    //   accessor: "payout",
    // },
    // {
    //   Header: "PROFIT",
    //   accessor: "profit",
    // },
    // {
    //   Header: "MARGIN",
    //   accessor: "margin",
    // },
    {
      Header: "TCL",
      accessor: (row) => formatTimeToACLAndTCL(row.tcl),
    },
    {
      Header: "ACL",
      accessor: (row) => formatTimeToACLAndTCL(row.acl),
    },
    // {
    //   Header: "TOTALCOST",
    //   accessor: "totalCost",
    // },
  ];
  const buyerHead = useMemo(() => buyers_column, []);
  const data2 = useMemo(() => buyers, [buyers]);

  const buyerTable = useTable(
    {
      columns: buyerHead,
      data: data2,
    },
    useSortBy
  );
  const {
    getTableProps: getTableProps2,
    getTableBodyProps: getTableBodyProps2,
    headerGroups: headerGroups2,
    rows: row2,
    prepareRow: prepareRow2,
  } = buyerTable;

  // campaingn table

  const totalRowCampaign = {
    campaign: "Total",
    incoming: 0,
    live: 0,
    // completed: 0,
    // ended: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    // rpc: 0,
    // revenue: 0,
    // payout: 0,
    // profit: 0,
    // margin: 0,
    // totalCost: 0,
  };

  campaign.forEach((item) => {
    totalRowCampaign.incoming += item.incoming;
    totalRowCampaign.live += item.live;
    // totalRowCampaign.completed += item.completed;
    // totalRowCampaign.ended += item.ended;
    totalRowCampaign.connected += item.connected;
    totalRowCampaign.paid += item.paid;
    totalRowCampaign.converted += item.converted;
    totalRowCampaign.noConnect += item.noConnect;
    totalRowCampaign.duplicate += item.duplicate;
    totalRowCampaign.blocked += item.blocked;
    // totalRowCampaign.rpc += item.rpc;
    // totalRowCampaign.revenue += item.revenue;
    // totalRowCampaign.payout += item.payout;
    // totalRowCampaign.profit += item.profit;
    // totalRowCampaign.margin += item.margin;
    // totalRowCampaign.totalCost += item.totalCost;
  });

  // Calculate the total profit as a percentage
  // totalRowCampaign.profit = (totalRowCampaign.profit / campaign.length).toFixed(
  //   2
  // );

  // Calculate the total margin as a percentage
  // totalRowCampaign.margin = (totalRowCampaign.margin / campaign.length).toFixed(
  //   2
  // );




  campaign.push(totalRowCampaign);
  const campaign_column = [
    {
      Header: "CAMPAIGN",
      accessor: "campaign",
    },
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    // {
    //   Header: "COMPLETED",
    //   accessor: "completed",
    // },
    // {
    //   Header: "ENDED",
    //   accessor: "ended",
    // },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    // {
    //   Header: "RPC",
    //   accessor: "rpc",
    // },
    // {
    //   Header: "REVENUE",
    //   accessor: "revenue",
    // },
    // {
    //   Header: "PAYOUT",
    //   accessor: "payout",
    // },
    // {
    //   Header: "PROFIT",
    //   accessor: "profit",
    // },
    {
    Header: "TCL",
    accessor: (row) => formatTimeToACLAndTCL(row.tcl),
  },
  {
    Header: "ACL",
    accessor: (row) => formatTimeToACLAndTCL(row.acl),
  },
    // {
    //   Header: "TOTALCOST",
    //   accessor: "totalCost",
    // },
  ];
  const campaingnHead = useMemo(() => campaign_column, []);
  const data3 = useMemo(() => campaign, [campaign]);

  const campaingnTable = useTable(
    {
      columns: campaingnHead,
      data: data3,
    },
    useSortBy
  );
  const {
    getTableProps: getTableProps3,
    getTableBodyProps: getTableBodyProps3,
    headerGroups: headerGroups3,
    rows: row3,
    prepareRow: prepareRow3,
  } = campaingnTable;

  // for Number

  const totalRowNumber = {
    twilioNumber: "Total",
    incoming: 0,
    live: 0,
    // completed: 0,
    // ended: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    // rpc: 0,
    // revenue: 0,
    // payout: 0,
    // profit: 0,
    // margin: 0,
    // totalCost: 0,
  };

  numbers.forEach((item) => {
    totalRowNumber.incoming += item.incoming;
    totalRowNumber.live += item.live;
    // totalRowNumber.completed += item.completed;
    // totalRowNumber.ended += item.ended;
    totalRowNumber.connected += item.connected;
    totalRowNumber.paid += item.paid;
    totalRowNumber.converted += item.converted;
    totalRowNumber.noConnect += item.noConnect;
    totalRowNumber.duplicate += item.duplicate;
    totalRowNumber.blocked += item.blocked;
    // totalRowNumber.rpc += item.rpc;
    // totalRowNumber.revenue += item.revenue;
    // totalRowNumber.payout += item.payout;
    // totalRowNumber.profit += item.profit;
    // totalRowNumber.margin += item.margin;
    // totalRowNumber.totalCost += item.totalCost;
  });

  // Calculate the total profit as a percentage
  // totalRowNumber.profit = (totalRowNumber.profit / numbers.length).toFixed(2);

  // Calculate the total margin as a percentage
  // totalRowNumber.margin = (totalRowNumber.margin / numbers.length).toFixed(2);

  numbers.push(totalRowNumber);
  const Number_column = [
    {
      Header: "Number",
      accessor: "twilioNumber",
    },
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    // {
    //   Header: "COMPLETED",
    //   accessor: "completed",
    // },
    // {
    //   Header: "ENDED",
    //   accessor: "ended",
    // },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    // {
    //   Header: "RPC",
    //   accessor: "rpc",
    // },
    // {
    //   Header: "REVENUE",
    //   accessor: "revenue",
    // },
    // {
    //   Header: "PAYOUT",
    //   accessor: "payout",
    // },
    // {
    //   Header: "PROFIT",
    //   accessor: "profit",
    // },
    // {
    //   Header: "MARGIN",
    //   accessor: "margin",
    // },
    // {
    //   Header: "TOTALCOST",
    //   accessor: "totalCost",
    // },
  ];
  const numberHead = useMemo(() => Number_column, []);
  const data4 = useMemo(() => numbers, [numbers]);
  const numberTable = useTable(
    {
      columns: numberHead,
      data: data4,
    },
    useSortBy
  );
  const {
    getTableProps: getTableProps4,
    getTableBodyProps: getTableBodyProps4,
    headerGroups: headerGroups4,
    rows: row4,
    prepareRow: prepareRow4,
  } = numberTable;

   // for Date table

   const totalRowDate = {
    date: "Total",
    incoming: 0,
    live: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    // rpc: 0,
    // revenue: 0,
    // payout: 0,
    // profit: 0,
    // totalCost: 0,
  };

  dateDetails.forEach((item) => {
    totalRowDate.incoming += item.incoming;
    totalRowDate.live += item.live;
    totalRowDate.connected += item.connected;
    totalRowDate.paid += item.paid;
    totalRowDate.converted += item.converted;
    totalRowDate.noConnect += item.noConnect;
    totalRowDate.duplicate += item.duplicate;
    totalRowDate.blocked += item.blocked;
    // totalRowDate.rpc += item.rpc;
    // totalRowDate.revenue += item.revenue;
    // totalRowDate.payout += item.payout;
    // totalRowDate.profit += item.profit;
    // totalRowDate.totalCost += item.totalCost;
  });

  // Calculate the total profit as a percentage
  // totalRowDate.profit = (totalRowDate.profit / dateDetails.length).toFixed(2);
  dateDetails.push(totalRowDate);

  const Date_column = [
    {
      Header: "DATE",
      accessor: "date",
    },
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    // {
    //   Header: "RPC",
    //   accessor: "rpc",
    // },
    // {
    //   Header: "REVENUE",
    //   accessor: "revenue",
    // },
    // {
    //   Header: "PAYOUT",
    //   accessor: "payout",
    // },
    // {
    //   Header: "PROFIT",
    //   accessor: "profit",
    // },
    // {
    //   Header: "TOTALCOST",
    //   accessor: "totalCost",
    // },
  ];
  const DateHead = useMemo(() => Date_column, []);
  const data5 = useMemo(() => dateDetails, [dateDetails]);
  const DateTable = useTable(
    {
      columns: DateHead,
      data: data5,
    },
    useSortBy
  );
  const {
    getTableProps: getTableProps5,
    getTableBodyProps: getTableBodyProps5,
    headerGroups: headerGroups5,
    rows: row5,
    prepareRow: prepareRow5,
  } = DateTable;


  // .............................................
  // callDetails table

  // const callDetails_column = [
  //   {
  //     Header: "CALL DATES",
  //     accessor: "callDate",
  //     Cell: ({ value }) => {
  //       const date = new Date(value);
  //     const month = date.toLocaleString("en-US", { month: "short" });
  //     const day = date.toLocaleString("en-US", { day: "2-digit" });
  //     const time = date.toLocaleString("en-US", {
  //       hour: "numeric",
  //       minute: "numeric",
  //       second: "numeric",
  //       hour12: true,
  //     });
  //     return <span>{`${month} ${day}  ${time}`}</span>; },
  //   },
  //   {
  //     Header: "CAMPAIGN",
  //     accessor: "campaign",
  //   },
  //   {
  //     Header: "PUBLISHER",
  //     accessor: "publisher",
  //   },
  //   {
  //     Header: "CALLER ID",
  //     accessor: "callerId",
  //   },
  //   {
  //     Header: "DIALED #",
  //     accessor: "dialed",
  //   },
  //   {
  //     Header: "Target No.",
  //     accessor: "targetNo",
  //   },
  //   {
  //     Header: "CALL STATUS",
  //     accessor: "status",
  //   },
  //   {
  //     Header: "DUPLICATE",
  //     accessor: "duplicate",
  //   },
  //   {
  //     Header: "TARGET",
  //     accessor: "target",
  //   },
  //   {
  //     Header: "DURATION",
  //     accessor: "callDurations",
  //   },
  // ];

  // const callDetailsHead = useMemo(() => callDetails_column, []);
  // const callDetailsData = useMemo(() => callDetails || [], [callDetails]);
  // const callDetailsTable = useTable(
  //   {
  //     columns: callDetailsHead,
  //     data: callDetailsData,
  //     initialState: { pageIndex: 0, pageSize: 50 },
  //   },
  //   useGlobalFilter,
  //   useSortBy,
  //   usePagination
  // );

  // const {
  //   getTableProps: getTablePropsCallDetails,
  //   getTableBodyProps: getTableBodyPropsCallDetails,
  //   headerGroups: headerGroupsCallDetails,
  //   rows: rowCallDetails,
  //   prepareRow: prepareRowCallDetails,
  //   page,
  //   gotoPage,
  //   pageCount,
  //   nextPage,
  //   previousPage,
  //   canNextPage,
  //   canPreviousPage,
  //   setPageSize,
  //   setGlobalFilter,
  //   state: { pageIndex, pageSize, globalFilter },
  // } = callDetailsTable;

  // const createPages = (count) => {
  //   let arrPageCount = [];

  //   for (let i = 1; i <= count; i++) {
  //     arrPageCount.push(i);
  //   }

  //   return arrPageCount;
  // };


  //date format in call log
const formatDate = (value) => {
  const date = new Date(value);
  const month = date.toLocaleString("en-US", { month: "short" });
  const day = date.toLocaleString("en-US", { day: "2-digit" });
  const time = date.toLocaleString("en-US", {
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    hour12: true,
  });
  return `${month} ${day} ${time}`;
};

  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text color="#fff" fontWeight="bold" fontSize="3xl" mb="30px" ps="20px">
        All Reports & Charts
      </Text>
      <Flex className="topSelector" style={{
      background: 'linear-gradient(127.09deg, rgba(6, 11, 40, 0.94) 19.41%, rgba(10, 14, 35, 0.49) 76.65%)',
    }}>
        {/* {userFilterLoading ? (
          <p>Loading Please wait...</p>
        ) : (
          <select
            id="select_user_option"
            onChange={userFilterChnage}
            name="userFilter"
          >
            <option value="">Choose User Options</option>
            <optgroup label="Publisher">
              {userFilter.data[0].publishers.length > 0 ? (
                userFilter.data[0].publishers?.map((item, index) => (
                  <>
                    <option value={item.id}>{item.name}</option>
                  </>
                ))
              ) : (
                <></>
              )}
            </optgroup>
            <optgroup label="Buyers">
              {userFilter.data[0].buyers.length > 0 ? (
                userFilter.data[0].buyers?.map((item, index) => (
                  <>
                    <option value={item.id}>{item.name}</option>
                  </>
                ))
              ) : (
                <></>
              )}
            </optgroup>
          </select>
        )} */}
        {timezoneFilterLoading ? (
          <p>Loading Please wait...</p>
        ) : (
          <select id="select_time_option">
            {" "}
            <i className="fa-solid fa-plus"></i>
            {timezoneFilter.length > 0 ? (
              timezoneFilter?.map((item, index) => (
                <>
                  <option value={item.value}>{item.text}</option>
                </>
              ))
            ) : (
              <></>
            )}
          </select>
        )}
        <div style={{ position: "relative" }}>
          <div className="dropdown customDrop" style={{border:"none"}}>
            <a
              href=""
              className="js-link"
              onClick={(e) => {
                e.preventDefault();
                viewCalanderHandle();
              }}
            >
              {showCalanderBtnTxt} <i className="fa fa-chevron-down"></i>
            </a>
          </div>
          <div className={`datePick ${showCalander}`}>
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={handleSelect}
            />
            <div className="applyBtn">
              <button className="cancel" onClick={handleCancel}>
                Cancel
              </button>
              <button onClick={handleApply}>Apply</button>
            </div>
          </div>
        </div>
      </Flex>
      <Card px="0px" pb="0px">
        <CardHeader mb="34px" px="22px">
          <Text color="#fff" fontSize="lg" fontWeight="bold">
            Timeline
          </Text>
        </CardHeader>
        <CardBody h="100%">
          <Box w="100%" h="100%">
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <BarChart
                chartData={barChartDataCharts1}
                chartOptions={barChartOptionsCharts1}
              />
            )}
          </Box>
        </CardBody>
      </Card>

      <Card p="28px 0px 0px 0px" style={{ marginTop: "20px" }}>
        <CardHeader mb="20px" ps="22px">
          <Flex
            direction="column"
            alignSelf="flex-start"
            style={{ margin: "0 auto" }}
          >
            <Text fontSize="2xl" color="#fff" fontWeight="bold" mb="6px">
              Reports Summary
            </Text>
          </Flex>
        </CardHeader>
      </Card>

      <div className="tabs_new">
        <div className="w3-bar w3-black">
          <button
            data-tab="tab-1"
            className={`w3-bar-item w3-button ${tabMenu1}`}
            onClick={tabHandle}
          >
            Campaign
          </button>
          <button
            data-tab="tab-2"
            className={`w3-bar-item w3-button ${tabMenu2}`}
            onClick={tabHandle}
          >
            Publishers
          </button>
          <button
            data-tab="tab-3"
            className={`w3-bar-item w3-button ${tabMenu3}`}
            onClick={tabHandle}
          >
            Targets
          </button>
          <button
            data-tab="tab-4"
            className={`w3-bar-item w3-button ${tabMenu4}`}
            onClick={tabHandle}
          >
            Numbers
          </button>
          <button
            data-tab="tab-5"
            className={`w3-bar-item w3-button ${tabMenu5}`}
            onClick={tabHandle}
          >
          Date
          </button>
        </div>
        <div className="wapr_tab-black">
          <div id="tab-1" className={`w3-container city ${tab1}`}>
            {/* Campaign Summary Reports*/}
            <Card px="0px">
              <CardHeader
                px="22px"
                mb="32px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Campaign
                </Text>
                <div>
                  <ExportToExcelButton
                    columns={campaign_column}
                    data={row3}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX="scroll">
                <Table
                  {...getTableProps3()}
                  className="report_custom_table"
                  variant="simple"
                >
                  <Thead>
                    {headerGroups3?.map((headerGroup, index) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup?.headers?.map((column, index) => (
                          <Th
                            // className="publisherName"
                            color="gray.400"
                            fontSize="10px"
                            fontFamily="Inter"
                            borderColor="#56577A"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header")}
                              <Icon
                                w={{ sm: "10px", md: "14px" }}
                                h={{ sm: "10px", md: "14px" }}
                                color={
                                  campaingnHead.isSorted
                                    ? "gray.500"
                                    : "gray.400"
                                }
                                float="right"
                                as={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? TiArrowSortedDown
                                      : TiArrowSortedUp
                                    : TiArrowUnsorted
                                }
                              />
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps3()}>
                    {campaign?.length > 0 ? (
                      row3?.map((row, index) => {
                        prepareRow3(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row?.cells?.map((cell, index) => {
                              const header = headerGroups3[0].headers[index];
                              return (
                                <Td
                                  minW="150px"
                                  borderColor="#56577A"
                                  {...cell.getCellProps()}
                                  key={index}
                                >
                                  <Stack direction="row" spacing="2px">
                                    <Text
                                      className="publisherName"
                                      color="#fff"
                                      fontSize="10px"
                                      fontWeight="bold"
                                      style={{ paddingLeft: "5px !important" }}
                                    >
                                      {cell.render("Cell")}
                                    </Text>
                                  </Stack>
                                </Td>
                              );
                            })}

                            {/* <Td minW="150px" borderColor="#56577A">
                            <Stack direction="row" spacing="2px">
                              <Text
                                className="publisherName"
                                color="#fff"
                                fontSize="10px"
                                fontWeight="bold"
                                style={{ paddingLeft: "5px !important" }}
                              >
                                {item.campaign}
                              </Text>
                            </Stack>
                          </Td>
                          <Td minW="50px" borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.incoming}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.live}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.completed}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.ended}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.connected}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.paid}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.converted}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.noConnect}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.duplicate}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.blocked}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.rpc}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.revenue}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.payout}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.profit}%
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.margin}%
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.totalCost}
                            </Text>
                          </Td> */}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
          <div id="tab-2" className={`w3-container city ${tab2}`}>
            {/* Publisher Summary Reports*/}
            <Card px="0px">
              <CardHeader
                px="22px"
                mb="32px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Publishers
                </Text>
                <div>
                  <ExportToExcelButton
                    columns={publisher_column}
                    data={row1}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX="scroll">
                <Table
                  {...getTableProps1()}
                  className="report_custom_table"
                  variant="simple"
                >
                  <Thead>
                    {headerGroups1?.map((headerGroup, index) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup?.headers?.map((column, index) => (
                          <Th
                            // className="publisherName"
                            color="gray.400"
                            fontSize="10px"
                            fontFamily="Inter"
                            borderColor="#56577A"
                            minW={index === 0 ? "100px" : "auto"}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header")}
                              <Icon
                                w={{ sm: "10px", md: "14px" }}
                                h={{ sm: "10px", md: "14px" }}
                                color={
                                  PublisherHead.isSorted
                                    ? "gray.500"
                                    : "gray.400"
                                }
                                float="right"
                                as={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? TiArrowSortedDown
                                      : TiArrowSortedUp
                                    : TiArrowUnsorted
                                }
                              />
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps1()}>
                    {publishers.length > 0 ? (
                      row1?.map((row, index) => {
                        prepareRow1(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row?.cells?.map((cell, index) => {
                              const header = headerGroups1[0].headers[index];
                              return (
                                <Td
                                  minW="150px"
                                  borderColor="#56577A"
                                  {...cell.getCellProps()}
                                  key={index}
                                >
                                  <Stack direction="row" spacing="2px">
                                    <Text
                                      className="publisherName"
                                      color="#fff"
                                      fontSize="10px"
                                      fontWeight="bold"
                                      style={{ paddingLeft: "5px !important" }}
                                    >
                                   {cell.render("Cell")}
                                    </Text>
                                  </Stack>
                                </Td>
                              );
                            })}
                            {/* <Td minW="150px" borderColor="#56577A">
                            <Stack direction="row" spacing="2px">
                              <Text
                                className="publisherName"
                                color="#fff"
                                fontSize="10px"
                                fontWeight="bold"
                                style={{ paddingLeft: "5px !important" }}
                              >
                                {item.publisher}
                              </Text>
                            </Stack>
                          </Td>
                          <Td minW="50px" borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.incoming}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.live}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.completed}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.ended}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.connected}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.paid}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.converted}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.noConnect}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.duplicate}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.blocked}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.rpc}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.revenue}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.payout}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.profit}%
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.margin}%
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.totalCost}
                            </Text>
                          </Td> */}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
          <div id="tab-3" className={`w3-container city ${tab3}`}>
            {/* Targets Summary Reports*/}
            <Card px="0px">
              <CardHeader
                px="22px"
                mb="32px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Targets
                </Text>
                <div>
                  <ExportToExcelButton
                    columns={buyers_column}
                    data={row2}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX="scroll">
                <Table
                  {...getTableProps2()}
                  className="report_custom_table"
                  variant="simple"
                >
                  <Thead>
                    {headerGroups2?.map((headerGroup, index) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup?.headers?.map((column, index) => (
                          <Th
                            // className="publisherName"
                            color="gray.400"
                            fontSize="10px"
                            fontFamily="Inter"
                            borderColor="#56577A"
                            minW={index === 0 ? "100px" : "auto"}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header")}
                              <Icon
                                w={{ sm: "10px", md: "14px" }}
                                h={{ sm: "10px", md: "14px" }}
                                color={
                                  buyerHead.isSorted ? "gray.500" : "gray.400"
                                }
                                float="right"
                                as={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? TiArrowSortedDown
                                      : TiArrowSortedUp
                                    : TiArrowUnsorted
                                }
                              />
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps2()}>
                    {buyers.length > 0 ? (
                      row2?.map((row, index) => {
                        prepareRow2(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row?.cells?.map((cell, index) => {
                              const header = headerGroups2[0].headers[index];
                              return (
                                <Td
                                  minW="150px"
                                  borderColor="#56577A"
                                  {...cell.getCellProps()}
                                  key={index}
                                >
                                  <Stack direction="row" spacing="2px">
                                    <Text
                                      className="publisherName dfdfdfd"
                                      color="#fff"
                                      fontSize="10px"
                                      fontWeight="bold"
                                      style={{ paddingLeft: "5px !important" }}
                                    >
                                      {cell.render("Cell")}
                                    </Text>
                                  </Stack>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
          <div id="tab-4" className={`w3-container city ${tab4}`}>
            <Card px="0px">
              <CardHeader
                px="22px"
                mb="32px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Number
                </Text>
                <div>
                  <ExportToExcelButton
                    columns={Number_column}
                    data={row4}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX="scroll">
                <Table
                  {...getTableProps4()}
                  className="report_custom_table"
                  variant="simple"
                >
                  <Thead>
                    {headerGroups4?.map((headerGroup, index) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup?.headers?.map((column, index) => (
                          <Th
                            // className="publisherName"
                            color="gray.400"
                            fontSize="10px"
                            fontFamily="Inter"
                            borderColor="#56577A"
                            minW={index === 0 ? "100px" : "auto"}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header")}
                              <Icon
                                w={{ sm: "10px", md: "14px" }}
                                h={{ sm: "10px", md: "14px" }}
                                color={
                                  numberHead.isSorted ? "gray.500" : "gray.400"
                                }
                                float="right"
                                as={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? TiArrowSortedDown
                                      : TiArrowSortedUp
                                    : TiArrowUnsorted
                                }
                              />
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  {/* <Thead>
                    <Tr>
                      <Th
                        className="publisherName"
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Number
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Incoming
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Live
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Completed
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Ended
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Connected
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Paid
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Converted
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        No Connect
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Duplicate
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Blocked
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        RPC
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Revenue
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Payout
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Profit
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Margin
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        TotalCost
                      </Th>
                    </Tr>
                  </Thead> */}
                  <Tbody {...getTableBodyProps4()}>
                    {numbers.length > 0 ? (
                      row4.map((row, index) => {
                        prepareRow4(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row?.cells?.map((cell, index) => {
                              const header = headerGroups4[0].headers[index];
                              return (
                                <Td
                                  minW="150px"
                                  borderColor="#56577A"
                                  {...cell.getCellProps()}
                                  key={index}
                                >
                                  <Stack direction="row" spacing="2px">
                                    <Text
                                      className="publisherName"
                                      color="#fff"
                                      fontSize="10px"
                                      fontWeight="bold"
                                      style={{ paddingLeft: "5px !important" }}
                                    >
                                      {cell.render("Cell")}
                                    </Text>
                                  </Stack>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
          <div id="tab-5" className={`w3-container city ${tab5}`}>
          <Card px="0px">
              <CardHeader
                px="22px"
                mb="32px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Date 
                </Text>
                <div>
                  <ExportToExcelButton
                    columns={Date_column}
                    data={row5}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX="scroll">
                <Table
                  {...getTableProps5()}
                  className="report_custom_table"
                  variant="simple"
                >
                  <Thead>
                    {headerGroups5?.map((headerGroup, index) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup?.headers?.map((column, index) => (
                          <Th
                            color="gray.400"
                            fontSize="10px"
                            fontFamily="Inter"
                            borderColor="#56577A"
                            minW={index === 0 ? "100px" : "auto"}
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header")}
                              <Icon
                                w={{ sm: "10px", md: "14px" }}
                                h={{ sm: "10px", md: "14px" }}
                                color={
                                  DateHead.isSorted ? "gray.500" : "gray.400"
                                }
                                float="right"
                                as={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? TiArrowSortedDown
                                      : TiArrowSortedUp
                                    : TiArrowUnsorted
                                }
                              />
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps5()}>
                    {dateDetails.length > 0 ? (
                      row5.map((row, index) => {
                        prepareRow5(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row?.cells?.map((cell, index) => {
                              const header = headerGroups5[0].headers[index];
                              return (
                                <Td
                                  minW="150px"
                                  borderColor="#56577A"
                                  {...cell.getCellProps()}
                                  key={index}
                                >
                                  <Stack direction="row" spacing="2px">
                                    <Text
                                      className="publisherName"
                                      color="#fff"
                                      fontSize="10px"
                                      fontWeight="bold"
                                      style={{ paddingLeft: "5px !important" }}
                                    >
                                      {cell.render("Cell")}
                                    </Text>
                                  </Stack>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      {/* Call Details*/}
      <Card p="28px 0px 0px 0px" style={{ marginTop: "20px" }}>
        <CardHeader mb="20px" ps="22px">
          <Flex
            direction="column"
            alignSelf="flex-start"
            style={{ margin: "0 auto" }}
          >
            <Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
            CALL LOGS
            </Text>
          </Flex>
        </CardHeader>
      </Card>

      <Card px="0px" style={{ marginTop: "10px" }}>
        <CardHeader
          px="22px"
          mb="32px"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text color="#fff" fontSize="lg" fontWeight="bold">
            Call Details
          </Text>
          {/* <div>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              style={{ marginRight: ".8rem" }}
              _focus={{ borderColor: "teal.300" }}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <ExportToExcelButton
              columns={callDetails_column}
              data={rowCallDetails}
              fileName="table_data"
              sheetName="Sheet 1"
            />
          </div> */}
        </CardHeader>
         {/* table using react table */}
         <CardBody overflowX="scroll">
          <Table className="report_custom_table" variant="simple">
            <Thead>
              <Tr>
                <Th
                  className="publisherName"
                  minW="50px"
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Call Dates
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Campaign
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Publisher
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Caller ID
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Dialed #
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Target No.
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  CALL STATUS
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Duplicate
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Target
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Duration
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {callDetails.length > 0 ? (
                callDetails?.map((item, index) => (
                  item.campaign != null && (
                  <Tr>
                    <Td minW="50px" borderColor="#56577A">
                      <Stack direction="row" spacing="2px">
                        <Text
                          className="publisherName"
                          color="#fff"
                          fontSize="10px"
                          fontWeight="bold"
                          style={{ paddingLeft: "5px !important" }}
                        >
                          {formatDate(item.startTime)}
                        </Text>
                      </Stack>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                       {item.campaign.name}  
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.publisher.name}  
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.from}  
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.forwardedFrom}  
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.to}  
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.status}  
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      No
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.target.name}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                      {item.duration}
                      </Text>
                    </Td>
                  </Tr>
                  )
                ))
              ) : (
                <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
              )}
            </Tbody>
          </Table>
        </CardBody>
        
        {/* using react table */}

        {/* <CardBody overflowX="scroll">
          <Table
            {...getTablePropsCallDetails()}
            className="report_custom_table"
            variant="simple"
          >
            <Thead>
              {headerGroupsCallDetails?.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup?.headers?.map((column, index) => (
                    <Th
                      // className="publisherName"
                      minW="50px"
                      color="gray.400"
                      fontSize="10px"
                      fontFamily="Inter"
                      borderColor="#56577A"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <Icon
                          w={{ sm: "10px", md: "14px" }}
                          h={{ sm: "10px", md: "14px" }}
                          color={
                            callDetailsHead.isSorted ? "gray.500" : "gray.400"
                          }
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      </Flex>{" "}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyPropsCallDetails()}>
              {callDetails.length > 0 ? (
                page?.map((row, index) => {
                  prepareRowCallDetails(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row?.cells?.map((cell, index) => {
                        const header =
                          headerGroupsCallDetails[0].headers[index];
                        return (
                          <Td
                            minW="50px"
                            borderColor="#56577A"
                            {...cell.getCellProps()}
                            key={index}
                          >
                            <Stack direction="row" spacing="2px">
                              <Text
                                className="publisherName"
                                color="#fff"
                                fontSize="10px"
                                fontWeight="bold"
                                style={{ paddingLeft: "5px !important" }}
                              >
                               {cell.render("Cell")}
                              </Text>
                            </Stack>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td borderColor="#56577A" colSpan={11}>
                    <Stack direction="row" spacing="16px">
                      <Text color="#fff" fontSize="lm" fontWeight="bold">
                        There are No Call Records Available
                      </Text>
                    </Stack>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </CardBody> */}
        {/* <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
          px={{ md: "22px" }}
        >
          <Text
            fontSize="sm"
            color="white"
            fontWeight="500"
            mb={{ sm: "24px", md: "0px" }}
          >
            Showing {pageSize * pageIndex + 1} to{" "}
            {pageSize * (pageIndex + 1) <= callDetails.length
              ? pageSize * (pageIndex + 1)
              : callDetails.length}{" "}
            of {callDetails.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-hover"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="#fff"
              border="1px solid lightgray"
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-hover"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="160px"
                    bg={pageNumber === pageIndex + 1 ? "brand.200" : "#fff"}
                    key={index}
                  >
                    <Text
                      fontSize="xs"
                      color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-hover"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="160px"
              bg="#fff"
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
            >
              <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex> */}
      </Card>

      {/*<SimpleGrid columns={{ sm: "1", md: "2" }} spacing='24px' style={{ marginTop: '10px' }}>
        <Card px='0px'>
          <CardHeader px='22px' mb='32px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold'>
              Publishers
            </Text>
          </CardHeader>
          <CardBody overflowX="scroll">
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Name
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Live
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Hours
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Impression
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Calls
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Converted
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Revenue
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Payout
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Profit
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW='180px' borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇺🇸</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        United States
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      2500
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $214,000
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>

                </Tr>

              </Tbody>
            </Table>
          </CardBody>
        </Card>

        <Card px='0px'>
          <CardHeader px='22px' mb='32px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold'>
              Targets Top Performers
            </Text>
          </CardHeader>
          <CardBody overflowX="scroll">
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Country:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Sales:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Value:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Bounce:
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW='180px' borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇺🇸</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        United States
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      2500
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $214,000
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇩🇪</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Germany
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      3900
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $446,700
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      19,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇬🇧</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Great Britain
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      1300
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $121,900
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      39,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td border='none'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇧🇷</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Brasil
                      </Text>
                    </Stack>
                  </Td>
                  <Td border='none'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      920
                    </Text>
                  </Td>
                  <Td border='none'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $52,100
                    </Text>
                  </Td>
                  <Td border='none'>
                    {" "}
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      29,9%
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </CardBody>
        </Card>
  </SimpleGrid>*/}
    </Flex>
  );
}
