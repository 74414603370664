/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { FaSpinner } from "react-icons/fa";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";

// Chakra imports
import {
  Flex,
  Text,
  Button,
  Switch,
} from '@chakra-ui/react'


// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";
import Loading from "components/Loading";
import packageJson from "../../../appConfig.js";

const BuyerList = (props) => {
  const user = JSON.parse(localStorage?.getItem("user"));
  const apiUrl = packageJson.apiurl;

  function buyerStatus(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return (
      <div>
        <i
          className="fa-sharp fa-solid fa-circle"
          style={{ color: activated }}
        />
      </div>
    );
  }

  const handleEdit = (originalRow) => {
    props.addBuyer('Edit Target', originalRow);
  };

  let buyerListColumns = user.role == 0 || user.role == 1 ? 
  [
    // {
    //   Header: "Target ID",
    //   accessor: "id",
    // },
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Creation Date",
      accessor: (row) => row.createdAt.split("T")[0],
    },
    {
      Header: "#",
      accessor: isCheckedBuyer,
    },
  ] : [];

  buyerListColumns = [
    ...buyerListColumns, 
    ...[
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Sub Id",
        accessor: "subid",
      },
      {
        Header: "Type",
        accessor: getBuyerType,
      },
      {
        Header: "Number",
        accessor: "phoneNumber",
      },
      {
        Header: "Timeout",
        accessor: "connectiontimeout",
      },
    ]
  ];

  if (user.role == 0)  {
    buyerListColumns.push({
      Header: "Client",
      accessor: "by.name",
      id: "client",
    });
  }

  if (user.role == 1)  {
    buyerListColumns.push({
      Header: "Status",
      accessor: buyerStatus,
      id: "status",
    });
  }

  buyerListColumns.push(
    {
      Header: "Action",
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(originalRow);
            }}
            title="Edit"
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </a>{" "}
          |{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleDelete(originalRow);
            }}
            title="Delete"
          >
            <i className="fa-solid fa-trash"></i>
          </a>
          {(user.role == 0 || user.role == 1) &&
            <>
            {" "}
            |{" "}
            {/* need to work on funtionality for reset cap and duplicate call */}
            <a
              href="#"
              onClick={(e) => {
                e.preventDefault();
                handleDelete(originalRow);
              }}
              title="Duplicate Target"
            >
              <i className="fa fa-clone"></i>
            </a>
            {" "}
            |{" "}
            <a
              href="#"
              // onClick={(e) => {
              //   e.preventDefault();
              //   handleDelete(originalRow);
              // }}
              title="Reset Caps"
            >
              <i className="fa fa-undo"></i>
            </a>
            </>
          }
        </div>
      ),
      id: "action",
    }
  );

  function isCheckedBuyer(originalRow, rowIndex) {
    let buyerChecked = false;
    if (originalRow.status == 1) {
      buyerChecked = true;
    }
    return (
      <Switch
        id="client-switch"
        onChange={enableBuyer}
        name="buyer"
        value={originalRow._id}
        isChecked={buyerChecked}
      />
    );
  }

  const enableBuyer = async (event) => {
    const loadToast = toast.loading("Loading...");
    let buyerId = event.target.value;
    let status = 1;
    if (event.target.checked) {
      status = 1;
    } else {
      status = 0;
    }

    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: buyerId, status: status }),
    };
    const response = await fetch(
      apiUrl + "/api/buyers/status-change",
      requestOptions
    );
    const result = await response.json();
    if (result.statusCode == 200) {
      toast.dismiss(loadToast);
      toast.success(result.msg);
    } else {
      toast.dismiss(loadToast);
      toast.error(result.msg);
    }
    await props.refresh();
  };

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>{type}</div>;
  }

  const handleDelete = (originalRow) => {
    //
    if (!confirm("Do You want to Delete This Buyer?")) {
      return false;
    }
    let config = {
      method: "post",
      url: apiUrl + "/api/buyers/delete",
      headers: {
        "Content-Type": "application/json",
      },
      data: { id: originalRow._id },
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          // setLoadingData(true);
          // refreshSet(response._id);
          toast.success("Buyer has been Deleted Successfully");
          props.refresh();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  return (
    <Card p={0}>
      <CardBody>
        {user.status == 1 &&
          <Button
            variant="outlineWhite"
            onClick={() => props.addBuyer('Create Target')}
          >
            + Add Buyer
          </Button>
        }
        {!props.loading &&
          <SearchTable1 tableData={props.tableData} columnsData={buyerListColumns} />
        }
        {props.loading &&
          <Loading />
        }
      </CardBody>
    </Card>
  );
};

export default BuyerList;