/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button, Switch, useDisclosure, Select } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchTable1 from "components/Tables/SearchTable1";
import StrategyNav from 'components/Navbars/Strategy';
import axios from "axios";
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import Loading from 'components/Loading';
import { caseInsensitiveSort, simpleSort } from 'variables/general';

function Campaigns() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [clients, setClients] = useState([]);
  const [clientsLoading, setClientsLoading] = useState(true);
  const [clientFilterValue, setClientFilterValue] = useState('');
  const [campaigns, setCampaigns] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, setRefresh] = useState('');

  const user = JSON.parse(localStorage?.getItem("user"));
  const token = localStorage?.getItem("token");

  useEffect(() => {
    if (clientsLoading) {
      getClientsLists();
    }
    if (loadingData) {
      getData();
    }
  }, [refresh]);

  const getClientsLists = async () => {
    let result = await fetch(apiUrl + "/api/users/client/lists");
    result = await result.json();
    setClients(result);
    setClientsLoading(false);
  }

  const clientFilterHandle = e => {
    const { name, value } = e.target
    setClientFilterValue(value);
    setLoadingData(true);
    setRefresh(Math.random());
  }

  function isCheckedCampaign(originalRow, rowIndex) {
    const campaignChecked = (originalRow.status == 1);
    return <Switch id="client-switch" onChange={enableCampaign} name="client" value={originalRow._id} isChecked={campaignChecked} />
  }

  const enableCampaign = async (event) => {
    const loadToast = toast.loading('Loading...');
    let clientId = event.target.value;
    let status = 1;
    if (event.target.checked) {
      status = 1;
    } else {
      status = 0;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: clientId, status: status })
    };
    const response = await fetch(apiUrl+'/api/campaign/status-change', requestOptions);
    const result = await response.json();
    if(result.statusCode == 200) {
      toast.success(result.msg);
    } else {
      toast.error(result.msg);
    }
    toast.dismiss(loadToast);
    setLoadingData(true);
    setRefresh(Math.random());
  }

  function campaignStatus(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return (
      <div>
        <i
          className="fa-sharp fa-solid fa-circle"
          style={{ color: activated }}
        />
      </div>
    );
  }

  const handlerViewData = (originalRow) => {
    //history.push("/administrator/campaign/" + originalRow._id + "/view");
  };

  let columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header:"Creation Date",
      accessor:row => row.createdAt.split("T")[0],
    },
  ];

  if (user.role == 0 || user.role == 1) {
    columnsData.push(
      {
        Header: "#",
        accessor: isCheckedCampaign,
        sortType: (prev, curr, columnId) => {
          return simpleSort(prev, curr, 'status');
        },
      }
    );
  }

  columnsData = columnsData.concat([
    {
      Header: "Name",
      accessor: "name",
      sortType: (prev, curr, columnId) => {
        return caseInsensitiveSort(prev, curr, columnId);
      },
      Cell: ({ row }) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handlerViewData(row.original);
            }}
            style={{ textDecoration: 'none', fontWeight: 'normal' }}
            onMouseOver={(e) => {
              e.target.style.fontWeight = 'bold';
            }}
            onMouseOut={(e) => {
              e.target.style.fontWeight = 'normal';
            }}
          >
            {row.original.name}
          </a>
        </div>
      ),
    },
  ]);

  if (user.role != 0) {
    columnsData.push(
      {
        Header: 'Call Routing',
        accessor: (originalRow, rowIndex) => (
          <div>
            {originalRow.callrouting == 1 ? "Priority(Standard)" : "Call Flow"}
          </div>
        ),
        id: 'callrouting',
      }
    );
  }

  columnsData.push(
    {
      Header: "Status",
      accessor: campaignStatus,
      sortType: (prev, curr, columnId) => {
        return simpleSort(prev, curr, columnId);
      },
      id: "status",
    }
  );

  columnsData.push(
    {
      Header: "Action",
      disableSortBy: true,
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(originalRow);
            }}
            title="Edit Campaign"
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </a>
          {user.role == 0 || user.role == 1 && <>
          {" "}
          |{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              deleteCampaign(originalRow);
            }}
            title="Delete Campaign"
          >
            <i className="fa-solid fa-trash"></i>
          </a>{" "}
          |{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              createDuplicate(originalRow);
            }}
            title="Create Duplicate"
          >
            <i className="fa-solid fa-newspaper"></i>
          </a>
          </>}
        </div>
      ),
      id: "action",
    }
  );

  const createDuplicate = async (originalRow) => {
    if (!confirm("Do You want to Duplicate This Campaign?")) {
      return false;
    }
    let config = {
      method: 'post',
      url: apiUrl + '/api/campaign/duplicate',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "id": originalRow._id }
    };
    axios.request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          setLoadingData(true);
          setRefresh(Math.random());
          toast.success('Campaign has been Duplicated Successfully');
        } 0
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  const addCampaigns = async () => {
    history.push('/administrator/campaign/create');
  }

  const getData = async () => {
    const loadToast = toast.loading('Loading...');
    let getUrl = apiUrl;
    let requestBody = {};
    if (user.role == 0) {
      getUrl += "/api/campaign/list-campaign";
      requestBody = JSON.stringify({ client: clientFilterValue });
    } else if (user.role == 1) {
      getUrl += "/api/admin/list-campaign";
      requestBody = JSON.stringify({ id: user.id });
    } else if (user.role == 2) {
      getUrl += "/api/campaign/publisher/list-campaign";
      requestBody = JSON.stringify({ _id: user.id, token: token });
    } else {
      getUrl += '/api/campaign/buyer/list-campaign';
      requestBody = JSON.stringify({ _id: user.id, token: token });
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: requestBody,
    };

    let result = await fetch(getUrl, requestOptions);
    result = await result.json();
    setCampaigns(result.data);
    setLoadingData(false);
    toast.dismiss(loadToast);
  }
  const handleEdit = (originalRow) => {
    history.push('/administrator/campaign/' + originalRow._id + '/edit');
  };

  const deleteCampaign = async (originalRow) => {
    if (!confirm("Do You want to Delete This Campaign?")) {
      return false;
    }
    let config = {
      method: 'post',
      url: apiUrl + '/api/campaign/delete',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "id": originalRow._id }
    };
    axios.request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          setLoadingData(true);
          setRefresh(response.data.data._id);
          toast.success('Campaign has been Deleted Successfully');
        } 0
      })
      .catch((error) => {
        toast.error(error);
      });
  }

  return (
    <Flex className='addpub' direction='column'>
      <Card>
        <CardBody>
          {(user.role == 0 || user.role == 1) &&
            <StrategyNav current="/administrator/campaign/lists" />
          }
          {user.role != 0 && user.role != 1 &&
            <Text fontSize='lg' mb={6} color='#fff' fontWeight='bold'>
              Campaigns Lists
            </Text>
          }

          <Flex 
            flexDirection={{base: 'column', md: 'row'}}
            alignItems={{base: 'flex-start', md: 'center'}}
            justifyContent={'space-between'}
          >
            {user.status == 1 &&
              <Button
                // isDisabled={true}
                variant='outlineWhite'
                alignSelf='flex-end'
                onClick={addCampaigns}
              >
                <Text fontSize='16px' color='#fff'>
                  + Add New Campaigns
                </Text>
              </Button>
            }
            {user.role == 0 && clientsLoading &&
            <Loading />}

            {user.role == 0 && !clientsLoading &&
              <Select variant="borderless" w={{base: '100%', md: '25%'}} name="userFilter" onChange={clientFilterHandle}>
                <option value=''>Choose User Options</option>
                {clients.data.length > 0 && 
                  clients.data.map((item, index) => <option key={item._id} value={item._id}>{item.name}</option>
                )}
              </Select>
            }
          </Flex>

          {loadingData ? (
            <Loading />
          ) : (
            <SearchTable1 tableData={campaigns} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Campaigns;
