/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
// Assets
import signUpImage from "assets/img/signUpImage.png";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";

function SignUpCover() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";
  const [message, setMessage] = useState('');
  const [enableBuyer, setEnableBuyer] = useState(false);
  const [showBuyer, setShowBuyer] = useState("hide");
  const [user, setUser] = useState({
    name: "",
    email: "",
    password: "",
    role: 1,
    phoneType: 1,
    phone: "",
    connectionTimeout: 30
  })

  const SignInRedirect = e => {
    history.push("/auth/authentication/sign-in/cover");
  }

  const handleChange = e => {
    const { name, value } = e.target
    setUser({
      ...user,//spread operator 
      [name]: value
    })
  }

  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const register = (e) => {
    e.preventDefault();
    const { name, email, password, role, phoneType, phone, connectionTimeout } = user;
    const loadToast = toast.loading('Please Wait....');
    if ((name == "") && (role == 2)) {
      toast.dismiss(loadToast);
      toast.error("Name Field is Required!");
      return false;
    } else if ((email == "") && (role == 2)) {
      toast.dismiss(loadToast);
      toast.error("Email Field is Required!");
      return false;
    } else if ((password == "") && (role == 2)) {
      toast.dismiss(loadToast);
      toast.error("Password Field is Required!");
      return false;
    } else if ((phone == "") && (role == 3)) {
      toast.dismiss(loadToast);
      toast.error("Phone Field is Required!");
      return false;
    } else if ((connectionTimeout == "") && (role == 3)) {
      toast.dismiss(loadToast);
      toast.error("Connection Timeout Field is Required!");
      return false;
    }

    if (!isValidEmail(email)) {
      toast.dismiss(loadToast);
      toast.error("Invalid Email Entered!");
      return false;
    }

    let config = {
      method: 'post',
      url: apiUrl + '/api/auth/client/register',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { user }
    };
    axios.request(config)
      .then((response) => {
        if(response.data.statusCode == 200) {
          toast.dismiss(loadToast);
          toast.success(response.data.msg);
          history.push("/auth/authentication/sign-in/cover");
        } else {
          toast.dismiss(loadToast);
          toast.error(response.data.msg);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadToast);
      });

  }
  return (
    <Flex position='relative' overflow={{ lg: "hidden" }} as='form' onSubmit={register}>
      <Flex
        flexDirection='column'
        h={{ sm: "initial", md: "unset" }}
        w={{ base: "90%" }}
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        pt={{ sm: "100px", md: "0px" }}
        me={{ base: "auto", lg: "50px", xl: "auto" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          flexDirection='column'
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='50px'
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            textAlign='center'
            justifyContent='center'
            align='center'
            mt={{ base: "60px", md: "140px", lg: "200px" }}
            mb='50px'>
            <Text
              fontSize='4xl'
              lineHeight='39px'
              color='white'
              fontWeight='bold'>
              Welcome!
            </Text>
            <Text
              fontSize='md'
              color='white'
              fontWeight='500'
              mt='10px'
              w={{ base: "100%", md: "90%", lg: "90%", xl: "80%" }}>
              Create an account to manage calls between Publishers and Buyers.
            </Text>
          </Flex>
          <GradientBorder p='2px' me={{ base: "none", lg: "30px", xl: "none" }}>
            <Flex
              background='transparent'
              borderRadius='30px'
              direction='column'
              p='40px'
              minW={{ base: "unset", md: "430px", xl: "450px" }}
              w='100%'
              mx={{ base: "0px" }}
              bg={{
                base: "rgb(19,21,56)",
              }}>
              <FormControl>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'>
                  Name
                </FormLabel>

                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    h='46px'
                    type='text'
                    placeholder='Your name'
                    name="name"
                    value={user.name}
                    onChange={handleChange}
                  />
                  <Input
                    type='hidden'
                    name="role"
                    value={user.role}
                    onChange={handleChange}
                  />
                </GradientBorder>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'>
                  Email
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    h='46px'
                    type='email'
                    placeholder='Your email address'
                    name="email"
                    value={user.email}
                    onChange={handleChange}
                  />
                </GradientBorder>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'>
                  Password
                </FormLabel>
                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    h='46px'
                    type='password'
                    placeholder='Your password'
                    name="password"
                    value={user.password}
                    onChange={handleChange}
                  />
                </GradientBorder>
                <Button
                  variant='brand'
                  fontSize='10px'
                  type='submit'
                  w='100%'
                  h='45'
                  mb='20px'>
                  SIGN UP
                </Button>
              </FormControl>
              <Flex
                flexDirection='column'
                justifyContent='center'
                alignItems='center'
                maxW='100%'
                mt='0px'>
                <Text color={textColor} fontWeight='medium'>
                  Already have an account?
                  <Link
                    color={titleColor}
                    as='span'
                    ms='5px'
                    href='#'
                    fontWeight='bold'
                    onClick={SignInRedirect}
                  >
                    Sign In
                  </Link>
                </Text>
              </Flex>
            </Flex>
          </GradientBorder>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='90px'>
          <AuthFooter />
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX='hidden'
          h='1300px'
          maxW={{ md: "50vw", lg: "48vw" }}
          w='960px'
          position='absolute'
          left='0px'>
          <Box
            bgImage={signUpImage}
            w='100%'
            h='1300px'
            bgSize='cover'
            bgPosition='50%'
            position='absolute'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            position='absolute'>
            <Text
              textAlign='center'
              color='white'
              letterSpacing='8px'
              fontSize='20px'
              fontWeight='500'>
              CALL TRACKING MANAGEMENT:
            </Text>
            <Text
              textAlign='center'
              color='transparent'
              letterSpacing='8px'
              fontSize='36px'
              fontWeight='bold'
              bgClip='text !important'
              bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
              CREATE ACCOUNT
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignUpCover;
