/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import {
  Flex,
  Icon,
  Input,
  InputGroup,
  InputRightElement,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import React, { useMemo, useRef, useEffect } from "react";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import { SearchIcon } from "@chakra-ui/icons";
import Paginator from "components/Paginator";

const useInstance = (instance) => {
  if (instance && instance.getInstanceCallback) {
    instance.getInstanceCallback(instance);
  }
};

function SearchTable1(props) {
  const { columnsData, tableData, getInstanceCallback } = props;
  const columns = useMemo(() => columnsData, []);
  const data = useMemo(() => tableData, [tableData]);

  const currentPageIndexRef = useRef(0);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: currentPageIndexRef.current },
      getInstanceCallback,
    },
    (hooks) => hooks.useInstance.push(useInstance),
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    gotoPage,
    pageCount,
    prepareRow,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, globalFilter },
  } = tableInstance;

  useEffect(() => {
    currentPageIndexRef.current = pageIndex;
  }, [pageIndex]);

  let totalRow = (tableInstance.rows?.filter(n => n.original.isTotalRow === true))[0];
  if (totalRow) {
    prepareRow(totalRow);
  } else {
    totalRow = {};
  }

  return (
    <>
      <Flex
        direction='column'
        w='100%'
        overflowX={{ sm: "scroll", lg: "hidden" }}>
        <Flex direction='row' w='100%'>
        <Paginator
          width='75%'
          pageIndex={pageIndex}
          tableInstance={tableInstance}
        />
        <Flex justify='space-between' align='center' w='25%' mb='20px'>
          <InputGroup>
          <Input
            color='gray.400'
            bg='#323b66'
            border='1px solid'
            borderColor='transparent'
            borderRadius='15px'
            type='text'
            placeholder='Search...'
            _placeholder={{ color: '#5C6CBF' }}
            _hover={{ border: 0 }}
            fontSize='sm'
            onChange={(e) => setGlobalFilter(e.target.value)}
          />
          <InputRightElement height="100%">
            <SearchIcon />
          </InputRightElement>
          </InputGroup>
        </Flex>
        </Flex>
        <Table {...getTableProps()} className="report_custom_table" variant='simple' color='gray.500' mb='24px' width='100%'>
          <Thead>
            {headerGroups.map((headerGroup, index) => (
              <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index2) => (
                  <Th
                    borderColor='#56577A'
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    pe='0px'
                    key={index2}>
                    <Flex
                      justify='space-between'
                      align='center'
                      fontSize={{ sm: "10px", lg: "12px" }}
                      color='gray.400'>
                      <span>{column.render("Header")}</span>
                      {!column.disableSortBy &&
                      <Icon
                        w={{ sm: "10px", md: "18px" }}
                        h={{ sm: "10px", md: "18px" }}
                        float='right'
                        as={
                          column.isSorted
                            ? column.isSortedDesc
                              ? TiArrowSortedDown
                              : TiArrowSortedUp
                            : TiArrowUnsorted
                        }
                      />}
                    </Flex>
                  </Th>
                ))}
              </Tr>
            ))}
          </Thead>
          <Tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              if (row.original.isTotalRow) {
                return (<React.Fragment key={index}></React.Fragment>);
              }
              prepareRow(row);
              return (
                <Tr {...row.getRowProps()} key={index}>
                  {row.cells.map((cell, index2) => {
                    return (
                      <Td
                        borderColor='#56577A'
                        color='white'
                        {...cell.getCellProps()}
                        fontSize={{ sm: "14px" }}
                        align='center'
                        key={index2}>
                        {cell.render("Cell")}
                      </Td>
                    );
                  })}
                </Tr>
              );
            })}
            {Object.keys(totalRow).length > 0 && <Tr {...totalRow.getRowProps()}>
              {totalRow.cells?.map((cell, i) => {
                return (
                  <Td
                    borderColor='#56577A'
                    color='white'
                    {...cell.getCellProps()}
                    fontSize={{ sm: "14px" }}
                    fontWeight='bold'
                    align='center'
                    key={i}>
                    {cell.render('Cell')}
                  </Td>
                );
              })}
            </Tr>
          }
          </Tbody>
        </Table>
      </Flex>
    </>
  );
}

export default SearchTable1;
