/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  ChakraProvider,
  Portal,
  useDisclosure,
} from "@chakra-ui/react";
import "assets/css/vud-dashboard-styles.css";
// Custom components
import MainPanel from "components/Layout/MainPanel";
import PanelContainer from "components/Layout/PanelContainer";
import PanelContent from "components/Layout/PanelContent";
// Layout components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import { SidebarContext } from "contexts/SidebarContext";
import React, { useState, useMemo } from "react";
import "react-quill/dist/quill.snow.css"; // ES6
import { Redirect, Route, Switch } from "react-router-dom";
// routes
import adminRoutes from "administratorRoutes.js";
import buyerRoutes from "buyerRoutes.js";
import publisherRoutes from "publisherRoutes.js";

import { Toaster } from 'react-hot-toast';
// Custom Chakra theme
import theme from "theme/themeAdmin.js";

const defaultSidebarWidth = 190;

export default function Dashboard(props) {
  const user = JSON.parse(localStorage?.getItem("user"));
  const userRole = user?.role?.toString();
  const pathLabels = {
      '0': "administrator",
      '1': "administrator",
      '2': "publisher",
  };
  const pathRoutes = {
      '0': adminRoutes,
      '1': adminRoutes,
      '2': publisherRoutes,
  };
  const pathLabel = userRole in pathLabels ? pathLabels[userRole] : 'buyer';
  const path = `/${pathLabel}`;
  const redirectToPath = `${path}/dashboard/default`;
  const routes = userRole in pathRoutes ? pathRoutes[userRole] : buyerRoutes;
  const { ...rest } = props;
  // states and functions
  const [sidebarVariant, setSidebarVariant] = useState("transparent");
  const [fixed, setFixed] = useState(false);
  const [toggleSidebar, setToggleSidebar] = useState(false);
  const [sidebarWidth, setSidebarWidth] = useState(defaultSidebarWidth);

  const getActiveRoute = (routes) => {
    for (let i = 0; i < routes.length; i++) {
      if (routes[i].children?.length > 0) {
        for (const child of routes[i].children) {
          if (location.href.indexOf(routes[i].layout + child.path) > -1) {
            return [
              { text: routes[i].name, href: '#' + routes[i].layout + routes[i].path, },
              { text: child.name, href: '#' + routes[i].layout + child.path },
            ];
          }
        }
      }
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) !== -1
        ) {
          return [ { text: routes[i].name, href: '#' + routes[i].layout + routes[i].path, } ];
        }
    }

    // Needs general workaround for route paths with params, eg. /campaign/:id/edit
    if (location.hash.match(/\/campaign\/(.*){24}\/edit/)?.length > 0) {
      return [ { text: 'Strategy', href: '#/' + pathLabel + '/campaign/lists' }, { text: 'Edit Campaign' } ];
    }

    return [{}];
  };

  const getActiveNavbar = (routes) => {
    let activeNavbar = false;
    for (let i = 0; i < routes.length; i++) {
        if (
          window.location.href.indexOf(routes[i].layout + routes[i].path) == -1
        ) {
          return routes[i].secondaryNavbar;
        }
      }
    return activeNavbar;
  };

  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if ('roles' in prop && prop.roles.indexOf(userRole) === -1) {
        return;
      }
      if (prop.layout === "/" + pathLabel) {
        if (prop.children?.length > 0) {
          return getRoutes(prop.children);
        }

        const ComponentName = prop.component;
        return (
          <Route
            path={prop.layout + prop.path}
            component={(props) => <ComponentName {...props} />}
            key={key}
          />
        );
      }
    });
  };

  document.documentElement.dir = "ltr";
  document.documentElement.layout = pathLabel;

  return (
    <>
      <ChakraProvider theme={theme} resetCss={false} overflowX='hidden'>
      <Toaster position="top-right"/>
        <SidebarContext.Provider
          value={{
            sidebarWidth,
            setSidebarWidth,
            toggleSidebar,
            setToggleSidebar,
          }}>
          <Sidebar
            routes={routes}
            logoText={"Call Management"}
            display='flex'
            sidebarVariant={sidebarVariant}
            {...rest}
          />
          <MainPanel
            w={{
              base: "100%",
              xl: `calc(100% - ${sidebarWidth}px)`,
            }}
          >
            <Portal>
              <AdminNavbar
                breadcrumbs={getActiveRoute(routes)}
                secondary={getActiveNavbar(routes)}
                fixed={fixed}
                routes={routes}
                {...rest}
              />
            </Portal>

            <PanelContent mt={{ base: "108px", md: 0 }}>
              <PanelContainer>
                <Switch>
                  {getRoutes(routes)}
                  <Redirect from={path} to={redirectToPath} />
                </Switch>
              </PanelContainer>
            </PanelContent>
          </MainPanel>
        </SidebarContext.Provider>
      </ChakraProvider>
    </>
  );
}
