/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Flex,
  Button,
  useDisclosure,
  Text,
} from '@chakra-ui/react'

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import StrategyNav from 'components/Navbars/Strategy';
import NewPublisher from './NewPublisher';
import CTModal from "components/Modal";
import PublisherList from "./PublisherList";


import axios from "axios";
import toast from "react-hot-toast";
import packageJson from "../../../appConfig.js";

function Publishers() {
  const apiUrl = packageJson.apiurl;
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, refreshSet] = React.useState("");
  const [modalType, setModalType] = useState("");
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [publisherRow, setPublisherRow] = useState({});

  //publisher list
  const [publishersData, setPublishersData] = useState([]);
  const user = JSON.parse(localStorage?.getItem("user"));

  useEffect(() => {
    getPublishersData();
  }, [refresh]);

  const openEditPublisher = (row) => {
    setModalType("Edit Publisher");
    setPublisherRow(row);
    onOpen();
  };

  const getPublishersData = async () => {
    const loadToast = toast.loading('Loading...');
    let result = await axios.get(apiUrl + "/api/users/publishers", { params: { id: user.id } });
    setPublishersData(result.data);
    setLoadingData(false);
    toast.dismiss(loadToast);
  };

  const OpenModal = (componentTitle) => {
    setModalType(componentTitle);
    onOpen();
  };

  return (
    <>
      <Flex
        className="addpub"
        direction="column"
        gap={10}
      >
        <Card>
          {(user.role == 0 || user.role == 1) &&
            <StrategyNav current="/administrator/publishers/users/lists" />
          }

          {(user.role != 0 && user.role != 1) &&
            <Text fontSize='lg' mb={6} color='#fff' fontWeight='bold'>
              Publisher Lists
            </Text>
          }

          <CardBody>
            <Flex
              flexDirection={{base: 'column', md: 'row'}}
              alignItems={'flex-start'}
              gap={4}
            >
              {(user.role == 0 || user.role == 1) && user.status == 1 &&
                <Button
                  variant="outlineWhite"
                  onClick={() => OpenModal('Add Publisher')}
                >
                  + Add Publisher
                </Button>
              }
            </Flex>
            <Flex flexDirection={'column'}>
                <PublisherList
                  tableData={publishersData}
                  refresh={refreshSet}
                  loading={loadingData}
                  onEdit={openEditPublisher}
                />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={modalType}
      >
        {["Add Publisher", "Edit Publisher"].includes(modalType) &&
          <NewPublisher
            onClose={onClose}
            publisher={publisherRow}
            clearPublisher={setPublisherRow}
            refresh={refreshSet}
          />
        }
      </CTModal>
    </>
  );
}

export default Publishers;
