/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import {
  Flex,
  Box,
} from "@chakra-ui/react";

// Custom Components
import PropTypes from "prop-types";
import React, { useState, useEffect } from "react";
import packageJson from '../../appConfig.js';
import WebSocket from 'websocket';

import callLiveImg from '../../icons/calls-live.png';
import callCompleteImg from '../../icons/calls-total.png';
import callMissedImg from '../../icons/calls-missed.png';
import callConvertImg from '../../icons/calls-converted.png';
const user = JSON.parse(localStorage?.getItem("user"));

export default function HeaderLinks(props) {
  const [liveCalls, setLiveCalls] = useState(0);
  const [completedCalls, setCompletedCalls] = useState(0);
  const [missedCalls, setMissedCalls] = useState(0);
  const [convertedCalls, setConvertedCalls] = useState(0);
  const websocketUrl = packageJson.websocket;

  const { variant, children, fixed, secondary, ...rest } = props;

  useEffect(() => {
    const ws = new WebSocket.w3cwebsocket(websocketUrl);

    // Event: WebSocket opened
    ws.onopen = () => {
      console.log('WebSocket connection established!');
      ws.send(JSON.stringify({ userId: user.id, role: user.role }));
    };

    // Event: WebSocket message received
    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);
      setLiveCalls(data.live);
      setCompletedCalls(data.completed);
      setMissedCalls(data.missed);
      setConvertedCalls(data.converted);
    };

    // Event: WebSocket closed
    ws.onclose = () => {
      console.log('WebSocket connection closed!');
    };

    // Clean up the WebSocket connection on unmount
    return () => {
      if (ws && ws.readyState === WebSocket.OPEN) {
        ws.close();
      }
    };
  }, []);

  return (
    <Flex
      w="100%"
      alignItems='center'
      justifyContent='center'>
        <div className="headerCalls">
          <Box className="livecallsSummary" title={"Live"}>
           <img src={callLiveImg} />
            <div className="callsSummary">
              <span style={{ color: "#0fff69" }}> {liveCalls} </span>
              <Box display={{base: "inline", lg: "none", xl: "inline"}}> Live </Box>
              </div>
           </Box>
          <Box className="livecallsSummary" title={"Total Today"}>
              <img src={callCompleteImg} />
              <div className="callsSummary">
              <span style={{ color: "#11caff" }}> {completedCalls} </span>
              <Box display={{base: "inline", lg: "none", xl: "inline"}}> Total Today </Box>
           </div>
          </Box>
          <Box className="livecallsSummary" title={"Missed"} display={{base: "none", "2sm": "flex"}}>
              <img src={callMissedImg} />
              <div className="callsSummary">
              <span style={{ color: "red" }}> {missedCalls} </span>
              <Box display={{base: "inline", lg: "none", xl: "inline"}}> Missed </Box>
           </div>
          </Box>
          <Box className="livecallsSummary" title={"Converted"} display={{base: "none", "2sm": "flex"}}>
              <img src={callConvertImg} />
              <div className="callsSummary">
              <span style={{ color: "#d001f9" }}> {convertedCalls} </span>
              <Box display={{base: "inline", lg: "none", xl: "inline"}}> Converted </Box>
           </div>
          </Box>
        </div>
    </Flex>
  );
}

HeaderLinks.propTypes = {
  variant: PropTypes.string,
  fixed: PropTypes.bool,
  secondary: PropTypes.bool,
};
