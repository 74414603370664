import React, { useState, useEffect } from 'react';
import axios from 'axios';
import toast, { Toaster } from "react-hot-toast";
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Select,
  Flex,
} from "@chakra-ui/react";
import { FaSpinner } from "react-icons/fa";
import states from 'states-us';
import packageJson from "../../appConfig";

import {
  Elements,
  CardElement, 
  useStripe,
  useElements,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { error } from 'three';

const CardForm = (props) => {
  const { paymentMethod } = props;
  const stripe = useStripe();
  const elements = useElements();
	const [processing, setProcessing] = useState(false);
  const [data, setData] = useState({
    address_line1: paymentMethod?.address_line1 ?? '',
    address_line2: paymentMethod?.address_line2 ?? '',
    address_city: paymentMethod?.address_city ?? '',
    address_state: paymentMethod?.address_state ?? '',
    address_zip: paymentMethod?.address_zip ?? '',
    address_country: paymentMethod?.address_country ?? 'US',
    name: paymentMethod?.name ?? '',
    exp_month: paymentMethod?.exp_month ?? '',
    exp_year: paymentMethod?.exp_year ?? '',
  });
  const user = JSON.parse(localStorage?.getItem("user"));

  const handleSubmit = async (event) => {
    // Block native form submission.
    event.preventDefault();
    setProcessing(true);

    if (!paymentMethod) {
      if (!stripe || !elements) {
        return;
      }

      const card = elements.getElement(CardElement);

      if (card == null) {
        return;
      }

      const newData = { ...data };
      delete newData.address_zip;
      delete newData.exp_month;
      delete newData.exp_year;

      const payload = await stripe.createToken(card, newData);

      if (payload.error) {
        setProcessing(false);
        toast.error(payload.error.message);
      } else {
        try {
          await axios.post('/api/billing/card', {
            token: payload.token.id,
            userId: user.id,
          });
          toast.success("Card successfully added");
          setProcessing(false);
          props.onClose();
        } catch (e) {
          toast.error("Unable to save card to customer profile");
          setProcessing(false);
        }
      }
    } else {
      try {
        if (data.exp_month > 12 || data.exp_month < 1 || data.exp_year < new Date().getFullYear()) {
          toast.error("Invalid date entered");
          return;
        }
        await axios.put('/api/billing/card', {
          customer: paymentMethod.customer,
          card: paymentMethod.id,
          userId: user.id,
          data: data,
        }).then(({ status, data }) => {
          if (data.success) {
            toast.success("Card successfully updated");
            setProcessing(false);
            props.onClose();
          } else {
            toast.error("Unable to update card");
            console.log(data.error);
            setProcessing(false);
          }
        });
        
      } catch (e) {
        toast.error("Unable to update card");
        console.log(e);
        setProcessing(false);
      }
    }
  };

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <form onSubmit={handleSubmit}>
      {!paymentMethod &&
        <CardElement 
          options={{
            style: {
              base: {
                fontSize: '16px',
                color: '#fff',
                '::placeholder': {
                  color: '#aab7c4',
                },
              },
              invalid: {
                color: '#9e2146',
              },
            },
          }}
        />
      }
      <Flex gap={3} flexDirection={"column"} mt={3}>
        <FormControl>
          <FormLabel variant="ct-label">
            Name on card
          </FormLabel>
          <Input
            name="name"
            type='text'
            variant='borderless'
            value={data.name}
            onChange={handleChange}
          />
        </FormControl>
        {!!paymentMethod &&
          <FormControl>
            <FormLabel variant="ct-label">
              Expiry date
            </FormLabel>
            <Flex flexDirection={'row'} gap={1}>
              <Input
                name="exp_month"
                type='number'
                variant='borderless'
                value={data.exp_month}
                placeholder='MM'
                onChange={handleChange}
              />
              {'/'}
              <Input
                name="exp_year"
                type='number'
                variant='borderless'
                value={data.exp_year}
                placeholder='YYYY'
                onChange={handleChange}
              />
            </Flex>
          </FormControl>
        }
        <FormControl>
          <FormLabel variant="ct-label">
            Address Line 1
          </FormLabel>
          <Input
            name="address_line1"
            type='text'
            variant='borderless'
            value={data.address_line1}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Address Line 2
          </FormLabel>
          <Input
            name="address_line2"
            type='text'
            variant='borderless'
            value={data.address_line2}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            City
          </FormLabel>
          <Input
            name="address_city"
            type='text'
            variant='borderless'
            value={data.address_city}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            State
          </FormLabel>
          <Select
            name="address_state"
            type='text'
            variant='borderless'
            value={data.address_state}
            onChange={handleChange}
          >
            {states.map(state => {
              return (<option key={state.abbreviation} value={state.name}>{state.name}</option>);
            })}
          </Select>
        </FormControl>
        {!!paymentMethod &&
          <FormControl>
            <FormLabel variant="ct-label">
              Zip code
            </FormLabel>
            <Input
              name="address_zip"
              type='text'
              variant='borderless'
              value={data.address_zip}
              onChange={handleChange}
            />
          </FormControl>
        }
        <FormControl>
          <FormLabel variant="ct-label">
            Country
          </FormLabel>
          <Input
            name="address_country"
            type='text'
            variant='borderless'
            value={data.address_country}
            disabled
          />
        </FormControl>
      </Flex>
      <Button
        mt={10}
        variant="formButton"
        type='submit'
      >
        {processing ? <FaSpinner className="fa-spin" /> : "Save"}
      </Button>
    </form>
  );
};

const stripePromise = loadStripe(packageJson.stripePKey);

const StripeCardForm = ({ onClose, paymentMethod }) => {
  return (
    <Elements stripe={stripePromise}>
      <CardForm
        paymentMethod={paymentMethod}
        onClose={onClose}
      />
    </Elements>
  );
}

export default StripeCardForm;