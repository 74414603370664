/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button, Switch } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BasicTable from "components/Tables/BasicTable";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import AccountNav from 'components/Navbars/Account';
import Loading from "components/Loading.js";

function DataTables() {
  const apiUrl = packageJson.apiurl;
  const [clients, setClient] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState('');
  const user = JSON.parse(localStorage?.getItem("user"));

  function isCheckedClient(originalRow, rowIndex) {
    let clientChecked = false;
    if(originalRow.status == 1) {
      clientChecked = true;
    }
    return <Switch id="client-switch" onChange={enableClient} name="client" value={originalRow._id} isChecked={clientChecked} />
  }

  const enableClient = async (event) => {
    const loadToast = toast.loading('Loading...');
    let clientId = event.target.value;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: clientId })
    };
    const response = await fetch(apiUrl+'/api/twilio/subaccount/create', requestOptions);
    const result = await response.json();
    if(result.statusCode == 200) {
      setLoadingData(true);
      refershSet(Math.random());
      toast.dismiss(loadToast);
      toast.success(result.message);
    } else {
      toast.dismiss(loadToast);
      setLoadingData(true);
      refershSet(Math.random());
      toast.error('Error approving client! ');
      console.log(result.message)
    }
  }

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header:"Creation Date",
      accessor:row => row.createdAt.split("T")[0],
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Email",
      accessor: "email",
    },
    {
      Header: "Approve",
      accessor: isCheckedClient,
    },
  ];

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, [refersh]);

  const getData = async () => {
    const loadToast = toast.loading('Loading...');
    let result = await fetch(apiUrl + "/api/admin/client/lists");//
    result = await result.json();
    setClient(result.data);
    setLoadingData(false);
    toast.dismiss(loadToast);
  }

  return (
    <Flex direction='column' mx='auto' className="addpub">
      <Card
        w="100%"
        p='20px'
        alignSelf='flex-start'
        justifySelf='flex-start'>
        <CardBody style={{display: 'block'}}><AccountNav current='/administrator/accounts/management' userRole={user.role}/></CardBody>
        <Text fontSize='lg' mb={6} color='#fff' fontWeight='bold'>
          Pending Clients
        </Text>
        <CardBody className="scrolltable">
          {loadingData ? (
            <Loading />
          ) : (
            <div className="clientListing"> <SearchTable1 tableData={clients} columnsData={columnsData} /> </div>
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTables;
