/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
    Button,
    Flex,
    FormControl,
    FormLabel,
    Grid,
    Icon,
    Input,
    Stack,
    Tab,
    TabList,
    TabPanel,
    TabPanels,
    Tabs,
    Text,
    Textarea,
    Switch,
    Select,
    Tag,
    TagLabel,
    TagCloseButton,
    Box,
  } from "@chakra-ui/react";

  import React, { useEffect, useState } from "react";
  // Custom components
  import axios from "axios";
  import { AiOutlineCloudUpload } from "react-icons/ai";
  import toast, { Toaster } from 'react-hot-toast';
  import packageJson from '../../appConfig.js';
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user"));
  
  
  function NewFile(props) {
    const apiUrl = packageJson.apiurl;
    const [file, setFile] = useState(null);
  
    const uploadFile = async () => {
      if (file) {
        const formData = new FormData();
        formData.append("playFile", file);
        formData.append("by", user.id);
        axios.post(apiUrl + "/api/ivrflow/files", formData, { headers: { "Content-Type": "multipart/form-data" } })
          .then(({ status, data }) => {
            if (data.success) {
              toast.success("File uploaded successfully.");
              props.onClose();
            } else {
              toast.error(data.error);
            }
          }).catch(error => {
            toast.error(error.message);
          })
      } else {
        toast.error("No file selected.");
      }
    };

    const onHandleChange = (e) => {
      setFile(e.target.files[0]);
    };
    
    
    return (
      <Flex direction='column' w='100%'>
        <FormControl>
          <FormLabel
            htmlFor="playFile"
            style={{
              display: "inline-flex",
              flexDirection: "row",
              gap: "4px",
            }} 
            borderWidth={"1px"} 
            p={2} 
            maxW={"170px"}
          >
            <p>Choose File</p>
            <AiOutlineCloudUpload style={{border: "1px solid #323b66", fontSize: "28px", padding: "4px", cursor: "pointer"}}/>
          </FormLabel>
          <Input
            type="file"
            id="playFile"
            variant="borderless"
            name="playFile"
            onChange={onHandleChange}
            accept="audio/mp3, audio/wav"
            display={"none"}
          />
          <Text fontSize={"sm"} fontWeight={"bold"}>
            {file?.name?.substring(0, 30)}
            {file?.name?.length > 30 ? "..." : ""}
          </Text>
        </FormControl>
        <Button
          mt={10}
          onClick={uploadFile}
          variant="formButton"
        >
          Upload
        </Button>
      </Flex>
    );
  }
  
  export default NewFile;
  