import {
  Button,
  Flex,
  Stack,
  Text,
} from "@chakra-ui/react";
import React from "react";
import { commonColors } from 'variables/general';
import { ChevronRightIcon, ChevronLeftIcon } from "@chakra-ui/icons";

const Paginator = (props) => {
 const { pageIndex, tableInstance } = props;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

  const h = '32px';
  const w = '25px';

  return (
    <Flex
          direction={{ sm: "column", md: "row" }}
          justify='space-between'
          align='center'
          w={props.width || '100%'}>
          {tableInstance?.pageCount > 0 ? (
          <Stack direction='row' alignSelf='flex-start' spacing='4px' ml='0' my='10px'>
            <Button
              borderRadius='0'
              padding='0'
              w={w}
              h={h}
              variant='no-hover'
              onClick={() => tableInstance?.previousPage()}
              transition='all .5s ease'
              bg={commonColors.darkNavBg}>
              <ChevronLeftIcon color={tableInstance?.canPreviousPage ? 'white' : commonColors.darkBlue} w='25px' h='auto' />
            </Button>
            {createPages(tableInstance?.pageCount).map((pageNumber, index) => {
                const isCurrent = (pageNumber === pageIndex + 1);
                return (
                  <Button
                    borderRadius='0'
                    padding='0'
                    w={w}
                    h={h}
                    variant='no-hover'
                    transition='all .5s ease'
                    onClick={() => tableInstance?.gotoPage(pageNumber - 1)}
                    bg={isCurrent ? commonColors.highlightBlue : commonColors.darkNavBg}
                    key={index}>
                    <Text
                      fontSize='md'
                      color={"#fff"}>
                      {pageNumber}
                    </Text>
                  </Button>
                );
            })}
            <Button
              borderRadius='0'
              padding='0'
              w={w}
              h={h}
              variant='no-hover'
              onClick={() => tableInstance?.nextPage()}
              transition='all .5s ease'
              bg={commonColors.darkNavBg}>
              <ChevronRightIcon color={tableInstance?.canNextPage ? 'white' : commonColors.darkBlue} w='25px' h='auto' />
            </Button>
          </Stack>
          ) : <></>}
        </Flex>
  );
};

export default Paginator;
