/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import React, { useRef, useEffect, useState, useCallback } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  ListItem,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
} from "@chakra-ui/react";

import { useHistory } from "react-router-dom";
import packageJson from "../../../appConfig.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import RadioCardGroup from "components/Form/RadioButton.js";
import CardHeader from "components/Card/CardHeader";
import toast, { Toaster } from 'react-hot-toast';
import 'reactflow/dist/style.css';


function IVRTree() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user")); //
  const [refersh, refershSet] = React.useState("");
  const [formData, setFormData] = useState({
    callFlowName: "",
    recordCallsEnable: false,
    RecordingDisclaimer: "",
    ForwardMessageByEmailEnable: false,
    ForwardMessageEmail: "",
    addWhisperMessageEnable: false,
    addWhisperMessage: "",
    missedCallNotificationEnable: false,
    missedCallNotificationSmsEnable: false,
    missedCallNotificationPhone: "",
    missedCallNotificationEmailEnable: false,
    missedCallNotificationEmail: "",
    notificationMessage: "",
    enabledNotificationToClient: false,
    emailClientDetailsEnabled: false,
    disableLinkForCallRecording: false,
    sendEmailIfCallEndedEnable: false,
    sendEmailIfCallEndedAfterSec: 0,
    emailNotificationsRecipients: "",
    press1ToConnectEnable: false,
    speechOrPlayChoice: "textToSpeech",
    TextToSpeech: "",
    playFile: null,
    enabledTrackingCallerId: false,
    AssignMonetaryValueToCallsEnable: false,
    AssignMonetaryValueToCallsPrice: 0,
    AssignMonetaryCallsOverSec: 0,
  });

  const onHandleChange = (e) => {
    const { name, type, checked, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const onHandleRadioChange = () => {
    console.log("change");
  };

  const handleFormSubmit = async () => {
    try {
      // Send the form data to the server using Axios
      const response = await axios.post(apiUrl, formData);
      console.log("Form data sent successfully!", response.data);
      // Add any additional actions you want to perform after a successful submission
    } catch (error) {
      console.error("Error sending form data:", error);
    }
  };

  /*********************Call IVR Flow Fcuntionality******************************/
  const [callFlow, setCallFlow] = useState([
    {
      id: '1',
      type: 'START'
    }
  ]);

  const [greeting, setGreeting] = useState({
    id: ((Math.random() + 1).toString(36).substring(4)).toUpperCase(),
    type: 'GREETING',
    vissible: "show",
    choose: 1,
    textToSpeech: "",
    audioFile: ""
  });

  const [forwardCall, setForwardCall] = useState({
    id: ((Math.random() + 1).toString(36).substring(4)).toUpperCase(),
    type: 'FORWARDCALL',
    vissible: "show",
    phone: "",
    second: 20
  });

  const [voicemail, setVoicemail] = useState({
    id: ((Math.random() + 1).toString(36).substring(4)).toUpperCase(),
    type: 'VOICEMAIL',
    vissible: "show",
    choose: 1,
    textToSpeech: "",
    audioFile: "",
    emailNotification: ""
  });

  const greetingNodeInsert = e => {
    const newCallFlow = [...callFlow];
    newCallFlow.push(greeting);
    setCallFlow(newCallFlow);
  }

  const forwardCallNodeInsert = e => {
    const newCallFlow = [...callFlow];
    newCallFlow.push(forwardCall);
    setCallFlow(newCallFlow);
  }

  const voicemailCallNodeInsert = e => {
    const newCallFlow = [...callFlow];
    newCallFlow.push(voicemail);
    setCallFlow(newCallFlow);
  }

  return (
    <Flex className='addpub' flexDirection='column' gap={5}>
      <FormControl>
      <FormLabel variant="ct-label">
        Call Flow Name <i className="fa-regular fa-circle-question" />
      </FormLabel>
        <Input
          type="text"
          variant="borderless"
          placeholder="Title of your Call flow"
          name="callFlowName"
          onChange={onHandleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Record Calls
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="recordCallsEnable"
            value={formData.recordCallsEnable}
            defaultIsChecked={formData.recordCallsEnable}
          />
        </FormLabel>
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Recording Disclaimer
        </FormLabel>
        <Textarea
          variant="borderless"
          name="RecordingDisclaimer"
          placeholder="Enter Desclaimer massage"
          value={formData.RecordingDisclaimer} 
          onChange={onHandleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Forward SMS Messages via email
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="ForwardMessageByEmailEnable"
            value={formData.ForwardMessageByEmailEnable}
            defaultIsChecked={formData.ForwardMessageByEmailEnable}
          />
        </FormLabel>
      </FormControl>
      {formData.ForwardMessageByEmailEnable &&
        <FormControl>
          <FormLabel variant="ct-label">
            Email Address
          </FormLabel>
          <Input
            type="email"
            variant="borderless"
            name="ForwardMessageEmail"
            value={formData.ForwardMessageEmail} 
            onChange={onHandleChange}
          />
        </FormControl>
      }
      <FormControl>
        <FormLabel variant="ct-label">
          Add a Whisper Message
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="addWhisperMessageEnable"
            value={formData.addWhisperMessageEnable}
            defaultIsChecked={formData.addWhisperMessageEnable}
          />
        </FormLabel>
      </FormControl>
      {formData.addWhisperMessageEnable &&
        <FormControl>
          <FormLabel variant="ct-label">
            Call Whisper Text
          </FormLabel>
          <Textarea
            variant="borderless"
            name="addWhisperMessage"
            placeholder="Enter Whisper Text"
            value={formData.addWhisperMessage} 
            onChange={onHandleChange}
          />
        </FormControl>
      }
      <Text align="center" color={"#fff"} fontSize='xl' fontWeight='bold'>
        Automations
      </Text>
      <Flex flexDirection={"column"} gap={2}>
        <FormLabel variant="ct-label">
          Send a Missed Call Notification
        </FormLabel>
        <FormControl>
          <FormLabel variant="ct-label">
            SMS
            <Switch
              ml={10}
              onChange={onHandleChange}
              name="missedCallNotificationSmsEnable"
              value={formData.missedCallNotificationSmsEnable}
              defaultIsChecked={formData.missedCallNotificationSmsEnable}
            />
          </FormLabel>
        </FormControl>
        {formData.missedCallNotificationSmsEnable &&
          <FormControl>
            <Input
              type="number"
              variant="borderless"
              name="missedCallNotificationPhone"
              placeholder="Enter phone number"
              value={formData.missedCallNotificationPhone} 
              onChange={onHandleChange}
            />
          </FormControl>
        }
        <FormControl>
          <FormLabel variant="ct-label">
            Email
            <Switch
              ml={10}
              onChange={onHandleChange}
              name="missedCallNotificationEmailEnable"
              value={formData.missedCallNotificationEmailEnable}
              defaultIsChecked={formData.missedCallNotificationEmailEnable}
            />
          </FormLabel>
        </FormControl>
        {formData.missedCallNotificationEmailEnable &&
          <FormControl>
            <Input
              type="email"
              variant="borderless"
              name="missedCallNotificationEmail"
              placeholder="Enter an email address"
              value={formData.missedCallNotificationEmail} 
              onChange={onHandleChange}
            />
          </FormControl>
        }
      </Flex>
      <FormControl>
        <FormLabel variant="ct-label">
          Notification Message
        </FormLabel>
        <Textarea
          variant="borderless"
          name="notificationMessage"
          placeholder="Max Lenth is 160 characters."
          value={formData.notificationMessage} 
          onChange={onHandleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Include caller number in the body?
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="enabledNotificationToClient"
            value={formData.enabledNotificationToClient}
            defaultIsChecked={formData.enabledNotificationToClient}
          />
        </FormLabel>
        <FormLabel variant="ct-label">
          This is helpful if you are sending the notification text to your client instead of the caller.
        </FormLabel>
      </FormControl>
      <Text align="center" color={"#fff"} fontSize='xl' fontWeight='bold'>
        Email Call Details
      </Text>
      <Flex flexDirection={"column"} gap={2}>
        <FormControl>
          <FormLabel variant="ct-label">
            Dont include the link to the call recording
            <Switch
              ml={10}
              onChange={onHandleChange}
              name="disableLinkForCallRecording"
              value={formData.disableLinkForCallRecording}
              defaultIsChecked={formData.disableLinkForCallRecording}
            />
          </FormLabel>
        </FormControl>
        <Flex flexDirection={"row"} justifyContent={"space-between"}>
          <FormControl flexBasis={"65%"}>
            <FormLabel variant="ct-label">
              Send email if call is over
              <Switch
                ml={10}
                onChange={onHandleChange}
                name="sendEmailIfCallEndedEnable"
                value={formData.sendEmailIfCallEndedEnable}
                defaultIsChecked={formData.sendEmailIfCallEndedEnable}
              />
            </FormLabel>
          </FormControl>
          <FormControl flexBasis={"35%"}>
            <InputGroup variant="borderless">
              <Input
                type="number"
                // variant="borderless"
                name="sendEmailIfCallEndedAfterSec"
                value={formData.sendEmailIfCallEndedAfterSec} 
                onChange={onHandleChange}
              />
              <InputRightAddon children={`sec's`} />
            </InputGroup>
          </FormControl>
        </Flex>
        {formData.sendEmailIfCallEndedEnable &&
          <FormControl>
            <FormLabel variant="ct-label">
              Email Notifications Recipients
            </FormLabel>
            <Textarea
              variant="borderless"
              name="emailNotificationsRecipients"
              value={formData.emailNotificationsRecipients} 
              onChange={onHandleChange}
            />
            <FormLabel variant="ct-label">
              If multiple, separate by commas.
            </FormLabel>
          </FormControl>
        }
      </Flex>
      <FormControl flexBasis={"65%"}>
        <FormLabel variant="ct-label">
          Press 1 To Connect
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="press1ToConnectEnable"
            value={formData.press1ToConnectEnable}
            defaultIsChecked={formData.press1ToConnectEnable}
          />
        </FormLabel>
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Speech or Play Choice
        </FormLabel>
        <RadioCardGroup 
          defaultValue={formData.speechOrPlayChoice}
          name="speechOrPlayChoice"
          onChange={(value) => setFormData({
            ...formData,
            speechOrPlayChoice: value,
          })}
          options={[
            {value: "TextToSpeech", text: "Text to Speech"},
            {value: "Play", text: "Play Audio"},
          ]}
        />
      </FormControl>
      {formData.speechOrPlayChoice === "TextToSpeech" &&
        <FormControl>
          <FormLabel variant="ct-label">
            Text to Speech
          </FormLabel>
          <Textarea
            variant="borderless"
            name="TextToSpeech"
            value={formData.TextToSpeech} 
            onChange={onHandleChange}
          />
        </FormControl>
      }
      {formData.speechOrPlayChoice === "Play" &&
        <FormControl>
          <FormLabel variant="ct-label">
            Play File
          </FormLabel>
          <Input
            type="file"
            variant="borderless"
            name="playFile"
            value={formData.playFile} 
            onChange={onHandleChange}
          />
        </FormControl>
      }
      <FormControl flexBasis={"65%"}>
        <FormLabel variant="ct-label">
          Track Number Caller ID
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="enabledTrackingCallerId"
            value={formData.enabledTrackingCallerId}
            defaultIsChecked={formData.enabledTrackingCallerId}
          />
        </FormLabel>
      </FormControl>
      <FormControl flexBasis={"65%"}>
        <FormLabel variant="ct-label">
          Assign monetary value to calls
          <Switch
            ml={10}
            onChange={onHandleChange}
            name="AssignMonetaryValueToCallsEnable"
            value={formData.AssignMonetaryValueToCallsEnable}
            defaultIsChecked={formData.AssignMonetaryValueToCallsEnable}
          />
        </FormLabel>
      </FormControl>
      {formData.AssignMonetaryValueToCallsEnable &&
        <>
          <FormControl>
            <InputGroup variant="borderless">
              <InputLeftAddon children={`Assign a monetary value of $`} />
              <Input
                type="number"
                name="AssignMonetaryValueToCallsPrice"
                value={formData.AssignMonetaryValueToCallsPrice} 
                onChange={onHandleChange}
              />
            </InputGroup>
          </FormControl>
          <FormControl>
            <InputGroup variant="borderless">
              <InputLeftAddon children={`for calls over`} />
              <Input
                type="number"
                name="AssignMonetaryCallsOverSec"
                value={formData.AssignMonetaryCallsOverSec} 
                onChange={onHandleChange}
              />
              <InputLeftAddon children={`sec`} />
            </InputGroup>
          </FormControl>
        </>
      }
      <Button variant="formButton">
        Save
      </Button>
      {/* sidebar start*/}

      {/* <div className={`FixedSide`}>
        <div className={`side_bar side_menu`}>
          <div className="menu_header">
            <div className="left_br">
              <span>Call Flow Settings</span>
              <i className="fa-regular fa-circle-question" />
            </div>
            <div className="right_br">
              <i className="fa-solid fa-copy"></i>
              <i className="fa-solid fa-trash" ></i>
              <i className="fa-solid fa-xmark" />
            </div>
          </div>
          <div className="node_name">
            <div className="coluem">
              <label>
                Call Flow Name <i className="fa-regular fa-circle-question" />
              </label>
              <input
                type="text"
                classname="Menu"
                placeholder="Title of your Call flow"
                name="RecordingDisclaimer"
                onChange={onHandleChange}
              />
            </div>


            <div className="coluem full-nt">
              <div className="recordcall">
                <label>
                  <input type="checkbox" name="recordCallsEnable" onChange={onHandleChange} />
                  Record Calls <i className="fa-regular fa-circle-question" />
                </label>
                <p>Recording Disclaimer</p>
                <textarea placeholder="Enter Desclaimer massage" name="RecordingDisclaimer" onChange={onHandleChange}> </textarea>
              </div>
            </div>

            <div className="coluem full-nt">
              <div className="recordcall">
                <label>
                  <input type="checkbox" name="ForwardMessageByEmailEnable" onChange={onHandleChange} />
                  Forward SMS Messages via email <i className="fa-regular fa-circle-question" />
                </label>
                <p>Email Address</p>
                <input type="email" name="ForwardMessageEmail" onChange={onHandleChange} />
              </div>
            </div>

            <div className="coluem full-nt">
              <div className="recordcall">
                <label>
                  <input type="checkbox" name="addWhisperMessageEnable" onChange={onHandleChange} />
                  Add a Whisper Message <i className="fa-regular fa-circle-question" />
                </label>
                <p>Call Whisper Text</p>
                <textarea placeholder="Enter Whisper Text" name="addWhisperMessage" onChange={onHandleChange}> </textarea>
              </div>
            </div>

            <div className="coluem full-nt">
              <div className="recordcall">
                <p>AUTOMATIONS</p>
                <div>
                  <label><input type="checkbox" name="missedCallNotificationEnable" onChange={onHandleChange} /> Send a Missed Call Notification </label>
                  <div className="innerElements">
                    <span> <input type="radio" style={{ width: "15px" }} name="missedCallNotificationSmsEnable" onChange={onHandleChange} /> SMS
                      <input type="number" placeholder="Enter phone number" name="missedCallNotificationPhone" onChange={onHandleChange} />
                    </span>
                    <span>
                      <input type="radio" style={{ width: "15px" }} name="missedCallNotificationEmailEnable" onChange={onHandleChange} /> Email
                      <input type="email" placeholder="Enter an email address" name="missedCallNotificationEmail" onChange={onHandleChange} />
                      <span>
                        Notification Message
                        <textarea style={{ color: "#000" }} name="notificationMessage" onChange={onHandleChange}></textarea>
                        Max Lenth is 160 characters.
                      </span>
                      <span style={{ display: "flex" }}> <input type="checkbox" style={{ width: "20px", margin: "5px" }} name="enabledNotificationToClient" onChange={onHandleChange} /> Include caller number in the body? This is helpful if you are sending the notification text to your client instead of the caller.</span>
                    </span>
                  </div>
                </div>
                <div>
                  <label><input type="checkbox" name="emailClientDetailsEnabled" onChange={onHandleChange} /> Email Call Details </label>
                  <div className="innerElements">
                    <span style={{ display: "flex" }}> <input style={{ width: "20px", marginRight: "5px" }} type="checkbox" name="disableLinkForCallRecording" onChange={onHandleChange} /> Dont include the link to the call recording
                    </span>
                    <span>
                      <Flex style={{ gap: "5px" }}>
                        <input type="checkbox" style={{ width: "20px" }} name="sendEmailIfCallEndedEnable" onChange={onHandleChange} />
                        <p>Send email if call is over</p>
                        <input type="number" style={{ padding: "0 15px", height: "25px", width: "60px" }} name="sendEmailIfCallEndedAfterSec" onChange={onHandleChange} /> sec's
                      </Flex>
                      <span>
                        Email Notifications Recipients
                        <textarea style={{ color: "#000" }} name="emailNotificationsRecipients" onChange={onHandleChange}></textarea>
                        If multiple, separate by commas.
                      </span>
                    </span>
                  </div>
                </div>
              </div>
            </div>

            <div className="coluem full-nt">
              <div className="recordcall">
                <label>
                  <input type="checkbox" name="press1ToConnectEnable" />
                  Press 1 To Connect <i className="fa-regular fa-circle-question" onChange={onHandleChange} />
                </label>
                <div className="radioType">
                  <span style={{ color: "#000", display: "flex", alingItems: "center" }}><input type="radio" name="speechOrPlayChoice" value="TextToSpeech" style={{ width: "15px", marginBottom: "0", marginRight: "5px" }} onChange={onHandleChange} /> Text to Speech </span>
                  <span style={{ color: "#000", display: "flex", alingItems: "center" }}><input type="radio" name="speechOrPlayChoice" value="Play" style={{ width: "15px", marginBottom: "0", marginRight: "5px" }} onChange={onHandleChange} /> Play Audio </span>
                </div>
                <div>
                  <textarea style={{ color: "#000" }} name="TextToSpeech" onChange={onHandleChange}> </textarea>
                  <input type="file" style={{ color: "#000", border: "1px dashed" }} name="playFile" onChange={onHandleChange} />
                </div>
              </div>
            </div>

            <div className="coluem full-nt">
              <div className="recordcall">
                <label>
                  <input type="checkbox" name="enabledTrackingCallerId" onChange={onHandleChange} />
                  Tracking Number Caller ID<i className="fa-regular fa-circle-question" />
                </label>
              </div>
            </div>

            <div className="coluem full-nt">
              <div className="recordcall">
                <label>
                  <input type="checkbox" name="AssignMonetaryValueToCallsEnable" onChange={onHandleChange} />
                  Assign monetary value to calls <i className="fa-regular fa-circle-question" />
                </label>
                <div className="radioTy">
                  <span style={{ color: "#000", display: "flex", alingItems: "center" }}>Assign a monetary value of $<input type="number" placeholder="$50" style={{ height: "25px", width: "45%" }} name="AssignMonetaryValueToCallsPrice" onChange={onHandleChange} />  </span>
                  <span style={{ color: "#000", display: "flex", alingItems: "center" }}>for calls over<input type="number" placeholder="120" style={{ height: "25px", width: "45%" }} name="AssignMonetaryCallsOverSec" onChange={onHandleChange} /> sec  </span>
                </div>
              </div>
            </div>
            <Button className="save">Save</Button>
          </div>
        </div>
      </div> */}

      {/* sidebar end */}

      {/* <Card px='0px'>
        <Button className="back"><i className="fa-solid fa-left-long"></i> Go Back</Button>
        <CardBody>
          <div className="callFlow">
            <ul className="firstListingbox">
              {callFlow.map((item) => (
                <li key={item.id}>
                  {item.type === 'START' ? (
                    <div className="newCall">
                      <i className="fa-solid fa-phone"></i>
                      <p>NEW CALL IS RECEIVED <span style={{ display: "block" }}>What should happen next?</span></p>
                    </div>
                  ) : item.type === 'GREETING' ? (
                    <div className="newCall">
                      <i className="fa-solid fa-xmark"></i>
                      <i className="fa-solid fa-envelope"></i>
                      <p>Greeting</p>
                      <div className={item.vissible}>
                        <p>Use text to speech or play an audio file to your caller.</p>
                        <div className="radioType">
                          <span><input type="radio" name="choose" value="1" checked={item.choose === 1} /> Text to Speech </span>
                          <span><input type="radio" name="choose" value="2" checked={item.choose === 2} /> Play Audio </span>
                        </div>
                        <div>
                          <textarea style={{ color: "#000" }} name="speechToText">{item.textToSpeech}</textarea>
                          <input type="file" />
                        </div>
                        <div style={{ textAlign: "right", margin: "20px 0" }}>
                          <Button className="cancel">Cancel</Button>
                          <Button className="save">Save</Button>
                        </div>
                      </div>
                    </div>
                  ) : item.type === 'FORWARDCALL' ? (
                    <div className="newCall">
                      <i className="fa-solid fa-xmark"></i>
                      <i className="fa-solid fa-share-from-square"></i>
                      <p>Forward Call</p>
                      <div className={item.vissible}>
                        <p>Forward the incoming call to a specific number.</p>
                        <div className="radioType">
                          <span>
                            <input type="text" style={{ color: "#000" }} className="countrySelection" name="phone" value={item.phone} /> </span>
                        </div>
                        <div>
                          <span>Wait
                            <select>
                              <option value="20" selected={item.second === 20 ? true : false}>20S</option>
                              <option value="30" selected={item.second === 30 ? true : false}>30S</option>
                            </select>
                            then move to next step if no one answers.</span>
                        </div>
                        <div style={{ textAlign: "right", margin: "20px 0" }}><Button className="cancel">Cancel</Button><Button className="save">Save</Button></div>
                      </div>
                    </div>
                  ) : item.type === 'VOICEMAIL' ? (
                    <div className="newCall">
                      <i className="fa-solid fa-xmark"></i>
                      <i className="fa-solid fa-circle-play"></i>
                      <p>Voicemail</p>
                      <div className={item.vissible}>
                        <p>Use text to speech or play an audio file to your caller.</p>
                        <div className="radioType">
                          <span><input type="radio" name="choose" value="1" checked={item.choose === 1} /> Text to Speech </span>
                          <span><input type="radio" name="choose" value="2" checked={item.choose === 2} /> Play Audio </span>
                        </div>
                        <div>
                          <textarea style={{ color: "#000" }} name="speechToText">{item.textToSpeech}</textarea>
                          <input type="file" name="audioFile" />
                        </div>
                        <div>
                          <p style={{ textAlign: "left", margin: "15px 0px" }}>Send an email notification with a voicemail recording link.</p>
                          <input type="email" name="emailNotification" value={item.emailNotification} style={{ height: "40px", width: "100%", color: "#000", padding: "10px" }} />
                        </div>
                        <div style={{ textAlign: "right", margin: "20px 0" }}><Button className="cancel">Cancel</Button><Button className="save">Save</Button></div>
                      </div>
                    </div>
                  ) : null}
                </li>
              ))}
            </ul>
            <div className="nextSetp" style={{ background: "#582cff" }}>
              <p>Add a next step to your call flow</p>
              <ul className="callSteps">
                <li onClick={greetingNodeInsert}>
                  <i className="fa-solid fa-envelope"></i>
                  <p>Greeting</p>
                </li>
                <li onClick={forwardCallNodeInsert}>
                  <i className="fa-solid fa-share-from-square"></i>
                  <p>Forward Call</p>
                </li>
                <li onClick={voicemailCallNodeInsert}>
                  <i className="fa-solid fa-circle-play"></i>
                  <p>Voicemail</p>
                </li>
                <li>
                  <i className="fa-solid fa-users"></i>
                  <p>Multi Ringing</p>
                </li>
                <li>
                  <i className="fa-solid fa-share-nodes"></i>
                  <p>Call Tree Menu</p>
                </li>
              </ul>
            </div>
          </div>



        </CardBody>
      </Card> */}
    </Flex>
  );
}

export default IVRTree;
