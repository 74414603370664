/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState, useMemo } from "react";

// Chakra imports
import {
  Flex,
  Text,
  Button,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Icon,
} from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BasicTable from "components/Tables/BasicTable";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";
import packageJson from "../../../appConfig.js";
// Data
//import { columnsData1 } from "variables/columnsData";
//import tableData1 from "variables/tableData1.json";
import { useTable, useSortBy, usePagination } from "react-table";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import ExportToExcelButton from "components/ExportExcel/ExportToExcelButton";

function DataTables() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user"));
  const [publishers, setPublishers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState("");

  useEffect(() => {
    if (loadingData) {
      getData();
      getDirectForwardDetails();
    }
  }, [refersh]); //

  const getData = async () => {
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    let result = await fetch(
      apiUrl + "/api/number/client/forwarding/number/lists",
      requestOptions
    );
    result = await result.json();
    setPublishers(result.data);
    setLoadingData(false);
    toast.dismiss(loadToast);
  };

  const handleEdit = (originalRow) => {
    history.push("/admin/direct-forward/number/" + originalRow._id + "/edit");
  };

  const addNumber = (originalRow) => {
    history.push("/admin/direct-forward/number/create");
  };

  const deleteNumber = (originalRow) => {
    if (!confirm("Do You want to Delete This Number?")) {
      return false;
    }

    let config = {
      method: "post",
      url: apiUrl + "/api/number/client/forwarding/number/delete",
      headers: {
        "Content-Type": "application/json",
      },
      data: { id: originalRow._id },
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          //refershSet("");
          setLoadingData(true);
          refershSet(Math.random());
          toast.success("Number has been Deleted Successfully");
        }
      })
      .catch((error) => {
        toast.error(error);
      });
  };

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header:"Creation Date",
      accessor:row => row.createdAt.split("T")[0],
    },
    {
      Header: "Number",
      accessor: "phone",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Destination",
      accessor: "destination",
    },
    {
      Header: "Timeout",
      accessor: "timeout",
    },
    {
      Header: "Status",
      accessor: (originalRow, rowIndex) => (
        <div>
          <i
            className="fa-sharp fa-solid fa-circle"
            style={{ color: "#22ca0c" }}
          />
        </div>
      ),
      id: "status",
    },
    {
      Header: "Action",
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(originalRow);
            }}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </a>{" "}
          |{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              deleteNumber(originalRow);
            }}
          >
            <i className="fa-solid fa-trash"></i>
          </a>
        </div>
      ),
      id: "action",
    },
  ];

  // direct forward report
  const [directForward, setDirectForward] = useState([]);

  const getDirectForwardDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };

    let result = await fetch(
      apiUrl + "/api/admin/report/direct/forward",
      requestOptions
    );
    result = await result.json();
    console.log(result,"result")
    setDirectForward(result);
  };

  console.log(directForward,"directForward")
  // new changes
  const directForward_column = [
    {
      Header: "DATE",
      accessor: "createdAt",
    },
    {
      Header: "TO",
      accessor: "to",
    },
    {
      Header: "FROM",
      accessor: "from",
    },
    {
      Header: "THROUGH",
      accessor: "forwardedFrom",
    },
    {
      Header: "STATUS",
      accessor: "status",
    },
  ];

  const directHead = useMemo(() => directForward_column, []);
  const data4 = useMemo(() => directForward, [directForward]);

  const directTable = useTable(
    {
      columns: directHead,
      data: data4,
    },
    useSortBy
    // usePagination
  );

  const {
    getTableProps: getTableProps4,
    getTableBodyProps: getTableBodyProps4,
    headerGroups: headerGroups4,
    // page,
    rows: row4,
    prepareRow: prepareRow4,
  } = directTable;

  return (
    <Flex
      className="addpub"
      direction="column"
      pt={{ sm: "125px", lg: "75px" }}
    >
      <Card px="0px">
        <CardHeader px="22px" mb="24px">
          <Flex direction="column">
            <Text fontSize="lg" mb="5px" color="#fff" fontWeight="bold">
              Direct Forwarding
            </Text>
            <Text fontSize="sm" color={"gray.400"} fontWeight="400" mb="15px">
              A lightweight, extendable, dependency-free javascript HTML table
              plugin.
            </Text>
          </Flex>
        </CardHeader>

        <CardBody>
          <Button
            variant="brand"
            alignSelf="flex-end"
            mt="24px"
            w="100px"
            h="35px"
            onClick={addNumber}
          >
            <Text fontSize="10px" color="#fff">
              Setup Forwarding
            </Text>
          </Button>
          {loadingData ? (
            <p>Loading Please wait...</p>
          ) : (
            <SearchTable1 tableData={publishers} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
      <Card px="0px" mt="1rem">
        <CardHeader
          px="22px"
          mb="32px"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text color="#fff" fontSize="lg" fontWeight="bold">
            Direct Forward Report
          </Text>
          <div>
            <ExportToExcelButton
              columns={directForward_column}
              data={row4}
              fileName="table_data"
              sheetName="Sheet 1"
            />
          </div>
        </CardHeader>
        <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
          <Table
            {...getTableProps4()}
            className="report_custom_table"
            variant="simple"
          >
            <Thead>
              {headerGroups4?.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup?.headers?.map((column, index) => (
                    <Th
                      color="gray.400"
                      fontSize="10px"
                      fontFamily="Inter"
                      borderColor="#56577A"
                      minW={index === 0 ? "100px" : "auto"}
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      pe="0px"
                      key={index}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <Icon
                          w={{ sm: "10px", md: "14px" }}
                          h={{ sm: "10px", md: "14px" }}
                          color={directHead.isSorted ? "gray.500" : "gray.400"}
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      </Flex>
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyProps4()}>
              {directForward.length > 0 ? (
                row4?.map((row, index) => {
                  prepareRow4(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row?.cells?.map((cell, index) => {
                        const header = headerGroups4[0].headers[index];
                        if (header.Header === "DATE") {
                          return (
                            <Td
                              borderColor="#56577A"
                              color="white"
                              {...cell.getCellProps()}
                              fontSize={{ sm: "14px" }}
                              key={index}
                              style={{
                                textAlign: "left",
                                paddingLeft: "5px",
                              }}
                            >
                              {cell.row.original.createdAt.substring(0, 10)}
                              {cell.row.original.createdAt.substring(11, 16)}
                            </Td>
                          );
                        } else {
                          return (
                            <Td
                              borderColor="#56577A"
                              color="white"
                              {...cell.getCellProps()}
                              fontSize={{ sm: "14px" }}
                              key={index}
                              style={{
                                textAlign: "left",
                                paddingLeft: "5px",
                              }}
                            >
                              {cell.render("Cell")}
                            </Td>
                          );
                        }
                      })}
                    </Tr>
                  );
                })
              ) : (
                <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTables;
