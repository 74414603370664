/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";

// Chakra imports
import {
  Button,
  Flex,
  Grid,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Progress,
  LightMode,
  Stack,
  Table,
  Tbody,
  Text,
  Th,
  Thead,
  Tr,
  useColorModeValue,
  useDisclosure,
} from "@chakra-ui/react";

// Images
import bgCardReports from "assets/img/background-card-reports.png";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import IconBox from "components/Icons/IconBox";
import TablesReportsRow from "components/Tables/TablesReportsRow";

// Icons
import { CartIcon, RocketIcon } from "components/Icons/Icons";
import { AiFillLike } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { IoEllipsisHorizontalSharp } from "react-icons/io5";

// Data
import { tablesReportsData } from "variables/general";

function Reports() {
  // Ellipsis Modals
  const {
    isOpen: isOpen1,
    onOpen: onOpen1,
    onClose: onClose1,
  } = useDisclosure();

  const {
    isOpen: isOpen2,
    onOpen: onOpen2,
    onClose: onClose2,
  } = useDisclosure();

  const {
    isOpen: isOpen3,
    onOpen: onOpen3,
    onClose: onClose3,
  } = useDisclosure();

  const {
    isOpen: isOpen4,
    onOpen: onOpen4,
    onClose: onClose4,
  } = useDisclosure();

  return (
    <Flex direction='column' pt={{ base: "150px", lg: "75px" }}>
      {/* Table */}
      <Card overflowX={{ sm: "scroll", lg: "hidden" }}>
        <CardBody>
          <Table variant='simple' color='#fff'>
            <Thead>
              <Tr my='.8rem' ps='0px' color='gray.400'>
                <Th
                  ps='0px'
                  color='gray.400'
                  fontFamily='Inter'
                  borderColor='#56577A'>
                  Name
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Inter'
                  borderColor='#56577A'>
                  Role
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Inter'
                  borderColor='#56577A'>
                  Status
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Inter'
                  borderColor='#56577A'>
                  Email
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Inter'
                  borderColor='#56577A'>
                  Created At
                </Th>
                <Th
                  color='gray.400'
                  fontFamily='Inter'
                  borderColor='#56577A'>
                  Emp Id
                </Th>
              </Tr>
            </Thead>
            <Tbody pb='0px'>
              {tablesReportsData.map((row, index, arr) => {
                return (
                  <TablesReportsRow
                    name={row.name}
                    image={row.image}
                    email={row.email}
                    domain={row.domain}
                    review={row.review}
                    employed={row.employed}
                    id={row.id}
                    isLast={index === arr.length - 1 ? true : false}
                    key={index}
                  />
                );
              })}
            </Tbody>
          </Table>
        </CardBody>
      </Card>
    </Flex>
  );
}

export default Reports;
