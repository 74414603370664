import {
  useRadio,
  useRadioGroup,
  Box,
  HStack
} from '@chakra-ui/react';

const RadioCard =(props) => {
  const { getInputProps, getCheckboxProps } = useRadio(props);

  const input = getInputProps();
  const checkbox = getCheckboxProps();

  return (
    <Box
      as='label'
      bg={'#323b66'}
      minW={'80px'}
      style={{marginLeft: '2px'}}
      color={'white'}
      textAlign={'center'}
    >
      <input {...input} />
      <Box
        fontSize={'xs'}
        fontWeight={'bold'}
        py={1}
        px={1}
        {...checkbox}
        cursor='pointer'
        _checked={{
          bg: 'blue.500',
          color: 'white',
        }}
        _focus={{
          boxShadow: 'outline',
        }}
      >
        {props.children}
      </Box>
    </Box>
  );
};

const RadioCardGroup = (props) => {
  const { getRootProps, getRadioProps } = useRadioGroup({
    name: props.name,
    defaultValue: props.defaultValue,
    onChange: props.onChange,
    value: props.value,
  });

  const group = getRootProps();

  return (
    <HStack {...group}>
      {props.options.map(({ value, text }) => {
        const radio = getRadioProps({ value });
        return (
          <RadioCard key={value} {...radio} style={{marginLeft: '0'}}>
            {text}
          </RadioCard>
        )
      })}
    </HStack>
  )
};

export default RadioCardGroup;
