import { commonColors } from 'variables/general';

const Card = {
  baseStyle: {
    p: "22px",
    display: "flex",
    flexDirection: "column",
    width: "100%",
    borderRadius: "10px",
    bg: commonColors.bgBlue,
  },
};

export const CardComponent = {
  components: {
    Card,
  },
};
