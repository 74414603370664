import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import { Text } from "@chakra-ui/react";

export default function ComingSoon() {
  return (
    <Card
      w="100%"
      p='50px'
      style={{textAlign: 'center'}}>
      <CardBody>
        <Text width='100%' fontSize='30px' fontWeight='bold' color='white'>
          Coming Soon
        </Text>
      </CardBody>
    </Card>
  );
};
