/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import React, { useRef, useEffect, useState, useMemo } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
  SliderMark,
  Tooltip,
  Select,
  Box,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  ListItem,
  InputGroup,
  InputRightAddon,
  InputLeftAddon,
  useDisclosure,
} from "@chakra-ui/react";
import { AiOutlineCloudUpload } from "react-icons/ai";
import axios from "axios";

import { useHistory } from "react-router-dom";
import { langToLang } from "language-name-to-language-name";
import packageJson from "../../../appConfig.js";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import RadioCardGroup from "components/Form/RadioButton.js";
import CTModal from "components/Modal";
import CardHeader from "components/Card/CardHeader";
import toast, { Toaster } from 'react-hot-toast';
import 'reactflow/dist/style.css';
import { commonColors } from 'variables/general';


export default function IVRFlow(props) {
  const languages = useMemo(() => Object.values(langToLang("en")).reverse(), []);
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user")); //
  const [refersh, refershSet] = React.useState("");
  const [showTooltip, setShowTooltip] = React.useState(false);
  const [planKey, setPlanKey] = useState(null);
  const [routingPlans, setRoutingPlans] = useState([]);
  const [currentPlan, setCurrentPlan] = useState("");
  const [edit, setEdit] = useState(false);
  const [playFiles, setPlayFiles] = useState([])
  const { isOpen, onOpen, onClose, onCloseComplete } = useDisclosure();
  const [formData, setFormData] = useState({
    name: "",
    timeLimit: "",
    tries: 1,
    speechMode: "textToSpeech",
    textToSpeech: "",
    playFile: "",
    language: "English",
    voice: "man",
    linkedPlans: [],
  });

  useEffect(() => {
    getRoutingPlans();
    if (props.ivrFlow) {
      setFormData(props.ivrFlow)
      const ivrRow = props.ivrFlow;
      if (!ivrRow.playFile) {
        ivrRow.playFile = "";
      }
      setFormData(ivrRow);
      setEdit(true);
    }
  }, []);

  const onHandleChange = (e) => {
    if (e.target.name === "playFile") {
      setFormData({
        ...formData,
        [e.target.name]: e.target.files[0],
      });
    } else if (e.target.name === "selectedFile") {
      setFormData({
        ...formData,
        playFile: e.target.value,
      });
    } else {
      setFormData({
        ...formData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCreateIvr = () => {
    const loadToast = toast.loading("Loading...");
    const ivrData = { ...formData };
    if (ivrData.speechMode === "textToSpeech") {
      delete ivrData.playFile;
    }
    const multipartForm = new FormData();
    multipartForm.append("by", user.id);
    for (let key in ivrData) {
      if (key === "linkedPlans") {
        multipartForm.append(key, JSON.stringify(ivrData[key]));
      } else {
        multipartForm.append(key, ivrData[key]);
      }
    }
    try {
      axios.post("/api/ivrflow", multipartForm, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({ status, data}) => {
          if (data.success) {
            toast.dismiss(loadToast);
            toast.success("Ivr created successfully");
            props.onClose();
          } else {
            toast.dismiss(loadToast);
            toast.error(data.error);
          }
        });
    } catch (error) {
      toast.dismiss(loadToast);
      toast.error(error);
    }
  };

  const handleUpdateIvr = () => {
    const loadToast = toast.loading("Loading...");
    const multipartForm = new FormData();
    multipartForm.append("id", formData._id);
    const ivrData = { ...formData };
    delete ivrData._id;
    delete ivrData.createdAt;
    delete ivrData.updatedAt;
    delete ivrData.__v;
    delete ivrData.by;
    if (ivrData.speechMode === "textToSpeech") {
      delete ivrData.playFile;
    }
    multipartForm.append("updatedIvr", JSON.stringify(ivrData));
    try {
      axios.put("/api/ivrflow", multipartForm, { headers: { 'Content-Type': 'multipart/form-data' } })
        .then(({ status, data}) => {
          if (data.success) {
            toast.dismiss(loadToast);
            toast.success("Ivr updated successfully");
            props.onClose();
          } else {
            toast.dismiss(loadToast);
            toast.error(data.error);
          }
        });
    } catch (error) {
      toast.dismiss(loadToast);
      toast.error(error);
    }
  };

  const addRoutingPlan = () => {
    const formDataCopy = { ...formData };
    const existingLinkedKey = formDataCopy.linkedPlans.filter(plan => plan.planKey != planKey);
    if (currentPlan !== "") {
      existingLinkedKey.push({
        planKey: planKey,
        routingPlan: currentPlan,
      });
    }
    formDataCopy.linkedPlans = existingLinkedKey;
    setFormData(formDataCopy);
    setCurrentPlan("");
    setPlanKey(null);
    onClose();
  };

  const removeRoutingPlan = () => {
    const formDataCopy = { ...formData };
    const existingLinkedKey = formDataCopy.linkedPlans.filter(plan => plan.planKey != planKey);
    formDataCopy.linkedPlans = existingLinkedKey;
    setFormData(formDataCopy);
    setCurrentPlan("");
    setPlanKey(null);
    onClose();
  }

  const getRoutingPlans = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    let result = await fetch(apiUrl + "/api/routingplan/lists", requestOptions);
    result = await result.json();
    setRoutingPlans(result);
    await getFiles();
    toast.dismiss(loadToast);
  };

  const getFiles = () => {
    const requestOptions = {
      params: { userId: user.id },
      headers: { 'Content-Type': 'multipart/form-data' }
    }
    try {
      axios.get(apiUrl + "/api/ivrflow/files", requestOptions)
        .then(({ status, data}) => {
          if (data.success) {
            setPlayFiles(data.media);
          } else {
            console.log(data.error);
          }
        })
    } catch(error) {
      console.log(error.message);
    }
  };

  return (
    <Flex flexDirection={"column"} gap={5}>
      <FormControl>
        <FormLabel variant="ct-label">
          IVR Name <i className="fa-regular fa-circle-question" />
        </FormLabel>
        <Input
          type="text"
          variant="borderless"
          name="name"
          value={formData.name}
          onChange={onHandleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Time limit <i className="fa-regular fa-circle-question" />
        </FormLabel>
        <Input
          type="number"
          variant="borderless"
          name="timeLimit"
          value={formData.timeLimit}
          onChange={onHandleChange}
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Tries <i className="fa-regular fa-circle-question" />
        </FormLabel>
        <Slider
          id='slider'
          defaultValue={formData.tries}
          value={formData.tries}
          min={1}
          max={100}
          colorScheme='blue'
          onChange={(value) => setFormData({...formData, tries: value})}
          onMouseEnter={() => setShowTooltip(true)}
          onMouseLeave={() => setShowTooltip(false)}
        >
          <SliderTrack>
            <SliderFilledTrack />
          </SliderTrack>
          <Tooltip
            hasArrow
            bg='blue.500'
            color='white'
            placement='top'
            isOpen={showTooltip}
            label={`${formData.tries}`}
          >
            <SliderThumb />
          </Tooltip>
        </Slider>
      </FormControl>
      <FormControl>
        <RadioCardGroup 
          defaultValue={formData.speechMode}
          name="speechMode"
          onChange={(value) => setFormData({
            ...formData,
            speechMode: value,
          })}
          value={formData.speechMode}
          options={[
            {value: "textToSpeech", text: "Text to Speech"},
            {value: "play", text: "Play Audio"},
          ]}
        />
      </FormControl>
      {formData.speechMode === "textToSpeech" &&
        <FormControl>
          <FormLabel variant="ct-label">
            Text to Speech
          </FormLabel>
          <Textarea
            variant="borderless"
            name="textToSpeech"
            value={formData.textToSpeech} 
            onChange={onHandleChange}
          />
        </FormControl>
      }
      {formData.speechMode === "play" &&
        <FormControl>
          <FormLabel variant="ct-label">
            Play File
          </FormLabel>
          <Flex flexDirection={"row"} gap={2}>
            <Select
              variant="borderless"
              flexBasis={"60%"}
              value={Object.keys(formData.playFile) > 0 ? "" : formData.playFile}
              name="selectedFile"
              onChange={onHandleChange}
            >
              {playFiles.map(audio => {
                return (<option key={audio._id}>{audio.name}</option>);
              })}
            </Select>
            <label
              for="playFile"
              flexBasis={"20%"}
            >
              <AiOutlineCloudUpload style={{border: "1px solid #323b66", fontSize: "28px", padding: "4px", cursor: "pointer"}}/>
            </label>
            <Input
              type="file"
              id="playFile"
              variant="borderless"
              name="playFile"
              onChange={onHandleChange}
              accept="audio/mp3, audio/wav"
              display={"none"}
            />
          </Flex>
          <Text fontSize={"sm"} fontWeight={"bold"}>
            {formData.playFile.name?.substring(0, 30)}
            {formData.playFile.name?.length > 30 ? "..." : ""}
          </Text>
        </FormControl>
      }
      <FormControl>
        <FormLabel variant="ct-label">Language</FormLabel>
        <Select
          variant="borderless"
          value={formData.language}
          name="language"
          onChange={onHandleChange}
        >
          {languages.map(lang => {
            return (<option key={lang.name}>{lang.name}</option>);
          })}
        </Select>
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">Voice</FormLabel>
        <Select
          variant="borderless"
          value={formData.voice}
          name="voice"
          onChange={onHandleChange}
        >
          <option value="man">Man</option>
          <option value="woman">Woman</option>
        </Select>
      </FormControl>
      <Flex
        maxW={"150px"}
        justifyContent="center"
        flexWrap={"wrap"}
        columnGap={"5%"}
        rowGap={"10px"}
      >
        {[...Array(10).keys()].map(key => {
          const val = key+1 < 10 ? key+1 : 0;
          const buttonStyle = {textAlign: "center", verticalAlign: "middle"};
          if (formData.linkedPlans.find(plan => Number(plan.planKey) === val)) {
            buttonStyle.backgroundColor = "#3182ce";
            buttonStyle.color = "white";
          }
          return (
            <Button
              flexBasis="30%"
              style={buttonStyle}
              variant="blueOutline"
              onClick={(e) => {setPlanKey(e.target.textContent); onOpen()}}
              key={"planKey"+val}
            >
              <Text fontSize={{base: "sm", md: "lg"}}>
                {val}
              </Text>
            </Button>
          );
        })}
      </Flex>
      <Button
        variant="formButton"
        onClick={edit ? handleUpdateIvr : handleCreateIvr} 
      >
        {edit ? "Update" : "Create"}
      </Button>
      <CTModal
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={formData.linkedPlans.find(plan => plan.planKey == planKey) ? "Edit Routing Plan" : "Add Routing Plan"}
        onCloseComplete={() => setPlanKey(null)}
        isCentered={true}
      >
        {/* {planKey !== null &&  */}
          <Flex flexDirection={"column"} bg={"#323b66"} p={"10px"} gap={5}>
            <Flex flexDirection={"row"} justifyContent={"center"} gap={2}>
              <FormLabel variant="ct-label" flexBasis={"50%"} textAlign={"right"} alignSelf={"flex-end"}>Routing Plan</FormLabel>
              <FormControl flexBasis={"50%"}>
                <Select
                  variant="borderless"
                  bg={commonColors.darkNavBg}
                  value={currentPlan || formData.linkedPlans.find(plan => plan.planKey == planKey)?.routingPlan}
                  onChange={(e) => setCurrentPlan(e.target.value)}
                >
                  <option value="">Select routing plan</option>
                  {routingPlans.map(plan => {
                    return (<option key={plan._id} value={plan._id}>{plan.name}</option>);
                  })}
                </Select>
              </FormControl>
            </Flex>

            <Flex flexDirection={"row"} justifyContent={"flex-end"} gap={3}>
              {formData.linkedPlans.find(plan => plan.planKey == planKey) &&
                <Button
                  variant="formButton"
                  bg={"red.500"}
                  _hover={"red"}
                  onClick={removeRoutingPlan} 
                >
                  Remove
                </Button>
              }
              <Button
                variant="formButton"
                onClick={addRoutingPlan} 
              >
                Save
              </Button>
            </Flex>
          </Flex>
        {/* } */}
      </CTModal>
    </Flex>
  );
}