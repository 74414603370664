/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import Loading from "components/Loading";
import axios from "axios";
import toast from "react-hot-toast";

// Chakra imports
import {
  Flex,
  Text,
} from '@chakra-ui/react'


// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";

const PublisherList = (props) => {
  const deletePublisher = (row) => {
    if (confirm("Are you sure you want to delete this Publisher?")) {
      try {
        axios.post("/api/users/publisher/delete", { id: row._id }, { headers: { "Content-Type": "application/json" } })
          .then(({ status, data }) => {
            if (data.success) {
              toast.success("Publisher successfully deleted.");
              props.refresh(Math.random());
            } else {
              toast.error(data.error);
            }
          }).catch(error => {
            toast.error(error.message);
          });
      } catch(error) {
        toast.error(error.message);
      }
    }
  };

  const columnsDataPublishers = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Creation Date",
      accessor: row => row.createdAt.split("T")[0],
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Phone",
      accessor: "phone",
    },
    {
      Header: "Phone Type",
      accessor: "phoneType",
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <div>
          <a href="#" onClick={e => { e.preventDefault(); props.onEdit(originalRow); }} ><i className="fa-solid fa-pen-to-square"></i></a> | <a href="#" onClick={e => { e.preventDefault(); deletePublisher(originalRow); }} ><i className="fa-solid fa-trash"></i></a>
        </div>
      ),
      id: 'action',
    }
  ];

  return (
    <Card>
      <CardBody>
        {!props.loading && 
          <SearchTable1 tableData={props.tableData} columnsData={columnsDataPublishers} />
        }
        {props.loading &&
          <Loading />
        }
      </CardBody>
  </Card>
  );
};

export default PublisherList;
