import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Select,
  Icon,
  Button,
  Switch,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useState, useEffect } from "react";
import packageJson from "../../../appConfig.js";
import axios from "axios";

const SearchFilter = (props) => {
  const [getMessage, setGetMessage] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedText, setSelectedText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const allItems = [];
  const totalRows = getMessage.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = getMessage.slice(startIndex, endIndex);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const switcher = props.switcher;

  useEffect(() => {
    const getData = async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ by: user.id }),
      };
      let result = await fetch(
        apiUrl + "/api/logs/admin/message",
        requestOptions
      );
      result = await result.json();
      setGetMessage(result?.messages);
      setLoadingData(false);
    };
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getData();
    }
  }, []);



  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when rowsPerPage changes
  }, [rowsPerPage]);

  const handleRowClick = (text) => {
    setSelectedText(text);
    onOpen();
  };

  // checkbox
  const handleCheckboxChange = (checkedItems) => {
    setSelectedItems(checkedItems);
  };

  const handleSelectAll = () => {
    setSelectedItems(allItems);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const isAllSelected = selectedItems.length === allItems.length;
  return (
    <>
      <Flex flexDirection={'column'} gap={5}>
        <FormControl>
          <FormLabel variant="ct-label">
            Number
          </FormLabel>
          <Flex align="center">
            <Input
              name="Number"
              type="text"
              variant="borderless"
            />
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Date range
          </FormLabel>
          <Flex justify="space-between" align="center" gap="2">
            <Input
              name="startDate"
              type="date"
              variant="borderless"
            />
            <Input
              name="endDate"
              type="date"
              variant="borderless"
            />
          </Flex>
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Messages Direction
          </FormLabel>
          <Select
            name=" Messages directions"
            variant="borderless"
          >
            <option value="Conversion">Show All Direction</option>
            <option value="Conversion">Inbound Only</option>
            <option value="Conversion">Outbound Only</option>
          </Select>
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
              Select All
              <Switch
                style={{ marginLeft: '10px' }}
                onChange={(e) =>
                  e.target.checked ? handleSelectAll() : handleDeselectAll()
                }
                name="enablePayoutHours"
                value={isAllSelected}
                defaultIsChecked={false}
              />
          </FormLabel>
          <FormControl>
            <CheckboxGroup
              value={selectedItems}
              onChange={handleCheckboxChange}
            >
              <Stack
                flexDirection={{base: 'column', md: 'row'}}
                flexWrap={'wrap'}
                gap={2}
              >
                {allItems.map((item, index) => (
                  <Checkbox
                    variant={'ct-checkbox'}
                    key={index}
                    value={item}
                    mt={index === 0 ? '0.5rem' : ''}
                  >
                    {item}
                  </Checkbox>
                ))}
              </Stack>
            </CheckboxGroup>
          </FormControl>
        </FormControl>
        <Button variant="formButton">Search</Button>
      </Flex>
      <Table className="report_custom_table" variant="simple" mt="10">
        <Thead>
          <Tr>
            <Th>
              S.No
            </Th>
            <Th>
              DATE
            </Th>
            <Th>
              PHONE NUMBER
            </Th>
            <Th>
              FROM
            </Th>
            <Th>
              TEXT
            </Th>
          </Tr>
        </Thead>
        <Tbody>
          {currentRows.length > 0 ? (
            currentRows?.map((item, index) => (
              <Tr key={index}>
                <Td borderColor="#56577A">
                  <Text color="#fff" fontSize="10px" my="10px">
                  {(currentPage - 1) * rowsPerPage + index + 1}
                  </Text>
                </Td>
                <Td borderColor="#56577A">
                  <Text color="#fff" fontSize="10px" my="10px">
                  {formatDateString(item.date)}
                  </Text>
                </Td>
                <Td borderColor="#56577A">
                  <Text color="#fff" fontSize="10px" my="10px">
                    {item.to}
                  </Text>
                </Td>
                <Td borderColor="#56577A">
                  <Text color="#fff" fontSize="10px" my="10px">
                    {item.from}
                  </Text>
                </Td>
                <Td borderColor="#56577A">
                  <Text
                    color="#fff"
                    fontSize="10px"
                    onClick={() => handleRowClick(item.body)}
                    my="10px"
                    sx={{
                      cursor: "pointer",
                    }}
                  >
                    <i className="fa-solid fa-eye"></i>
                  </Text>
                </Td>
              </Tr>
            ))
          ) : (
            <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
          )}
        </Tbody>
      </Table>
      <Flex wrap="wrap" align="center" gap="2" mt="15">
        <Button variant="formButton" onClick={() => setShowFilter(false)}>
          Close
        </Button>
        <Button variant="formButton">Export</Button>
      </Flex>
    </>
  );
};

export default SearchFilter;