/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Stack,
  Text,
  Switch,
  Select,
} from "@chakra-ui/react";
// Custom components
import axios from "axios";
import React, { useEffect, useState } from "react";
import './NewBuyer.css';
import toast from 'react-hot-toast';
import { QuestionIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import { useHistory } from 'react-router-dom';
import packageJson from '../../../appConfig.js';
const user = JSON.parse(localStorage?.getItem("user"));

function NewBuyer(props) {
  const apiUrl = packageJson.apiurl;
  const [clients, setClients] = useState([]);
  const [clientLoading, setclientLoading] = useState(true);
  const [timezoneFilter, setTimezoneFilter] = useState([]); //
  const [timezoneFilterLoading, setTimezoneFilterLoading] = useState(true);
  const [errors, setErrors] = useState({});
  const [edit, setEdit] = useState(false);
  const [buyers, setBuyers] = useState({
    name: "",
    email: "",
    password: "",
    // subid: "",
    phonetype: "1",
    phoneNumber: "",
    connectiontimeout: "",
    by: user.id,
    timezone: "",
    cccap: "",
    capOn: "Conversion",
  });//

  useEffect(() => {
    getUserFilterData();
    if (props.target) {
      const { defaultPayout } = props.target;
      setBuyers(props.target);
      setEndDay(defaultPayout?.to?.dayName)
      setStartDay(defaultPayout?.from?.dayName)
      setEndTime(defaultPayout?.to?.end_time)
      setStartTime(defaultPayout?.to?.start_time)
      setnewValue(defaultPayout)
      setEnableGlobalCap(defaultPayout?.enableGlobalCap)
      setEnableMonthlyCap(defaultPayout?.enableMonthlyCap)
      setEnableDailyCap(defaultPayout?.enableDailyCap)
      setEnableHourlyCap(defaultPayout?.enableHourlyCap)
      setPayoutHoursEnabled(defaultPayout?.enablePayoutHours)
      SetGlobalCap(defaultPayout?.enableGlobalCap  ? "show" : "hide")
      SetDailyCap(defaultPayout?.enableDailyCap ? "show" : "hide")
      SetHourlyCap(defaultPayout?.enableHourlyCap ? "show" : "hide")
      SetMonthCap(defaultPayout?.enableMonthlyCap ? "show" : "hide")
      setEdit(true);
    }
  }, []);

  const getUserFilterData = async () => {
    let result = await fetch(apiUrl + "/api/users/client/lists");
    result = await result.json();
    setClients(result);
    setclientLoading(false);
  }

  const handleChange = e => {
    const { name, value } = e.target
    setBuyers({
      ...buyers,//spread operator 
      [name]: value
    })
  }

  //  new field added
  const [enableGlobalCap, setEnableGlobalCap] = useState(false);
  const [enableMonthlyCap, setEnableMonthlyCap] = useState(false);
  const [enableDailyCap, setEnableDailyCap] = useState(false);
  const [enableHourlyCap, setEnableHourlyCap] = useState(false);
  const [payoutHoursEnabled, setPayoutHoursEnabled] = useState(false);
  const daysOfWeek = [
    { value: "Day", label: "Day" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];

const [startDay, setStartDay] = useState(daysOfWeek[0].value);
  const [endDay, setEndDay] = useState(daysOfWeek[0].value);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const handleStartDayChange = (event) => {
    const selectedDay = event.target.value;
    setStartDay(selectedDay);
    setnewValue((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        dayName: selectedDay,
      },
    }));
  };

  const handleEndDayChange = (event) => {
    const selectedDay = event.target.value;
    setEndDay(selectedDay);
    setnewValue((prevValue) => ({
      ...prevValue,
      to: {
        ...prevValue.to,
        dayName: selectedDay,
      },
    }));
  };

  const handleStartTimeChange = (event) => {
    const selectedTime1 = event.target.value;
    setStartTime(selectedTime1);
    setnewValue((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        start_time: selectedTime1,
      },
      to: {
        ...prevValue.to,
        start_time: selectedTime1,
      },
    }));
  };

  const handleEndTimeChange = (event) => {
    const selectedTime = event.target.value;
    setEndTime(selectedTime);
    setnewValue((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        end_time: selectedTime,
      },
      to: {
        ...prevValue.to,
        end_time: selectedTime,
      },
    }));
  };

  const [newValue, setnewValue] = useState({
    enableGlobalCap: false,
    enableMonthlyCap: false,
    enableHourlyCap: false,
    enableDailyCap: false,
    enableConCurrencyCap: false,
    // payoutHoursEnabled: false,
    enablePayoutHours: false,
    // maxConcurreny: "",
    restrictDuplicate: "",
    GlobalCallCap: "",
    monthlyCapValue: "",
    dailyCapValue: "",
    hourlyCapValue: "",
    // maxConcurrenyValue: "",
    from: {
      dayName: "",
      start_time: "",
      end_time: "",
    },
    to: {
      dayName: "",
      start_time: "",
      end_time: "",
    },
  });

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setnewValue({
      ...newValue, //spread operator
      [name]: value,
    });
  };
const onPayoutHours = (e) => {
  const { name, value } = e.target;
  let enabled = false;
  if (e.target.value == "false") {
    setPayoutHoursEnabled(true);
    enabled = true;
  } else {
    setPayoutHoursEnabled(false);
    enabled = false;
  }
  setnewValue({
    ...newValue, //spread operator
    [name]: enabled,
  });
};


const [viewGlobalCap, SetGlobalCap] = useState("hide");
const onGlobalCapChange = (e) => {
  const { name, value } = e.target;
  let enabled = false;
  if (e.target.value == "false") {
    setEnableGlobalCap(true);
    SetGlobalCap("show");

    enabled = true;
  } else {
    setEnableGlobalCap(false);
    SetGlobalCap("hide");
    enabled = false;
  }
  setnewValue({
    ...newValue, //spread operator
    [name]: enabled,
  });
};

const [viewMonthCap, SetMonthCap] = useState("hide");
const onMonthlyCapChange = (e) => {
  const { name, value } = e.target;
  let enabled = false;
  if (e.target.value == "false") {
    setEnableMonthlyCap(true);
    SetMonthCap("show");
    enabled = true;
  } else {
    setEnableMonthlyCap(false);
    SetMonthCap("hide");
    enabled = false;
  }
  setnewValue({
    ...newValue, //spread operator
    [name]: enabled,
  });
};

const [viewDailyCap, SetDailyCap] = useState("hide");
const onDailyCapChange = (e) => {
  const { name, value } = e.target;
  let enabled = false;
  if (e.target.value == "false") {
    setEnableDailyCap(true);
    SetDailyCap("show");
    enabled = true;
  } else {
    setEnableDailyCap(false);
    SetDailyCap("hide");
    enabled = false;
  }
  setnewValue({
    ...newValue, //spread operator
    [name]: enabled,
  });
};

const [viewHourlyCap, SetHourlyCap] = useState("hide");
const onHourlyCapChange = (e) => {
  const { name, value } = e.target;
  let enabled = false;
  if (e.target.value == "false") {
    setEnableHourlyCap(true);
    SetHourlyCap("show");
    enabled = true;
  } else {
    setEnableHourlyCap(false);
    SetHourlyCap("hide");
    enabled = false;
  }
  setnewValue({
    ...newValue, //spread operator
    [name]: enabled,
  });
};

// const [viewMaxConCurrency, SetMaxConCurrency] = useState("hide");
// const onMaxConCurrencyCapChange = (e) => {
//   const { name, value } = e.target;
//   let enabled = false;
//   if (e.target.value == "false") {
//     setEnableConCurrencyCap(true);
//     SetMaxConCurrency("show");
//     enabled = true;
//   } else {
//     setEnableConCurrencyCap(false);
//     SetMaxConCurrency("hide");
//     enabled = false;
//   }
//   setnewValue({
//     ...newValue, //spread operator
//     [name]: enabled,
//   });
// };

  const [skills, setSkills] = useState([
    {
      name: "wwww",
      id: 1,
    },
    {
      name: "wwww.123",
      id: 2,
    },
    {
      name: "123",
      id: 3,
    },
  ]);

  const [toggle, setToggle] = useState(false);

  // Creates the skills in the text area
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setSkills([
        ...skills,
        {
          name: e.target.value,
          id: skills.length === 0 ? 1 : skills[skills.length - 1].id + 1,
        },
      ]);
      e.target.value = "";
    }
  };
  useEffect(() => {
    if (timezoneFilterLoading) {
      getTimezoneFilterData();
    }
  }, []);

  const getTimezoneFilterData = async () => {
    let result = await fetch(apiUrl + "/api/report/timezone/lists");
    result = await result.json();
    setTimezoneFilter(result.data);
    setTimezoneFilterLoading(false);
  };
  
  function isValidEmail(email) {
    return /\S+@\S+\.\S+/.test(email);
  }

  const validateBuyers = () => {
    // const loadToast = toast.loading("Loading...");
    const { name, email, password, phonetype, phoneNumber, connectiontimeout, timezone } = buyers;
    let hasErrors = false;
    const formErrors = {};

    if ((email != "") && (!isValidEmail(email))) {
      return "Invalid Email Entered!";
    }

    const requiredFields = ['name', 'email', 'password', 'phoneNumber', 'timezone'];
    if (user.role == 1 || user.role == 0) {
      requiredFields.push('cccap');
    }

    requiredFields.forEach(field => {
      const fieldValue = buyers[field];
      if (field === "name" && (fieldValue === "" || fieldValue.length < 5)) {
        formErrors[field] = "Invalid Name Entered! Must be at least 5 characters long.";
        hasErrors = true;
      } else if (field === "password" && !edit && fieldValue.length < 5) {
        formErrors[field] = "Password must have at least 5 characters";
        hasErrors = true;
      } else if (field === "phoneNumber" && fieldValue.length !== 12) {
        formErrors[field] = "Phone Number must be 12 digits";
        hasErrors = true;
      } else if (field === "cccap" && (isNaN(fieldValue) || fieldValue === "")) {
        formErrors[field] = "CCCAP must be a valid number";
        hasErrors = true;
      } else if (field === "email" && !isValidEmail(fieldValue)) {
        formErrors[field] = "Invalid Email Entered";
        hasErrors = true;
      } else if (field === "timezone" && fieldValue === "") {
        formErrors[field] = "Please select a timezone";
        hasErrors = true;
      } else if (field === "connectiontimeout" && (fieldValue === "" || isNaN(Number(fieldValue)))) {
        formErrors[field] = "Please enter a valid connection timeout value";
        hasErrors = true;
      }
    });

    if (hasErrors) {
      return Object.values(formErrors)[0];
    }
    return "";
  };

  const updateBuyer = () => {
    const loadToast = toast.loading('Please Wait....');
    const formError = validateBuyers();
    if (formError !== "") {
      toast.dismiss(loadToast);
      toast.error(formError);
      return
    }
    let data = { ...buyers };
    delete data.createdAt;
    delete data.updatedAt;
    delete data.__v;
    delete data._id;
    delete data.tags;
    delete data.status;
    if (user.role == 0 || user.role == 1) {
      data = { target: { ...data, defaultPayout: newValue }, _id: props.target._id };
    }
  
    let config = {
      method: 'post',
      url: apiUrl + '/api/buyers/update',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then(({ status, data }) => {
        if (!data.success) {
          toast.dismiss(loadToast);
          toast.error(data.error);
        } else {
          toast.dismiss(loadToast);
          toast.success('Buyer has been successfully updated');
          props.onClose();
        }
      })
      .catch((error) => {
        toast.dismiss(loadToast);
      });
  };

  const createNewbuyer = () => {
    const loadToast = toast.loading('Please Wait....');
    const formError = validateBuyers();
    if (formError !== "") {
      toast.dismiss(loadToast);
      toast.error(formError);
      return
    }
    let data = { ...buyers };
    if (user.role == 0 || user.role == 1) {
      data = { ...data, defaultPayout: newValue };
    }
  
    let config = {
      method: 'post',
      url: apiUrl + '/api/buyers',
      headers: {
        'Content-Type': 'application/json'
      },
      data: data,
    };
    axios.request(config)
      .then((response) => {
        if (response.data.error) {
          toast.dismiss(loadToast);
          toast.error(response.data.error);
        } else {
          toast.dismiss(loadToast);
          toast.success('Buyer has been Successfully Created');
          props.refresh(response.data);
          props.onClose();
        }
      })
      .catch((error) => {
        toast.dismiss(loadToast);
        toast.error(error.message);
      });
  }
  return (
    <Flex direction='column' w='100%'>
      <Stack direction='column' spacing='20px'>
        <FormControl>
          <FormLabel variant="ct-label">
            Name <Tooltip
              label="Your target name will be used to display information around the Rings Tracker protal. Short target names work best."
              placement="right"
              hasArrow
              fontSize="11px"
              color="black"
              bg="white"
              arrowSize={7}
              maxWidth="150px"
            >
              <QuestionIcon marginLeft="5px"/>
            </Tooltip>
          </FormLabel>
          <Input
            name="name"
            type='text'
            variant='borderless'
            value={buyers.name}
            onChange={handleChange}
          />
        </FormControl>
        {!edit &&
        <>
        <FormControl>
          <FormLabel variant="ct-label">
            Email
          </FormLabel>
          <Input
            name="email"
            type='text'
            variant='borderless'
            value={buyers.email}
            onChange={handleChange}
          />
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Password
          </FormLabel>
          <Input
            name="password"
            type='password'
            variant='borderless'
            value={buyers.password}
            onChange={handleChange}
          />
        </FormControl>
        </>
        }
        <FormControl>
          <FormLabel variant="ct-label">
            Phone Number <Tooltip
              label="Phone numbers should follow this format: +(country code) (phone number).US Example:+12223334444"
              placement="right"
              hasArrow
              fontSize="11px"
              color="black"
              bg="white"
              arrowSize={7}
              maxWidth="150px"
            >
                <QuestionIcon marginLeft="5px"/>
            </Tooltip>
          </FormLabel>
          <Input
            onChange={handleChange}
            value={buyers.phoneNumber}
            name="phoneNumber"
            type='text'
            variant='borderless'
          />
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Connection Timeout <Tooltip
              label="If this target does not answer a call within the specified amount of seconds, the call will be re-routed. If the duration is set to 0, it will assume a default of 30 seconds."
              placement="right"
              hasArrow
              fontSize="11px"
              color="black"
              bg="white"
              arrowSize={7}
              maxWidth="150px"
            >
                <QuestionIcon marginLeft="5px"/>
            </Tooltip>
          </FormLabel>
          <Input
            onChange={handleChange}
            value={buyers.connectiontimeout}
            name="connectiontimeout"
            type='number'
            variant='borderless'
          />
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Timezone <Tooltip
              label="Select the Time Zone that this target operates in. All caps operate based on this time zone."
              placement="right"
              hasArrow
              fontSize="11px"
              color="black"
              bg="white"
              arrowSize={7}
              maxWidth="150px"
            >
              <QuestionIcon marginLeft="5px"/>
            </Tooltip>
          </FormLabel>
          {timezoneFilterLoading ? (
            <p>Loading Please wait...</p>
          ) : (
            <Select
              onChange={handleChange}
              value={buyers.timezone}
              name="timezone"
              variant='borderless'
            >
            <option value=''>Select Time Zone</option>
              {timezoneFilter.length > 0 ? (
                timezoneFilter.map((item, index) => (
                  <>
                    <option value={item.value}>{item.text}</option>
                  </>
                ))
              ) : (
                <></>
              )}
            </Select>
          )}
        </FormControl>
        {(user.role == 0 || user.role == 1) &&
          <>
          {user.role == 0 &&
            <FormControl>
              <FormLabel variant="ct-label">
                Assign To
              </FormLabel>
              {clientLoading ? (
                <p>Loading Please wait...</p>
              ) : (
                <Select
                  variant='borderless'
                  name="by" 
                  onChange={handleChange}
                  value={buyers.by}
                >
                  <option value=''>Choose User Options</option>
                  {clients.data.length > 0 && clients.data.map((item, index) =>
                    <>
                      <option value={item._id}>{item.name}</option>
                    </>
                  )}
                </Select>
              )}
            </FormControl>
            }
            <Flex flexDirection={'column'}>
              <FormLabel variant="ct-label">
                Hours Of Operation <Tooltip
                  label=" Set your target's hours of operation. For weekly schedule use advanced settings."
                  placement="right"
                  hasArrow
                  fontSize="11px"
                  color="black"
                  bg="white"
                  arrowSize={7}
                  maxWidth="150px"
                >
                  <QuestionIcon marginLeft="5px"/>
                </Tooltip>
                <Switch
                  style={{ marginLeft: '10px' }}
                  onChange={onPayoutHours}
                  name="enablePayoutHours"
                  value={payoutHoursEnabled}
                  isChecked={payoutHoursEnabled}
                />
              </FormLabel>
              {payoutHoursEnabled &&
                <Flex flexDirection={'column'} gap={2}>
                  <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent={{base: 'flex-start', md: 'space-between'}} gap={2}>
                    <FormControl>
                      <FormLabel variant="ct-label">
                        From
                      </FormLabel>
                      <Select
                        onChange={handleStartDayChange}
                        value={startDay}
                        variant='borderless'
                      >
                        {daysOfWeek.map((day,index) => (
                          <option
                            key={day.value}
                            value={day.value}
                            disabled={index === 0}
                          >
                            {day.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel variant="ct-label">
                        To
                      </FormLabel>
                      <Select
                        onChange={handleEndDayChange}
                        value={endDay}
                        variant='borderless'
                      >
                        {daysOfWeek.map((day,index) => (
                          <option
                            key={day.value}
                            value={day.value}
                            disabled={index === 0}
                          >
                            {day.label}
                          </option>
                        ))}
                      </Select>
                    </FormControl>
                  </Flex>
                  <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent={{base: 'flex-start', md: 'space-between'}} gap={2}>
                    <FormControl>
                      <FormLabel variant="ct-label">
                        Open
                      </FormLabel>
                      <Input
                        type="time"
                        onChange={handleStartTimeChange}
                        value={startTime}
                        variant='borderless'
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel variant="ct-label">
                        Close
                      </FormLabel>
                      <Input
                        type="time"
                        onChange={handleEndTimeChange}
                        value={endTime}
                        variant='borderless'
                      />
                    </FormControl>
                  </Flex>
                </Flex>
              }
            </Flex>
            <FormControl>
              <FormLabel variant="ct-label">
                CC CAP
              </FormLabel>
              <Input
                onChange={handleChange}
                value={buyers.cccap}
                name="cccap"
                type="number"
                variant='borderless'
              />
            </FormControl>
            <Text align="left" mb="20px"  fontWeight='bold' color={"#fff"} fontSize='ls'>
              Cap Settings
            </Text>
            <FormControl>
              <FormLabel variant="ct-label">
                Cap On
              </FormLabel>
              <Select
                onChange={handleChange}
                value={buyers.capOn}
                name="capOn"
                variant='borderless'
              >
                <option value="Conversion">
                  Conversion
                </option>
                <option value="Connection">
                  Connection
                </option>
              </Select>
            </FormControl>
            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent={'space-between'} alignItems={{base: 'flex-start', md: 'center'}}>
              <FormLabel variant="ct-label" flexBasis={'50%'}>
                Global Call Cap <Tooltip
                  label="Calls will route to this target until the global call cap is reached."
                  placement="right"
                  hasArrow
                  fontSize="11px"
                  color="black"
                  bg="white"
                  arrowSize={7}
                    
                >
                  <QuestionIcon marginLeft="5px"/>
                </Tooltip>
                <Switch
                  id="global-call-cap"
                  style={{ "margin-left": "10px" }}
                  onChange={onGlobalCapChange}
                  name="enableGlobalCap"
                  value={enableGlobalCap}
                  isChecked={enableGlobalCap}
                />
              </FormLabel>
              {viewGlobalCap === "show" ? (
                <Input
                  onChange={handleChange1}
                  value={newValue.GlobalCallCap}
                  name="GlobalCallCap"
                  type="number"
                  variant='borderless'
                  flexBasis={'50%'}
                />
              ) : null}
            </Flex>
            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent={'space-between'} alignItems={{base: 'flex-start', md: 'center'}}>
              <FormLabel variant="ct-label" flexBasis={'50%'}>
                Monthly Cap<Tooltip
                  label="Calls will route to this target until the monthly call cap is reached."
                  placement="right"
                  hasArrow
                  fontSize="11px"
                  color="black"
                  bg="white"
                  arrowSize={7}
                    
                >
                  <QuestionIcon marginLeft="5px"/>
                </Tooltip>
                <Switch
                  id="enableMonthlyCap"
                  style={{ "margin-left": "10px" }}
                  onChange={onMonthlyCapChange}
                  name="enableMonthlyCap"
                  value={enableMonthlyCap}
                  isChecked={enableMonthlyCap}
                />
              </FormLabel>
              {viewMonthCap === "show" ? (
                <Input
                  onChange={handleChange1}
                  value={newValue.monthlyCapValue}
                  name="monthlyCapValue"
                  type="number"
                  variant='borderless'
                  flexBasis={'50%'}
                />
              ) : null}
            </Flex>
            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent={'space-between'} alignItems={{base: 'flex-start', md: 'center'}}>
              <FormLabel variant="ct-label" flexBasis={'50%'}>
                Daily Cap<Tooltip
                  label="Calls will route to this target until the daily call cap is reached."
                  placement="right"
                  hasArrow
                  fontSize="11px"
                  color="black"
                  bg="white"
                  arrowSize={7}
                    
                >
                  <QuestionIcon marginLeft="5px"/>
                </Tooltip>
                <Switch
                  id="global-call-cap"
                  style={{ "margin-left": "10px" }}
                  onChange={onDailyCapChange}
                  name="enableDailyCap"
                  value={enableDailyCap}
                  isChecked={enableDailyCap}
                />
              </FormLabel>
              {viewDailyCap === "show" ? (
                <Input
                  onChange={handleChange1}
                  value={newValue.dailyCapValue}
                  name="dailyCapValue"
                  type="number"
                  variant='borderless'
                  flexBasis={'50%'}
                />
              ) : null}
            </Flex>
            <Flex flexDirection={{base: 'column', md: 'row'}} justifyContent={'space-between'} alignItems={{base: 'flex-start', md: 'center'}}>
              <FormLabel variant="ct-label" flexBasis={'50%'}>
                Hourly Cap<Tooltip
                  label="Calls will route to this target until the hourly call cap is reached."
                  placement="right"
                  hasArrow
                  fontSize="11px"
                  color="black"
                  bg="white"
                  arrowSize={7}
                    
                >
                  <QuestionIcon marginLeft="5px"/>
                </Tooltip>
                <Switch
                  id="enableHourlyCap"
                  style={{ "margin-left": "10px" }}
                  onChange={onHourlyCapChange}
                  name="enableHourlyCap"
                  value={enableHourlyCap}
                  isChecked={enableHourlyCap}
                />
              </FormLabel>
              {viewHourlyCap === "show" ? (
                <Input
                  onChange={handleChange1}
                  value={newValue.hourlyCapValue}
                  name="hourlyCapValue"
                  type="number"
                  variant='borderless'
                  flexBasis={'50%'}
                />
              ) : null}
            </Flex>
            <Text align="left" mb="20px"  fontWeight='bold' color={"#fff"} fontSize='ls'>
              Restrict Duplicate Call Settings
            </Text>
            <FormControl>
              <FormLabel variant="ct-label">
                Restrict Duplicate
              </FormLabel>
              <Select
                onChange={handleChange1}
                value={newValue.restrictDuplicate}
                name="restrictDuplicate"
                variant='borderless'
              >
                <option value="option1">
                  Buyer Settings(Do Not Restrict)
                </option>
                <option value="option1">
                  Buyer Settings(Do Not Restrict)
                </option>
              </Select>
            </FormControl>
            {/* <Text
              align="left"
              mb="20px"
              fontWeight="bold"
              color={"#fff"}
              fontSize="ls"
            >
              Tag Routing Filters
            </Text>
            <Button variant="brand">
              ADD Filter
            </Button> */}
          </>
        }
      </Stack>
      <Button
        mt={10}
        onClick={edit ? updateBuyer : createNewbuyer}
        variant="formButton"
      >
        { edit ? "Update" : "Create" }
      </Button>
    </Flex>
  );
}

export default NewBuyer;
