/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  DarkMode,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Element, Link } from "react-scroll";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import axios from "axios";
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

function NewUser(props) {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user"));
  const [refersh, refershSet] = React.useState('');
  const [loading, setLoading] = React.useState(true);
  const [twilioExist, setTwilioExist] = React.useState(false);
  const [twilio, setTwilio] = useState({
    _id: "",
    name: "",
    accountId: "",
    securityKey: "",
    clientId: user.id
  })

  const [businessName, setBusinessName] = useState({
    name: "",
    clientId: user.id
  });

  useEffect(() => {
    if (loading == true) {
      fetchTwillio();
    }
  }, [])

  const fetchTwillio = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    const response = await fetch(apiUrl + '/api/users/client/twilio/fetch', requestOptions);
    const result = await response.json();
    if (result.statusCode == 200) {
      setLoading(false);
      setTwilio(result.data);
      setTwilioExist(true);
      refershSet(result.data._id);
    } else {
      setTwilioExist(false);
    }
    toast.dismiss(loadToast);
  }


  const businessNameHandleChange = e => {
    const { name, value } = e.target
    setBusinessName({
      ...businessName,//spread operator 
      [name]: value
    })
  }

  const handleChange = e => {
    const { name, value } = e.target
    setTwilio({
      ...twilio,//spread operator 
      [name]: value
    })
  }

  const CreateTwillioSubAccount = async () => {
    const loadToast = toast.loading('Loading...');
    const { name } = businessName;
    if (name == "") {
      toast.error("Business Name is required!");
      toast.dismiss(loadToast);
      return false;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ data: businessName })
    };
    const response = await fetch(apiUrl + '/api/twilio/subaccount/create', requestOptions);
    const result = await response.json();
    if (result.statusCode == 200) {
      setLoading(false);
      setTwilio(result.data);
      setTwilioExist(true);
      refershSet(result.data._id);
      toast.success("Your Twilio Subaccount has been Created!");
    } else {
      setTwilioExist(false);
      toast.error("Twilio Credentials update failed due to error!");
    }
    toast.dismiss(loadToast);
  }

  const removeTwilioAccount = async () => {
    const shouldDelete = window.confirm("Are you sure you want to delete your Twilio Subaccount? This action cannot be undone.");
    if (shouldDelete) {
      const loadToast = toast.loading('Loading...');
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ id: twilio._id, sid: twilio.accountId })
      };
      const response = await fetch(apiUrl + '/api/twilio/subaccount/delete', requestOptions);
      const result = await response.json();
      if (result.statusCode == 200) {
        setLoading(false);
        setTwilioExist(false);
        refershSet(Math.random());
        toast.success("Your Twilio Subaccount has been Deleted!");
      } else {
        setTwilioExist(false);
        toast.error("Twilio Credentials update failed due to error!");
      }
      toast.dismiss(loadToast);
    }
  }

  const updatePublisher = async () => {
    const loadToast = toast.loading('Loading...');
    const { name, accountId, securityKey } = twilio;
    if (name && accountId && securityKey) {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ twilio })
      };
      const response = await fetch(apiUrl + '/api/users/client/twilio/update', requestOptions);
      const result = await response.json();
      if (result.statusCode == 200) {
        toast.success("Twilio Credentials has been updated!");
      } else {
        toast.error("Twilio Credentials update failed due to error!");
      }
      toast.dismiss(loadToast);
    } else {
      toast.error("Please enter Required Fields");
      toast.dismiss(loadToast);
    }
  }
  return (
    <Flex
      className="editClientDiv"
      direction='column'
      minH='100vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel>
            <Card
              alignSelf={{ lg: "flex-end" }}
              justifySelf={{ lg: "flex-end" }}>
              <Element id='profile' name='profile'>
                <CardBody>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    justify='space-between'
                    align='center'
                    w='100%'>
                    <Flex align='center'>
                      <Flex direction='column'>
                        <Text color='#fff' fontWeight='bold' fontSize='lg'>
                          Twillio Subaccount Imformations
                        </Text>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardBody>
              </Element>
            </Card>

            <Card
              className={twilioExist ? 'hide' : 'show'}
              alignSelf='flex-end'
              style={{ marginTop: 30 }}
              justifySelf='flex-end'>
              <Element id='info' name='info'>
                <CardBody>
                  <Stack direction='column' spacing='20px' w='100%'>
                    <Stack direction='row' spacing={{ sm: "24px", lg: "30px" }}>
                      <FormControl>
                        <Input
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          bg='#0F1535'
                          borderRadius='15px'
                          placeholder='Enter Your Business Name'
                          fontSize='xs'
                          name="name"
                          value={businessName.name}
                          onChange={businessNameHandleChange}
                        />
                      </FormControl>
                      <FormControl>
                        <Button
                          variant='brand'
                          alignSelf='flex-end'
                          mt='0px'
                          w='100%'
                          h='35px'
                          onClick={CreateTwillioSubAccount}
                        >
                          <Text fontSize='10px' color='#fff'>
                            Create Account
                          </Text>
                        </Button>
                      </FormControl>
                    </Stack>
                  </Stack>
                </CardBody>
              </Element>
            </Card>

            <Card style={{ marginTop: 30 }} className={twilioExist ? 'show' : 'hide'}>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Twilio Account Integration
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Twilio Account Name
                      </FormLabel>
                      <Input
                        type='text'
                        readOnly={true}
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Twilio Account Name'
                        fontSize='xs'
                        name="name"
                        value={twilio.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Account ID
                      </FormLabel>
                      <Input
                        type='text'
                        readOnly={true}
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Twilio Account ID'
                        fontSize='xs'
                        name="accountId"
                        value={twilio.accountId}
                        onChange={handleChange}
                      />
                    </FormControl>

                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Security Key
                      </FormLabel>
                      <Input
                        type='text'
                        readOnly={true}
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Twilio Security Key'
                        fontSize='xs'
                        name="securityKey"
                        value={twilio.securityKey}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Stack>
                  <Flex justify='space-between'>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w='100px'
                      h='35px'
                      background="red"
                      onClick={removeTwilioAccount}
                    >
                      <Text fontSize='10px' color='#fff'>
                        Remove Account
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>


          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default NewUser;
