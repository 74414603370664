/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  List,
  ListItem,
  ListIcon,
  OrderedList,
  UnorderedList,
  useColorModeValue,
} from "@chakra-ui/react";

// Custom components//
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
//import Globe from "components/Globe/Globe";
import IconBox from "components/Icons/IconBox";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import packageJson from "../../../appConfig.js";
export default function Default() {
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [campaign, setCampaign] = useState([]);
  const [callDetails, setCallDetails] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState("");
  const [barChartDataCharts1, setBarChartDataCharts1] = useState();
  const [barChartOptionsCharts1, setBarChartOptionsCharts1] = useState();

  useEffect(() => {
    if (loadingData) {
      getChartDetails();
    }
    getCampaignDetails();
    let intervalid = setInterval(() => {
      getCampaignDetails();
    }, 15000);
    getCallDetails();
    return function cleanup() {
      clearInterval(intervalid);
    };
  }, [refersh]);

  const getChartDetails = async () => {
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userDetails.id, token: token }),
    };
    let result = await fetch(
      apiUrl + "/api/report/v2/call/timeline",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      const barChartColumn = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
            },
          },
          theme: "dark",
        },
        xaxis: {
          categories: result.column,
          show: true,
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "10px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          color: "black",
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "10px",
            },
          },
        },
        grid: {
          strokeDashArray: 5,
          borderColor: "#56577A",
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          type: "solid",
          colors: ["#582CFF"],
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            columnWidth: "40px",
          },
        },
      };

      let chartDetails = [
        {
          name: "Timeline",
          data: result.calls,
        },
      ];
      setBarChartOptionsCharts1(barChartColumn);
      setBarChartDataCharts1(chartDetails);
      setLoadingData(false);
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  const getCampaignDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userDetails.id, token: token }),
    };
    let result = await fetch(
      apiUrl + "/api/report/v2/campaign/call/details",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      setCampaign(result.data);
    } else {
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  const getCallDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userDetails.id, token: token }),
    };
    let result = await fetch(
      apiUrl + "/api/report/v2/call/summary",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      setCallDetails(result.data);
    } else {
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  // Chakra Color Mode
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text color="#fff" fontSize="2xl" mb="30px" ps="20px">
        All Reports & Charts
      </Text>

      <UnorderedList className="report_list" id="report_list">
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> CAMPAIGN{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> TARGET{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> BUYER{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> NUMBER POOL{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> PUBISHER{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> NUMBER{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> INBOUND CALL ID{" "}
        </ListItem>
        <ListItem>
          {" "}
          <i className="fa-sharp fa-solid fa-filter"></i> CALLER ID{" "}
        </ListItem>
        <ListItem className="active">
          <select id="select_option">
            {" "}
            <i className="fa-solid fa-plus"></i>
            <option> FILTER </option>
            <option> FILTER </option>
            <option> FILTER </option>
            <option> FILTER </option>
            <option> FILTER </option>
            <option> FILTER </option>
          </select>
        </ListItem>
      </UnorderedList>
      <Card px="0px" pb="0px">
        <CardHeader mb="34px" px="22px">
          <Text color="#fff" fontSize="lg" fontWeight="bold">
            Timeline
          </Text>
        </CardHeader>
        <CardBody h="100%">
          <Box w="100%" h="100%">
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <BarChart
                chartData={barChartDataCharts1}
                chartOptions={barChartOptionsCharts1}
              />
            )}
          </Box>
        </CardBody>
      </Card>

      <Card p="28px 0px 0px 0px" style={{ marginTop: "20px" }}>
        <CardHeader mb="20px" ps="22px">
          <Flex
            direction="column"
            alignSelf="flex-start"
            style={{ margin: "0 auto" }}
          >
            <Text fontSize="2xl" color="#fff" fontWeight="bold" mb="6px">
              Reports Summary
            </Text>
          </Flex>
        </CardHeader>
      </Card>

      <div className="tabs_new">
        <div className="w3-bar w3-black">
          <button data-tab="tab-1" className="w3-bar-item w3-button active">
            Campaign
          </button>
        </div>
        <div className="wapr_tab-black">
          <div id="tab-1" className="w3-container city">
            {/* Campaign Summary Reports*/}
            <Card px="0px">
              <CardHeader px="22px" mb="32px">
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Campaign
                </Text>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <Table className="report_custom_table" variant="simple">
                  <Thead>
                    <Tr>
                      <Th
                        className="publisherName"
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Campaign
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Incoming
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Live
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Completed
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Ended
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Connected
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Paid
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Converted
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        No Connect
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Duplicate
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Blocked
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        RPC
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Revenue
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Payout
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Profit
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        Margin
                      </Th>
                      <Th
                        color="gray.400"
                        fontSize="10px"
                        fontFamily="Inter"
                        borderColor="#56577A"
                      >
                        TotalCost
                      </Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {campaign.length > 0 ? (
                      campaign.map((item, index) => (
                        <Tr>
                          <Td minW="150px" borderColor="#56577A">
                            <Stack direction="row" spacing="2px">
                              <Text
                                className="publisherName"
                                color="#fff"
                                fontSize="10px"
                                fontWeight="bold"
                                style={{ paddingLeft: "5px !important" }}
                              >
                                {item.campaign}
                              </Text>
                            </Stack>
                          </Td>
                          <Td minW="50px" borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.incoming}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.live}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.completed}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.ended}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.connected}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.paid}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.converted}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.noConnect}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.duplicate}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.blocked}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.rpc}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.revenue}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.payout}
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.profit}%
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              {item.margin}%
                            </Text>
                          </Td>
                          <Td borderColor="#56577A">
                            <Text
                              color="#fff"
                              fontSize="10px"
                              fontWeight="bold"
                            >
                              ${item.totalCost}
                            </Text>
                          </Td>
                        </Tr>
                      ))
                    ) : (
                      <Tr>
                        <Td borderColor="#56577A" colSpan={17}>
                          <Stack direction="row" spacing="16px">
                            <Text color="#fff" fontSize="sm" fontWeight="bold">
                              There are No Campaign Reports Available.
                            </Text>
                          </Stack>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      {/* Call Details*/}
      <Card p="28px 0px 0px 0px" style={{ marginTop: "20px" }}>
        <CardHeader mb="20px" ps="22px">
          <Flex
            direction="column"
            alignSelf="flex-start"
            style={{ margin: "0 auto" }}
          >
            <Text fontSize="2xl" color="#fff" fontWeight="bold" mb="6px">
              Calls Summary Report
            </Text>
          </Flex>
        </CardHeader>
      </Card>

      <Card px="0px" style={{ marginTop: "10px" }}>
        <CardHeader px="22px" mb="32px">
          <Flex className="call_details_head">
            <Text color="#fff" fontSize="lg" fontWeight="bold">
              Call Details
            </Text>
            <UnorderedList className="call_detail_list">
              <ListItem>
                {" "}
                <i className="fa-solid fa-minus"></i> CLEAR SELECTION
              </ListItem>
              <ListItem className="active">
                EDIT CALLS <i className="fa-solid fa-caret-down"></i>{" "}
              </ListItem>
              <ListItem>
                CUSTOMIZE COLUMNS <i className="fa-solid fa-caret-down"></i>{" "}
              </ListItem>
              <ListItem>EXPORT CSV</ListItem>
            </UnorderedList>
          </Flex>
        </CardHeader>
        <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
          <Table className="report_custom_table" variant="simple">
            <Thead>
              <Tr>
                <Th
                  className="publisherName"
                  minW="50px"
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Call Dates
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Campaign
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Publisher
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Caller ID
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Dialed #
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  TimeToCall
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Duplicate
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Target
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Revenue
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Duration
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Payout
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {callDetails.length > 0 ? (
                callDetails.map((item, index) => (
                  <Tr>
                    <Td minW="50px" borderColor="#56577A">
                      <Stack direction="row" spacing="2px">
                        <Text
                          className="publisherName"
                          color="#fff"
                          fontSize="10px"
                          fontWeight="bold"
                          style={{ paddingLeft: "5px !important" }}
                        >
                          {item.callDate}
                        </Text>
                      </Stack>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.campaign}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.publisher}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.callerId}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.dialed}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.timeToCall}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.duplicate}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.target}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        ${item.revenue}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.callDurations} Sec
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        ${item.payout}
                      </Text>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr>
                  <Td borderColor="#56577A" colSpan={11}>
                    <Stack direction="row" spacing="16px">
                      <Text color="#fff" fontSize="lm" fontWeight="bold">
                        There are No Call Records Available
                      </Text>
                    </Stack>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </CardBody>
      </Card>

      {/*<SimpleGrid columns={{ sm: "1", md: "2" }} spacing='24px' style={{ marginTop: '10px' }}>
        <Card px='0px'>
          <CardHeader px='22px' mb='32px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold'>
              Publishers
            </Text>
          </CardHeader>
          <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Name
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Live
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Hours
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Impression
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Calls
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Converted
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Revenue
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Payout
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Profit
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW='180px' borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇺🇸</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        United States
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      2500
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $214,000
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>

                </Tr>

              </Tbody>
            </Table>
          </CardBody>
        </Card>

        <Card px='0px'>
          <CardHeader px='22px' mb='32px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold'>
              Targets Top Performers
            </Text>
          </CardHeader>
          <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Country:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Sales:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Value:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Bounce:
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW='180px' borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇺🇸</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        United States
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      2500
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $214,000
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇩🇪</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Germany
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      3900
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $446,700
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      19,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇬🇧</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Great Britain
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      1300
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $121,900
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      39,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td border='none'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇧🇷</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Brasil
                      </Text>
                    </Stack>
                  </Td>
                  <Td border='none'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      920
                    </Text>
                  </Td>
                  <Td border='none'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $52,100
                    </Text>
                  </Td>
                  <Td border='none'>
                    {" "}
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      29,9%
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </CardBody>
        </Card>
  </SimpleGrid>*/}
    </Flex>
  );
}
