/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState, useCallback } from "react";

// Chakra imports
import { Box, Button, Flex, Grid, Icon, Spacer, Text, Switch, useDisclosure, Input, FormControl, FormLabel } from "@chakra-ui/react";
import axios from "axios";
import Big from "big.js";
import toast, { Toaster } from "react-hot-toast";
import moment from "moment";

// Custom components
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
//import BillingRow from "components/Tables/BillingRow";
import InvoicesRow from "components/Tables/InvoicesRow";
import TransactionRow from "components/Tables/TransactionRow";
import AccountNav from 'components/Navbars/Account';
import CTModal from "components/Modal";
import StripeCardForm from "components/Stripe/CardForm";


// Icons
import { IoEllipsisHorizontalSharp } from "react-icons/io5";
import { RiMastercardFill, RiVisaFill } from "react-icons/ri";
import { FaCcDiscover } from "react-icons/fa";
import { SiAmericanexpress } from "react-icons/si";
import { MdPayment } from "react-icons/md";
import {
  FaArrowDown,
  FaArrowUp,
  FaFilePdf,
  FaPencilAlt,
} from "react-icons/fa";

// Data
import {
  commonColors,
} from "variables/general";

function Billing() {
  const user = JSON.parse(localStorage?.getItem("user"));
  const [formType, setFormType] = useState("");
  const [editRow, setEditRow] = useState({});
  const [customer, setCustomer] = useState({});
  const [userPaymentMethods, setUserPaymentMethods] = useState([]);
  const [defaultPaymentMethod, setDefaultPaymentMethod] = useState({});
  const [userBilling, setUserBilling] = useState([]);
  const [recharge, setRecharge] = useState({
    rechargeAmount: '',
    autoRecharge: false,
    rechargeThreshold: 0,
  });
  const { isOpen, onOpen, onClose } = useDisclosure();

  useEffect(() => {
    getUserPaymentmethods();
  }, []);

  const getUserPaymentmethods = async () => {
    let success = false;
    await axios.get('/api/billing/payment-methods', { params: { userId: user.id }})
    .then(({ status, data }) => {
      if (data.success) {
        const defaultPayMethod = data.paymentMethods.find(pay => pay.default);
        setUserPaymentMethods(data.paymentMethods);
        setDefaultPaymentMethod(defaultPayMethod ?? {});
        return defaultPayMethod?.customer;
      } else {
        console.log(data.error);
        return null;
      }
    })
    .then((customer) => {
      getCustomer(customer);
    })
    .catch((error) => {
      console.log('error', error);
    });
    return success;
  };

  const getCustomer = (customer) => {
    axios.get('/api/billing/customer', { params: { id: customer, userId: user.id }})
    .then(({ status, data}) => {
      setCustomer(data);
      setRecharge({
        rechargeAmount: data.user.rechargeAmount,
        autoRecharge: data.user.autoRecharge,
        rechargeThreshold: data.user.rechargeThreshold,
      });
      setUserBilling(data.userBilling);
    })
    .catch((error) => {
      console.log('error', error);
    });
  };

  const addFundToStripe = () => {
    setFormType("Add Funds");
    onOpen();    
  };

  const showAddPaymentForm = () => {
    setFormType("Add New Card")
    onOpen();
  };

  const editPaymentMethod = (paymentMethod) => {
    setFormType("Update Payment Method");
    setEditRow(paymentMethod);
    onOpen();
  };

  const refreshPage = () => {
    onClose();
    getUserPaymentmethods();
  };

  const updateRecharge = async () => {
    await axios.put('api/billing/update-recharge', { userId: user.id, recharge: recharge })
      .then(({ status, data}) => {
        if (data.success) {
          toast.success("Recharge values updated successfully.");
          refreshPage();
        } else {
          toast.error("Unable to update recharge value.");
          console.log(data.error);
        }
      })
      .catch (error => {
        toast.error("Unable to update recharge value.");
        console.log(error.message);
      });
  };

  const cardBrandIcon = {
    "Visa": RiVisaFill,
    "MasterCard": RiMastercardFill,
    "Discover": FaCcDiscover,
    "American Express": SiAmericanexpress,
    "generic": MdPayment,
  };

  const getPaymentsAfterClose = (id) => {
    setTimeout(() => {getCustomer(id)}, 30000);
  };


  return (
    <Flex direction='column' mx='auto'>
    <Card
      w="100%"
      p='20px'
      alignSelf='flex-start'
      justifySelf='flex-start'>
    <CardBody style={{display: 'block'}}><AccountNav current='/administrator/accounts/billing' userRole={user.role} /></CardBody>
      <Grid templateColumns={{ sm: "1fr", lg: "49% 49%" }}>
        <Box>
          <Grid
            templateColumns={{
              sm: "1fr",
              md: "1fr 1fr",
            }}
            gap='26px'>
            {/* MasterCard */}
            {userPaymentMethods.length > 0 &&
              <Card
                bg={commonColors.darkBlue}
                p='10px'>
                <CardBody h='100%' w='100%'>
                  <Flex
                    direction='column'
                    color='white'
                    h='100%'
                    p='0px 10px'
                    w='100%'>
                    <Flex justify='space-between' align='center'>
                      <Text fontSize='md'>
                        Primary Card
                      </Text>
                      <Icon
                        as={defaultPaymentMethod.brand in cardBrandIcon ? cardBrandIcon[defaultPaymentMethod.brand] : cardBrandIcon["generic"]}
                        w='48px'
                        h='auto'
                        color='gray.400'
                      />
                    </Flex>
                    <Flex direction='column'>
                      <Box>
                        <Text
                          fontSize={{ sm: "xl", lg: "lg", xl: "xl" }}
                          letterSpacing='2px'
                          mt='10px'>
                          XXXX XXXX XXXX {defaultPaymentMethod.last4}
                        </Text>
                      </Box>
                      <Flex mt='14px'>
                        <Flex direction='column' me='34px'>
                          <Text fontSize='xs'>VALID THRU</Text>
                          <Text fontSize='xs' fontWeight='bold'>
                            {`${defaultPaymentMethod.exp_month}/${defaultPaymentMethod.exp_year}`}
                          </Text>
                        </Flex>
                      </Flex>
                    </Flex>
                  </Flex>
                </CardBody>
              </Card>
            }
            {/* Credit Balance */}
            {userPaymentMethods.length > 0 &&
              <Card bg={commonColors.darkBlue}>
                <Flex direction='column'>
                  <Flex
                    justify='space-between'
                    mb='18px'
                    borderRadius='18px'>
                    <Flex direction='column'>
                      <Text color='#E9EDF7' fontSize='12px'>
                        Account Balance
                      </Text>
                      <Text color='#fff' fontWeight='bold' fontSize='34px'>
                        ${customer.balance ? Big(customer.balance).toFixed(2) : "0.00"}
                      </Text>
                    </Flex>
                    <Flex direction='column'>
                      <Button
                        bg='transparent'
                        _hover='none'
                        _active='none'
                        alignSelf='flex-end'
                        p='0px'>
                        <Icon
                          as={IoEllipsisHorizontalSharp}
                          color='#fff'
                          w='24px'
                          h='24px'
                          justifySelf='flex-start'
                          alignSelf='flex-start'
                        />
                      </Button>
                    </Flex>
                  </Flex>
                  <Flex justify='space-between' align='center'>
                    <Button
                      w='165px' fontSize='14px' p='10px 25px'
                      style={{ background: commonColors.highlightBlue, color: '#fff' }}
                      className='contextSwitchButton'
                      onClick={addFundToStripe}
                      title="Minimum funding amount: $10"
                    >
                      Add Funds
                    </Button>
                  </Flex>
                </Flex>
              </Card>
            }
          </Grid>
          {/* Payment Method */}
          <Card p='16px' mt='24px' bg={commonColors.darkNavBg}>
            <CardHeader>
              <Flex
                justify='space-between'
                align='center'
                minHeight='60px'
                w='100%'>
                <Text fontSize='lg' color='#fff'>
                  Payment Method
                </Text>
                <Button
                  w='165px' fontSize='14px' p='10px 25px'
                  style={{ background: commonColors.highlightBlue, color: '#fff' }}
                  className='contextSwitchButton'
                  onClick={showAddPaymentForm}
                >
                  Add New Card
                </Button>
              </Flex>
            </CardHeader>
            {userPaymentMethods.length > 0 &&
              <CardBody>
                <Flex
                  direction={{ base: "column", md: "row" }}
                  align='center'
                  w='100%'
                  justify='flex-start'
                  py='1rem'
                  flexWrap={{ base: 'nowrap', md: 'wrap'}}
                >
                    {userPaymentMethods.map(pay => {
                      return (
                        <Flex
                          p='10px'
                          bg={commonColors.darkBlue}
                          borderRadius='10px'
                          border='1px solid white'
                          align='center'
                          justifyContent='center'
                          mr='20px'
                        >
                          <Text color='#fff' fontSize='xs'>
                            XXXX XXXX XXXX {pay.last4}
                          </Text>
                          <Button
                            p='0px'
                            bg='transparent'
                            w='16px'
                            h='16px'
                            variant='no-hover'
                            onClick={() => editPaymentMethod(pay)}
                          >
                            <Icon as={FaPencilAlt} color='#fff' w='12px' h='12px' />
                          </Button>
                        </Flex>
                      )
                    })}
                </Flex>
              </CardBody>
            }
          </Card>
        </Box>
        {/* Invoices Card */}
        <Card
          p='22px'
          my={{ sm: "24px", lg: "0px" }}
          ms={{ sm: "0px", lg: "24px" }}
          bg={commonColors.darkNavBg}>
          <CardHeader>
            <Flex
              justify='space-between'
              align='center'
              mb='1rem'
              w='100%'>
              <Text fontSize='lg' color='#fff'>
                Monthly Billing
              </Text>
              {userBilling.length > 6 &&
                <Button
                  w='135px' fontSize='14px' p='10px 25px'
                  style={{ background: commonColors.highlightBlue, color: '#fff' }}
                  className='contextSwitchButton'>
                  View All
                </Button>
              }
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction='column' w='100%'>
              {userBilling.map((row, i) => {
                return (
                  <InvoicesRow
                    date={row.createdAt}
                    code={row._id}
                    price={row.amount}
                    logo={FaFilePdf}
                    format={"PDF"}
                    key={row._id}
                  />
                );
              })}
              {userBilling.length === 0 &&
                <Text color='gray.400' fontSize='xs'>
                  No bills found
              </Text>
              }
            </Flex>
          </CardBody>
        </Card>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", lg: "49% 49%" }}>
        {/* Transactions List */}
        <Card className='rechargeSetting' my='24px' ms={{ lg: "24px" }} bg={commonColors.darkNavBg}>
          <CardHeader mb='12px'>
            <Flex direction='column' w='100%'>
              <Flex
                direction={{ sm: "column", lg: "row" }}
                justify={{ sm: "center", lg: "space-between" }}
                align={{ sm: "center" }}
                w='100%'
                my={{ md: "12px" }}>
                <Text
                  color='#fff'
                  fontSize={{ sm: "lg", md: "xl", lg: "lg" }}>
                  Recharge Settings
                </Text>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody className='rechargeAmount'>
            <Flex
              className='currentPlan'
              direction="column"
              w='100%'
              justify='left'
              py='1rem'
            >
              <Box w="100%" mb={2}>
                <Text color='#fff' fontSize='sm' mb={2}>
                  Recharge Threshold
                </Text>
                <Input
                  name="rechargeThreshold"
                  type='text'
                  variant='borderless'
                  value={recharge.rechargeThreshold}
                  onChange={(e) => setRecharge({...recharge, rechargeThreshold: e.target.value})}
                  w="35%"
                />
              </Box>
              <Text color='#fff' fontSize='sm'>
                Recharge Amount
              </Text>

              <Box w='100%'>
                <select
                  value={recharge.rechargeAmount}
                  onChange={(e) => setRecharge({...recharge, rechargeAmount: e.target.value})}
                  name='rechargeAmount'
                  style={{
                    color: 'white', background: commonColors.darkBlue, marginTop: 10,
                    padding: 5, width: '35%',
                  }}
                >
                  <option value='25'>$25</option>
                  <option value='50'>$50</option>
                  <option value='100'>$100</option>
                </select>
                <Spacer />
                <Flex
                  className='currentPlan'
                  direction={{ sm: "column", md: "row" }}
                  w='100%'
                  justify='flex-start'
                  py='1rem'>
                  <Text color='#fff' fontSize='sm'>
                    Auto Recharge
                  </Text>
                  <Switch ml='25px' isChecked={recharge.autoRecharge} onChange={() => setRecharge({...recharge, autoRecharge: !recharge.autoRecharge})} />
                </Flex>
                <Button
                  w='165px' fontSize='14px' p='10px 25px'
                  style={{ background: commonColors.highlightBlue, color: '#fff' }}
                  className='contextSwitchButton'
                  onClick={updateRecharge}
                >
                  Update
                </Button>
              </Box>
            </Flex>
          </CardBody>
        </Card>
        <Card my='24px' ms={{ lg: "24px" }} bg={commonColors.darkNavBg}>
          <CardHeader mb='12px'>
            <Flex direction='column' w='100%'>
              <Flex
                direction={{ sm: "column", lg: "row" }}
                justify={{ sm: "center", lg: "space-between" }}
                align={{ sm: "center" }}
                w='100%'
                my={{ md: "12px" }}>
                <Text
                  color='#fff'
                  fontSize={{ sm: "lg", md: "xl", lg: "lg" }}>
                  Rings Tracker Plan
                </Text>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody className='rechargeAmount'>
            <Flex direction='column' w='100%'>
            <Flex
              className='currentPlan'
              direction="row"
              justify='flex-start'
              py='1rem'>
              <Text color='#fff' fontSize='lg' borderRadius='10px'
                bg={commonColors.darkBlue} mr='20px' p='10px 20px'>
                Current Plan
              </Text>
              <Text color='#fff' fontSize='lg'borderRadius='10px'
                bg={commonColors.darkBlue} p='10px 20px'>
                  Enterprise
               </Text>
              </Flex>
                <Button
                  w='195px' fontSize='14px' p='10px 25px'
                  style={{ background: commonColors.highlightBlue, color: '#fff' }}
                  className='contextSwitchButton'>
                  View Current Pricing
                </Button>
            </Flex>
          </CardBody>
        </Card>
      </Grid>
      <Grid templateColumns={{ sm: "1fr", lg: "100%" }}>
        {/* Transactions List */}
        <Card bg={commonColors.darkNavBg}>
          <CardHeader mb='12px'>
            <Flex direction='column' w='100%'>
              <Flex
                direction={{ sm: "column", lg: "row" }}
                justify={{ sm: "center", lg: "space-between" }}
                align={{ sm: "center" }}
                w='100%'
                my={{ md: "12px" }}>
                <Text
                  color='#fff'
                  fontSize={{ sm: "lg", md: "xl", lg: "lg" }}>
                  Transactions History
                </Text>
              </Flex>
            </Flex>
          </CardHeader>
          <CardBody>
            <Flex direction='column' w='49%'>
              {customer?.userPayments?.length > 0 &&
                <Text color='gray.400' fontSize='xs' mb='18px'>
                  Newest
                </Text>
              }
              {customer?.userPayments?.length > 0 && customer?.userPayments?.map((row, i) => {
                return (
                  <TransactionRow
                    name={row.description}
                    logo={row.object === "charge" ? FaArrowUp : FaArrowDown}
                    date={moment.unix(row.created).format('LLLL')}
                    price={Big(row.amount_captured).div(100).toFixed(2)}
                    key={row.id}
                    last4={defaultPaymentMethod.last4}
                  />
                );
              })}
              {!customer?.userPayments || customer?.userPayments?.length === 0 &&
                <Text color='gray.400' fontSize='xs' mb='18px'>
                  No previous transactions.
                </Text>
              }
            </Flex>
          </CardBody>
        </Card>
      </Grid>
      </Card>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={formType}
      >
        {formType === "Add New Card" &&
          <StripeCardForm onClose={refreshPage} />
        }
        {formType === "Update Payment Method" &&
          <StripeCardForm
            paymentMethod={editRow}
            onClose={refreshPage} 
          />
        }
        {formType === "Add Funds" &&
          <FundForm
            userId={user.id}
            onClose={refreshPage}
            reloadTransactions={getPaymentsAfterClose}
            customerId={customer.id}
          />
        }
      </CTModal>
    </Flex>
  );
}

export default Billing;

const FundForm = (props) => {
  const [amount, setAmount] = useState("");
  const addFunds = () => {
    if (Number(amount) >= 10) {
      axios.post('/api/billing/add-fund', { userId: props.userId, amount: amount, return: location.href })
        .then(({ status, data}) => {
          if (data.success) {
            toast.success("Funds added successfully");
          } else {
            toast.error("Unable to add funds");
            console.log(data.error);
          }
        }).catch(error => {
          toast.error("Unable to add funds");
          console.log(error.message);
        }).finally(() => {
          props.reloadTransactions(props.customerId)
          props.onClose();
        });
    } else {
      toast.error("The minimum funding amount is $10.");
    }
  }

  return (
    <Box>
      <FormControl>
        <FormLabel variant="ct-label">Amount</FormLabel>
        <Input
          variant='borderless'
          type="number"
          placeholder="Enter funding amount"
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
        />
      </FormControl>
      <Text fontSize='xs' color='#fff'>
        Minimum funding amount is $10
      </Text>

      <Button
        variant="formButton"
        onClick={addFunds}
        mt={5}
      >
        Confirm
      </Button>
    </Box>
  );
}
