/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  DarkMode,
  Select
} from "@chakra-ui/react";
// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useRef, useEffect, useState } from "react";
import { Element, Link } from "react-scroll";
import { useHistory } from "react-router-dom";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
const token = localStorage?.getItem("token");
const user = JSON.parse(localStorage?.getItem("user"));

function NewUser() {
  const apiUrl = packageJson.apiurl;
  const [notifications, setNotifications] = useState({
    enableNotifications: false,
    email: false,
    whatsup: false,
    sms: false,
    alertOption: '',
    phoneNumber: "",
    by: user.id
  });

  useEffect(() => {
    fetchNotificationSettings();
  }, []);

  const fetchNotificationSettings = async () => {
    const loadToast = toast.loading('Loading...');
    try {
      const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ by: user.id }),
      };
      const response = await fetch(apiUrl + '/api/notification/lists', requestOptions);
      const result = await response.json();

      if (result.status === "success") {
        toast.dismiss(loadToast);
        setNotifications(prevState => ({ ...prevState, ...result.data }));
      } else {
        toast.dismiss(loadToast);
        toast.error('Something Went Wrong!');
      }
    } catch (error) {
      toast.dismiss(loadToast);
      console.error('Error fetching notification settings:', error);
      toast.error('Error fetching notification settings. Please try again later.');
    }
  };

  const handleSwitchChange = (event) => {
    const { name, checked } = event.target;
    setNotifications(prevState => ({ ...prevState, [name]: checked }));
  };

  // Function to update the phoneNumber state when the Input field changes
  const handleInputChange = (event) => {
    const { value } = event.target;
    setNotifications(prevState => ({ ...prevState, phoneNumber: value }));
  };

  const handleAlertChange = (event) => {
    const { value } = event.target;
    setNotifications(prevState => ({ ...prevState, alertOption: value }));
  };

  const updateSettings = async () => {
    const { alertOption, phoneNumber } = notifications;
    if (phoneNumber == "") {
      toast.error('Phone Number Field is required!');
      return;
    } else if (alertOption == "") {
      toast.error('Alert Option Field is required!');
      return;
    }

    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ notifications }),
    };

    try {
      const response = await fetch(apiUrl + '/api/notification/update', requestOptions);
      const result = await response.json();
      if (result.status === "success") {
        toast.dismiss(loadToast);
        toast.success('Settings has been Updated!');
      } else {
        toast.dismiss(loadToast);
        toast.error('Something Went Wrong!');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      toast.dismiss(loadToast);
      toast.error('Error creating client. Please try again later.');
    }
  }

  const sendEmailNotification = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id }),
    };
    try {
      const response = await fetch(apiUrl + '/api/notification/email/notify', requestOptions);
      const result = await response.json();
      if (result.status === "success") {
        toast.dismiss(loadToast);
        toast.success("Test Email Sent!");
      } else {
        toast.dismiss(loadToast);
        toast.error('Something Went Wrong!');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      toast.dismiss(loadToast);
      toast.error('Error creating client. Please try again later.');
    }
  }

  const sendSmsNotification = async () => {
    const loadToast = toast.loading('Loading...');
    if(notifications.phoneNumber == "") {
      toast.error('Phone Field is Required!');
      toast.dismiss(loadToast);
      return;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: notifications.phoneNumber }),
    };
    try {
      const response = await fetch(apiUrl + '/api/notification/sms/notify', requestOptions);
      const result = await response.json();
      if (result.status === "success") {
        toast.dismiss(loadToast);
        toast.success("Test SMS Sent!");
      } else {
        toast.dismiss(loadToast);
        toast.error('Something Went Wrong!');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      toast.dismiss(loadToast);
      toast.error('Error creating client. Please try again later.');
    }
  }

  const sendWhatsupNotification = async () => {
    const loadToast = toast.loading('Loading...');
    if(notifications.phoneNumber == "") {
      toast.error('Phone Field is Required!');
      toast.dismiss(loadToast);
      return;
    }
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ phone: notifications.phoneNumber }),
    };
    try {
      const response = await fetch(apiUrl + '/api/notification/whatsup/notify', requestOptions);
      const result = await response.json();
      if (result.status === "success") {
        toast.dismiss(loadToast);
        toast.success("Test WhatsApp Message Sent!");
      } else {
        toast.dismiss(loadToast);
        toast.error('Something Went Wrong!');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      toast.dismiss(loadToast);
      toast.error('Error creating client. Please try again later.');
    }
  }

  return (
    <Flex
      className="editClientDiv"
      direction='column'
      minH='100vh'
      align='center'>
      <Tabs variant='unstyled' w='100%'>
        <TabPanels maxW={{ md: "90%", lg: "100%" }} mx='0'>
          <TabPanel p='0'>
            <Card
              alignSelf={{ lg: "flex-end" }}
              justifySelf={{ lg: "flex-end" }}>
              <Element id='profile' name='profile'>
                <CardBody>
                  <Flex
                    direction={{ sm: "column", md: "row" }}
                    justify='space-between'
                    align='center'
                    w='100%'>
                    <Flex align='center'>
                      <Flex direction='column'>
                        <Text color='#fff' fontSize='lg'>
                          Enable Notifications
                        </Text>
                      </Flex>
                    </Flex>
                    <Flex
                      align='center'
                      alignSelf={{ sm: "flex-start", lg: null }}>
                      <DarkMode>
                        <Switch
                          name="enableNotifications"
                          colorScheme='brand'
                          isChecked={notifications.enableNotifications}
                          onChange={handleSwitchChange}
                        />
                      </DarkMode>
                    </Flex>
                  </Flex>
                </CardBody>
              </Element>
            </Card>

            <Card
              style={{ marginTop: 20 }}
              alignSelf='flex-end'
              justifySelf='flex-end'>
              <Element to='notifications' name='notifications'>
                <CardHeader mb='10px'>
                  <Flex direction='column'>
                    <Text color='#fff' fontSize='lg' mb='4px'>
                      Notifications Settings
                    </Text>
                  </Flex>
                </CardHeader>
                <CardBody overflowX={{ sm: "scroll", lg: "hidden" }}>
                  <Table className='report_custom_table'>
                    <Thead>
                      <Tr>
                        <Th
                          color='gray.400'
                          fontSize='sm'
                          borderColor='gray.500'
                          borderColor='gray.500'
                          fontWeight='500'
                          ps='0px'
                          textTransform='capitalise'>
                          Type
                        </Th>
                        <Th
                          color='gray.400'
                          fontSize='sm'
                          borderColor='gray.500'
                          fontWeight='500'
                          textTransform='capitalise'>
                          Email
                        </Th>
                        <Th
                          color='gray.400'
                          fontSize='sm'
                          borderColor='gray.500'
                          fontWeight='500'
                          textTransform='capitalise'>
                          WhatsApp
                        </Th>
                        <Th
                          color='gray.400'
                          fontSize='sm'
                          borderColor='gray.500'
                          fontWeight='500'
                          textTransform='capitalise'>
                          SMS
                        </Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      <Tr>
                        <Td borderColor='gray.500' ps='0px' minW={{ sm: "300px" }}>
                          <Flex direction='column'>
                            <Text
                              color='#fff'
                              fontWeight='500'
                              fontSize='sm'
                              mb='4px'>
                              Missed Call Notifications
                            </Text>
                            <Text color='gray.400' fontSize='xs' fontWeight='400'>
                              Notify me real-time with alerts for missed calls, ensuring timely follow-ups.
                            </Text>
                          </Flex>
                        </Td>
                        <Td borderColor='gray.500'>
                          <DarkMode>
                            <Switch
                              name="email"
                              isChecked={notifications.email}
                              colorScheme='brand'
                              onChange={handleSwitchChange}
                            />
                          </DarkMode>
                        </Td>
                        <Td borderColor='gray.500'>
                          <DarkMode>
                            <Switch
                              name="whatsup"
                              isChecked={notifications.whatsup}
                              colorScheme='brand'
                              onChange={handleSwitchChange}
                            />
                          </DarkMode>
                        </Td>
                        <Td borderColor='gray.500'>
                          <DarkMode>
                            <Switch
                              name="sms"
                              isChecked={notifications.sms}
                              colorScheme='brand'
                              onChange={handleSwitchChange}
                            />
                          </DarkMode>
                        </Td>
                      </Tr>

                      <Tr>
                        <Td borderColor='gray.500' ps='0px' minW={{ sm: "300px" }}>
                          <Flex direction='column'>
                            <Text
                              color='#fff'
                              fontWeight='500'
                              fontSize='sm'
                              mb='4px'>
                              Notification Test
                            </Text>
                            <Text color='gray.400' fontSize='xs' fontWeight='400'>
                              You can test Email, WhatsApp, and SMS notifications in one click.
                            </Text>
                          </Flex>
                        </Td>
                        <Td borderColor='gray.500'>
                          <DarkMode>
                            <Button
                              variant='brand'
                              w='150px'
                              h='35px'
                              alignSelf='flex-end'
                              onClick={sendEmailNotification}
                            >
                              <Text fontSize='10px' color='#fff' fontWeight='bold'>
                                NotifyMe
                              </Text>
                            </Button>
                          </DarkMode>
                        </Td>
                        <Td borderColor='gray.500'>
                          <DarkMode>
                          <Button
                              variant='brand'
                              w='150px'
                              h='35px'
                              alignSelf='flex-end'
                              onClick={sendWhatsupNotification}
                            >
                              <Text fontSize='10px' color='#fff' fontWeight='bold'>
                                NotifyMe
                              </Text>
                            </Button>
                          </DarkMode>
                        </Td>
                        <Td borderColor='gray.500'>
                          <DarkMode>
                          <Button
                              variant='brand'
                              w='150px'
                              h='35px'
                              alignSelf='flex-end'
                              onClick={sendSmsNotification}
                            >
                              <Text fontSize='10px' color='#fff' fontWeight='bold'>
                                NotifyMe
                              </Text>
                            </Button>
                          </DarkMode>
                        </Td>
                      </Tr>
                    </Tbody>
                  </Table>
                </CardBody>
              </Element>
            </Card>

            {/* Change Password Card */}
            <Card
              alignSelf='flex-end'
              justifySelf='flex-end'
              mt='20px'>
              <Element id='change-password' name='change-password'>
                <CardBody>
                  <Stack direction='column' spacing='20px' w='100%'>
                    <FormControl>
                      <FormLabel
                        fontWeight='bold'
                        color='#fff'
                        fontSize='xs'
                        mb='10px'>
                        How often you want to see the alerts?
                      </FormLabel>
                      <Select
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        bg='#0F1535'
                        borderRadius='15px'
                        placeholder='Choose Alert Options'
                        color='gray.400'
                        fontSize='xs'
                        name="alertOption"
                        value={notifications.alert}
                        onChange={handleAlertChange}
                      >
                        <option value='1' selected={notifications.alertOption == 1 ? true : false} >Everytime Call is Missed/unanswered</option>
                        <option value='2' selected={notifications.alertOption == 2 ? true : false}>Every 15 mins</option>
                        <option value='3' selected={notifications.alertOption == 3 ? true : false}>Every 30 mins</option>
                        <option value='4' selected={notifications.alertOption == 4 ? true : false}>Every hour</option>
                        <option value='5' selected={notifications.alertOption == 5 ? true : false}>Every 4 hours</option>
                        <option value='6' selected={notifications.alertOption == 6 ? true : false}>Every 8 hours</option>
                        <option value='7' selected={notifications.alertOption == 7 ? true : false}>Once a day (12 midnight eastern)</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <FormLabel
                        fontWeight='bold'
                        color='#fff'
                        fontSize='xs'
                        mb='10px'>
                        Enter Phone Number For Notifications
                      </FormLabel>
                      <Input
                        type="text"
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        bg='#0F1535'
                        borderRadius='15px'
                        placeholder='+19999999999'
                        fontSize='xs'
                        value={notifications.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </FormControl>


                    <Flex
                      direction={{ sm: "column", lg: "row" }}
                      justify='space-between'
                      w='100%'>
                      <Button
                        variant='brand'
                        w='150px'
                        h='35px'
                        alignSelf='flex-end'
                        onClick={updateSettings}
                      >
                        <Text fontSize='10px' color='#fff' fontWeight='bold'>
                          Update
                        </Text>
                      </Button>
                    </Flex>
                  </Stack>
                </CardBody>
              </Element>
            </Card>

          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default NewUser;
