import React, { useEffect, useRef, useState } from "react";
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
  IconButton,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import "./NewBuyer.css";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { Tooltip } from "@chakra-ui/react";
import { QuestionIcon } from "@chakra-ui/icons";
import packageJson from "../../../appConfig.js";
import { VSeparator } from "components/Separator/Separator";
import { useSortBy, useTable } from "react-table";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import toast, { Toaster } from "react-hot-toast";
import axios from "axios";
import { useHistory } from "react-router-dom";
const EditGroupTarget = (props) => {
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const history = useHistory();
  // for timeZone
  const [timezoneFilter, setTimezoneFilter] = useState([]);
  const [timezoneFilterLoading, setTimezoneFilterLoading] = useState(true);
  const [payoutHoursEnabled, setPayoutHoursEnabled] = useState(false);
  const [enableGlobalCap, setEnableGlobalCap] = useState(false);
  const [enableMonthlyCap, setEnableMonthlyCap] = useState(false);
  const [enableDailyCap, setEnableDailyCap] = useState(false);
  const [enableHourlyCap, setEnableHourlyCap] = useState(false);
  //   for onChange
  const [targetGroup, setTargetGroup] = useState({
    name: "",
    by: user.id,
    timezone: "",
    simuldial: false,
    schedule: "off",
    enablePayoutHours: false,
    from: {
      dayName: "",
      start_time: "",
      end_time: "",
    },
    to: {
      dayName: "",
      start_time: "",
      end_time: "",
    },
    overrideCapacity: "off",
    enableGlobalCap: false,
    enableMonthlyCap: false,
    enableHourlyCap: false,
    enableDailyCap: false,
    GlobalCallCap: "",
    monthlyCapValue: "",
    dailyCapValue: "",
    hourlyCapValue: "",
    overrideConcurrency: "",
    targets: [],
  });

  

  const [selectedButton, setSelectedButton] = useState("");
  const handleButtonClick = (value) => {
    setSelectedButton(value);
    setTargetGroup({
      ...targetGroup,
      schedule: value,
    });
  };
console.log(targetGroup,"targetGroup")
  const [selectedButton1, setSelectedButton1] = useState("");
  const handleButtonClickoverrideCapacity = (value) => {
    setSelectedButton1(value);
    setTargetGroup({
      ...targetGroup,
      overrideCapacity: value,
    });
  };

  const [selectedButton2, setSelectedButton2] = useState("");
  const handleButtonClickoverrideConcurrency = (value) => {
    setSelectedButton2(value);
    setTargetGroup({
      ...targetGroup,
      overrideConcurrency: value,
    });
  };

  const daysOfWeek = [
    { value: "Day", label: "Day" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];

  const [startDay, setStartDay] = useState(daysOfWeek[0].value);
  const [endDay, setEndDay] = useState(daysOfWeek[0].value);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();
  const handleStartDayChange = (event) => {
    const selectedDay = event.target.value;
    setStartDay(selectedDay);
    setTargetGroup((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        dayName: selectedDay,
      },
    }));
  };

  const handleEndDayChange = (event) => {
    const selectedDay = event.target.value;
    setEndDay(selectedDay);
    setTargetGroup((prevValue) => ({
      ...prevValue,
      to: {
        ...prevValue.to,
        dayName: selectedDay,
      },
    }));
  };

  const handleStartTimeChange = (event) => {
    const selectedTime1 = event.target.value;
    setStartTime(selectedTime1);
    setTargetGroup((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        start_time: selectedTime1,
      },
      to: {
        ...prevValue.to,
        start_time: selectedTime1,
      },
    }));
  };

  const handleEndTimeChange = (event) => {
    const selectedTime = event.target.value;
    setEndTime(selectedTime);
    setTargetGroup((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        end_time: selectedTime,
      },
      to: {
        ...prevValue.to,
        end_time: selectedTime,
      },
    }));
  };

  useEffect(() => {
    if (timezoneFilterLoading) {
      getTimezoneFilterData();
    }
    if (listTargetGroupLoading) {
      getListOfTarget();
    }
  }, []);

  const [getSimuldial, setSimuldial] = useState(false);
  const onSimuldial = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setSimuldial(true);
      enabled = true;
    } else {
      setSimuldial(false);
      enabled = false;
    }
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: enabled,
    });
  };

  const getTimezoneFilterData = async () => {
    let result = await fetch(apiUrl + "/api/report/timezone/lists");
    result = await result.json();
    setTimezoneFilter(result.data);
    setTimezoneFilterLoading(false);
  };

  console.log(targetGroup, "targetGroup-edit");

  const [listTargetGroup, setListTargetGroup] = useState([]);
  const [listTargetGroupLoading, setListTargetGroupLoading] = useState(true);
  const getListOfTarget = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        by: user.id,
      }),
    };
    let result = await fetch(
      apiUrl + "/api/targetgroup/list-target/all",
      requestOptions
    );
    result = await result.json();
    setListTargetGroup(result.targets);
    setListTargetGroupLoading(false);
  };

  console.log("in edit");

  const onPayoutHours = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setPayoutHoursEnabled(true);
      enabled = true;
    } else {
      setPayoutHoursEnabled(false);
      enabled = false;
    }
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: enabled,
    });
  };

  //   Global call
  const [viewGlobalCap, SetGlobalCap] = useState("hide");
  const onGlobalCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableGlobalCap(true);
      SetGlobalCap("show");

      enabled = true;
    } else {
      setEnableGlobalCap(false);
      SetGlobalCap("hide");
      enabled = false;
    }
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: enabled,
    });
  };

  const [viewMonthCap, SetMonthCap] = useState("hide");
  const onMonthlyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableMonthlyCap(true);
      SetMonthCap("show");
      enabled = true;
    } else {
      setEnableMonthlyCap(false);
      SetMonthCap("hide");
      enabled = false;
    }
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: enabled,
    });
  };

  const [viewDailyCap, SetDailyCap] = useState("hide");
  const onDailyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableDailyCap(true);
      SetDailyCap("show");
      enabled = true;
    } else {
      setEnableDailyCap(false);
      SetDailyCap("hide");
      enabled = false;
    }
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: enabled,
    });
  };

  const [viewHourlyCap, SetHourlyCap] = useState("hide");
  const onHourlyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableHourlyCap(true);
      SetHourlyCap("show");
      enabled = true;
    } else {
      setEnableHourlyCap(false);
      SetHourlyCap("hide");
      enabled = false;
    }
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: enabled,
    });
  };

  // ******************Table Changes********

  const [selectedOption, setSelectedOption] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  console.log(selectedItems, "selectedItems");
  const columns_list = React.useMemo(
    () => [
      { Header: "Target", accessor: "name" },
      { Header: "Destination", accessor: "phoneNumber" },
      { Header: "Status", accessor: "status" },
      {
        Header: "Action",
        accessor: (originalRow, rowIndex) => (
          <a onClick={() => handleDeleteClick(originalRow)}>
            {" "}
            <i className="fa-solid fa-trash"></i>
          </a>
        ),
      },
    ],
    []
  );

  useEffect(() => {
    // Prepopulate the table with the data from targetGroup.targets
    const prepopulateItems = targetGroup.targets.map((targetId) => {
      const targetItem = listTargetGroup.find((item) => item._id === targetId);
      return {
        ...targetItem,
      };
    });
    setSelectedItems(prepopulateItems);
  }, [listTargetGroup, targetGroup.targets]);

  const handleDeleteClick = (originalRow) => {
    console.log(originalRow, "originalRow");
    const itemId = originalRow._id;
    // Remove the item from selectedItems state
    setSelectedItems((prevItems) =>
      prevItems.filter((item) => item._id !== itemId)
    );

    // Remove the item's _id from targetGroup.targets state
    setTargetGroup((prevTargetGroup) => ({
      ...prevTargetGroup,
      targets: prevTargetGroup.targets.filter(
        (targetId) => targetId !== itemId
      ),
    }));
  };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows, // Use the rows variable directly from useTable hook
    prepareRow,
  } = useTable({ columns: columns_list, data: selectedItems }, useSortBy);

  const handleSelectChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleAddClick = () => {
    if (selectedOption) {
      const selectedItem = listTargetGroup.find(
        (item) => item._id === selectedOption
      );

      // Check if the selected item is already in the table
      const isAlreadyAdded = selectedItems.some(
        (item) => item._id === selectedItem._id
      );

      if (!isAlreadyAdded) {
        setSelectedItems((prevItems) => [...prevItems, selectedItem]);
        setTargetGroup((prevTargetGroup) => ({
          ...prevTargetGroup,
          targets: [...prevTargetGroup.targets, selectedItem._id],
        }));
      } else {
        toast.error("Entery Already Added");
      }
    }
  };

  useEffect(() => {
    if (targetGroup.schedule === "off") {
      targetGroup.from.dayName = "";
      targetGroup.from.end_time = "";
      targetGroup.from.start_time = "";
      targetGroup.to.dayName = "";
      targetGroup.to.end_time = "";
      targetGroup.to.start_time = "";
      targetGroup.enablePayoutHours = false;
      setPayoutHoursEnabled(false);
      setStartDay(daysOfWeek[0].value);
    }
    if (targetGroup.overrideCapacity === "off") {
      targetGroup.GlobalCallCap = "";
      targetGroup.monthlyCapValue = "";
      targetGroup.dailyCapValue = "";
      targetGroup.hourlyCapValue = "";
      targetGroup.enableGlobalCap = false;
      targetGroup.enableHourlyCap = false;
      targetGroup.enableMonthlyCap = false;
      targetGroup.enableDailyCap = false;
      setEnableGlobalCap(false);
      SetGlobalCap("hide");
      setEnableMonthlyCap(false);
      SetMonthCap("hide");
      setEnableDailyCap(false);
      SetDailyCap("hide");
      setEnableHourlyCap(false);
      SetHourlyCap("hide");
    }
    // if (targetGroup.overrideConcurrency === "off") {
    //   targetGroup.ConcurrencyValue = "";
    //   targetGroup.enableConcurrencyCap = false;
    //   setConcurrencyCap(false);
    //   SetConcurrencyCap("hide");
    // }
  }, [selectedButton]);

  // ***************************************

  const handleChange = (e) => {
    const { name, value } = e.target;
    setTargetGroup({
      ...targetGroup, //spread operator
      [name]: value,
    });
  };

  const fetchGroupBuyers = async () => {
    try {
      let buyerId = props.match.params.id;
      console.log(buyerId, "buyerId");

      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ by: user.id }), // Assuming user.id is a valid value
      };

      const response = await fetch(
        apiUrl + `/api/targetgroup/${buyerId}`,
        requestOptions
      );
      const data = await response.json();
      console.log(data, "data received");
      setTargetGroup(data);
    } catch (error) {
      console.log("Error fetching group buyers:", error);
      // Handle the error here, show an error message or take appropriate action.
    }
  };

  useEffect(() => {
    if (targetGroup.schedule!=='off'){
      if (targetGroup.enablePayoutHours === true) {
        setPayoutHoursEnabled(true);
        setStartDay(targetGroup.from.dayName);
        setEndDay(targetGroup.to.dayName);
        setStartTime(targetGroup.from.start_time);
        setEndTime(targetGroup.from.end_time);
      }
    }

    if (targetGroup.overrideCapacity!=='off'){
      if (targetGroup.enableGlobalCap === true) {
        setEnableGlobalCap(true);
        SetGlobalCap("show");
      }
      if (targetGroup.enableMonthlyCap === true) {
        setEnableMonthlyCap(true);
        SetMonthCap("show");
      }
      if (targetGroup.enableHourlyCap === true) {
        setEnableHourlyCap(true);
        SetHourlyCap("show");
      }
      if (targetGroup.enableDailyCap === true) {
        setEnableDailyCap(true);
        SetDailyCap("show");
      }
    }
    if (targetGroup.schedule !== "") {
      setSelectedButton(targetGroup.schedule);
    }
    if (targetGroup.overrideCapacity !== "") {
      setSelectedButton1(targetGroup.overrideCapacity);
    }
    if (targetGroup.overrideConcurrency !== "") {
      setSelectedButton2(targetGroup.overrideConcurrency);
    }
  }, [targetGroup]);

  useEffect(() => {
    fetchGroupBuyers();
  }, []);

  const UpdatebuyerGroup = () => {
    const loadToast = toast.loading("Please Wait....");
    let buyerId = props.match.params.id;
    // validation
    if (targetGroup.name == "") {
      toast.dismiss(loadToast);
      toast.error("Name is required!");
      return false;
    } else if (targetGroup.schedule == "") {
      toast.dismiss(loadToast);
      toast.error("Override Schedule is Not selected");
      return false;
    } else if (targetGroup.overrideCapacity == "") {
      toast.dismiss(loadToast);
      toast.error("Override Capacity is Not selected");
      return false;
    } else if (targetGroup.targets.length === 0) {
      toast.dismiss(loadToast);
      toast.error("At least one target is required");
      return false;
    }

    let config = {
      method: "put",
      url: apiUrl + `/api/targetgroup/${buyerId}`,
      headers: {
        "Content-Type": "application/json",
      },
      data: targetGroup,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.error) {
          toast.dismiss(loadToast);
          toast.error(response.data.error[0].message);
        } else {
          toast.dismiss(loadToast);
          toast.success("Target Group added Successfully ");
          history.push("/admin/buyers/users/lists");
        }
      })
      .catch((error) => {
        toast.dismiss(loadToast);
        console.log(error);
      });
  };

  return (
    <Flex
      className="buyerEditCustom"
      direction="column"
      minH="200vh"
      align="center"
      mt={{ sm: "120px", md: "75px" }}
    >
      <Tabs variant="unstyled" mt="24px">
        <TabPanels mt="24px" maxW={{ md: "90%", lg: "85%" }} mx="auto">
          <TabPanel>
            <Card>
              <CardHeader mb="20px">
                <Text color={"#fff"} fontSize="lg" fontWeight="bold" mb="3px">
                  Create Group
                </Text>
              </CardHeader>
              <CardBody>
                <Flex
                  style={{ maxWidth: "780px", marginLeft: "1rem" }}
                  direction="column"
                  w="100%"
                >
                  <Stack direction="column" spacing="20px">
                    <FormControl style={{ margin: "15px 0px" }}>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                          style={{ width: "18%" }}
                        >
                          Name{" "}
                          <Tooltip
                            label="The Name Used to Identify this group throughout Rings Tracker. Shorter names are better"
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                            maxWidth="150px"
                          >
                            <QuestionIcon marginLeft="5px" />
                          </Tooltip>
                        </FormLabel>
                        <div className="inputPrice">
                          <Input
                            type="textarea"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Enter Target Name"
                            fontSize="xs"
                            name="name"
                            onChange={handleChange}
                            value={targetGroup.name}
                          />
                        </div>
                      </Flex>
                    </FormControl>
                    <FormControl style={{ margin: "15px 0px" }}>
                      <Flex>
                        {" "}
                        <FormLabel
                          style={{ width: "18%" }}
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Timezone{" "}
                          <Tooltip
                            label="Select the Time Zone that this target operates in. All caps operate based on this time zone."
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                            maxWidth="150px"
                          >
                            <QuestionIcon marginLeft="5px" />
                          </Tooltip>
                        </FormLabel>
                        <div className="inputPrice">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "row",
                            }}
                          >
                            {timezoneFilterLoading ? (
                              <p>Loading Please wait...</p>
                            ) : (
                              <Select
                                onChange={handleChange}
                                value={targetGroup.timezone}
                                name="timezone"
                                border=".5px solid"
                                borderColor="rgba(226, 232, 240, 0.3)"
                                _hover="none"
                                color="#fff"
                                bg="#0F1535"
                                borderRadius="15px"
                                color="gray.400"
                                fontSize="xs"
                              >
                                <option value="">Select Time Zone</option>
                                {timezoneFilter?.length > 0 ? (
                                  timezoneFilter.map((item, index) => (
                                    <>
                                      <option value={item.value}>
                                        {item.text}
                                      </option>
                                    </>
                                  ))
                                ) : (
                                  <></>
                                )}
                              </Select>
                            )}
                          </div>
                        </div>
                      </Flex>
                    </FormControl>
                    <FormControl style={{ margin: "15px 0px" }}>
                      <Flex>
                        <FormLabel
                          htmlFor="email-alerts"
                          mb="0"
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                          style={{ width: "16%" }}
                        >
                          Simuldial
                          <Tooltip
                            label="Dial all targets in this group at the same time. The first target to answer gets the call ensuring the shortest hold time possible for callers."
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                          >
                            <QuestionIcon marginLeft="5px" />
                          </Tooltip>
                        </FormLabel>
                        <div>
                          <Switch
                            style={{ flexBasis: "100%" }}
                            onChange={onSimuldial}
                            name="simuldial"
                            value={targetGroup.simuldial}
                            isChecked={targetGroup.simuldial}
                          />
                        </div>
                      </Flex>
                    </FormControl>
                    <FormControl style={{ margin: "15px 0px" }}>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                          style={{ width: "21%" }}
                        >
                          Override Schedule
                          <Tooltip
                            label="Override pre-configured settings of targets inside of this group."
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                          >
                            <QuestionIcon marginLeft="5px" />
                          </Tooltip>
                        </FormLabel>
                        <div className="inputPrice">
                          {/* Step 4: Attach handleButtonClick to each button */}
                          <Button
                            variant="primary"
                            name="schedule"
                            onClick={() => handleButtonClick("off")}
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton === "off" ? "#63B3ED" : "#0d6efd"
                            }
                          >
                            OFF
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => handleButtonClick("campaign")}
                            name="schedule"
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton === "campaign"
                                ? "#63B3ED"
                                : "#0d6efd"
                            }
                          >
                            Campaign
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() => handleButtonClick("global")}
                            name="schedule"
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton === "global"
                                ? "#63B3ED"
                                : "#0d6efd"
                            }
                          >
                            Global
                          </Button>
                        </div>
                      </Flex>
                    </FormControl>
                    {targetGroup.schedule === "off" ? (
                      <></>
                    ) : (
                      <FormControl>
                        <Flex
                          display="flex"
                          alignItems="center"
                          className="payoutHours"
                        >
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                            style={{ width: "19%" }}
                          >
                            Hours Of Operation
                            <Tooltip
                              label=" Set your target's hours of operation. For weekly schedule use advanced settings."
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                              maxWidth="150px"
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <span>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onPayoutHours}
                              name="enablePayoutHours"
                              value={payoutHoursEnabled}
                              isChecked={payoutHoursEnabled}
                            />
                            <div className="openClose hide">
                              <table>
                                <tr>
                                  <th></th>
                                  <th>Open</th>
                                  <th>Close</th>
                                </tr>
                                <tr>
                                  <td
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                    }}
                                  >
                                    <div>
                                      <select
                                        id="start-day"
                                        value={startDay}
                                        onChange={handleStartDayChange}
                                        style={{
                                          background: "#161c4b",
                                          color: "white",
                                          WebkitAppearance: "none",
                                          appearance: "none",
                                          width: "77px",
                                          textAlign: "center",
                                        }}
                                      >
                                        {daysOfWeek.map((day, index) => (
                                          <option
                                            key={day.value}
                                            value={day.value}
                                            disabled={index === 0}
                                          >
                                            {day.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                    <span
                                      style={{
                                        marginLeft: ".5rem",
                                        marginRight: ".9rem",
                                        background: "none",
                                      }}
                                    >
                                      to
                                    </span>
                                    <div>
                                      <select
                                        id="end-day"
                                        value={endDay}
                                        onChange={handleEndDayChange}
                                        style={{
                                          background: "#161c4b",
                                          color: "white",
                                          WebkitAppearance: "none",
                                          appearance: "none",
                                        }}
                                      >
                                        {daysOfWeek.map((day, index) => (
                                          <option
                                            key={day.value}
                                            value={day.value}
                                            disabled={index === 0}
                                          >
                                            {day.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      id="intialTime"
                                      value={startTime}
                                      onChange={handleStartTimeChange}
                                    />{" "}
                                  </td>
                                  <td>
                                    <input
                                      type="time"
                                      id="endTime"
                                      value={endTime}
                                      onChange={handleEndTimeChange}
                                    />{" "}
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </span>
                        </Flex>
                      </FormControl>
                    )}
                    <VSeparator className="verticleSeparator" />
                    <CardHeader mb="40px">
                      <Text
                        color={"#fff"}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="20px"
                      >
                        Cap Settings
                      </Text>
                    </CardHeader>
                    <FormControl style={{ margin: "10px 0px" }}>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                          style={{ width: "21%" }}
                        >
                          Override Capacity
                          <Tooltip
                            label="Override pre-configured capacity settings for targets inside this group. If set to 'Campaign', the group will have a counter for each campaign. If set to 'Global', all targets will share the same counter within the group."
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                            maxWidth="150px"
                          >
                            <QuestionIcon marginLeft="5px" />
                          </Tooltip>
                        </FormLabel>
                        <div className="inputPrice">
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleButtonClickoverrideCapacity("off")
                            }
                            name="overrideCapacity"
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton1 === "off" ? "#63B3ED" : "#0d6efd"
                            }
                          >
                            OFF
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleButtonClickoverrideCapacity("campaign")
                            }
                            name="overrideCapacity"
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton1 === "campaign"
                                ? "#63B3ED"
                                : "#0d6efd"
                            }
                          >
                            Campaign
                          </Button>
                          <Button
                            variant="primary"
                            onClick={() =>
                              handleButtonClickoverrideCapacity("global")
                            }
                            name="overrideCapacity"
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton1 === "global"
                                ? "#63B3ED"
                                : "#0d6efd"
                            }
                          >
                            Global
                          </Button>
                        </div>
                      </Flex>
                    </FormControl>
                    {targetGroup.overrideCapacity === "off" ? (
                      <></>
                    ) :<>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Global Call Cap
                        <Tooltip
                          label="Calls will route to this target until the global call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                          <QuestionIcon marginLeft="5px" />
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "46px" }}
                          onChange={onGlobalCapChange}
                          name="enableGlobalCap"
                          value={enableGlobalCap}
                          isChecked={enableGlobalCap}
                        />
                        {viewGlobalCap === "show" ? (
                          <Input
                            onChange={handleChange}
                            value={targetGroup.GlobalCallCap}
                            name="GlobalCallCap"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Global Call Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Monthly Cap{" "}
                        <Tooltip
                          label="Calls will route to this target until the monthly call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                          <QuestionIcon marginLeft="5px" />
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "58px" }}
                          onChange={onMonthlyCapChange}
                          name="enableMonthlyCap"
                          value={enableMonthlyCap}
                          isChecked={enableMonthlyCap}
                        />
                        {viewMonthCap === "show" ? (
                          <Input
                            onChange={handleChange}
                            value={targetGroup.monthlyCapValue}
                            name="monthlyCapValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder=" Monthly Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Daily Cap{" "}
                        <Tooltip
                          label="Calls will route to this target until the daily call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                          <QuestionIcon marginLeft="5px" />
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "78px" }}
                          onChange={onDailyCapChange}
                          name="enableDailyCap"
                          value={enableDailyCap}
                          isChecked={enableDailyCap}
                        />
                        {viewDailyCap === "show" ? (
                          <Input
                            onChange={handleChange}
                            value={targetGroup.dailyCapValue}
                            name="dailyCapValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Daily Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Hourly Cap{" "}
                        <Tooltip
                          label="Calls will route to this target until the hourly call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                          <QuestionIcon marginLeft="5px" />
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "68px" }}
                          onChange={onHourlyCapChange}
                          name="enableHourlyCap"
                          value={enableHourlyCap}
                          isChecked={enableHourlyCap}
                        />
                        {viewHourlyCap === "show" ? (
                          <Input
                            onChange={handleChange}
                            value={targetGroup.hourlyCapValue}
                            name="hourlyCapValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Hourly Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl></>}
                    <FormControl style={{ margin: "20px 0px" }}>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                          style={{ width: "23.5%" }}
                        >
                          Override Concurrency
                          <Tooltip
                            label="Override pre-configured capacity settings for targets inside this group. If set to 'Campaign', the group will have a counter for each campaign. If set to 'Global', all targets will share the same counter within the group."
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                            maxWidth="150px"
                          >
                            <QuestionIcon marginLeft="3px" />
                          </Tooltip>
                        </FormLabel>
                        <div className="inputPrice">
                          <Button
                            variant="primary"
                            name="overrideConcurrency"
                            onClick={() =>
                              handleButtonClickoverrideConcurrency("off")
                            }
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton2 === "off" ? "#63B3ED" : "#0d6efd"
                            }
                          >
                            OFF
                          </Button>
                          <Button
                            variant="primary"
                            name="overrideConcurrency"
                            onClick={() =>
                              handleButtonClickoverrideConcurrency("campaign")
                            }
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton2 === "campaign"
                                ? "#63B3ED"
                                : "#0d6efd"
                            }
                          >
                            Campaign
                          </Button>
                          <Button
                            variant="primary"
                            name="overrideConcurrency"
                            onClick={() =>
                              handleButtonClickoverrideConcurrency("global")
                            }
                            style={{
                              borderRadius: "0px",
                              color: "#fff",
                              fontWeight: "500",
                              marginLeft: "0px",
                            }}
                            backgroundColor={
                              selectedButton2 === "global"
                                ? "#63B3ED"
                                : "#0d6efd"
                            }
                          >
                            Global
                          </Button>
                        </div>
                      </Flex>
                    </FormControl>
                    <VSeparator className="verticleSeparator" />
                    <CardHeader mb="40px">
                      <Text
                        color={"#fff"}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        Add Targets{" "}
                        <Tooltip
                          label="Add targets to this group which will use the group's settings"
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                          <QuestionIcon marginLeft="5px" />
                        </Tooltip>
                      </Text>
                    </CardHeader>
                    <FormControl style={{ margin: "20px 0px" }}>
                      <Flex align="center">
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Select Target
                          <Tooltip
                            label="Select the Time Zone that this target operates in. All caps operate based on this time zone."
                            placement="right"
                            hasArrow
                            fontSize="11px"
                            color="black"
                            bg="white"
                            arrowSize={7}
                            style={{ width: "25%" }}
                          >
                            <QuestionIcon marginLeft="5px" />
                          </Tooltip>
                        </FormLabel>

                        {listTargetGroupLoading ? (
                          <p>Loading Please wait...</p>
                        ) : (
                          <Select
                            value={selectedOption}
                            onChange={handleSelectChange}
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            bg="#0F1535"
                            borderRadius="15px"
                            color="gray.400"
                            fontSize="xs"
                            inputControl={<Input />}
                            isSearchable
                            mx="10px"
                            width="70%"
                          >
                            <option value="">Select an option</option>
                            {listTargetGroup.map((item) => (
                              <option key={item._id} value={item._id}>
                                {item.name}
                              </option>
                            ))}
                          </Select>
                        )}
                        <Button
                          onClick={handleAddClick}
                          disabled={!selectedOption}
                          size="sm"
                          variant="outline"
                          borderColor="#0d6efd"
                          color="#FFF"
                          borderWidth="3px"
                        >
                          Add
                        </Button>
                      </Flex>
                    </FormControl>
                    <Table
                      {...getTableProps()}
                      className="report_custom_table"
                      variant="simple"
                      style={{ marginTop: "10px" }}
                    >
                      <Thead>
                        {headerGroups.map((headerGroup, index) => (
                          <Tr
                            key={index}
                            {...headerGroup.getHeaderGroupProps()}
                          >
                            {headerGroup.headers.map((column, index) => (
                              <Th
                                key={index}
                                color="gray.400"
                                fontSize="10px"
                                fontFamily="Inter"
                                borderColor="#56577A"
                                {...column.getHeaderProps(
                                  column.getSortByToggleProps()
                                )}
                              >
                                <Flex
                                  justify="space-between"
                                  align="center"
                                  fontSize={{ sm: "10px", lg: "12px" }}
                                  color="gray.400"
                                >
                                  {column.render("Header")}
                                  <Icon
                                    w={{ sm: "10px", md: "14px" }}
                                    h={{ sm: "10px", md: "14px" }}
                                    color={
                                      columns_list.isSorted
                                        ? "gray.500"
                                        : "gray.400"
                                    }
                                    float="right"
                                    as={
                                      column.isSorted
                                        ? column.isSortedDesc
                                          ? TiArrowSortedDown
                                          : TiArrowSortedUp
                                        : TiArrowUnsorted
                                    }
                                  />
                                </Flex>
                              </Th>
                            ))}
                          </Tr>
                        ))}
                      </Thead>
                      <Tbody {...getTableBodyProps()}>
                        {selectedItems.length === 0 ? (
                          <Tr>
                            <Td colSpan={columns_list.length}>
                              <div style={{ color: "#fff" }}>
                                No Target added
                              </div>
                            </Td>
                          </Tr>
                        ) : (
                          rows.map((row, index) => {
                            prepareRow(row);
                            return (
                              <Tr {...row.getRowProps()} key={index}>
                                {row.cells.map((cell, cellIndex) => (
                                  <Td
                                    key={cellIndex}
                                    minW="150px"
                                    color="#fff"
                                    borderColor="#56577A"
                                    {...cell.getCellProps()}
                                  >
                                    <Stack direction="row" spacing="2px">
                                      <Text
                                        className="publisherName"
                                        color="#fff"
                                        fontSize="10px"
                                        fontWeight="bold"
                                        style={{
                                          paddingLeft: "5px !important",
                                        }}
                                      >
                                        {cell.value}
                                      </Text>
                                    </Stack>
                                  </Td>
                                ))}
                              </Tr>
                            );
                          })
                        )}
                      </Tbody>
                    </Table>
                  </Stack>
                  <Flex justify="space-between" mx="auto">
                    <Button
                      onClick={UpdatebuyerGroup}
                      variant="brand"
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      fontSize="10px"
                      color="#fff"
                    >
                      Update Group
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
};

export default EditGroupTarget;
