/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, useDisclosure } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import StrategyNav from 'components/Navbars/Strategy';
import NewBuyer from "./NewBuyer";
import BuyerList from './BuyerList'
import CTModal from "components/Modal";
import axios from "axios";
import toast from "react-hot-toast";
import packageJson from "../../../appConfig.js";

function Buyers() {
  const apiUrl = packageJson.apiurl;
  const [data, setData] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refresh, refreshSet] = React.useState("");
  const [modalType, setModalType] = useState("");
  const [editRow, setEditRow] = useState(null)
  const { isOpen, onOpen, onClose } = useDisclosure();
  const user = JSON.parse(localStorage?.getItem("user"));

  const addBuyer = async (type, row=null) => {
    setModalType(type);
    setEditRow(row);
    onOpen();
  };

  const getData = async () => {
    const dataUrl =  user.role == 1
      ? apiUrl + "/api/admin/list-buyers"
      : apiUrl + "/api/buyers/list-buyers";

    axios
      .get(dataUrl, { params: { by: user.id } })
      .then((response) => {
        setData(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const getPageData = async () => {
    const loadToast = toast.loading("Loading...");
    await getData();
    setLoadingData(false);
    toast.dismiss(loadToast);
  };

  const onFormClose = () => {
    getPageData();
    onClose();
  };

  useEffect(() => {
    getPageData();
  }, [refresh]);

  return (
    <>
      <Flex
        className="addpub"
        direction="column"
        gap={10}
      >
        {/* Datatable with search */}
        <Card>
          <CardBody>
            {(user.role == 0 || user.role == 1) &&
              <StrategyNav current="/administrator/buyers/users/lists" />
            }
            {user.role != 0 && user.role != 1 &&
              <Text fontSize='lg' mb={6} color='#fff' fontWeight='bold'>
                Buyers Lists
              </Text>
            }
            <Flex flexDirection={'column'}>
              <BuyerList 
                loading={loadingData}
                tableData={data}
                refresh={onFormClose}
                addBuyer={addBuyer}
              />
            </Flex>
          </CardBody>
        </Card>
      </Flex>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={modalType}
      >
        {["Create Target", "Edit Target"].indexOf(modalType) !== -1 &&
          <NewBuyer
            target={editRow}
            onClose={onFormClose}
            refresh={refreshSet}
          />
        }
      </CTModal>
      </>
  );
}

export default Buyers;
