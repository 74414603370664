/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Select,
  Input,

} from "@chakra-ui/react";

// Custom components
import React, { useEffect, useState } from "react";
import axios from "axios";
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
const user = JSON.parse(localStorage?.getItem("user"));

function NewPublisher(props) {
  const apiUrl = packageJson.apiurl;
  const [prefixSearch, setPrefixSearch] = useState("");
  const [numbers, setNumbers] = useState([]);
  const [prefixError, setPrefixError] = useState(null);
  const [newUser, setNewUser] = useState({
    name: '',
    email: "",
    password: "",
    phoneType: '',
    number: '',
    by: null,
  });
  const [clients, setClients] = useState([]);
  const [edit, setEdit] = useState(false);

  useEffect(() => {
    getClients();

    if ("name" in props.publisher) {
      setEdit(true);
      const { publisher } = props;
      setNewUser({
        name: publisher.name,
        email: publisher.email,
        phoneType: publisher.phoneType,
        number: publisher.phone,
        newPassword: "",
        password: "",
      });
    }

    return () => {
      props.refresh(Math.random());
      props.clearPublisher({});
    };
  }, []);

  useEffect(() => {
    if (prefixSearch.length === 3 && newUser.phoneType !== "") {
      axios("/api/twilio/prefix/numbers", {
        method: 'get',
        headers: {
          'Content-Type': 'application/json'
        },
        params: {
          prefix: prefixSearch,
          phoneType: newUser.phoneType,
          clientId: user.id,
        },
      }).then(({ data }) => {
        if (data.numbers) {
          setNumbers(data.numbers);
          setPrefixError(null);
        } else {
          setPrefixError(data.error);
        }
      }).catch(({ message, response }) => {
        setPrefixError(response.data.error);
        console.log(message);
      });
    }
  }, [prefixSearch, newUser.phoneType]);

  const changeValues = (e) => {
    const field = e.target.name;
    const value = e.target.value;
    const formValues = {...newUser};
    formValues[field] = value;
    setNewUser(formValues);
  }

  const getClients = async () => {
    let result = await fetch(apiUrl + "/api/users/client/lists");
    result = await result.json();
    setClients(result);
  }

  const editPublisher = async() => {
    const loadToast = toast.loading('Loading...');
    const editedUser = { ...newUser };
    if (newUser.password === "" && newUser.newPassword !== "") {
      toast.dismiss(loadToast);
      toast.error("Please enter old password!");
      return;
    }

    if (newUser.name == "") {
      toast.dismiss(loadToast);
      toast.error("Name Field is required!");
      return;
    }

    if (newUser.password === newUser.newPassword) {
      editedUser.password = ""; 
      editedUser.newPassword = "";
    }

    try {
      const  path= '/api/users/publisher/update';
      await axios.post(apiUrl + path,
        {
          name: editedUser.name,
          phoneType: editedUser.phoneType,
          email: editedUser.email,
          phone: editedUser.number,
          id: props.publisher._id,
          password: editedUser.password,
          newPassword: editedUser.newPassword,
        },
        {
          headers: {
            'Content-Type': 'application/json'
          },
        })
        .then(({ data, status}) => {
          if (data.success) {
            toast.success('Publisher updated successfully');
            props.onClose();
          } else {
            toast.error(data.error);
            return;
          }
        })
        .catch((error) => {
          toast.error(error.message);
        }
      );  
    } catch (error) {
      toast.error(error.message);
      console.error('Error:', error);
    }
    toast.dismiss(loadToast);
  };

  const handleCreatePublisher = async () => {
    const loadToast = toast.loading('Loading...');
    if (!newUser.name || newUser.name === "") {
      toast.dismiss(loadToast);
      toast.error("Publisher Name required!");
      return;
    }

    if (newUser.email == "") {
      toast.dismiss(loadToast);
      toast.error("Email Field is required!");
      return;
    } 

    if (newUser.password == "") {
      toast.dismiss(loadToast);
      toast.error("Password Field is required!");
      return;
    }

    if (!newUser.phoneType || newUser.phoneType === "") {
      toast.dismiss(loadToast);
      toast.error("Phone Type required!");
      return;
    }

    if (user.role == 0 && (!newUser.by || newUser.by === "")) {
      toast.dismiss(loadToast);
      toast.error("Client is required!");
      return;
    }

    let path = "";
    if (newUser.phoneType == "TFN") {
      path = '/api/twilio/phonenumber/TFNforpublisher';
    } else {
      path = '/api/twilio/phonenumber/Localforpublisher';
    }

    try {
      const response = await fetch(apiUrl + path, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          publisherName: newUser.name,
          by: newUser.by ? newUser.by : user.id,
          selectedValue: newUser.phoneType,
          phone: newUser.number,
        })
      });

      const data = await response.json();
      if (data.status === "success") {
        const publisher = {
          ...newUser,
          number: data.phoneNumber,
          numberSID: data.sid,
        };
        await addPublishersJs(publisher);
      } else {
        toast.dismiss(loadToast);
        toast.error(data.error);
        return;
      }
      toast.dismiss(loadToast);
    } catch (error) {
      toast.dismiss(loadToast);
      console.error('Error:', error);
    }
  };

  async function addPublishersJs(publisher) {
    try {
      const  path= '/api/users'

      const pubData = {
          name: publisher.name, // Assuming server expects "name" instead of "publisherName"
          by: publisher.by ? publisher.by : user.id,
          email: publisher.email,
          password: publisher.password,
          phoneType: publisher.phoneType,
          role: 2,
          phone: publisher.number, // Assuming server expects "phone" instead of "phoneNumber"
          phoneSID: publisher.numberSID,
      };

      await axios.post(apiUrl + path, pubData,
        {
          headers: {
            'Content-Type': 'application/json'
          },
        })
        .then(({ data, status}) => {
          if (data.success) {
            toast.success('Publisher added successfully');
            props.onClose();
            props.refresh(data.user);
          } else {
            toast.error(data.error);
            return;
          }
        })
        .catch((error) => {
          console.log(error);
        }
      );  
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <Flex direction="column" color={'white'} gap={5}>
      <FormControl>
        <FormLabel variant="ct-label">Name</FormLabel>
        <Input
          variant='borderless'
          type="text"
          placeholder="Enter Publisher Name"
          name="name"
          value={newUser.name} // Bind the value to the state variable
          onChange={changeValues} // Call this function on input change
        />
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Email
        </FormLabel>
        <Input
          variant="borderless"
          type='email'
          name="email"
          value={newUser.email}
          onChange={changeValues}
          disabled={edit}
        />
      </FormControl>
      <Flex
        flexDirection={{base: 'colum', md: 'row'}}
        gap={5}
      >
        <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
          <FormLabel variant="ct-label">
            {edit ? "Old" : ""} Password
          </FormLabel>
          <Input
            variant="borderless"
            type='password'
            name="password"
            value={newUser.password}
            onChange={changeValues}
          />
        </FormControl>
        {/* a */}
      </Flex>
      {edit &&
        <FormControl>
          <FormLabel variant="ct-label">
            New Password
          </FormLabel>
          <Input
            variant="borderless"
            type='password'
            name="newPassword"
            value={newUser.newPassword}
            onChange={changeValues}
          />
        </FormControl>
      }
      {!edit &&
        <>
          <FormControl>
            <FormLabel variant="ct-label">Number Type</FormLabel>
            <Select
              variant="borderless"
              value={newUser.phoneType}
              name="phoneType"
              onChange={changeValues}
            >
              <option value="">Select Number Type</option>
              <option value="TFN">TFN</option>
              <option value="DID">DID</option>
            </Select>
          </FormControl>
          <FormControl>
            <FormLabel variant="ct-label">Search Prefix</FormLabel>
            <Input
              variant='borderless'
              type="text"
              placeholder="Search prefix"
              onChange={(e) => setPrefixSearch(e.target.value)} // Call this function on input change
              maxLength={3}
            />
            {prefixError &&
              <FormLabel variant="ct-label" style={{color: "red"}}>{prefixError}</FormLabel>
            }
          </FormControl>
        </>
      }
      {prefixSearch.length === 3 && !edit &&
        <FormControl>
          <FormLabel variant="ct-label">Number</FormLabel>
          <Select
            variant="borderless"
            name="number"
            value={newUser.number}
            onChange={changeValues}
          >
            <option value="">Select Numbers</option>
            {numbers.map(number => {
              return (<option value={number}>{number}</option>);
            })}
          </Select>
        </FormControl>
      }
      {edit && prefixSearch.length !== 3 &&
        <FormControl>
          <FormLabel variant="ct-label">Number</FormLabel>
          <Input
            variant="borderless"
            value={newUser.number}
            disabled
          />
        </FormControl>
      }
      {user.role == 0 && !edit &&
        <FormControl>
          <FormLabel variant="ct-label">
            Assign To
          </FormLabel>
          <Select
            variant='borderless'
            name="by" 
            onChange={changeValues}
            value={newUser.by}
          >
            <option value=''>Choose User Options</option>
            {clients.data && clients.data.map((item, index) =>
              <>
                <option key={item._id} value={item._id}>{item.name}</option>
              </>
            )}
          </Select>
        </FormControl>
      }
      <Button
        variant="formButton"
        onClick={edit ? editPublisher : handleCreatePublisher} 
      >
        { edit ? "Update" : "Create" }
      </Button>
    </Flex>
  );
}

export default NewPublisher;
