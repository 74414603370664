import ContextSwitcher from './ContextSwitcher';

const Strategy = ({ current }) => {
  const menu = [
    {
      label: 'Campaigns',
      href: '/administrator/campaign/lists',
    },
    {
      label: 'Publishers',
      href: '/administrator/publishers/users/lists',
    },
    {
      label: 'Numbers',
      href: '/administrator/number/default',
    },
    {
      label: 'Buyers',
      href: '/administrator/buyers/users/lists',
    },
  ];

  const activeTab = (menu.findIndex(x => x.href == current) || 0) + 1;

  return (
    <ContextSwitcher
      options={menu}
      activeTab={activeTab}
    />
  );
};

export default Strategy;
