/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import ComingSoon from "views/Pages/ComingSoon";

import Default from "views/Dashboard/Default.js";

import LivePublishers from "views/LiveCalls/Clients/publishers.js";
import LiveBuyers from "views/LiveCalls/Clients/buyers.js";
import LiveCampaigns from "views/LiveCalls/Clients/campaigns.js";

import Campaigns from "views/Pages/Administrator/Campaigns.js";
import NewCampaign from "views/Pages/Administrator/NewCampaign.js";

import Publishers from "views/Pages/Administrator/Publishers.js";
import Buyers from "views/Pages/Administrator/Buyers.js";
import Numbers from "views/Numbers/Administrator/Numbers.js";

import MessageVoicemail from "views/MessageVoicemail/Admin/MessageList.js";

import NotifyMe from "views/Pages/Users/NotifyMe.js";

import RoutingScheme from "views/RoutingPlans/Clients/routingPlanLists";
import RoutingFlow from "views/CallFlows/Clients/callFlowLists";
import Media from "views/Media/MediaList";

import ReportDefault from "views/Pages/AdministratorReports/Default.js";

import Settings from "views/Pages/Account/Settings.js";
import Billing from "views/Pages/Account/Billing.js";
import Client from "views/Pages/Account/Clients.js";
import ClientCreate from "views/Pages/Account/ClientCreate";

import Integrations from "views/Pages/Administrator/Integration.js";

/*
import Tables from "views/Dashboard/Tables.js";
import Calendar from "views/Applications/Calendar";
import DataTables from "views/Applications/DataTables";
import Kanban from "views/Applications/Kanban.js";
import Wizard from "views/Applications/Wizard.js";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import CRM from "views/Dashboard/CRM.js";
import OrderDetails from "views/Ecommerce/Orders/OrderDetails";
import OrderList from "views/Ecommerce/Orders/OrderList";
import EditProduct from "views/Ecommerce/Products/EditProduct";
import NewProduct from "views/Ecommerce/Products/NewProduct";
import ProductPage from "views/Ecommerce/Products/ProductPage";
import Invoice from "views/Pages/Account/Invoice.js";
import Alerts from "views/Pages/Alerts";
import Charts from "views/Pages/Charts.js";
import Pricing from "views/Pages/Pricing.js";
import Overview from "views/Pages/Profile/Overview.js";
import Projects from "views/Pages/Profile/Projects.js";
import Teams from "views/Pages/Profile/Teams.js";
import General from "views/Pages/Projects/General.js";
import Timeline from "views/Pages/Projects/Timeline.js";
import RTLPage from "views/Pages/RTLPage.js";
import NewUser from "views/Pages/Administrator/NewUser.js";
import EditPublisher from "views/Pages/Administrator/EditPublisher.js";
import NewBuyer from "views/Pages/Administrator/NewBuyer.js";
import EditByer from "views/Pages/Administrator/EditByer.js";
import Reports from "views/Pages/Administrator/Reports.js";
import EditCampaign from "views/Pages/Administrator/EditCampaign.js";
import Widgets from "views/Pages/Widgets.js";
import ForgetPassword from "views/Authentication/SignIn/ForgetPassword.js";
import ResetPassword from "views/Authentication/SignIn/ResetPassword.js";
import Numbers from "views/Numbers/Administrator/Numbers.js";
import CreateNumber from "views/Numbers/Administrator/CreateNumber.js";
import EditNumber from "views/Numbers/Administrator/EditNumber.js";
import Client from "views/Pages/Administrator/Clients.js";
import EditClient from "views/Pages/Administrator/EditClient.js";
import ClientsInviations from "views/Pages/Administrator/ClientsInviations.js";
import AddClientsInviations from "views/Pages/Administrator/AddClientsInviations.js";
import createClient from "views/Pages/Administrator/ClientCreate";
*/

const user = JSON.parse(localStorage?.getItem("user"));

const dashRoutes = [
  {
    name: "Dashboard",
    path: "/dashboard/default",
    component: Default,
    layout: "/administrator",
  },


  {
    name: "Live Calls",
    path: "/live-calls/publishers",
    layout: "/administrator",
    children: [
      {
        name: "Publishers",
        path: "/live-calls/publishers",
        component: LivePublishers,
        layout: "/administrator",
      },
      {
        name: "Buyers",
        path: "/live-calls/buyers",
        component: LiveBuyers,
        layout: "/administrator",
      },
      {
        name: "Campaigns",
        path: "/live-calls/campaigns",
        component: LiveCampaigns,
        layout: "/administrator",
      },
    ],
  },


  {
    name: "Strategy",
    path: "/campaign/lists",
    layout: "/administrator",
    children: [
      {
        name: "Campaigns",
        path: "/campaign/lists",
        component: Campaigns,
        layout: "/administrator",
      },
      {
        name: "Add Campaign",
        showInMainNav: false,
        path: "/campaign/create",
        component: NewCampaign,
        layout: '/administrator',
      },
      {
        name: "Edit Campaign",
        showInMainNav: false,
        path: "/campaign/:id/edit",
        component: NewCampaign,
        layout: '/administrator',
      },

      {
        name: "Publishers",
        path: "/publishers/users/lists",
        component: Publishers,
        layout: "/administrator",
      },
      {
        name: "Buyers",
        path: "/buyers/users/lists",
        component: Buyers,
        layout: "/administrator",
      },
      {
        name: "Numbers",
        path: "/number/default",
        component: Numbers,
        layout: "/administrator",
      },
    ],
  },

  {
    name: "Messages",
    path: "/messages-voicemails/lists",
    component: MessageVoicemail,
    layout: "/administrator",
  },


  {
    name: "Monitoring",
    path: "/notify/me/settings",
    component: NotifyMe,
    layout: "/administrator",
  },

  {
    name: "Routing",
    path: "/callflows/routings",
    layout: "/administrator",
    roles: ["1"],
    children: [
      {
        name: "Routing Scheme",
        path: "/callflows/routings",
        component: RoutingScheme,
        layout: "/administrator",
      },
      {
        name: "Routing Flow",
        path: "/callflows/lists",
        component: RoutingFlow,
        layout: "/administrator",
      },
      { // Does nothing
        name: "Buyer's Group",
        path: "#",
      },
      {
        name: "Media Files",
        path: "/callflows/manage/media",
        component: Media,
        layout: "/administrator",
      },
      { // Does nothing
        name: "Template",
        path: "#",
      },
    ],
  },

  {
    name: "Reports",
    path: "/reports/default",
    component: ReportDefault,
    layout: "/administrator",
  },


  {
    name: "Account Settings",
    path: "/accounts/profile",
    component: Settings,
    layout: "/administrator",
    children: [
      {
        name: 'Profile',
        path: '/accounts/profile',
        component: Settings,
        layout: '/administrator',
      },
      {
        name: 'Billing',
        path: '/accounts/billing',
        component: Billing,
        layout: '/administrator',
      },
      {
        name: 'Pending Clients',
        path: '/accounts/management',
        component: Client,
        layout: '/administrator',
      },
      {
        name: 'Add Client',
        path: '/accounts/new',
        component: ClientCreate,
        layout: '/administrator',
      },
    ],
  },

  { // Does nothing
    name: "Switch Company",
    path: "/coming-soon",
    component: ComingSoon,
    layout: "/administrator",
  },

  {
    name: "Integrations",
    path: "/twilio/integration",
    component: Integrations,
    roles: ["0"],
    layout: "/administrator",
  },

/*
  {
    name: "Publishers",
    path: "/publishers",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Lists",
        path: "/publishers/users/lists",
        component: Publishers,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/publishers/users/create",
        component: NewPublisher,
        layout: "/administrator",
      },
      {
        name: "Edit Publisher",
        path: "/publishers/users/:id/edit",
        component: EditPublisher,
        layout: "/administrator",
      },
      {
        name: "Edit Client",
        path: "/client/:id/edit",
        component: EditClient,
        layout: "/administrator",
      },
      {
        name: "Add Invitations",
        path: "/client/invitation/create",
        component: AddClientsInviations,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/client/create",
        component: createClient,
        layout: "/administrator",
      },
    ],
  },
  {
    name: "Buyers",
    path: "/buyers",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Lists",
        path: "/buyers/users/lists",
        component: Buyers,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/buyers/users/create",
        component: NewBuyer,
        layout: "/administrator",
      },
      {
        name: "Edit Buyer",
        path: "/buyers/users/:id/edit",
        component: EditByer,
        layout: "/administrator",
      }
    ],
  },
  {
    name: "Report",
    path: "/reports",
    icon: <StatsIcon color='inherit' />,
    authIcon: <StatsIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Report",
        path: "/reports/default",
        component: ReportDefault,
        layout: "/administrator",
      }
    ],
  },
  {
    name: "Clients",
    path: "/client",
    icon: <PersonIcon color='inherit' />,
    authIcon: <PersonIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "All Clients",
        path: "/client/lists",
        component: Client,
        layout: "/administrator",
      },
      {
        name: "Invitations",
        path: "/client/invitation/lists",
        component: ClientsInviations,
        layout: "/administrator",
      }
    ],
  },
  {
    name: "Publishers",
    path: "/publishers/users/lists",
    component: Publishers,
    layout: "/administrator",
    icon: <PersonIcon color='inherit' />,
    authIcon: <PersonIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/publishers/users/lists",
        component: Publishers,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/publishers/users/create",
        component: NewPublisher,
        layout: "/administrator",
      },
      {
        name: "Edit Publisher",
        path: "/publishers/users/:id/edit",
        component: EditPublisher,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Buyers",
    path: "/buyers/users/lists",
    component: Buyers,
    layout: "/administrator",
    icon: <PersonIcon color='inherit' />,
    authIcon: <PersonIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/buyers/users/lists",
        component: Buyers,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/buyers/users/create",
        component: NewBuyer,
        layout: "/administrator",
      },
      {
        name: "Edit Buyer",
        path: "/buyers/users/:id/edit",
        component: EditByer,
        layout: "/administrator",
      }
    ],
  },
  {
    name: "Campaign",
    path: "/campaign/lists",
    component: Campaigns,
    layout: "/administrator",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/campaign/lists",
        component: Campaigns,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/campaign/create",
        component: NewCampaign,
        layout: "/administrator",
      },
      {
        name: "Edit Campaign",
        path: "/campaign/:id/edit",
        component: EditCampaign,
        layout: "/administrator",
      }
    ],
  },
  {
    name: "Numbers",
    path: "/number",
    icon: <HomeIcon color='inherit' />,
    authIcon: <HomeIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Manage Numbers",
        path: "/number/default",
        component: Numbers,
        layout: "/administrator",
      },
      {
        name: "Add New",
        path: "/number/create",
        component: CreateNumber,
        layout: "/administrator",
      },
      {
        name: "Edit Number",
        path: "/number/:id/edit",
        component: EditNumber,
        layout: "/administrator",
      }
    ],
  },
  {
    name: "Account Informations",
    category: "accounts",
    items: [
      {
        name: "Profile",
        path: "/accounts",
        collapse: true,
        icon: <DocumentIcon color='inherit' />,
        items: [
          {
            name: "Manage Profile",
            path: "/accounts/profile",
            component: Settings,
            layout: "/administrator",
          },
          {
            name: "Settings",
            path: "/accounts/settings",
            component: Settings,
            layout: "/administrator",
          },
        ],
      },
      {
        name: "Integrations",
        path: "/twilio",
        collapse: true,
        icon: <DocumentIcon color='inherit' />,
        items: [
          {
            name: "Manage Twilio",
            path: "/twilio/integration",
            component: Integrations,
            layout: "/administrator",
          },
        ],
      },
      {
        name: "Billings",
        path: "/billing",
        collapse: true,
        icon: <DocumentIcon color='inherit' />,
        items: [
          {
            name: "Client Billings",
            path: "/billing/clients",
            component: Billing,
            layout: "/administrator",
          },
        ],
      },
    ],
  },
*/
];

export default dashRoutes;
