/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useRef, useState } from "react";

// Chakra imports
import {
  Box,
  Button,
  DarkMode,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Icon,
  Input,
  Link,
  Switch,
  Text,
} from "@chakra-ui/react";
import axios from "axios";
import { useHistory } from "react-router-dom";
// Assets
import signUpImage from "assets/img/signUpImage.png";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

// Icons
import { FaApple, FaFacebook, FaGoogle } from "react-icons/fa";
import { el } from "date-fns/locale";

function SignUpCover(props) {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [refersh, refershSet] = React.useState('');
  const [loadingEmail, setLoadingEmail] = React.useState(true);
  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";
  const [twilio, setTwilio] = useState({
    _id: "",
    name: "",
    clientId: ""
  })

  useEffect(() => {
    if (loadingEmail == true) {
      verifyInvite();
    }
  }, [refersh]);

  const verifyInvite = async () => {
    const loadToast = toast.loading('Loading...');
    let clientId = props.match.params.id;
    twilio.clientId = clientId;
    setTwilio(twilio);
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: clientId })
    };
    const response = await fetch(apiUrl + '/api/admin/client/twilio/verify', requestOptions);
    const result = await response.json();
    if (result.statusCode == 200) {
      setLoadingEmail(false);
      toast.success(result.msg);
    } else {
      toast.error(result.msg);
      history.push("/auth/authentication/sign-in/cover");
    }
    toast.dismiss(loadToast);
  }

  const SignInRedirect = e => {
    history.push("/auth/authentication/sign-in/cover");
  }

  const handleChange = e => {
    const { name, value } = e.target
    setTwilio({
      ...twilio,//spread operator 
      [name]: value
    })
  }

  const register = async () => {
    const loadToast = toast.loading('Please Wait....');
    const { name } = twilio;
    if (name == "") {
      toast.error("Business Name Field is Required!");
      toast.dismiss(loadToast);
      return false;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ twilio })
    };
    const response = await fetch(apiUrl + '/api/twilio/register/subaccount/twilio/create', requestOptions);
    const result = await response.json();
    if (result.statusCode == 200) {
      toast.success("Twilio Credentials has been Created and Waiting for Approval.");
      history.push("/auth/authentication/sign-in/cover");
    } else {
      toast.error("Twilio Credentials update failed due to error!");
    }
    toast.dismiss(loadToast);
  }
  return (
    <Flex position='relative' overflow={{ lg: "hidden" }}>
      <Flex
        flexDirection='column'
        h={{ sm: "initial", md: "unset" }}
        w={{ base: "90%" }}
        maxW='1044px'
        mx='auto'
        justifyContent='space-between'
        pt={{ sm: "100px", md: "0px" }}
        me={{ base: "auto", lg: "50px", xl: "auto" }}>
        <Flex
          className="full_width_landing"
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          flexDirection='column'
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='50px'
          w={{ base: "100%", md: "50%", lg: "42%" }}>
          <Flex
            direction='column'
            textAlign='center'
            justifyContent='center'
            align='center'
            mt={{ base: "60px", md: "140px", lg: "200px" }}
            mb='50px'>
            <Text
              fontSize='4xl'
              lineHeight='39px'
              color='white'
              fontWeight='bold'>
              Twilio Integrations
            </Text>
            <Text
              fontSize='md'
              color='white'
              fontWeight='500'
              mt='10px'
              w={{ base: "100%", md: "90%", lg: "90%", xl: "80%" }}>
              Your Account Required Twilio Details Before Approval!.
            </Text>
          </Flex>
          <GradientBorder p='2px' me={{ base: "none", lg: "30px", xl: "none" }}>
            <Flex
              background='transparent'
              borderRadius='30px'
              direction='column'
              p='40px'
              minW={{ base: "unset", md: "430px", xl: "450px" }}
              w='100%'
              mx={{ base: "0px" }}
              bg={{
                base: "rgb(19,21,56)",
              }}>
              <FormControl>
                <FormLabel
                  color={titleColor}
                  ms='4px'
                  fontSize='sm'
                  fontWeight='500'>
                  Enter Your Business Name
                </FormLabel>

                <GradientBorder
                  mb='24px'
                  h='50px'
                  w={{ base: "100%", lg: "fit-content" }}
                  borderRadius='20px'>
                  <Input
                    color={titleColor}
                    bg={{
                      base: "rgb(19,21,54)",
                    }}
                    border='transparent'
                    borderRadius='20px'
                    fontSize='sm'
                    size='lg'
                    w={{ base: "100%", md: "346px" }}
                    maxW='100%'
                    h='46px'
                    type='text'
                    placeholder='Enter Twilio Name'
                    name="name"
                    value={twilio.name}
                    onChange={handleChange}
                  />
                </GradientBorder>
                <Button
                  variant='brand'
                  fontSize='10px'
                  type='submit'
                  onClick={register}
                  w='100%'
                  h='45'
                  mb='20px'>
                  Create Twilio Credentials
                </Button>
              </FormControl>
            </Flex>
          </GradientBorder>
        </Flex>
      </Flex>
    </Flex>
  );
}

export default SignUpCover;
