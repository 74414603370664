/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
const token = localStorage?.getItem("token");
const user = JSON.parse(localStorage?.getItem("user"));

function NewUser() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [publisher, setPublisher] = useState({
    name: "",
    email: "",
    password: "",
    by: user.id
  })

  const handleChange = e => {
    const { name, value } = e.target
    setPublisher({
      ...publisher,//spread operator 
      [name]: value
    })
  }

  const createPublisher = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, email, password } = publisher
    if (name && email && password) {
      let config = {
        method: 'post',
        url: apiUrl+'/api/users',
        headers: {
          'Content-Type': 'application/json'
        },
        data: publisher
      };
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success('Group Publisher has been Created Successfully');
            history.push("/admin/publishers/users/lists");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    else {
      toast.error("You have Entered Invalid Input");
    };
  }
  return (
    <Flex
      direction='column'
      minH='100vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel>
            <Card>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Create Group Publisher
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Name
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Publisher Name'
                        fontSize='xs'
                        name="name"
                        value={publisher.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Email
                      </FormLabel>
                      <Input
                        type='email'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Publisher Email'
                        fontSize='xs'
                        name="email"
                        value={publisher.email}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <Grid
                      templateColumns={{ sm: "1fr 1fr", lg: "2fr 1fr 1fr" }}
                      gap='100px'>
                      <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
                        <FormLabel
                          fontWeight='bold'
                          color={"#fff"}
                          fontSize='xs'>
                          Password
                        </FormLabel>
                        <Input
                          type='text'
                          bg='#0F1535'
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          borderRadius='15px'
                          placeholder='Enter Publisher Password'
                          fontSize='xs'
                          name="password"
                          value={publisher.password}
                          onChange={handleChange}
                        />
                      </FormControl>
                      {/* <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
                        <FormLabel
                          fontWeight='bold'
                          color={"#fff"}
                          fontSize='xs'>
                          Sub ID
                        </FormLabel>
                        <Input
                          type='text'
                          bg='#0F1535'
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          borderRadius='15px'
                          placeholder='Enter Publisher Sub ID'
                          fontSize='xs'
                        />
                      </FormControl> */}
                      {/* <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
                        <FormLabel
                          fontWeight='bold'
                          color={"#fff"}
                          fontSize='xs'>
                          Number Creation
                        </FormLabel>
                        <Switch id="number-creation" />
                      </FormControl> */}
                    </Grid>
                  </Stack>
                  <Flex justify='space-between'>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w='100px'
                      h='35px'
                      onClick={createPublisher}
                    >
                      <Text fontSize='10px' color='#fff'>
                        Create
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default NewUser;
