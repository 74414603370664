import ContextSwitcher from './ContextSwitcher';

const Account = ({ current, userRole }) => {
  let menu = [
    {
      label: 'Profile',
      href: '/administrator/accounts/profile',
    },
    {
      label: 'Billing',
      href: '/administrator/accounts/billing',
    },
  ];
  if (userRole == 0) {
    menu = [
      ...menu,
      {
        label: 'Add Client',
        href: '/administrator/accounts/new',
      },
      {
        label: 'Pending Clients',
        href: '/administrator/accounts/management',
      }
    ];
  }

  const activeTab = (menu.findIndex(x => x.href == current) || 0) + 1;

  return (
    <ContextSwitcher
      options={menu}
      activeTab={activeTab}
    />
  );
};

export default Account;
