/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { VSeparator, HSeparator } from "components/Separator/Separator";
import packageJson from '../../../appConfig.js';
import Button1 from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function NewUser(props) {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [campaign, setCampaign] = useState({
    name: "",
    description: "",
    publishers: [],
    callrouting: ""
  });
  const [payout, setPayout] = useState({
    callLength: "",
    concurrencyCap: "",
    dailyCap: "",
    dailyPayoutCap: "",
    duplicatePayout: "disable",
    enableConCurrencyCap: false,
    enableDailyCap: false,
    enableDailyPayoutCap: false,
    enableGlobalCap: false,
    enableGlobalPayoutCap: false,
    enableHourlyCap: false,
    enableHourlyPayoutCap: false,
    enableMonthlyCap: false,
    enableMonthlyPayoutCap: false,
    enablePayoutHours: false,
    globalCap: "",
    globalPayoutCap: "",
    houlyCap: "",
    hourlyPayoutCap: "",
    limitPayoutEnabled: false,
    monthlyCap: "",
    monthlyPayoutCap: "",
    payoutAmount: "",
    payoutType: "",
    type: ""
  });
  let publisherChecked = false;
  //const [payout, setPayout] = useState([]);
  const [publishers, setPublishers] = useState([]);
  //const [campaignDetail, setCampaignDetail] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingCampaignData, setloadingCampaignData] = useState(true);
  const [loadingBuyerData, setLoadingBuyerData] = useState(true);
  const [refersh, refershSet] = React.useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [modelPhone, setModelPhone] = useState("");
  const [modelPhoneIndex, setModelPhoneIndex] = useState("");
  const [publisherFormToggle, setpublisherFormToggle] = useState("hide");
  const [targetFormToggle, setTargetFormToggle] = useState("hide");
  const [publisher, setPublisher] = useState({
    name: "",
    email: "",
    password: ""
  });
  const [buyer, setBuyer] = useState({
    name: "",
    subid: "",
    phonetype: "",
    phoneNumber: "",
    connectiontimeout: "",
    timezone: ""
  });

  const [payoutHoursEnabled, setPayoutHoursEnabled] = useState(false);
  const [limitPayoutEnabled, setLimitPayoutEnabled] = useState(false);
  const [callLengthShow, setCallLengthShow] = useState("hide");
  const [limitPayoutShow, setLimitPayoutShow] = useState("hide");
  const [enableGlobalCap, setEnableGlobalCap] = useState(false);
  const [enableGlobalPayoutCap, setEnableGlobalPayoutCap] = useState(false);
  const [enableMonthlyCap, setEnableMonthlyCap] = useState(false);
  const [enableMonthlyPayoutCap, setEnableMonthlyPayoutCap] = useState(false);
  const [enableDailyCap, setEnableDailyCap] = useState(false);
  const [enableDailyPayoutCap, setEnableDailyPayoutCap] = useState(false);
  const [enableHourlyCap, setEnableHourlyCap] = useState(false);
  const [enableHourlyPayoutCap, setEnableHourlyPayoutCap] = useState(false);
  const [enableConCurrencyCap, setEnableConCurrencyCap] = useState(false);
  const [routingPlanLists, setRoutingPlanLists] = useState([]);
  const [updateRouting, setUpdateRouting] = React.useState([]);
  const [routingPlan, setRoutingPlan] = useState({
    priority: "",
    weight: "",
    callType: 0,
    callLength: "",
    revenue: ""
  });
  const [loadingRoutingPlans, setLoadingRoutingPlans] = useState(true);

  useEffect(() => {
    if (loadingCampaignData) {
      fetchCampaign();
    }
    /*if (loadingData) {
      getPublishers();
    }
    if (loadingBuyerData) {
      getBuyers();
    }*/
  }, [refersh]);

  const handleAddPublisher = e => {
    const { name, value } = e.target
    setPublisher({
      ...publisher,//spread operator 
      [name]: value
    })
  }

  const handleAddTarget = e => {
    const { name, value } = e.target
    setBuyer({
      ...buyer,//spread operator 
      [name]: value
    })
  }

  const createPublisher = () => {
    const { name, email, password } = publisher
    if (name && email && password) {
      const loadToast = toast.loading('Please Wait....');
      let config = {
        method: 'post',
        url: apiUrl + '/api/users',
        headers: {
          'Content-Type': 'application/json'
        },
        data: publisher
      };
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success('Publisher has been Created Successfully');
            setLoadingData(true);
            setloadingCampaignData(true);
            setLoadingBuyerData(true);
            refershSet(Math.random());
            setpublisherFormToggle('hide');
            //history.push("/admin/publishers/users/lists");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
    else {
      toast.error("You have Entered Invalid Input");
    };
  }

  const createNewbuyer = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, subid, phonetype, phoneNumber, connectiontimeout, timezone } = buyers
    let config = {
      method: 'post',
      url: apiUrl + '/api/buyers',
      headers: {
        'Content-Type': 'application/json'
      },
      data: buyer
    };
    axios.request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error[0].message);
          toast.dismiss(loadToast);
        } else {
          toast.success('Buyer has been  Successfully added');
          setLoadingData(true);
          setloadingCampaignData(true);
          setLoadingBuyerData(true);
          refershSet(Math.random());
          setTargetFormToggle('hide');
          toast.dismiss(loadToast);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadToast);
      });
  }

  const fetchCampaign = async () => {
    const loadToast = toast.loading('Loading...');
    let campaignId = props.match.params.id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: campaignId, token: token })
    };
    const response = await fetch(apiUrl + '/api/campaign/publisher/fetch', requestOptions);
    const data = await response.json();
    if (data.status == "success") {
      setCampaign(data.data);
      setPayout(data.data.defaultPayout);
      updatePayoutData(data.data.defaultPayout);
      setRoutingPlanLists(data.data.routing);
      setloadingCampaignData(false);
      setPublishers(data.data.publishers);
      setLoadingData(false);
      if (loadingData) {
        //getPublishers();
      }
      if (loadingBuyerData) {
        //getBuyers();
      }
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  }

  const updatePayoutData = async (payoutData) => {
    setPayoutHoursEnabled(payoutData.payoutHoursEnabled);
    setLimitPayoutEnabled(payoutData.limitPayoutEnabled);
    setEnableGlobalCap(payoutData.enableGlobalCap);
    setEnableGlobalPayoutCap(payoutData.enableGlobalPayoutCap);
    setEnableMonthlyCap(payoutData.enableMonthlyCap);
    setEnableMonthlyPayoutCap(payoutData.enableMonthlyPayoutCap);
    setEnableDailyCap(payoutData.enableDailyCap);
    setEnableDailyPayoutCap(payoutData.enableDailyPayoutCap);
    setEnableHourlyCap(payoutData.enableHourlyCap);
    setEnableHourlyPayoutCap(payoutData.enableHourlyPayoutCap);
    setEnableConCurrencyCap(payoutData.enableConCurrencyCap);
    if (payoutData.type == 1) {
      setCallLengthShow("show");
    }
    /*if (payoutData.limitPayoutEnabled == true) {
      setLimitPayoutShow("show");
    }*/
  }

  const getBuyers = async () => {
    const loadToast = toast.loading('Loading...');
    let result = await fetch(apiUrl + "/api/buyers/list-buyers");
    result = await result.json();
    setBuyers(result);
    setLoadingBuyerData(false);
    toast.dismiss(loadToast);
  }

  function isCheckedPublisher(originalRow, rowIndex) {
    publisherChecked = false;
    campaign.publishers.map(publisher => {
      if (originalRow._id == publisher.publisherId) {
        publisherChecked = true;
      }
    })
    return <Switch id="publisher-switch" onChange={addPublishers} name="publisher" value={originalRow._id} defaultIsChecked={publisherChecked} />
  }

  function campaignStatus(originalRow, rowIndex) {
    console.log(originalRow.publisherId.status);
    let activated = "#982E2B";
    if (originalRow.publisherId.status == 1) {
      activated = "#22ca0c";
    }
    return <div><i className="fa-sharp fa-solid fa-circle" style={{ color: activated }} /></div>
  }

  const columnsData = [
    {
      Header: 'Enabled',
      accessor: (originalRow, rowIndex) => (
        <div>
          <Switch isDisabled="true" id="publisher-switch" name="publisher" value={originalRow._id} defaultIsChecked={true} />
        </div>
      ),
      id: 'publisherEnabled',
    },
    {
      Header: "Publisher",
      accessor: "publisherId.name",
    },
    {
      Header: 'Phone Numbers',
      accessor: "phone",
      /*accessor: (originalRow, rowIndex) => (
        <div>
          +918433345654 | <a href="#" onClick={e => { e.preventDefault(); editPhone(originalRow); }} title="Edit Phone"><i className="fa-solid fa-pen-to-square"></i></a> | <a href="#" onClick={e => { e.preventDefault(); deletePhone(originalRow); }} title="Delete Phone"><i className="fa-solid fa-trash"></i></a>|
        </div>
      ),*/
      id: 'phone',
    },
    {
      Header: 'Status',
      accessor: campaignStatus,
      id: 'status',
    }
  ];

  const addTarget = (event) => {
    let targetId = event.target.value;
    if (event.target.checked) {
      let targetId = event.target.value;
      campaign.target.push(targetId);
    } else {
      let targetId = event.target.value;
      var index = campaign.target.indexOf(targetId)
      if (index !== -1) {
        campaign.target.splice(index, 1);
      }
    }
    //setCampaign(campaign);
  }

  const addPublishers = (event) => {
    let publisherId = event.target.value;
    if (event.target.checked) {
      let publisherId = event.target.value;
      campaign.publishers.push({ "publisherId": publisherId, phone: '' });
    } else {
      let publisherId = event.target.value;
      var index = campaign.publishers.map((o) => o.publisherId).indexOf(publisherId);
      //var index = campaign.publishers.indexOf({"publisherId": publisherId});
      if (index !== -1) {
        campaign.publishers.splice(index, 1);
      }
    }
  }

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>
      {type}
    </div>
  }

  const handleChange = e => {
    const { name, value } = e.target
    setCampaign({
      ...campaign,//spread operator 
      [name]: value
    })
  }

  const handleRoutingChange = e => {
    const { name, value } = e.target
    setRoutingPlan({
      ...routingPlan,//spread operator 
      [name]: value
    })
  }

  const addRoutingPlan = e => {
    let targetId = updateRouting._id;
    console.log(routingPlan.priority);
    if ((routingPlan.priority == "") || (routingPlan.weight == "") || (routingPlan.revenue == "") || (routingPlan.type == "") || (routingPlan.callLength == "")) {
      toast.error("Please Fill All Required Fields");
      return false;
    }
    routingPlanLists.push({
      target: targetId,
      name: updateRouting.name,
      phone: updateRouting.phoneNumber,
      priority: routingPlan.priority,
      weight: routingPlan.weight,
      callType: routingPlan.callType,
      callLength: routingPlan.callLength,
      revenue: routingPlan.revenue
    });
    setRoutingPlan([]);
    setLoadingRoutingPlans(true);
    refershSet(Math.random())
    handleClose();
  }

  const onPayoutChange = e => {
    const { name, value } = e.target
    if ((name == "type") && (value == 1)) {
      setCallLengthShow("show");
    } else if ((name == "type") && (value != 1)) {
      setCallLengthShow("hide");
    }
    setPayout({
      ...payout,//spread operator 
      [name]: value
    })
  }

  const onPayoutHours = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setPayoutHoursEnabled(true);
      enabled = true;
    } else {
      setPayoutHoursEnabled(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }
  const onChangeLimitPayout = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setLimitPayoutEnabled(true);
      setLimitPayoutShow("show");
      enabled = true;
    } else {
      setLimitPayoutEnabled(false);
      setLimitPayoutShow("hide");
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onGlobalCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableGlobalCap(true);
      enabled = true;
    } else {
      setEnableGlobalCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onGlobalPayoutCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableGlobalPayoutCap(true);
      enabled = true;
    } else {
      setEnableGlobalPayoutCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onMonthlyCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableMonthlyCap(true);
      enabled = true;
    } else {
      setEnableMonthlyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onMonthlyPayoutCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableMonthlyPayoutCap(true);
      enabled = true;
    } else {
      setEnableMonthlyPayoutCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onDailyCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableDailyCap(true);
      enabled = true;
    } else {
      setEnableDailyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onDailyPayoutCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableDailyPayoutCap(true);
      enabled = true;
    } else {
      setEnableDailyPayoutCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onHourlyCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableHourlyCap(true);
      enabled = true;
    } else {
      setEnableHourlyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onHourlyPayoutCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableHourlyPayoutCap(true);
      enabled = true;
    } else {
      setEnableHourlyPayoutCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }

  const onConCurrencyCapChange = e => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableConCurrencyCap(true);
      enabled = true;
    } else {
      setEnableConCurrencyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout,//spread operator 
      [name]: enabled
    })
  }
  return (
    <Flex
      className="custom_form_style"
      direction='column'
      minH='100vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel>
            <Card>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  View Campaign
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Name
                      </FormLabel>
                      <Input
                        type='textarea'
                        isReadOnly='true'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Publisher Name'
                        fontSize='xs'
                        name="name"
                        value={campaign.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Description
                      </FormLabel>
                      <textarea className="form-control chakra-input css-1c21k4t" id="exampleFormControlTextarea1" rows={3} style={{ display: 'block', fontSize: '0.75rem', background: '#0000', color: '#fff', lineHeight: '1.5rem', minHeight: '100px' }} name="description" value={campaign.description} onChange={handleChange} readOnly={true} />
                    </FormControl>
                    <VSeparator className="verticleSeparator" />
                    <Text align="center" color={"#fff"} fontSize='xl' fontWeight='bold'>
                    DEFAULT CAMPAIGN PAYOUT SETTINGS
                    </Text>
                    {loadingData ? (
                      <p>Loading Please wait...</p>
                    ) : (
                      <FormControl className='payOut'>
                        <Flex>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Payout Type
                          </FormLabel>
                          <Flex style={{ margin: '0px' }}>
                            <input disabled type="radio" name="payoutType" value="1" onChange={onPayoutChange} checked={payout.payoutType == 1 ? true : false} />
                            &nbsp; <label htmlFor="fixedAmount">Fixed Amount</label><br />
                            &nbsp; <input disabled type="radio" name="payoutType" value="2" onChange={onPayoutChange} checked={payout.payoutType == 2 ? true : false} />
                            &nbsp; <label htmlFor="Percentage">Revshare Percentage</label><br />
                          </Flex>
                        </Flex>
                        <Flex>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Payout On
                          </FormLabel>
                          <Select
                            isDisabled="true"
                            onChange={onPayoutChange}
                            name="type"
                            border='.5px solid'
                            borderColor='rgba(226, 232, 240, 0.3)'
                            _hover='none'
                            color='#fff'
                            bg='#0F1535'
                            borderRadius='15px'
                            color='gray.400'
                            fontSize='sm'
                            fontSize='xs'>
                            <option value=''>Choose Type</option>
                            <option value='1' selected={payout.type == 1 ? true : false}>Call Length</option>
                          </Select>
                        </Flex>
                        <Flex className={callLengthShow}>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Call Length
                          </FormLabel>
                          <div className="inputPrice">
                            <Input isReadOnly='true' type="number" className="payOutAmount" name="callLength" value={payout.callLength} onChange={onPayoutChange} />
                          </div>
                        </Flex>
                        <Flex>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Payout Amount
                          </FormLabel>
                          <div className="inputPrice">
                            <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="$" name="payoutAmount" onChange={onPayoutChange} value={payout.payoutAmount} />
                          </div>
                        </Flex>
                        <Flex>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Duplicate Payout
                          </FormLabel>
                          <Flex className="duplicatePayouts" style={{ margin: '0px' }}>
                            <Flex>
                              <input disabled type="radio" name="duplicatePayout" value="disable" onChange={onPayoutChange} checked={payout.duplicatePayout == "disable" ? true : false} />
                              &nbsp; <label htmlFor="html">Disable</label>
                              &nbsp; <input disabled type="radio" name="duplicatePayout" value="enable" onChange={onPayoutChange} checked={payout.duplicatePayout == "enable" ? true : false} />
                              &nbsp; <label htmlFor="css">Enable</label>
                              &nbsp; <input disabled type="radio" name="duplicatePayout" value="timelimit" onChange={onPayoutChange} checked={payout.duplicatePayout == "timelimit" ? true : false} />
                              &nbsp; <label htmlFor="javascript">Time Limit </label>
                            </Flex>
                          </Flex>
                        </Flex>
                        <Flex display="flex" alignItems="center">
                          <FormLabel htmlFor="email-alerts" mb="0">
                          Campaign Hours
                          </FormLabel>
                          <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onPayoutHours} name="enablePayoutHours" value={false} defaultIsChecked={payoutHoursEnabled} />
                        </Flex>
                        <Flex display="flex" alignItems="center">
                          <FormLabel htmlFor="email-alerts" mb="0">
                          Enable Campaign Caps
                          </FormLabel>
                          <Switch isDisabled="true" style={{ flexBasis: '100%' }} name="limitPayoutEnabled" value={limitPayoutEnabled} defaultIsChecked={false} onChange={onChangeLimitPayout} />
                        </Flex>
                        <div className={limitPayoutShow}>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Global Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onGlobalCapChange} name="enableGlobalCap" value={enableGlobalCap} defaultIsChecked={enableGlobalCap} />
                            </Flex>
                            <div>
                              <Input isDisabled="true" type="number" className="payOutAmount" placeholder="" name="globalCap" onChange={onPayoutChange} value={payout.globalCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Global Payout Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onGlobalPayoutCapChange} name="enableGlobalPayoutCap" value={enableGlobalPayoutCap} defaultIsChecked={enableGlobalPayoutCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="globalPayoutCap" onChange={onPayoutChange} value={payout.globalPayoutCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Monthly Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onMonthlyCapChange} name="enableMonthlyCap" value={enableMonthlyCap} defaultIsChecked={enableMonthlyCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="monthlyCap" onChange={onPayoutChange} value={payout.monthlyCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Monthly Payout Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onMonthlyPayoutCapChange} name="enableMonthlyPayoutCap" value={enableMonthlyPayoutCap} defaultIsChecked={enableMonthlyPayoutCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="monthlyPayoutCap" onChange={onPayoutChange} value={payout.monthlyPayoutCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Daily Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onDailyCapChange} name="enableDailyCap" value={enableDailyCap} defaultIsChecked={enableDailyCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="dailyCap" onChange={onPayoutChange} value={payout.dailyCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Daily Payout Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onDailyPayoutCapChange} name="enableDailyPayoutCap" value={enableDailyPayoutCap} defaultIsChecked={enableDailyPayoutCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="dailyPayoutCap" onChange={onPayoutChange} value={payout.dailyPayoutCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Hourly Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onHourlyCapChange} name="enableHourlyCap" value={enableHourlyCap} defaultIsChecked={enableHourlyCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="houlyCap" onChange={onPayoutChange} value={payout.houlyCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Hourly Payout Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onHourlyPayoutCapChange} name="enableHourlyPayoutCap" value={enableHourlyPayoutCap} defaultIsChecked={enableHourlyPayoutCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="hourlyPayoutCap" onChange={onPayoutChange} value={payout.hourlyPayoutCap} />
                            </div>
                          </Flex>
                          <Flex className="commomSwitch">
                            <Flex>
                              <FormLabel htmlFor="email-alerts" mb="0">
                                Concurrency Cap
                              </FormLabel>
                              <Switch isDisabled="true" style={{ flexBasis: '100%' }} onChange={onConCurrencyCapChange} name="enableConCurrencyCap" value={enableConCurrencyCap} defaultIsChecked={enableConCurrencyCap} />
                            </Flex>
                            <div>
                              <Input isReadOnly='true' type="number" className="payOutAmount" placeholder="" name="concurrencyCap" onChange={onPayoutChange} value={payout.concurrencyCap} />
                            </div>
                          </Flex>
                        </div>
                      </FormControl>
                    )}

                    <VSeparator className="verticleSeparator" />
                    <Text align="center" color={"#fff"} fontSize='xl' fontWeight='bold'>
                    Publisher's Settings
                    </Text>

                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Enable Publishers For This Campaign
                      </FormLabel>
                      {loadingData ? (
                        <p>Loading Please wait...</p>
                      ) : (
                        <SearchTable1 tableData={publishers} columnsData={columnsData} />
                      )}
                    </FormControl>
                    <VSeparator className="verticleSeparator" />
                    <Text align="center" color={"#fff"} fontSize='xl' fontWeight='bold'>
                      Routing Settings
                    </Text>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Call Routing
                      </FormLabel>
                      <Select
                        onChange={handleChange}
                        isDisabled="true"
                        name="callrouting"
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        bg='#0F1535'
                        borderRadius='15px'
                        color='gray.400'
                        fontSize='sm'
                        fontSize='xs'>
                        <option value=''>Choose Routing Option</option>
                        <option value='1' selected={campaign.callrouting == 1 ? true : false}>Priority(Standard)</option>
                        <option value='2' selected={campaign.callrouting == 2 ? true : false}>Call Flow</option>
                      </Select>
                    </FormControl>
                    <FormControl>
                      <Flex className="routingTargetView">

                        <FormControl>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Routing Plan
                          </FormLabel>
                          <Card px='0px' style={{ marginTop: '10px' }}>
                            <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                              <Table className="targetTable" variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Priority
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Weight
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Name
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Destination
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Type
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Revenue
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Status
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {routingPlanLists.length > 0 ? routingPlanLists.map((item, index) =>
                                    <Tr>
                                      <Td borderColor='#56577A'>
                                        <Stack direction='row' spacing='16px'>
                                          <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                            <Input type="number" placeholder="1" value={item.priority} />
                                          </Text>
                                        </Stack>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          <Input type="number" placeholder="1" value={item.weight} />
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          {item.name}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          {item.phone}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          {item.callType == "1" ? "Call Length" : "Dial"}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          ${item.revenue}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                      <i className='fa-sharp fa-solid fa-circle' style={{ color: '#22ca0c', fontSize: '12px' }} />
                                      </Td>
                                    </Tr>
                                  )
                                    : <Tr>
                                      <Td borderColor='#56577A' colSpan={8}>
                                        <Stack direction='row' spacing='16px'>
                                          <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                            There are No Routing Plans Added..
                                          </Text>
                                        </Stack>
                                      </Td>
                                    </Tr>
                                  }
                                </Tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </FormControl>
                      </Flex>
                    </FormControl>
                  </Stack>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal show={show} onHide={handleClose} className="routingPlanModel">
        <Modal.Header closeButton>
          <Modal.Title>Add Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="priority">Enter Priority</label>
            <input type="number" className="form-control" name="priority" value={routingPlan.priority} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="weight">Enter Weight</label>
            <input type="number" className="form-control" name="weight" value={routingPlan.weight} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Type</label>
            <select className="form-select" aria-label="Default select example" name="callType" onChange={handleRoutingChange}>
              <option value="0">Choose Option</option>
              <option value="1">Call Length</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Call Length(Sec)</label>
            <input type="number" className="form-control" name="callLength" placeholder="30" value={routingPlan.callLength} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Revenue($)</label>
            <input type="number" className="form-control" name="revenue" placeholder="30" value={routingPlan.revenue} onChange={handleRoutingChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button1 variant="secondary" onClick={handleClose}>
            Close
          </Button1>
          <Button1 variant="primary" onClick={addRoutingPlan}>
            Create
          </Button1>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
}

export default NewUser;
