/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
  Tag,
  TagLabel,
  TagCloseButton,
  Box,
} from "@chakra-ui/react";
// Custom components
import axios from "axios";
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { BsCircleFill } from "react-icons/bs";
import './NewBuyer.css';
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';
import packageJson from '../../../appConfig.js';
import { QuestionIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

function NewUser(props) {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [timezoneFilter, setTimezoneFilter] = useState([]);//
  const [timezoneFilterLoading, setTimezoneFilterLoading] = useState(true);
  const [buyers, setBuyers] = useState({
    _id: "",
    name: "",
    email: "",
    // subid: "",
    phonetype: "1",
    phoneNumber: "",
    connectiontimeout: "",
    cccap: "",
    timezone: "",
    capOn: "Conversion",
  })

  const [newValue, setnewValue] = useState({
    enableGlobalCap: false,
    enableMonthlyCap: false,
    enableHourlyCap: false,
    enableDailyCap: false,
    // enableConCurrencyCap: false,
    // payoutHoursEnabled: false,
    enablePayoutHours: false,
    maxConcurreny: "",
    restrictDuplicate: "",
    GlobalCallCap: "",
    monthlyCapValue: "",
    dailyCapValue: "",
    hourlyCapValue: "",
    maxConcurrenyValue: "",
    from: {
      dayName: "",
      start_time: "",
      end_time: "",
    },
    to: {
      dayName: "",
      start_time: "",
      end_time: "",
    },
  });

  const [enableGlobalCap, setEnableGlobalCap] = useState(false);
  const [enableMonthlyCap, setEnableMonthlyCap] = useState(false);
  const [enableDailyCap, setEnableDailyCap] = useState(false);
  const [enableHourlyCap, setEnableHourlyCap] = useState(false);
  // const [enableConCurrencyCap, setEnableConCurrencyCap] = useState(false);
  const [callLengthShow, setCallLengthShow] = useState("hide");
  const [payoutHoursEnabled, setPayoutHoursEnabled] = useState(false);

  const daysOfWeek = [
    { value: "Day", label: "Day" },
    { value: "monday", label: "Monday" },
    { value: "tuesday", label: "Tuesday" },
    { value: "wednesday", label: "Wednesday" },
    { value: "thursday", label: "Thursday" },
    { value: "friday", label: "Friday" },
    { value: "saturday", label: "Saturday" },
    { value: "sunday", label: "Sunday" },
  ];

  const [startDay, setStartDay] = useState(daysOfWeek[0].value);
  const [endDay, setEndDay] = useState(daysOfWeek[0].value);
  const [startTime, setStartTime] = useState();
  const [endTime, setEndTime] = useState();

  const handleStartDayChange = (event) => {
    const selectedDay = event.target.value;
    setStartDay(selectedDay);
    setnewValue((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        dayName: selectedDay,
      },
    }));
  };

  const handleEndDayChange = (event) => {
    const selectedDay = event.target.value;
    setEndDay(selectedDay);
    setnewValue((prevValue) => ({
      ...prevValue,
      to: {
        ...prevValue.to,
        dayName: selectedDay,
      },
    }));
  };

  const handleStartTimeChange = (event) => {
    const selectedTime1 = event.target.value;
    setStartTime(selectedTime1);
    setnewValue((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        start_time: selectedTime1,
      },
      to: {
        ...prevValue.to,
        start_time: selectedTime1,
      },
    }));
  };

  const handleEndTimeChange = (event) => {
    const selectedTime = event.target.value;
    setEndTime(selectedTime);
    setnewValue((prevValue) => ({
      ...prevValue,
      from: {
        ...prevValue.from,
        end_time: selectedTime,
      },
      to: {
        ...prevValue.to,
        end_time: selectedTime,
      },
    }));
  };

  const [viewGlobalCap, SetGlobalCap] = useState("hide");
  const onGlobalCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableGlobalCap(true);
      SetGlobalCap("show");

      enabled = true;
    } else {
      setEnableGlobalCap(false);
      SetGlobalCap("hide");
      enabled = false;
    }
    setnewValue({
      ...newValue, //spread operator
      [name]: enabled,
    });
  };

  const [viewMonthCap, SetMonthCap] = useState("hide");
  const onMonthlyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableMonthlyCap(true);
      SetMonthCap("show");
      enabled = true;
    } else {
      setEnableMonthlyCap(false);
      SetMonthCap("hide");
      enabled = false;
    }
    setnewValue({
      ...newValue, //spread operator
      [name]: enabled,
    });
  };

  const [viewDailyCap, SetDailyCap] = useState("hide");
  const onDailyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableDailyCap(true);
      SetDailyCap("show");
      enabled = true;
    } else {
      setEnableDailyCap(false);
      SetDailyCap("hide");
      enabled = false;
    }
    setnewValue({
      ...newValue, //spread operator
      [name]: enabled,
    });
  };

  const [viewHourlyCap, SetHourlyCap] = useState("hide");
  const onHourlyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableHourlyCap(true);
      SetHourlyCap("show");
      enabled = true;
    } else {
      setEnableHourlyCap(false);
      SetHourlyCap("hide");
      enabled = false;
    }
    setnewValue({
      ...newValue, //spread operator
      [name]: enabled,
    });
  };

  // const [viewMaxConCurrency, SetMaxConCurrency] = useState("hide");
  // const onMaxConCurrencyCapChange = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setEnableConCurrencyCap(true);
  //     SetMaxConCurrency("show");
  //     enabled = true;
  //   } else {
  //     setEnableConCurrencyCap(false);
  //     SetMaxConCurrency("hide");
  //     enabled = false;
  //   }
  //   setnewValue({
  //     ...newValue, //spread operator
  //     [name]: enabled,
  //   });
  // };

  useEffect(() => {
    if(newValue.enablePayoutHours=== true){
      setPayoutHoursEnabled(true);
      setStartDay(newValue.from.dayName);
      setEndDay(newValue.to.dayName)
      setStartTime(newValue.from.start_time)
      setEndTime(newValue.from.end_time)
    }
    if(newValue.enableGlobalCap=== true)
    {
     setEnableGlobalCap(true);
     SetGlobalCap("show")

    }
    if(newValue.enableMonthlyCap=== true)
    {
      setEnableMonthlyCap(true);
      SetMonthCap("show");
    }
    if(newValue.enableHourlyCap=== true)
    {
      setEnableHourlyCap(true);
      SetHourlyCap("show");
      
    }
    if(newValue.enableDailyCap=== true)
    {
      setEnableDailyCap(true);
      SetDailyCap("show");
    }
    // if(newValue.enableConCurrencyCap=== true)
    // {
    //   setEnableConCurrencyCap(true);
    //   SetMaxConCurrency("show");
    // }
    
   }, [newValue])

  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setnewValue({
      ...newValue, //spread operator
      [name]: value,
    });
  };


  useEffect(() => {
    fetchBuyers();
    if (timezoneFilterLoading) {
      getTimezoneFilterData();
    }
  }, [])

  const getTimezoneFilterData = async () => {
    let result = await fetch(apiUrl + "/api/report/timezone/lists");
    result = await result.json();
    console.log(result);
    setTimezoneFilter(result.data);
    setTimezoneFilterLoading(false);
  }
  const fetchBuyers = async () => {

    let buyerId = props.match.params.id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: buyerId })
    };
    const response = await fetch(apiUrl + '/api/buyers/fetch', requestOptions);
    const data = await response.json();
    console.log(data,"data receive");
    setBuyers(data);
    setnewValue(data.defaultPayout)

  }
  const onPayoutHours = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setPayoutHoursEnabled(true);
      enabled = true;
    } else {
      setPayoutHoursEnabled(false);
      enabled = false;
    }
    setnewValue({
      ...newValue, //spread operator
      [name]: enabled,
    });
  };

  const handleChange = e => {
    const { name, value } = e.target
    setBuyers({
      ...buyers,//spread operator 
      [name]: value
    })
  }

  const [skills, setSkills] = useState([
    {
      name: "wwww",
      id: 1,
    },
    {
      name: "wwww.123",
      id: 2,
    },
    {
      name: "123",
      id: 3,
    },
  ]);

  const [toggle, setToggle] = useState(false);

  // Creates the skills in the text area
  const keyPress = (e) => {
    if (e.keyCode === 13) {
      setSkills([
        ...skills,
        {
          name: e.target.value,
          id: skills.length === 0 ? 1 : skills[skills.length - 1].id + 1,
        },
      ]);
      e.target.value = "";
    }
  };
  const updateBuyer = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, email, phonetype, phoneNumber, connectiontimeout, timezone } = buyers;
    if (name && email &&  phonetype && phoneNumber && connectiontimeout && timezone) {
      let config = {
        method: 'post',
        url: apiUrl + '/api/buyers/update',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { ...buyers, defaultPayout: newValue },
      };
      axios.request(config)
        .then((response) => {
          if (response.data.error) {
            toast.dismiss(loadToast);
            toast.error(response.data.error[0].message);
          } else {
            toast.dismiss(loadToast);
            toast.success('Buyer has been  Successfully Updated');
            history.push('/admin/buyers/users/lists');
          }
        })
        .catch((error) => {
          toast.dismiss(loadToast);
          console.log(error);
        });
    } else {
      toast.dismiss(loadToast);
      toast.error("Invalid Input Entered");
    }
  }
  return (
    <Flex
      className='buyerEditCustom'
      direction='column'
      minH='200vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "58%" }} mx='auto'>
          <TabPanel>
            <Card>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                Create Target
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Name <Tooltip
                          label="Your target name will be used to display information around the Rings Tracker protal. Short target names work best."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                           <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                      </FormLabel>
                      <Input
                        name="name"
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Buyer Name'
                        fontSize='xs'
                        value={buyers.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Email
                      </FormLabel>
                      <Input
                        name="email"
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Buyer Email'
                        fontSize='xs'
                        value={buyers.email}
                        onChange={handleChange}
                      />
                    </FormControl>
                    {/* <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Sud ID
                      </FormLabel>
                      <Input
                        value={buyers.subid}
                        name="subid"
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Sub ID'
                        fontSize='xs'
                        onChange={handleChange}
                      />
                    </FormControl> */}
                    {/* <FormControl>
                        <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                          Buyer
                        </FormLabel>
                        <Input
                          type='text'
                          bg='#0F1535'
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          borderRadius='15px'
                          placeholder='Enter Buyer Name'
                          fontSize='xs'
                        />
                      </FormControl> */}
                    {/* <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Type
                      </FormLabel>
                      <Select
                        onChange={handleChange}
                        value={buyers.phonetype}
                        name="phonetype"
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        bg='#0F1535'
                        borderRadius='15px'
                        color='gray.400'
                        fontSize='sm'
                        fontSize='xs'>
                        <option value=''>Select Phone Type</option>
                        <option selected="{buyers.phonetype == 1?'selected':''}" value='1'>Number</option>
                        <option selected="{buyers.phonetype == 2?'selected':''}" value='2'>SIP</option>
                      </Select>
                    </FormControl> */}
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Phone Number <Tooltip
                          label="Phone numbers should follow this format: +(country code) (phone number).US Example:+12223334444"
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                           <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                      </FormLabel>
                      <Input
                        onChange={handleChange}
                        value={buyers.phoneNumber}
                        name="phoneNumber"
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Phone Number'
                        fontSize='xs'
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Connection Timeout <Tooltip
                          label="If this target does not answer a call within the specified amount of seconds, the call will be re-routed. If the duration is set to 0, it will assume a default of 30 seconds."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                           <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                      </FormLabel>
                      <Input
                        onChange={handleChange}
                        value={buyers.connectiontimeout}
                        name="connectiontimeout"
                        type='number'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Connection timeout'
                        fontSize='xs'
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        CC CAP
                      </FormLabel>
                      <Input
                        onChange={handleChange}
                        value={buyers.cccap}
                        name="cccap"
                        type='number'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter CC CAP'
                        fontSize='xs'
                      />
                    </FormControl>
                    {/* <FormControl>
                        <FormLabel
                          fontWeight='bold'
                          color='#fff'
                          fontSize='xs'
                          mb='10px'>
                          Tags
                        </FormLabel>
                        <Flex
                          direction='row'
                          p='12px'
                          wrap='wrap'
                          borderRadius='15px'
                          _focus={{ borderColor: "brand.200" }}
                          minH='60px'
                          cursor='text'
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'>
                          {skills.map((skill, index) => {
                            return (
                              <Tag
                                minW='80px'
                                fontSize='xs'
                                h='25px'
                                mb='6px'
                                me='6px'
                                key={skill.id}
                                borderRadius='12px'
                                variant='solid'
                                bg='brand.200'
                                key={index}>
                                <TagLabel w='100%'>{skill.name}</TagLabel>
                                <TagCloseButton
                                  justifySelf='flex-end'
                                  onClick={() =>
                                    setSkills([
                                      ...skills.filter(
                                        (element) => element.id !== skill.id
                                      ),
                                    ])
                                  }
                                />
                              </Tag>
                            );
                          })}
                          <Input
                            onChange={handleChange}
                            value={buyers.tag}
                            name="tag"
                            borderRadius='15px'
                            border='none'
                            _focus='none'
                            p='0px'
                            onKeyDown={(e) => keyPress(e)}
                            fontSize='xs'
                          />
                        </Flex>
                      </FormControl> */}
                    {/* <FormControl>
                        <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                          Disable Recordings  <Switch id="disable-recordings" style={{ "margin-left": "63px" }} />
                        </FormLabel>
                      </FormControl> */}
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Timezone <Tooltip
                          label="Select the Time Zone that this target operates in. All caps operate based on this time zone."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                           <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                      </FormLabel>
                      {timezoneFilterLoading ? (
                        <p>Loading Please wait...</p>
                      ) : (
                        <Select
                          onChange={handleChange}
                          value={buyers.timezone}
                          name="timezone"
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          bg='#0F1535'
                          borderRadius='15px'
                          color='gray.400'
                          fontSize='xs'>
                         <option value=''>Select Time Zone</option>
                          {timezoneFilter.length > 0 ? timezoneFilter.map((item, index) =>
                            <>
                              <option value={item.value}>{item.text}</option>
                            </>
                          )
                            : <>
                            </>
                          }
                        </Select>
                      )}
                    </FormControl>
                    <FormControl>
                      <Flex
                        className="payoutHours"
                        display="flex"
                        alignItems="center"
                      >
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Hours Of Operation <Tooltip
                          label=" Set your target's hours of operation. For weekly schedule use advanced settings."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                          maxWidth="150px"
                        >
                           <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                        </FormLabel>
                        <div>
                          <Switch
                            style={{ flexBasis: "100%" }}
                            onChange={onPayoutHours}
                            name="enablePayoutHours"                       
                            isChecked={payoutHoursEnabled}
                          />
                          <div className="openClose hide">
                            <table>
                              <tr>
                                <th></th>
                                <th>Open</th>
                                <th>Close</th>
                                {/* <th>Breaks</th> */}
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <div>
                                    <select
                                      id="start-day"
                                      value={startDay}
                                      onChange={handleStartDayChange}
                                      style={{
                                        background: "#161c4b",
                                        color: "white",
                                        WebkitAppearance: "none",
                                        appearance: "none",
                                        width:"77px",
                                        textAlign: "center"
                                      }}
                                    >
                                      {daysOfWeek.map((day,index) => (
                                        <option
                                          key={day.value}
                                          value={day.value}
                                          disabled={index === 0}
                                        >
                                          {day.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                      <span style={{marginLeft:".5rem",marginRight:".9rem",background:"none"}}>to</span>
                                  <div>
                                    <select
                                      id="end-day"
                                      value={endDay}
                                      onChange={handleEndDayChange}
                                      style={{
                                        background: "#161c4b",
                                        color: "white",
                                        WebkitAppearance: "none",
                                        appearance: "none",
                                      }}
                                    >
                                      {daysOfWeek.map((day,index) => (
                                        <option
                                          key={day.value}
                                          value={day.value}
                                          disabled={index === 0}
                                        >
                                          {day.label}
                                        </option>
                                      ))}
                                    </select>
                                  </div>
                                </td>
                                <td>
                                  <input
                                    type="time"
                                    id="intialTime"
                                    value={startTime}
                                    onChange={handleStartTimeChange}
                                  />{" "}
                                </td>
                                <td>
                                  <input
                                    type="time"
                                    id="endTime"
                                    value={endTime}
                                    onChange={handleEndTimeChange}
                                  />{" "}
                                </td>
                                {/* <td>
                                  <button>Add New Break</button>
                                </td> */}
                              </tr>
                            </table>
                          </div>
                        </div>
                      </Flex>
                    </FormControl>
                    <Box>
                      <Flex
                        h="1px"
                        w="100%"
                        bg="linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 47.22%, rgba(224, 225, 226, 0.15625) 94.44%)"
                        mb="20px"
                      ></Flex>
                      <Text
                        align="left"
                        mb="20px"
                        fontWeight="bold"
                        color={"#fff"}
                        fontSize="ls"
                      >
                        Cap Settings
                      </Text>
                    </Box>
                    {/* <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Cap On
                      </FormLabel>
                      <Select
                        // onChange={handleChange}
                        // value={buyers.capOn}
                        name="capOn"
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        color="gray.400"
                        fontSize="xs"
                      >
                        <option value="Conversion">Conversion</option>
                        <option value="Conversion">Conversion</option>
                      </Select>
                    </FormControl> */}
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Global Call Cap <Tooltip
                          label="Calls will route to this target until the global call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                           
                        >
                          <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "46px" }}
                          onChange={onGlobalCapChange}
                          name="enableGlobalCap"
                          value={enableGlobalCap}
                          isChecked={enableGlobalCap}
                        />
                        
                        {viewGlobalCap === "show" ? (
                          <Input
                            onChange={handleChange1}
                            value={newValue.GlobalCallCap}
                            name="GlobalCallCap"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Global Call Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Monthly Cap<Tooltip
                          label="Calls will route to this target until the monthly call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                           
                        >
                          <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "58px" }}
                          onChange={onMonthlyCapChange}
                          name="enableMonthlyCap"
                          value={enableMonthlyCap}
                          isChecked={enableMonthlyCap}
                        />
                        {viewMonthCap === "show" ? (
                          <Input
                            onChange={handleChange1}
                            value={newValue.monthlyCapValue}
                            name="monthlyCapValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder=" Monthly Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Daily Cap<Tooltip
                          label="Calls will route to this target until the daily call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7} 
                        >
                          <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "78px" }}
                          onChange={onDailyCapChange}
                          name="enableDailyCap"
                          value={enableDailyCap}
                          isChecked={enableDailyCap}
                        />
                        {viewDailyCap === "show" ? (
                          <Input
                            onChange={handleChange1}
                            value={newValue.dailyCapValue}
                            name="dailyCapValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Daily Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Hourly Cap<Tooltip
                          label="Calls will route to this target until the hourly call cap is reached."
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                           
                        >
                          <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                        <Switch
                          id="global-call-cap"
                          style={{ "margin-left": "68px" }}
                          onChange={onHourlyCapChange}
                          name="enableHourlyCap"
                          value={enableHourlyCap}
                          isChecked={enableHourlyCap}
                        />
                        {viewHourlyCap === "show" ? (
                          <Input
                            onChange={handleChange1}
                            value={newValue.hourlyCapValue}
                            name="hourlyCapValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Hourly Cap"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl>
                    {/* <Box>
                      <Flex
                        h="1px"
                        w="100%"
                        bg="linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 47.22%, rgba(224, 225, 226, 0.15625) 94.44%)"
                        mb="20px"
                      ></Flex>
                      <Text
                        align="left"
                        mb="20px"
                        fontWeight="bold"
                        color={"#fff"}
                        fontSize="ls"
                      >
                        Concurrency Settings
                      </Text>
                    </Box> */}
                    {/* <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Max Concurreny<Tooltip
                          label="Number of Concurrent call user can receive at a time"
                          placement="right"
                          hasArrow
                          fontSize="11px"
                          color="black"
                          bg="white"
                          arrowSize={7}
                           
                        >
                          <QuestionIcon marginLeft="5px"/>
                        </Tooltip>
                        <Switch
                          id="enableConCurrencyCap"
                          style={{ "margin-left": "92px" }}
                          onChange={onMaxConCurrencyCapChange}
                          name="enableConCurrencyCap"
                          value={enableConCurrencyCap}
                          isChecked={enableConCurrencyCap}
                        />
                        {viewMaxConCurrency === "show" ? (
                          <Input
                            onChange={handleChange1}
                            value={newValue.maxConcurrenyValue}
                            name="maxConcurrenyValue"
                            type="number"
                            bg="#0F1535"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            borderRadius="15px"
                            placeholder="Enter Max Concurreny"
                            fontSize="xs"
                            width="50%"
                            ml="3%"
                          />
                        ) : null}
                      </FormLabel>
                    </FormControl> */}
                    <Box>
                      <Flex
                        h="1px"
                        w="100%"
                        bg="linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 47.22%, rgba(224, 225, 226, 0.15625) 94.44%)"
                        mb="20px"
                      ></Flex>
                      <Text
                        align="left"
                        mb="20px"
                        fontWeight="bold"
                        color={"#fff"}
                        fontSize="ls"
                      >
                        Restrict Duplicate Call Settings
                      </Text>
                    </Box>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Restrict Duplicate
                      </FormLabel>
                      <Select
                        onChange={handleChange1}
                        value={newValue.restrictDuplicate}
                        name="restrictDuplicate"
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        color="gray.400"
                        fontSize="xs"
                      >
                        <option value="option1">
                          Buyer Settings(Do Not Restrict)
                        </option>
                        <option value="option1">
                          Buyer Settings(Do Not Restrict)
                        </option>
                      </Select>
                    </FormControl>
                    <Box>
                      <Text
                        align="left"
                        mb="20px"
                        fontWeight="bold"
                        color={"#fff"}
                        fontSize="ls"
                      >
                        Tag Routing Filters
                      </Text>
                      <Flex
                        h="1px"
                        w="100%"
                        bg="linear-gradient(90deg, rgba(224, 225, 226, 0) 0%, #E0E1E2 47.22%, rgba(224, 225, 226, 0.15625) 94.44%)"
                        mb="20px"
                      ></Flex>
                      <Button
                        variant="brand"
                        alignSelf="flex-end"
                        mt="24px"
                        w="100px"
                        h="35px"
                      >
                        <Text fontSize="10px" color="#fff">
                          ADD Filter
                        </Text>
                      </Button>
                    </Box>

                  </Stack>
                  <Flex justify='space-between' mx='auto' >
                    <Button onClick={updateBuyer}
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w='100px'
                      h='35px'
                    >
                      <Text fontSize='10px' color='#fff'>
                        Update
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>

    </Flex>

  );
}

export default NewUser;
