/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState, useMemo } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Grid,
  Icon,
  Progress,
  SimpleGrid,
  Spacer,
  Stack,
  Stat,
  StatHelpText,
  StatLabel,
  StatNumber,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
  Button,
  useColorModeValue,
  Input,
} from "@chakra-ui/react";

// Custom components//
import Card from "components/Card/Card.js";
import CardBody from "components/Card/CardBody.js";
import CardHeader from "components/Card/CardHeader.js";
import BarChart from "components/Charts/BarChart";
//import Globe from "components/Globe/Globe";
import IconBox from "components/Icons/IconBox";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import packageJson from "../../../appConfig.js";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import {
  TiArrowSortedDown,
  TiArrowSortedUp,
  TiArrowUnsorted,
} from "react-icons/ti";
import ExportToExcelButton from "components/ExportExcel/ExportToExcelButton";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";

export default function Default() {
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [campaign, setCampaign] = useState([]);
  const [callDetails, setCallDetails] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState("");
  const [barChartDataCharts1, setBarChartDataCharts1] = useState();
  const [barChartOptionsCharts1, setBarChartOptionsCharts1] = useState();
  useEffect(() => {
    if (loadingData) {
      getChartDetails();
    }
    getCampaignDetails();
    getCallDetails();
  }, [refersh]);

  const getChartDetails = async () => {
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userDetails.id, token: token }),
    };
    let result = await fetch(
      apiUrl + "/api/report/v3/call/timeline",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      const barChartColumn = {
        chart: {
          toolbar: {
            show: false,
          },
        },
        tooltip: {
          style: {
            fontSize: "12px",
            fontFamily: undefined,
          },
          onDatasetHover: {
            style: {
              fontSize: "12px",
              fontFamily: undefined,
            },
          },
          theme: "dark",
        },
        xaxis: {
          categories: result.column,
          show: true,
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "10px",
            },
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
        },
        yaxis: {
          show: true,
          color: "black",
          labels: {
            show: true,
            style: {
              colors: "#A0AEC0",
              fontSize: "10px",
            },
          },
        },
        grid: {
          strokeDashArray: 5,
          borderColor: "#56577A",
          yaxis: {
            lines: {
              show: true,
            },
          },
          xaxis: {
            lines: {
              show: false,
            },
          },
        },
        fill: {
          type: "solid",
          colors: ["#582CFF"],
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          bar: {
            borderRadius: 8,
            columnWidth: "40px",
          },
        },
      };

      let chartDetails = [
        {
          name: "Timeline",
          data: result.calls,
        },
      ];
      setBarChartOptionsCharts1(barChartColumn);
      setBarChartDataCharts1(chartDetails);
      setLoadingData(false);
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  const getCampaignDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userDetails.id, token: token }),
    };
    let result = await fetch(
      apiUrl + "/api/report/v3/campaign/call/details",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      setCampaign(result.data);
    } else {
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  const getCallDetails = async () => {
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: userDetails.id, token: token }),
    };
    let result = await fetch(
      apiUrl + "/api/report/v3/call/summary",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      setCallDetails(result.data);
    } else {
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  };

  // campaingn table
  const totalRowCampaign = {
    campaign: "Total",
    incoming: 0,
    live: 0,
    connected: 0,
    paid: 0,
    converted: 0,
    noConnect: 0,
    duplicate: 0,
    blocked: 0,
    // rpc: 0,
    // revenue: 0,
    // payout: 0,
    // profit: 0,
    // totalCost: 0,
  };

  campaign.forEach((item) => {
    totalRowCampaign.incoming += item.incoming;
    totalRowCampaign.live += item.live;
    totalRowCampaign.connected += item.connected;
    totalRowCampaign.paid += item.paid;
    totalRowCampaign.converted += item.converted;
    totalRowCampaign.noConnect += item.noConnect;
    totalRowCampaign.duplicate += item.duplicate;
    totalRowCampaign.blocked += item.blocked;
    // totalRowCampaign.rpc += item.rpc;
    // totalRowCampaign.revenue += item.revenue;
    // totalRowCampaign.payout += item.payout;
    // totalRowCampaign.profit += item.profit;
    // totalRowCampaign.totalCost += item.totalCost;
  });

  // Calculate the total profit as a percentage
  totalRowCampaign.profit = (totalRowCampaign.profit / campaign.length).toFixed(
    2
  );


  campaign.push(totalRowCampaign);

  const campaign_column = [
    {
      Header: "CAMPAIGN",
      accessor: "campaign",
    },
    {
      Header: "INCOMING",
      accessor: "incoming",
    },
    {
      Header: "LIVE",
      accessor: "live",
    },
    {
      Header: "CONNECTED",
      accessor: "connected",
    },
    {
      Header: "PAID",
      accessor: "paid",
    },
    {
      Header: "CONVERTED",
      accessor: "converted",
    },
    {
      Header: "NO CONNECT",
      accessor: "noConnect",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "BLOCKED",
      accessor: "blocked",
    },
    // {
    //   Header: "RPC",
    //   accessor: "rpc",
    // },
    // {
    //   Header: "REVENUE",
    //   accessor: "revenue",
    // },
    // {
    //   Header: "PAYOUT",
    //   accessor: "payout",
    // },
    // {
    //   Header: "PROFIT",
    //   accessor: "profit",
    // },
    // {
    //   Header: "TOTALCOST",
    //   accessor: "totalCost",
    // },
  ];
  const campaingnHead = useMemo(() => campaign_column, []);
  const data3 = useMemo(() => campaign, [campaign]);

  const campaingnTable = useTable(
    {
      columns: campaingnHead,
      data: data3,
    },
    useSortBy
  );
  const {
    getTableProps: getTableProps3,
    getTableBodyProps: getTableBodyProps3,
    headerGroups: headerGroups3,
    rows: row3,
    prepareRow: prepareRow3,
  } = campaingnTable;

  // callDetails table

  const callDetails_column = [
    {
      Header: "CALL DATES",
      accessor: "callDate",
      Cell: ({ value }) => {
        const date = new Date(value);
      const month = date.toLocaleString("en-US", { month: "short" });
      const day = date.toLocaleString("en-US", { day: "2-digit" });
      const time = date.toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        second: "numeric",
        hour12: true,
      });
      return <span>{`${month} ${day}  ${time}`}</span>; }
    },
    {
      Header: "CAMPAIGN",
      accessor: "campaign",
    },
    {
      Header: "PUBLISHER",
      accessor: "publisher",
    },
    {
      Header: "CALLER ID",
      accessor: "callerId",
    },
    {
      Header: "DIALED #",
      accessor: "dialed",
    },
    {
      Header: "Target No.",
      accessor: "targetNo",
    },
    {
      Header: "DUPLICATE",
      accessor: "duplicate",
    },
    {
      Header: "TARGET",
      accessor: "target",
    },

    {
      Header: "DURATION",
      accessor: "callDurations",
    },

  ];

  const callDetailsHead = useMemo(() => callDetails_column, []);
  const callDetailsData = useMemo(() => callDetails, [callDetails]);

  const callDetailsTable = useTable(
    {
      columns: callDetailsHead,
      data: callDetailsData,
      initialState: { pageIndex: 0, pageSize: 50 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination
  );
  const {
    getTableProps: getTablePropsCallDetails,
    getTableBodyProps: getTableBodyPropsCallDetails,
    headerGroups: headerGroupsCallDetails,
    rows: rowCallDetails,
    prepareRow: prepareRowCallDetails,
    page,
    gotoPage,
    pageCount,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    setPageSize,
    setGlobalFilter,
    state: { pageIndex, pageSize, globalFilter },
  } = callDetailsTable;

  const createPages = (count) => {
    let arrPageCount = [];

    for (let i = 1; i <= count; i++) {
      arrPageCount.push(i);
    }

    return arrPageCount;
  };

    //date format in call log
// const formatDate = (value) => {
//   const date = new Date(value);
//   const month = date.toLocaleString("en-US", { month: "short" });
//   const day = date.toLocaleString("en-US", { day: "2-digit" });
//   const time = date.toLocaleString("en-US", {
//     hour: "numeric",
//     minute: "numeric",
//     second: "numeric",
//     hour12: true,
//   });
//   return `${month} ${day} ${time}`;
// };
  // Chakra Color Mode
  return (
    <Flex flexDirection="column" pt={{ base: "120px", md: "75px" }}>
      <Text color="#fff" fontWeight="bold" fontSize="3xl" mb="30px" ps="20px">
        All Reports & Charts
      </Text>
      <Card px="0px" pb="0px">
        <CardHeader mb="34px" px="22px">
          <Text color="#fff" fontSize="lg" fontWeight="bold">
            Timeline
          </Text>
        </CardHeader>
        <CardBody h="100%">
          <Box w="100%" h="100%">
            {loadingData ? (
              <p>Loading Please wait...</p>
            ) : (
              <BarChart
                chartData={barChartDataCharts1}
                chartOptions={barChartOptionsCharts1}
              />
            )}
          </Box>
        </CardBody>
      </Card>

      <Card p="28px 0px 0px 0px" style={{ marginTop: "20px" }}>
        <CardHeader mb="20px" ps="22px">
          <Flex
            direction="column"
            alignSelf="flex-start"
            style={{ margin: "0 auto" }}
          >
            <Text fontSize="2xl" color="#fff" fontWeight="bold" mb="6px">
              Reports Summary 
            </Text>
          </Flex>
        </CardHeader>
      </Card>

      <div className="tabs_new">
        <div className="w3-bar w3-black">
          <button data-tab="tab-1" className="w3-bar-item w3-button active">
            Campaign
          </button>
        </div>
        <div className="wapr_tab-black">
          <div id="tab-1" className="w3-container city">
            {/* Campaign Summary Reports*/}
            <Card px="0px">
              <CardHeader
                px="22px"
                mb="32px"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Text color="#fff" fontSize="lg" fontWeight="bold">
                  Campaign
                </Text>
                <div>
                  <ExportToExcelButton
                    columns={campaign_column}
                    data={row3}
                    fileName="table_data"
                    sheetName="Sheet 1"
                  />
                </div>
              </CardHeader>
              <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                <Table
                  {...getTableProps3()}
                  className="report_custom_table"
                  variant="simple"
                >
                  <Thead>
                    {headerGroups3?.map((headerGroup, index) => (
                      <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                        {headerGroup?.headers?.map((column, index) => (
                          <Th
                            // className="publisherName"
                            color="gray.400"
                            fontSize="10px"
                            fontFamily="Inter"
                            borderColor="#56577A"
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                            key={index}
                          >
                            <Flex
                              justify="space-between"
                              align="center"
                              fontSize={{ sm: "10px", lg: "12px" }}
                              color="gray.400"
                            >
                              {column.render("Header")}
                              <Icon
                                w={{ sm: "10px", md: "14px" }}
                                h={{ sm: "10px", md: "14px" }}
                                color={
                                  campaingnHead.isSorted
                                    ? "gray.500"
                                    : "gray.400"
                                }
                                float="right"
                                as={
                                  column.isSorted
                                    ? column.isSortedDesc
                                      ? TiArrowSortedDown
                                      : TiArrowSortedUp
                                    : TiArrowUnsorted
                                }
                              />
                            </Flex>
                          </Th>
                        ))}
                      </Tr>
                    ))}
                  </Thead>
                  <Tbody {...getTableBodyProps3()}>
                    {campaign.length > 0 ? (
                      row3?.map((row, index) => {
                        prepareRow3(row);
                        return (
                          <Tr {...row.getRowProps()} key={index}>
                            {row?.cells?.map((cell, index) => {
                              const header = headerGroups3[0].headers[index];
                              return (
                                <Td
                                  minW="150px"
                                  borderColor="#56577A"
                                  {...cell.getCellProps()}
                                  key={index}
                                >
                                  <Stack direction="row" spacing="2px">
                                    <Text
                                      className="publisherName"
                                      color="#fff"
                                      fontSize="10px"
                                      fontWeight="bold"
                                      style={{ paddingLeft: "5px !important" }}
                                    >
                                      {cell.render("Cell")}
                                    </Text>
                                  </Stack>
                                </Td>
                              );
                            })}
                          </Tr>
                        );
                      })
                    ) : (
                      <Tr>
                        <Td borderColor="#56577A" colSpan={17}>
                          <Stack direction="row" spacing="16px">
                            <Text color="#fff" fontSize="sm" fontWeight="bold">
                              There are No Campaign Reports Available.
                            </Text>
                          </Stack>
                        </Td>
                      </Tr>
                    )}
                  </Tbody>
                </Table>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>

      {/* Call Details*/}
      <Card p="28px 0px 0px 0px" style={{ marginTop: "20px" }}>
        <CardHeader mb="20px" ps="22px">
          <Flex
            direction="column"
            alignSelf="flex-start"
            style={{ margin: "0 auto" }}
          >
            <Text fontSize="xl" color="#fff" fontWeight="bold" mb="6px">
            CALL LOGS
            </Text>
          </Flex>
        </CardHeader>
      </Card>

      <Card px="0px" style={{ marginTop: "10px" }}>
        <CardHeader
          px="22px"
          mb="32px"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          <Text color="#fff" fontSize="lg" fontWeight="bold">
            Call Details
          </Text>
          {/* search and export to excel */}
          <div>
            <Input
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              type="text"
              placeholder="Search..."
              minW="75px"
              maxW="175px"
              fontSize="sm"
              style={{ marginRight: ".8rem" }}
              _focus={{ borderColor: "teal.300" }}
              onChange={(e) => setGlobalFilter(e.target.value)}
            />
            <ExportToExcelButton
              columns={callDetails_column}
              data={rowCallDetails}
              fileName="table_data"
              sheetName="Sheet 1"
            />
          </div>
        </CardHeader>
        {/* <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
          <Table className="report_custom_table" variant="simple">
            <Thead>
              <Tr>
                <Th
                  className="publisherName"
                  minW="50px"
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Call Dates
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Campaign
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Publisher
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Caller ID
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Dialed #
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Target No.
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Duplicate
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Target
                </Th>
                <Th
                  color="gray.400"
                  fontSize="10px"
                  fontFamily="Inter"
                  borderColor="#56577A"
                >
                  Duration
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {callDetails.length > 0 ? (
                callDetails?.map((item, index) => (
                  <Tr>
                    <Td minW="50px" borderColor="#56577A">
                      <Stack direction="row" spacing="2px">
                        <Text
                          className="publisherName"
                          color="#fff"
                          fontSize="10px"
                          fontWeight="bold"
                          style={{ paddingLeft: "5px !important" }}
                        >
                          {formatDate(item.callDate)}
                        </Text>
                      </Stack>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.campaign}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.publisher}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.callerId}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.dialed}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.targetNo}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.duplicate}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.target}
                      </Text>
                    </Td>
                    <Td borderColor="#56577A">
                      <Text color="#fff" fontSize="10px" fontWeight="bold">
                        {item.callDurations}
                      </Text>
                    </Td>
                  </Tr>
                ))
              ) : (
                <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
              )}
            </Tbody>
          </Table>
        </CardBody> */}
        
        {/* react table with pagination */}
        <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
          <Table
            {...getTablePropsCallDetails()}
            className="report_custom_table"
            variant="simple"
          >
            <Thead>
              {headerGroupsCallDetails?.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup?.headers?.map((column, index) => (
                    <Th
                      minW="50px"
                      color="gray.400"
                      fontSize="10px"
                      fontFamily="Inter"
                      borderColor="#56577A"
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      key={index}
                    >
                      <Flex
                        justify="space-between"
                        align="center"
                        fontSize={{ sm: "10px", lg: "12px" }}
                        color="gray.400"
                      >
                        {column.render("Header")}
                        <Icon
                          w={{ sm: "10px", md: "14px" }}
                          h={{ sm: "10px", md: "14px" }}
                          color={
                            callDetailsHead.isSorted ? "gray.500" : "gray.400"
                          }
                          float="right"
                          as={
                            column.isSorted
                              ? column.isSortedDesc
                                ? TiArrowSortedDown
                                : TiArrowSortedUp
                              : TiArrowUnsorted
                          }
                        />
                      </Flex>{" "}
                    </Th>
                  ))}
                </Tr>
              ))}
            </Thead>
            <Tbody {...getTableBodyPropsCallDetails()}>
              {callDetails.length > 0 ? (
                rowCallDetails?.map((row, index) => {
                  prepareRowCallDetails(row);
                  return (
                    <Tr {...row.getRowProps()} key={index}>
                      {row?.cells?.map((cell, index) => {
                        const header =
                          headerGroupsCallDetails[0].headers[index];
                        return (
                          <Td
                            minW="50px"
                            borderColor="#56577A"
                            {...cell.getCellProps()}
                            key={index}
                          >
                            <Stack direction="row" spacing="2px">
                              <Text
                                className="publisherName"
                                color="#fff"
                                fontSize="10px"
                                fontWeight="bold"
                                style={{ paddingLeft: "5px !important" }}
                              >
                                {cell.render("Cell")}
                              </Text>
                            </Stack>
                          </Td>
                        );
                      })}
                    </Tr>
                  );
                })
              ) : (
                <Tr>
                  <Td borderColor="#56577A" colSpan={11}>
                    <Stack direction="row" spacing="16px">
                      <Text color="#fff" fontSize="lm" fontWeight="bold">
                        There are No Call Records Available
                      </Text>
                    </Stack>
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </CardBody>
        <Flex
          direction={{ sm: "column", md: "row" }}
          justify="space-between"
          align="center"
          px="22px"
          w="100%"
          px={{ md: "22px" }}
        >
          <Text
            fontSize="sm"
            color="white"
            fontWeight="500"
            mb={{ sm: "24px", md: "0px" }}
          >
            Showing {pageSize * pageIndex + 1} to
            {pageSize * (pageIndex + 1) <= callDetails.length
              ? pageSize * (pageIndex + 1)
              : callDetails.length}{" "}
            of {callDetails.length} entries
          </Text>
          <Stack direction="row" alignSelf="flex-end" spacing="4px" ms="auto">
            <Button
              variant="no-hover"
              onClick={() => previousPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="50%"
              bg="#fff"
              border="1px solid lightgray"
              display={
                pageSize === 5 ? "none" : canPreviousPage ? "flex" : "none"
              }
              _hover={{
                bg: "gray.200",
                opacity: "0.7",
                borderColor: "gray.500",
              }}
            >
              <Icon as={GrFormPrevious} w="16px" h="16px" color="gray.400" />
            </Button>
            {pageSize === 5 ? (
              <NumberInput
                max={pageCount - 1}
                min={1}
                w="75px"
                mx="6px"
                defaultValue="1"
                onChange={(e) => gotoPage(e)}
              >
                <NumberInputField />
                <NumberInputStepper>
                  <NumberIncrementStepper onClick={() => nextPage()} />
                  <NumberDecrementStepper onClick={() => previousPage()} />
                </NumberInputStepper>
              </NumberInput>
            ) : (
              createPages(pageCount).map((pageNumber, index) => {
                return (
                  <Button
                    variant="no-hover"
                    transition="all .5s ease"
                    onClick={() => gotoPage(pageNumber - 1)}
                    w="40px"
                    h="40px"
                    borderRadius="160px"
                    bg={pageNumber === pageIndex + 1 ? "brand.200" : "#fff"}
                    key={index}
                  >
                    <Text
                      fontSize="xs"
                      color={pageNumber === pageIndex + 1 ? "#fff" : "gray.600"}
                    >
                      {pageNumber}
                    </Text>
                  </Button>
                );
              })
            )}
            <Button
              variant="no-hover"
              onClick={() => nextPage()}
              transition="all .5s ease"
              w="40px"
              h="40px"
              borderRadius="160px"
              bg="#fff"
              display={pageSize === 5 ? "none" : canNextPage ? "flex" : "none"}
            >
              <Icon as={GrFormNext} w="16px" h="16px" color="gray.400" />
            </Button>
          </Stack>
        </Flex>
      </Card>

      {/*<SimpleGrid columns={{ sm: "1", md: "2" }} spacing='24px' style={{ marginTop: '10px' }}>
        <Card px='0px'>
          <CardHeader px='22px' mb='32px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold'>
              Publishers
            </Text>
          </CardHeader>
          <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Name
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Live
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Hours
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Impression
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Calls
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Converted
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Revenue
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Payout
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Profit
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW='180px' borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇺🇸</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        United States
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      2500
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $214,000
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>

                </Tr>

              </Tbody>
            </Table>
          </CardBody>
        </Card>

        <Card px='0px'>
          <CardHeader px='22px' mb='32px'>
            <Text color='#fff' fontSize='lg' fontWeight='bold'>
              Targets Top Performers
            </Text>
          </CardHeader>
          <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
            <Table variant='simple'>
              <Thead>
                <Tr>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Country:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Sales:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Value:
                  </Th>
                  <Th
                    color='gray.400'
                    fontSize='10px'
                    fontFamily='Inter'
                    borderColor='#56577A'>
                    Bounce:
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                <Tr>
                  <Td minW='180px' borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇺🇸</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        United States
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      2500
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $214,000
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      40,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇩🇪</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Germany
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      3900
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $446,700
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      19,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td borderColor='#56577A'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇬🇧</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Great Britain
                      </Text>
                    </Stack>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      1300
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $121,900
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      39,22%
                    </Text>
                  </Td>
                </Tr>
                <Tr>
                  <Td border='none'>
                    <Stack direction='row' spacing='16px'>
                      <Text>🇧🇷</Text>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        Brasil
                      </Text>
                    </Stack>
                  </Td>
                  <Td border='none'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      920
                    </Text>
                  </Td>
                  <Td border='none'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      $52,100
                    </Text>
                  </Td>
                  <Td border='none'>
                    {" "}
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      29,9%
                    </Text>
                  </Td>
                </Tr>
              </Tbody>
            </Table>
          </CardBody>
        </Card>
  </SimpleGrid>*/}
    </Flex>
  );
}
