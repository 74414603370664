/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useRef, useState } from "react";

// Chakra imports
import {
  Box,
  Flex,
  Button,
  FormControl,
  FormLabel,
  Heading,
  Input,
  Link,
  Switch,
  Text,
  DarkMode,
} from "@chakra-ui/react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

// Assets
import signInImage from "assets/img/signInImage.png";

// Custom Components
import AuthFooter from "components/Footer/AuthFooter";
import GradientBorder from "components/GradientBorder/GradientBorder";
import SignUpCover from "../SignUp/SignUpCover";

function SignInCover() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [enableBuyer, setEnableBuyer] = useState(false);
  const [auth, setAuth] = useState({
    email: "",
    password: "",
    role: 0
  })

  const SignUpRedirect = e => {
    history.push("/auth/authentication/sign-up/cover");
  }
  const ForgetPasswordRedirect = e => {
    history.push("/auth/authentication/sign-in/forget-password");
  }

  const handleChange = e => {
    const { name, value } = e.target
    setAuth({
      ...auth,//spread operator 
      [name]: value
    })
  }

  const loginUser = e => {
    e.preventDefault();
    const loadToast = toast.loading('Please Wait....');
    const { email, password, role } = auth;
    if ((email == "") && (password == "")) {
      toast.dismiss(loadToast);
      toast.error("Please Enter Email and Password");
      return false;
    }
    let config = {
      method: 'post',
      url: apiUrl + '/api/auth/login',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "email": email, "password": password , "role": role}
    };
    axios.request(config)
      .then((response) => {
        if (response.data.statusCode == 200) {
          let userDetails = { id: response.data.user._id, name: response.data.user.name, email: response.data.user.email, role: response.data.user.role, status: response.data.user.status };
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("user", JSON.stringify(userDetails));
          toast.dismiss(loadToast);
          toast.success('Logged In Success...');
          //history.push("/admin/dashboard/default");
          window.location.reload();
        } else {
          toast.dismiss(loadToast);
          toast.error("Login Failed due to wrong Details!");
        }
        console.log(response);
      })
      .catch((error) => {
        toast.dismiss(loadToast);
        toast.error(error);
      });
    //localStorage.setItem("token", data.token);
    //window.location.reload();
  }
  // Chakra color mode
  const titleColor = "white";
  const textColor = "gray.400";
  return (
    <Flex position='relative' as='form' onSubmit={loginUser}>
      <Flex
        minH='100vh'
        h={{ base: "120vh", lg: "fit-content" }}
        w='100%'
        maxW='1044px'
        mx='auto'
        pt={{ sm: "100px", md: "0px" }}
        flexDirection='column'
        me={{ base: "auto", lg: "50px", xl: "auto" }}>
        <Flex
          alignItems='center'
          justifyContent='start'
          style={{ userSelect: "none" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          w={{ base: "100%", md: "50%", lg: "450px" }}
          px='50px'>
          <Flex
            direction='column'
            w='100%'
            background='transparent'
            mt={{ base: "50px", md: "150px", lg: "160px", xl: "245px" }}
            mb={{ base: "60px", lg: "95px" }}>
            <Heading color={titleColor} fontSize='32px' mb='10px'>
              Sign In
            </Heading>
            <Text
              mb='36px'
              ms='4px'
              color={textColor}
              fontWeight='bold'
              fontSize='14px'>
              Enter your email and password to sign in
            </Text>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='white'>
                Email
              </FormLabel>
              <GradientBorder
                mb='24px'
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius='20px'>
                <Input
                  color='white'
                  bg={{ base: "rgb(19,21,54)", lg: "rgb(19,21,54)" }}
                  border='transparent'
                  borderRadius='20px'
                  fontSize='sm'
                  size='lg'
                  w={{ base: "100%", md: "346px" }}
                  maxW='100%'
                  h='46px'
                  placeholder='Your email address'
                  name="email"
                  onChange={handleChange}
                />
              </GradientBorder>
            </FormControl>
            <FormControl>
              <FormLabel ms='4px' fontSize='sm' fontWeight='500' color='white'>
                Password
              </FormLabel>
              <GradientBorder
                mb='24px'
                w={{ base: "100%", lg: "fit-content" }}
                borderRadius='20px'>
                <Input
                  color='white'
                  bg={{ base: "rgb(19,21,54)", lg: "rgb(19,21,54)" }}
                  border='transparent'
                  borderRadius='20px'
                  fontSize='sm'
                  size='lg'
                  w={{ base: "100%", md: "346px" }}
                  maxW='100%'
                  type='password'
                  placeholder='Your password'
                  name="password"
                  onChange={handleChange}
                />
              </GradientBorder>
            </FormControl>
            <Button
              variant='brand'
              fontSize='10px'
              type='submit'
              w='100%'
              h='45'
              mb='20px'>
              SIGN IN
            </Button>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Password not working?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold' onClick={ForgetPasswordRedirect}>
                  Forgot Password
                </Link>
              </Text>
            </Flex>
            <Flex
              flexDirection='column'
              justifyContent='center'
              alignItems='center'
              maxW='100%'
              mt='0px'>
              <Text color={textColor} fontWeight='medium'>
                Don't have an account?
                <Link color={titleColor} as='span' ms='5px' fontWeight='bold' onClick={SignUpRedirect}>
                  Sign Up
                </Link>
              </Text>
            </Flex>
          </Flex>
        </Flex>
        <Box
          w={{ base: "335px", md: "450px" }}
          mx={{ base: "auto", lg: "unset" }}
          ms={{ base: "auto", lg: "auto" }}
          mb='80px'>
          <AuthFooter />
        </Box>
        <Box
          display={{ base: "none", lg: "block" }}
          overflowX='hidden'
          h='100%'
          maxW={{ md: "50vw", lg: "50vw" }}
          minH='100vh'
          w='960px'
          position='absolute'
          left='0px'>
          <Box
            bgImage={signInImage}
            w='100%'
            h='100%'
            bgSize='cover'
            bgPosition='50%'
            display='flex'
            flexDirection='column'
            justifyContent='center'
            alignItems='center'
            position='absolute'>
            <Text
              textAlign='center'
              color='transparent'
              letterSpacing='8px'
              fontSize='36px'
              fontWeight='bold'
              bgClip='text !important'
              bg='linear-gradient(94.56deg, #FFFFFF 79.99%, #21242F 102.65%)'>
              RINGSTRACKER
            </Text>
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
}

export default SignInCover;
