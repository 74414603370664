/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button, useDisclosure } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import RoutingNav from "components/Navbars/Routing";
import Loading from 'components/Loading';
import NewRoutingPlan from './NewRoutingPlan';
import CTModal from "components/Modal";

// Data
//import { columnsData1 } from "variables/columnsData";
//import tableData1 from "variables/tableData1.json";

function DataTables() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const [routingPlans, setRoutingPlans] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState('');
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editRow, setEditRow] = useState(null);


  const handleEdit = (originalRow) => {
    setEditRow(originalRow);
    onOpen();
  };

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    // {
    //   Header: "Target ID",
    //   accessor: "id",
    // },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <div>
          <a href="#" onClick={e => { e.preventDefault(); handleEdit(originalRow); }} ><i className="fa-solid fa-pen-to-square"></i></a> | <a href="#" onClick={e => { e.preventDefault(); deleteRoutingPlan(originalRow); }} ><i className="fa-solid fa-trash"></i></a>
        </div>
      ),
      id: 'action',
    }
  ];

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, [refersh]);

  const addRoutingPlan = async () => {
    setEditRow(null);
    onOpen();
  }

  const refresh = () => {
    onClose();
    getData();
  };

  const getData = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    let result = await fetch(apiUrl + "/api/routingplan/lists", requestOptions);
    result = await result.json();
    setRoutingPlans(result);
    setLoadingData(false);
    toast.dismiss(loadToast);
  }

  const deleteRoutingPlan = async (originalRow) => {
    if (!confirm("Do You want to Delete This Routing Plan?")) {
      return false;
    }

    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: originalRow._id })
    };
    let result = await fetch(apiUrl + "/api/routingplan/delete", requestOptions);
    result = await result.json();
    if(result.statusCode == 200) {
      setLoadingData(true);
      refershSet(Math.random());
      toast.success('Routing Plan has been Deleted Successfully');
    } else {
      toast.success('Not Deleted due to error.');
    }
    toast.dismiss(loadToast);
  }


  return (
    <Flex className='addpub' direction='column'>
      <Card>
        <CardBody>
          <RoutingNav current="/administrator/callflows/routings" />
          <Button
            variant='outlineWhite'
            alignSelf='flex-end'
            onClick={addRoutingPlan}
          >
            <Text fontSize='16px' color='#fff'>
              + Add Routing Plan
            </Text>
          </Button>
          {loadingData ? (
            <Loading />
          ) : (
            <SearchTable1 tableData={routingPlans} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={editRow ? "Edit Routing Plan" : "Create Routing Plan"}
        size={"4xl"}
      >
        <NewRoutingPlan
          onClose={refresh}
          plan={editRow}
        />
      </CTModal>
    </Flex>
  );
}

export default DataTables;
