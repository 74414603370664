/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  FormErrorMessage,
  Input,
  Stack,
  Text,
  Switch,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import toast from 'react-hot-toast';
import { VSeparator } from "components/Separator/Separator";
import packageJson from '../../../appConfig.js';
import Button1 from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import RadioCardGroup from 'components/Form/RadioButton'
import Loading from 'components/Loading';
import { caseInsensitiveSort } from 'variables/general';

import NewPublisher from './NewPublisher';
import NewBuyer from './NewBuyer';
import CTModal from "components/Modal";

const user = JSON.parse(localStorage?.getItem("user"));

const NewCampaign = (props) => {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [campaign, setCampaign] = useState({
    name: "",
    //description: "",
    defaultPayout: [],
    publishers: [],
    reportDuplicateCalls: 0,
    reportDuplicateCallsPeriod: 0,
    callrouting: "",
    callFlow: null,
    routing: [],
    by: user.id
  })
  const [payout, setPayout] = useState({
    callLength: "",
    concurrencyCap: "",
    dailyCap: "",
    dailyPayoutCap: "",
    duplicatePayout: "disable",
    globalCap: "",
    globalPayoutCap: "",
    houlyCap: "",
    hourlyPayoutCap: "",
    limitPayoutEnabled: false,
    monthlyCap: "",
    monthlyPayoutCap: "",
    payoutAmount: "",
    payoutType: "1",
    type: "1",
    reportDuplicateCallsOn: "Correct",
    callLengthGreaterThanSeconds: "",
    duplicateTimePeriod: "",
    routePrevious: 'toNormal',
    strict: false,
    handleAnonymousCallsAsDuplicate: false,
    payoutOncePerCaller: false,
    recordCalls: false,
    waitForAnswer: false,
    targetDialAttempts: 3,
    filterRepeatCallers: 2,
  });
  const [fetchedOnce, setFetchedOnce] = useState(false);

  const campaignId = props.match?.params?.id || false;
  const editMode = !!campaignId;
  const defaultRoutingPlan = {
    priority: "",
    weight: "",
    callType: 0,
    callLength: "",
    revenue: ""
  };

  const [publishers, setPublishers] = useState([]);
  const [newPublisher, setNewPublisher] = useState({});
  const [buyers, setBuyers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingBuyerData, setLoadingBuyerData] = useState(true);
  const [refresh, setRefresh] = React.useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [updateRouting, setUpdateRouting] = React.useState([]);
  const [routingPlan, setRoutingPlan] = useState(defaultRoutingPlan);
  const [routingPlanLists, setRoutingPlanLists] = useState([]);
  const [loadingRoutingPlans, setLoadingRoutingPlans] = useState(true);
  const [viewReportDuplicateCallsOn, setViewReportDuplicateCallsOn] = useState(false);
  const [callFlows, setCallFlows] = useState([]);

  const { isOpen: isNewPublisherOpen, onOpen: onNewPublisherOpen, onClose: onNewPublisherClose } = useDisclosure();
  const { isOpen: isNewBuyerOpen, onOpen: onNewBuyerOpen, onClose: onNewBuyerClose } = useDisclosure();

  const [formErrors, setFormErrors] = useState({});

  useEffect(() => {
    fetchCampaign();
    if (loadingData) {
      getPublishers();
    }
    if (loadingBuyerData) {
      getBuyers();
    }
    if (loadingRoutingPlans) {
      getRoutingPlanLists();
    }
    getCallFlowData();
  }, [refresh]);

  const handleCreateNewPublisher = () => {
    onNewPublisherOpen();
  };

  const getPublishers = async () => {
    let result = await axios.get(apiUrl + "/api/users/publishers", { params: { id: user.id } });
    setPublishers(result.data);
    setLoadingData(false);
  }

  const getBuyers = async () => {
    const dataUrl =  user.role == 1
      ? apiUrl + "/api/admin/list-buyers"
      : apiUrl + "/api/buyers/list-buyers";
    let result = await axios.get(dataUrl, { params: { by: user.id } });
    setBuyers(result.data);
    setLoadingBuyerData(false);
  }

  const getRoutingPlanLists = async () => {
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(false);
  }

  const fetchCampaign = async () => {
    if (!editMode || fetchedOnce) {
      return;
    }
    setFetchedOnce(true);
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: campaignId }),
    };
    const response = await fetch(
      apiUrl + "/api/campaign/fetch",
      requestOptions
    );
    const data = await response.json();
    setCampaign(data);
    setPayout(data.defaultPayout);
    setRoutingPlanLists(data.routing);
    toast.dismiss(loadToast);
    setRefresh(Math.random());
  };

  const isCheckedPublisher = (originalRow, rowIndex) => {
    const attributes = {
      onChange: addPublishers,
      name: 'publisher',
      value: originalRow._id,
      isChecked: false,
    };

    campaign.publishers?.map((publisher) => {
      if (originalRow._id == publisher.publisherId?._id) {
        attributes.isChecked = true;
        return;
      }
    });

    return (
      <div>
        <Switch { ...attributes } />
      </div>
    );
  };

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: 'Enabled',
      accessor: isCheckedPublisher,
      id: 'publisherEnabled',
      sortType: (prev, curr) => {
        const allPubs = campaign.publishers.map((o) => o.publisherId._id);
        const inPrev = allPubs.indexOf(prev.original._id) !== -1;
        const inCurr = allPubs.indexOf(curr.original._id) !== -1;

        if (!inPrev && inCurr) {
          return 1;
        } else if (inPrev && !inCurr) {
          return -1;
        } else {
          return 0;
        }
      },
    },
    {
      Header: "Publisher",
      accessor: "name",
      sortType: (prev, curr, columnId) => {
        return caseInsensitiveSort(prev, curr, columnId);
      },
    },
    {
      Header: 'Phone Numbers',
      accessor: "phone",
      id: 'phone',
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <div>
          Using Campaign Payout Setting
        </div>
      ),
      id: 'configurations',
      disableSortBy: true,
    }
  ];

  const addPublishers = (event) => {
    const publisherId = event.target.value;
    let updatedPublishers = [...campaign.publishers];

    if (event.target.checked) {
      const newPublisher = { "publisherId": { _id: publisherId }, phone: '' };
      updatedPublishers = [...updatedPublishers, newPublisher];
    } else {
      updatedPublishers = updatedPublishers.filter(publisher => publisher.publisherId?._id !== publisherId);
    }

    setCampaign(prevCampaign => ({ ...prevCampaign, publishers: updatedPublishers }));
  };

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>
      {type}
    </div>
  }

  const addRouteModel = (originalRow) => {
    setUpdateRouting(originalRow);
    setRoutingPlan(defaultRoutingPlan);
    setShow(true);
  }

  const editRoute = (index) => {
    const editPlan = routingPlanLists[index];
    const originalRouting = {
      target: editPlan.target,
      name: editPlan.name,
      phone: editPlan.phone,
    };
    editPlan._tableId = index;
    setUpdateRouting(originalRouting);
    setRoutingPlan(editPlan);
    setShow(true);
  };

  const handleRoutingChange = e => {
    const { name, value } = e.target
    setRoutingPlan({
      ...routingPlan,
      [name]: value
    })
  }

  const addRoutingPlan = e => {
    if ((routingPlan.priority == "") || (routingPlan.weight == "") || (routingPlan.revenue == "") || (routingPlan.type == "") || (routingPlan.callLength == "")) {
      toast.error("Please complete all fields.");
      return false;
    }
    if (routingPlan._tableId !== undefined) {
      routingPlanLists[routingPlan._tableId] = {
        ...routingPlan,

      };
    } else {
      let targetId = updateRouting._id;
      routingPlanLists.push({
        target: targetId,
        name: updateRouting.name,
        phone: updateRouting.phoneNumber,
        priority: routingPlan.priority,
        weight: routingPlan.weight,
        callType: routingPlan.callType,
        callLength: routingPlan.callLength,
        revenue: routingPlan.revenue
      });
    }
    setRefresh(Math.random())
    handleClose();
  }

  const columnTargetData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: '#',
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-solid fa-check" />
          {/*<Switch id="email-alerts" onChange={addTarget} name="publisher" value={originalRow._id} />*/}
        </div>
      ),
      id: 'EnableTarget',
      disableSortBy: true,
    },
    {
      Header: "Name",
      accessor: "name",
      sortType: (prev, curr, columnId) => {
        return caseInsensitiveSort(prev, curr, columnId);
      },
    },
    {
      Header: "Type",
      accessor: getBuyerType,
    },
    {
      Header: "Destination",
      accessor: "phoneNumber",
    },
    {
      Header: 'Status',
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-sharp fa-solid fa-circle" style={{ color: '#22ca0c' }} />
        </div>
      ),
      sortType: (prev, curr, columnId) => {
        if (prev.original[columnId] > curr.original[columnId]) {
          return 1;
        } else if (prev.original[columnId] < curr.original[columnId]) {
          return -1;
        } else {
          return 0;
        }
      },
      id: 'EnableTargets',
    },
    {
      Header: 'Add',
      accessor: (originalRow, rowIndex) => (
        <div>
          <a href="#" onClick={e => { e.preventDefault(); addRouteModel(originalRow); }} title="Add Routing"><i className="fa-solid fa-plus"></i></a>
        </div>
      ),
      id: 'addRoute',
      disableSortBy: true,
    }
  ];

  const handleChange = e => {
    const { name, value } = e.target
    setCampaign({
      ...campaign,
      [name]: value
    })
  }

  const onViewReportDuplicatesCallsOnChange = e => {
    const { name, value } = e.target;

    setViewReportDuplicateCallsOn(e.target.value == "callLengthGreaterThan");
    setPayout({
      ...payout,
      [name]: value,
    });
  };

  const onChangeRoutePrevious = e => {
    setPayout((prevPayout) => ({
      ...prevPayout,
      routePrevious: e,
    }));
  };

  const getCallFlowData = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    };
    let result = await fetch(apiUrl + "/api/ivrflow", requestOptions);
    result = await result.json();
    setCallFlows(result.ivr);
    toast.dismiss(loadToast);
  }

  const onPayoutChange = e => {
    const name = e.target.name;
    let value = ['checkbox', 'radio'].includes(e.target.type)
      ? e.target.checked
      : e.target.value;

    setPayout({
      ...payout,
      [name]: value
    })
  };

  const saveCampaign = () => {
    campaign.routing = routingPlanLists;
    campaign.defaultPayout = payout;
    const loadToast = toast.loading('Please Wait....');
    const { name, publishers, callrouting, routing, callFlow } = campaign;

    const errors = {};
    if (name.length === 0) {
      errors.name = 'Please enter a name.';
    }
    if (publishers.length === 0) {
      errors.publishers = 'Please select one or more publishers.';
    }
    if (!callrouting) {
      errors.callrouting = 'Please select a routing method.';
    }
    if (callrouting == 1 && routing.length === 0) {
      errors.routing = 'Please add one or more routing plans.';
    }
    if (callrouting == 2 && !callFlow) {
      errors.callFlow = 'Please select a call flow.';
    }

    setFormErrors({ ...errors });

    if (Object.keys(errors).length === 0) {
      let config = {
        method: 'post',
        url: apiUrl + '/api/campaign/',
        headers: {
          'Content-Type': 'application/json'
        },
        data: campaign
      };
      if (editMode) {
        config.url += 'update';
      }
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success('Campaign has been ' + (editMode ? 'updated' : 'created') + ' successfully.');
            history.push("/administrator/campaign/lists");
          }
        })
        .catch((error) => {
          toast.error(error);
          toast.dismiss(loadToast);
        });
    }
    else {
      console.log(errors)
      toast.error("You have entered invalid input.");
      toast.dismiss(loadToast);
    };
  }

  const deleteRouting = (index) => {
    routingPlanLists.splice(index, 1);
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(true);
    setRefresh(Math.random())
  };

  const handlePublisherRefresh = (newPublisher) => {
    if (newPublisher.name) {
      setPublishers((prevPublishers) => [newPublisher, ...prevPublishers]);
    }
  };

  const handleBuyerRefresh = (newBuyer) => {
    if (newBuyer.name) {
      setBuyers((prevBuyers) => [newBuyer, ...prevBuyers]);
    }
  };

  return (
    <Card>
      <CardHeader mb='20px'>
        <Text color={"#fff"} fontSize='lg'>
          {editMode ? 'Edit' : 'Create'} Campaign
        </Text>
      </CardHeader>
      <CardBody>
    <Flex flexDirection='column' gap={5} w='100%'>
      <FormControl isInvalid={formErrors.name}>
        <FormLabel variant="ct-label">
          Name
        </FormLabel>
        <Input
          type='text'
          variant="borderless"
          name="name"
          maxWidth="32rem"
          value={campaign.name}
          onChange={handleChange}
        />
        <FormErrorMessage>{formErrors.name}</FormErrorMessage>
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Report Duplicate Calls On
        </FormLabel>
        <Select
          variant="borderless"
          name="reportDuplicateCallsOn"
          maxWidth="32rem"
          value={payout.reportDuplicateCallsOn}
          onChange={onViewReportDuplicatesCallsOnChange}
        >
          <option value="connect">Connect</option>
          <option value="incoming">Incoming</option>
          <option value="callLengthGreaterThan">
            Call Length Greater Than
          </option>
        </Select>
        {viewReportDuplicateCallsOn ? (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: "5px",
          }}
        >
          <Input
            variant="borderless"
            onChange={onPayoutChange}
            value={payout.callLengthGreaterThanSeconds}
            name="callLengthGreaterThanSeconds"
            type="number"
            placeholder="0"
            width="50px"
            m="10px 0"
          />{" "}
          <span
            style={{
              fontWeight: "400",
              color: "#fff",
              fontSize: "14px",
            }}
          >
            seconds
          </span>
        </div>
      ) : null}
      </FormControl>
      <FormControl>
        <FormLabel variant="ct-label">
          Route Previously Connected Calls
        </FormLabel>
        <RadioCardGroup 
          defaultValue="toNormal"
          name="routePrevious"
          onChange={onChangeRoutePrevious}
          value={payout.routePrevious}
          options={[
            { value: "toNormal",    text: "Normally" },
            { value: "toOriginal",  text: "To Original" },
            { value: "toDifferent", text: "To Different" },
          ]}
        />
      </FormControl>
        {(payout.routePrevious === "toOriginal" || payout.routePrevious === "toDifferent") ? (
        <FormControl>
            <FormLabel variant="ct-label">
              Strict
            </FormLabel>
            <div>
              <Switch
                style={{ flexBasis: "100%" }}
                onChange={onPayoutChange}
                name="strict"
                value={payout.strict}
                defaultIsChecked={payout.strict}
              />
            </div>
        </FormControl>) : null}
    <Flex flexWrap="wrap">
      <FormControl width={{ sm: '50%', md: '33%' }}>
        <FormLabel variant="ct-label">
          Handle Anonymous Calls As Duplicate
        </FormLabel>
        <Switch
          style={{ flexBasis: "100%" }}
          onChange={onPayoutChange}
          name="handleAnonymousCallsAsDuplicate"
          isChecked={payout.handleAnonymousCallsAsDuplicate}
        />
      </FormControl>
      <FormControl width={{ sm: '50%', md: '33%' }}>
        <FormLabel variant="ct-label">
          Record Calls
        </FormLabel>
        <Switch
          style={{ flexBasis: "100%" }}
          onChange={onPayoutChange}
          name="recordCalls"
          isChecked={payout.recordCalls}
        />
      </FormControl>
      {payout.recordCalls && <FormControl width={{ sm: '50%', md: '33%' }}>
        <FormLabel variant="ct-label">
          Wait for Answer
        </FormLabel>
        <Switch
          style={{ flexBasis: "100%" }}
          onChange={onPayoutChange}
          name="waitForAnswer"
          isChecked={payout.waitForAnswer}
        />
      </FormControl>}
    </Flex>

    <Flex>
      <FormControl width={{ sm: '50%', md: '33%' }}>
        <FormLabel variant="ct-label">
          Target Dial Attempts
        </FormLabel>
        <Input
          onChange={onPayoutChange}
          value={payout.targetDialAttempts}
          name="targetDialAttempts"
          type="number"
          variant="borderless"
          placeholder="0"
          width="50px"
        />
      </FormControl>
      <FormControl width={{ sm: '50%', md: '33%' }}>
        <FormLabel variant="ct-label">
          Filter Repeat Callers (seconds)
        </FormLabel>
        <Input
          type='number'
          variant="borderless"
          name="filterRepeatCallers"
          value={payout.filterRepeatCallers}
          onChange={onPayoutChange}
          width="50px"
        />
      </FormControl>
    </Flex>

      <VSeparator className="verticleSeparator" />
      <Text align="center" color={"#fff"} fontSize='xl'>
        Publishers
      </Text>
      <FormControl isInvalid={formErrors.publishers}>
        <FormErrorMessage>{formErrors.publishers}</FormErrorMessage>
        {loadingData ? (
          <Loading />
        ) : (
          <>
          <Button leftIcon={<AddIcon />} colorScheme="brand" mb={4} onClick={handleCreateNewPublisher}>
            Add Publisher
          </Button>

          <SearchTable1 tableData={publishers} columnsData={columnsData} key={campaign.publishers.length} />
          </>
        )}
      </FormControl>
      <VSeparator className="verticleSeparator" />
      <Text align="center" color={"#fff"} fontSize='xl'>
        Routing Settings
      </Text>
      <FormControl isInvalid={formErrors.callrouting}>
        <FormLabel variant="ct-label">
          Call Routing
        </FormLabel>
        <Select
          onChange={handleChange}
          value={campaign.callrouting}
          maxWidth="32rem"
          name="callrouting"
          variant="borderless"
        >
          <option value=''>Choose Routing Option</option>
          <option value='1'>Priority (Standard)</option>
          <option value='2'>Call Flow</option>
        </Select>
        <FormErrorMessage>{formErrors.callrouting}</FormErrorMessage>
      </FormControl>
      {campaign.callrouting == 1 &&
      <Flex flexDirection={'column'}>
        <FormControl>
          <FormLabel variant="ct-label">
            Choose Targets
          </FormLabel>
          {loadingBuyerData ? (
            <Loading />
          ) : (
          <>
            <Button leftIcon={<AddIcon />} colorScheme="brand" mb={4} onClick={onNewBuyerOpen}>
              Add Buyer
            </Button>

            <SearchTable1 tableData={buyers} columnsData={columnTargetData} />
          </>
          )}
        </FormControl>
        <FormControl isInvalid={formErrors.routing}>
          <FormLabel variant="ct-label">
            Routing Plan
          </FormLabel>
          <FormErrorMessage>{formErrors.routing}</FormErrorMessage>
          <Card px='0px' style={{ marginTop: '10px' }}>
            <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
              <Table className="targetTable" variant='simple'>
                <Thead>
                  <Tr>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Priority
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Weight
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Name
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Destination
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Type
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Revenue
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Status
                    </Th>
                    <Th
                      color='gray.400'
                      fontSize='10px'
                      fontFamily='Inter'
                      borderColor='#56577A'>
                      Action
                    </Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {routingPlanLists.length > 0 ? routingPlanLists.map((item, index) =>
                    <Tr key={index}>
                      <Td borderColor='#56577A'>
                          <Text color='#fff' fontSize='md'>
                            {item.priority}
                          </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='md'>
                          {item.weight}
                        </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='sm'>
                          {item.name}
                        </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='sm'>
                          {item.phone}
                        </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='sm'>
                        {item.callType == "1" ? "Call Length" : "Dial"}
                        </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='sm'>
                          ${item.revenue}
                        </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='sm'>
                          Active
                        </Text>
                      </Td>
                      <Td borderColor='#56577A'>
                        <Text color='#fff' fontSize='sm'>
                          <a href="#" onClick={e => { e.preventDefault(); editRoute(index); }} title="Edit Routing"><i className="fa-solid fa-pen-to-square"></i></a>
                          <a href="#" onClick={e => { e.preventDefault(); deleteRouting(index); }} title="Delete Routing"><i className="fa-solid fa-trash"></i></a>
                        </Text>
                      </Td>
                    </Tr>
                  )
                    : <Tr>
                      <Td borderColor='#56577A' colSpan={8}>
                        <Stack direction='row' spacing='16px'>
                          <Text color='#fff' fontSize='sm' fontWeight='bold'>
                            There are No Routing Plans Added..
                          </Text>
                        </Stack>
                      </Td>
                    </Tr>
                  }
                </Tbody>
              </Table>
            </CardBody>
          </Card>
        </FormControl>
      </Flex>
      }
      {campaign.callrouting == 2 &&
      <>
        <Select
          value={campaign.callFlow ?? ""}
          name="callFlow"
          onChange={handleChange}
          variant="borderless"
        >
        <option>Select Call Flow</option>
        {callFlows.map(flow => {
          return (<option value={flow._id} key={flow._id}>{flow.name}</option>)
        })}
        </Select>
        <FormErrorMessage>{formErrors.callFlow}</FormErrorMessage>
        </>
      }

      <Button
        variant='formButton'
        onClick={saveCampaign}
        mt="3rem"
      >
        {editMode ? 'Update' : 'Create'}
      </Button>

      <Modal show={show} onHide={handleClose} className="routingPlanModel">
        <Modal.Header closeButton>
          <Modal.Title>Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="hidden" name="_tableId" value={routingPlan._tableId} />
          <div className="form-group">
            <label htmlFor="priority">Enter Priority</label>
            <input type="number" className="form-control" name="priority" value={routingPlan.priority} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="weight">Enter Weight</label>
            <input type="number" className="form-control" name="weight" value={routingPlan.weight} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Type</label>
            <select className="form-select" value={routingPlan.callType} name="callType" onChange={handleRoutingChange}>
              <option value="0">Choose Option</option>
              <option value="1">Call Length</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Call Length(Sec)</label>
            <input type="number" className="form-control" name="callLength" placeholder="30" value={routingPlan.callLength} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Revenue($)</label>
            <input type="number" className="form-control" name="revenue" placeholder="30" value={routingPlan.revenue} onChange={handleRoutingChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button1 variant="secondary" onClick={handleClose}>
            Close
          </Button1>
          <Button1 variant="primary" onClick={addRoutingPlan}>
            Save
          </Button1>
        </Modal.Footer>
      </Modal>
    </Flex>
    </CardBody>

    <CTModal isOpen={isNewPublisherOpen} onClose={onNewPublisherClose} title="Add Publisher" showClose={false}>
      <NewPublisher
        onClose={onNewPublisherClose}
        publisher={newPublisher}
        clearPublisher={setNewPublisher}
        refresh={handlePublisherRefresh}
      />

    </CTModal>
    <CTModal isOpen={isNewBuyerOpen} onClose={onNewBuyerClose} title="Add Buyer" showClose={false}>
      <NewBuyer
        onClose={onNewBuyerClose}
        refresh={handleBuyerRefresh}
      />
    </CTModal>
    </Card>
  );
};

export default NewCampaign;
