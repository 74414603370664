/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  background,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { VSeparator, HSeparator } from "components/Separator/Separator";
import packageJson from "../../../appConfig.js";
import Button1 from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function NewUser() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user")); //
  const [routePlan, setRoutePlan] = useState({
    name: "",
    routing: [],
    by: user.id,
  });
  //const [payout, setPayout] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingBuyerData, setLoadingBuyerData] = useState(true);
  const [refersh, refershSet] = React.useState("");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [updateRouting, setUpdateRouting] = React.useState([]);
  const [routingPlan, setRoutingPlan] = useState({
    priority: "",
    weight: "",
    callType: 0,
    callLength: "",
    revenue: "",
  });
  
  const [routingPlanLists, setRoutingPlanLists] = useState([]);
  const [loadingRoutingPlans, setLoadingRoutingPlans] = useState(true);

  const [targetFormToggle, setTargetFormToggle] = useState("hide");

  const [buyer, setBuyer] = useState({
    name: "",
    email: "",
    password: "",
    subid: "",
    phonetype: "",
    phoneNumber: "",
    connectiontimeout: "",
    cccap: "",
    by: user.id,
    timezone: "",
  });


  useEffect(() => {
    if (loadingBuyerData) {
      getBuyers();
    }
    if (loadingRoutingPlans) {
      getRoutingPlanLists();
    }
  }, [refersh]);

  const handleAddTarget = (e) => {
    const { name, value } = e.target;
    setBuyer({
      ...buyer, //spread operator
      [name]: value,
    });
  };

  const createNewbuyer = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, email, password, subid, phonetype, phoneNumber, connectiontimeout, timezone } = buyer;
    if (name == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Name is required!");
      return false;
    } else if (email == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Email is required!");
      return false;
    } else if (password == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Password is required!");
      return false;
    } else if (subid == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer SubID is required!");
      return false;
    } else if (phonetype == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Phone Type is required!");
      return false;
    } else if (phoneNumber == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Phone is required!");
      return false;
    } else if (connectiontimeout == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Connection Timeout is required!");
      return false;
    } else if (timezone == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Timezone is required!");
      return false;
    }

    let config = {
      method: "post",
      url: apiUrl + "/api/buyers",
      headers: {
        "Content-Type": "application/json",
      },
      data: buyer,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error[0].message);
          toast.dismiss(loadToast);
        } else {
          toast.success("Buyer has been  Successfully added");
          setLoadingData(true);
          setloadingCampaignData(true);
          setLoadingBuyerData(true);
          refershSet(Math.random());
          setTargetFormToggle("hide");
          toast.dismiss(loadToast);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadToast);
      });
  };

  const showTargetForm = (e) => {
    setTargetFormToggle("show");
  };
  const cancelTarget = (e) => {
    setTargetFormToggle("hide");
  };

  const getBuyers = async () => {
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    let result = await fetch(apiUrl + "/api/admin/list-buyers", requestOptions);
    result = await result.json();
    result = result.filter(ar => !routingPlanLists.find(rm => (rm.target === ar._id)));
    setBuyers(result);
    setLoadingBuyerData(false);
    toast.dismiss(loadToast);
  };

  const getRoutingPlanLists = async () => {
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(false);
  };

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>{type}</div>;
  }

  const addRouteModel = (originalRow) => {
    setUpdateRouting(originalRow);
    //setUpdateRouting
    setShow(true);
  };

  const handleRoutingChange = (e) => {
    const { name, value } = e.target;
    setRoutingPlan({
      ...routingPlan, //spread operator
      [name]: value,
    });
  };

  const addRoutingPlan = (e) => {
    let targetId = updateRouting._id;
    if (
      routingPlan.priority == "" ||
      routingPlan.weight == "" ||
      routingPlan.revenue == "" ||
      routingPlan.type == "" ||
      routingPlan.callLength == ""
    ) {
      toast.error("Please Fill All Required Fields");
      return false;
    }
    routingPlanLists.push({
      target: targetId,
      name: updateRouting.name,
      phone: updateRouting.phoneNumber,
      priority: routingPlan.priority,
      weight: routingPlan.weight,
      callType: routingPlan.callType,
      callLength: routingPlan.callLength,
      revenue: routingPlan.revenue,
      status: updateRouting.status,
    });
    routingPlanLists.sort((a, b) => (a.weight < b.weight ? 1 : -1));
    setRoutingPlan([]);
    setLoadingRoutingPlans(true);
    setLoadingBuyerData(true);
    refershSet(Math.random());
    handleClose();
  };

  function isTargetActivated(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return (
      <div>
        <i
          className="fa-sharp fa-solid fa-circle"
          style={{ color: activated }}
        />
      </div>
    );
  }

  const columnTargetData = [
    {
      Header: "#",
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-solid fa-check" />
          {/*<Switch id="email-alerts" onChange={addTarget} name="publisher" value={originalRow._id} />*/}
        </div>
      ),
      id: "EnableTarget",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
      accessor: getBuyerType,
    },
    {
      Header: "Destination",
      accessor: "phoneNumber",
    },
    {
      Header: "Status",
      accessor: isTargetActivated,
      id: "EnableTargets",
    },
    {
      Header: "Add",
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              addRouteModel(originalRow);
            }}
            title="Add Routing"
          >
            <i className="fa-solid fa-plus"></i>
          </a>
        </div>
      ),
      id: "addRoute",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoutePlan({
      ...routePlan, //spread operator
      [name]: value,
    });
  };

  const createRoutePlan = () => {
    routePlan.routing = routingPlanLists;
    const loadToast = toast.loading('Please Wait....');
    const { name, routing } = routePlan;
    if (name && routing.length > 0) {
      let config = {
        method: "post",
        url: apiUrl + "/api/routingplan/create",
        headers: {
          "Content-Type": "application/json",
        },
        data: routePlan,
      };
      axios
        .request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success("Routing Plan has been Created Successfully");
            history.push("/admin/callflows/routings");
          }
        })
        .catch((error) => {
          toast.error(error);
          toast.dismiss(loadToast);
        });
    } else {
      toast.error("You have Entered Invalid Input");
      toast.dismiss(loadToast);
    }
  };

  const deleteRouting = (index) => {
    routingPlanLists.splice(index, 1);
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(true);
    refershSet(Math.random());
  };



  return (
    <Flex
      className="custom_form_style"
      direction="column"
      minH="100vh"
      align="center"
      mt={{ sm: "120px", md: "75px" }}
    >
      <Tabs variant="unstyled" mt="24px">
        <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
          <TabPanel>
            <Card>
              <CardHeader mb="40px">
                <Text color={"#fff"} fontSize="lg" fontWeight="bold" mb="3px">
                  Create Routing Plan
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Name
                      </FormLabel>
                      <Input
                        type="textarea"
                        bg="#0F1535"
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        borderRadius="15px"
                        placeholder="Enter Campaign Name"
                        fontSize="xs"
                        name="name"
                        value={routePlan.name}
                        onChange={handleChange}
                      />
                    </FormControl>

                    {/*<FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                      Choose Targets
                    </FormLabel>
                    <button onClick={() => showTargetForm()} className="add_publisher_btn" style={{ color: '#fff', borderColor: ' #fff', width: 'max-content', marginBottom: '20px', border: '1px solid', padding: '5px 10px', borderRadius: '20px', fontSize: '12px', marginTop: '15px' }}> Add Target </button>
                    <div className={`add_publisher_block ${targetFormToggle}`}>
                      <div className="new_target_form">
                        <CardBody>
                          <Flex direction='column' w='100%'>
                            <Stack direction='column' spacing='20px'>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Name
                                </FormLabel>
                                <Input
                                  type='text'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter Publisher Name'
                                  fontSize='xs'
                                  name="name"
                                  value={buyer.name}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Email
                                </FormLabel>
                                <Input
                                  name="email"
                                  type='text'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter Buyer Email'
                                  fontSize='xs'
                                  value={buyer.email}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Password
                                </FormLabel>
                                <Input
                                  name="password"
                                  type='password'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter Buyer Password'
                                  fontSize='xs'
                                  value={buyer.password}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  SubID
                                </FormLabel>
                                <Input
                                  type='number'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter SubID'
                                  fontSize='xs'
                                  name="subid"
                                  value={buyer.subid}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Type
                                </FormLabel>
                                <Select
                                  onChange={handleAddTarget}
                                  name="phonetype"
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  bg='#0F1535'
                                  borderRadius='15px'
                                  color='gray.400'
                                  fontSize='sm'
                                  fontSize='xs'>
                                  <option value=''>Select Phone Type</option>
                                  <option value='1'>Number</option>
                                  <option value='2'>SIP</option>
                                </Select>
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Phone Number
                                </FormLabel>
                                <Input
                                  type='text'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter Target Phone'
                                  fontSize='xs'
                                  name="phoneNumber"
                                  value={buyer.phoneNumber}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Connection Timeout
                                </FormLabel>
                                <Input
                                  type='number'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter Connection timeout'
                                  fontSize='xs'
                                  name="connectiontimeout"
                                  value={buyer.connectiontimeout}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  CC CAP
                                </FormLabel>
                                <Input
                                  type='number'
                                  bg='#0F1535'
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  borderRadius='15px'
                                  placeholder='Enter CCCAP'
                                  fontSize='xs'
                                  name="cccap"
                                  value={buyer.cccap}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                                  Timezone
                                </FormLabel>
                                <Select
                                  onChange={handleAddTarget}
                                  value={buyer.timezone}
                                  name="timezone"
                                  border='.5px solid'
                                  borderColor='rgba(226, 232, 240, 0.3)'
                                  _hover='none'
                                  color='#fff'
                                  bg='#0F1535'
                                  borderRadius='15px'
                                  color='gray.400'
                                  fontSize='xs'>
                                  <option value=''>Select Time Zone</option>
                                  <option value='(UTC-05:00) Eastern Time(US & Canada))'>(UTC-05:00) Eastern Time(US & Canada))</option>
                                  <option value='UTC+10:30 — Lord Howe Island'>UTC+10:30 — Lord Howe Island</option>
                                </Select>
                              </FormControl>
                            </Stack>
                            <Flex justify='space-between'>
                              <Button
                                variant='brand'
                                alignSelf='flex-end'
                                mt='24px'
                                w='100px'
                                h='35px'
                                onClick={createNewbuyer}
                              >
                                <Text fontSize='10px' color='#fff'>
                                  Add
                                </Text>
                              </Button>
                              <Button
                                variant='brand'
                                alignSelf='flex-end'
                                mt='24px'
                                w='100px'
                                h='35px'
                                onClick={cancelTarget}
                              >
                                <Text fontSize='10px' color='#fff'>
                                  Cancel
                                </Text>
                              </Button>
                            </Flex>
                          </Flex>
                        </CardBody>
                      </div>
                    </div>*/}

                    <Flex className="routingTarget">
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Choose Targets
                        </FormLabel>
                        {loadingBuyerData ? (
                          <p>Loading Please wait...</p>
                        ) : (
                          <SearchTable1
                            tableData={buyers}
                            columnsData={columnTargetData}
                          />
                        )}
                      </FormControl>
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Routing Plan
                        </FormLabel>
                        <Card px="0px" style={{ marginTop: "10px" }}>
                          <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                            <Table className="targetTable" variant="simple">
                              <Thead>
                                <Tr>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Priority
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Weight
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Name
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Destination
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Type
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Revenue
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Status
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Action
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {routingPlanLists.length > 0 ? routingPlanLists.map((item, index) =>
                                  <Tr>
                                    <Td borderColor='#56577A'>
                                      <Stack direction='row' spacing='16px'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          <Input type="number" placeholder="1" value={item.priority} />
                                        </Text>
                                      </Stack>
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                        <Input type="number" placeholder="1" value={item.weight} />
                                      </Text>
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                        {item.name}
                                      </Text>
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                        {item.phone}
                                      </Text>
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                        {item.callType == "1" ? "Call Length" : "Dial"}
                                      </Text>
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                        ${item.revenue}
                                      </Text>
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      {item.status == "1" ? <i className='fa-sharp fa-solid fa-circle' style={{ color: '#22ca0c', fontSize: '12px' }} /> : <i className='fa-sharp fa-solid fa-circle' style={{ color: '#982E2B', fontSize: '12px' }} />}
                                    </Td>
                                    <Td borderColor='#56577A'>
                                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                        <i className="fa-solid fa-pen-to-square"></i>
                                        <a href="#" onClick={e => { e.preventDefault(); deleteRouting(index); }} title="Delete Routing"><i className="fa-solid fa-trash"></i></a>
                                      </Text>
                                    </Td>
                                  </Tr>
                                )
                                  : (<Tr>
                                    <Td colSpan="8"><h1 color='#fff' fontSize='sm' fontWeight='bold' style={{color:'#fff', fontSize:'12px'}}>There are No Routing Plans Added..</h1></Td>
                                  </Tr>
                                )}
                              </Tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      </FormControl>
                    </Flex>
                  </Stack>
                  <Flex justify="space-between">
                    <Button
                      variant="brand"
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={createRoutePlan}
                    >
                      <Text fontSize="10px" color="#fff">
                        Create
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal show={show} onHide={handleClose} className="routingPlanModel">
        <Modal.Header closeButton>
          <Modal.Title>Add Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="priority">Enter Priority</label>
            <input
              type="number"
              className="form-control"
              name="priority"
              value={routingPlan.priority}
              onChange={handleRoutingChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="weight">Enter Weight</label>
            <input
              type="number"
              className="form-control"
              name="weight"
              value={routingPlan.weight}
              onChange={handleRoutingChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Type</label>
            <select
              className="form-select"
              aria-label="Default select example"
              name="callType"
              onChange={handleRoutingChange}
            >
              <option value="0">Choose Option</option>
              <option value="1">Call Length</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Call Length(Sec)</label>
            <input
              type="number"
              className="form-control"
              name="callLength"
              placeholder="30"
              value={routingPlan.callLength}
              onChange={handleRoutingChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Revenue($)</label>
            <input
              type="number"
              className="form-control"
              name="revenue"
              placeholder="30"
              value={routingPlan.revenue}
              onChange={handleRoutingChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button1 variant="secondary" onClick={handleClose}>
            Close
          </Button1>
          <Button1 variant="primary" onClick={addRoutingPlan}>
            Create
          </Button1>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
}

export default NewUser;
