/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { FaArrowDown, FaArrowUp } from "react-icons/fa";

function TransactionRow(props) {
  const { name, date, logo, price, last4 } = props;
  
  return (
    <Flex mb='24px' justifyContent='space-between'>
      <Flex alignItems='center'>
        <Box
          me='14px'
          borderRadius='50%'
          color={
            logo === FaArrowUp
              ? "#01B574"
              : logo === FaArrowDown
              ? "red.500"
              : "gray.400"
          }
          border='1px solid'
          display='flex'
          alignItems='center'
          justifyContent='center'
          w='35px'
          h='35px'>
          <Icon as={logo} w='12px' h='12px' />
        </Box>
        <Flex direction='column'>
          <Text fontWeight='500' fontSize='md' color='#fff' mb='4px'>
            {name ?? '--'}
          </Text>
          <Text fontSize={{ sm: "xs", md: "sm" }} color='gray.400'>
            {date} | Paid by XXXX-{last4}
          </Text>
        </Flex>
      </Flex>
      <Box
        color={
          price[0] === "+"
            ? "#01B574"
            : price[0] === "-"
            ? "red.500"
            : "gray.400"
        }>
        <Text fontWeight='500' fontSize='lg'>
          ${price}
        </Text>
      </Box>
    </Flex>
  );
}

export default TransactionRow;
