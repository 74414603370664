/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import axios from "axios";

// Chakra imports
import { Flex, Text, Button, useDisclosure, } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";
import CTModal from "components/Modal";
import IVRTree from './callFlowCreateNew';
import IVRFlow from './createIvrFlow';
import { useHistory } from 'react-router-dom';
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import RoutingNav from "components/Navbars/Routing";
import Loading from 'components/Loading';

function DataTables() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const [callFlows, setCallFlows] = useState([]);
  const [ivrFlows, setIvrFlows] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState('');
  const [form, setForm] = useState('');
  const [ivrRow, setIvrRow] = useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleEdit = (originalRow) => {
    history.push('/admin/callflows/' + originalRow._id + '/edit');
  };

  function callFlowStatus(originalRow, rowIndex) {
    let StatusTxt = "LIVE";
    let StatusColor = "#3c993f";
    if (originalRow.status == 1) {
      StatusTxt = "LIVE";
      StatusColor = "#680b14";
    }
    return <div
      style={{ background: StatusColor, width: "fit-content", padding: "0 10px", borderRadius: 4, fontSize: "0.7rem", fontWeight: 600 }}>{StatusTxt}</div>
  }

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Status",
      accessor: callFlowStatus,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Version",
      accessor: "version",
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <div>
          <a href="#" onClick={e => { e.preventDefault(); handleEdit(originalRow); }} ><i className="fa-solid fa-pen-to-square"></i></a> | <a href="#" onClick={e => { e.preventDefault(); deleteCallFlow(originalRow); }} ><i className="fa-solid fa-trash"></i></a>
        </div>
      ),
      id: 'action',
    }
  ];

  const ivrData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: 'Action',
      accessor: (originalRow, rowIndex) => (
        <div>
          <a href="#" onClick={e => { e.preventDefault(); handleEditIvr(originalRow); }} ><i className="fa-solid fa-pen-to-square"></i></a> | <a href="#" onClick={e => { e.preventDefault(); deleteIvrFlow(originalRow); }} ><i className="fa-solid fa-trash"></i></a>
        </div>
      ),
      id: 'action',
    }
  ];

  const handleEditIvr = (originalRow) => {
    setForm("IVR");
    setIvrRow(originalRow);
    onOpen();
  }

  useEffect(() => {
    if (loadingData) {
      // getData();
      getIvr();
    }
  }, [refersh]);

  const addCallFlow = async (form) => {
    setIvrRow(null);
    setForm(form);
    onOpen();
  }

  const getData = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    let result = await fetch(apiUrl + "/api/ivrflow", requestOptions);
    result = await result.json();
    setCallFlows(result);
    setLoadingData(false);
    toast.dismiss(loadToast);
  }

  const getIvr = async () => {
    const requestOptions = {
      headers: { 'Content-Type': 'application/json' },
      params: { userId: user.id }
    };
    try {
      axios.get(apiUrl + "/api/ivrflow", requestOptions)
        .then(({ status, data}) => {
          if (data.success) {
            setIvrFlows(data.ivr);
          } else {
            console.log(data.error);
          }
        });
    } catch(error) {
      console.log(error.message);
    } finally {
      setLoadingData(false);
    }
  };

  const deleteCallFlow = async (originalRow) => {
    if (!confirm("Do You want to Delete This Routing Plan?")) {
      return false;
    }

    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: originalRow._id })
    };
    let result = await fetch(apiUrl + "/api/callflow/delete", requestOptions);
    result = await result.json();
    if (result.statusCode == 200) {
      setLoadingData(true);
      refershSet(Math.random());
      toast.success('Call Flow has been Deleted Successfully');
    } else {
      toast.success('Not Deleted due to error.');
    }
    toast.dismiss(loadToast);
  }

  const deleteIvrFlow = async (originalRow) => {
    if (!confirm("Do you want to delete this Ivr flow?")) {
      return false;
    }

    const loadToast = toast.loading('Loading...');
    await axios.delete(apiUrl + "/api/ivrflow", { params: { id: originalRow._id }})
      .then(({ status, data }) => {
        if (data.success) {
          setLoadingData(true);
          refershSet(Math.random());
          toast.success('Ivr successfully deleted.');
        } else {
          toast.error("Ivr delete unsuccessful.");
        }
      }).catch(error => {
        toast.error("Ivr delete unsuccessful.");
      });
    toast.dismiss(loadToast);
  };

  const refresh = () => {
    onClose();
    setIvrRow(null);
    getData();
    getIvr();
  };


  return (
    <Flex className='addpub' direction='column'>
      <Card>
        <CardBody>
          <RoutingNav current="/administrator/callflows/lists" />
          <Flex flexDirection={{base: "column", md: "row"}} gap={5}>
              <Button
                variant='outlineWhite'
                onClick={() => addCallFlow("IVR")}
              >
                <Text fontSize='16px' color='#fff'>
                  + Create IVR Flow
                </Text>
              </Button>
          </Flex>

          {/* {loadingData ? (
            <Loading />
          ) : (
            <SearchTable1 tableData={callFlows} columnsData={columnsData} />
          )} */}
          {callFlows.length > 0 &&
            <SearchTable1 tableData={callFlows} columnsData={columnsData} />
          }
          {callFlows.length === 0 &&
            <Text fontSize={'md'} color='#fff' mt={2}>No call flow available</Text>
          }

          <Text fontSize={'lg'} color='#fff' fontWeight={"bold"} mt={10}>
            IVR Flows
          </Text>
          {ivrFlows.length > 0 &&
            <SearchTable1 tableData={ivrFlows} columnsData={ivrData} />
          }
          {ivrFlows.length === 0 &&
            <Text fontSize={'md'} color='#fff' mt={2}>No ivr flow available</Text>
          }
        </CardBody>
      </Card>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={ivrRow ? "Edit" + ` ${form} Flow` : "Create" + ` ${form} Flow`}
      >
        {form === "Call" &&
          <IVRTree onClose={onClose}/>
        }
        {form === "IVR" &&
          <IVRFlow
            ivrFlow={ivrRow}
            onClose={refresh} 
          />
        }
      </CTModal>
    </Flex>
  );
}

export default DataTables;
