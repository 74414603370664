/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Radio,
  RadioGroup,
  IconButton
} from "@chakra-ui/react";
import { RepeatIcon } from '@chakra-ui/icons';

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useRef, useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import AccountNav from 'components/Navbars/Account';
const token = localStorage?.getItem("token");
const user = JSON.parse(localStorage?.getItem("user"));

function NewUser() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [selectedOption, setSelectedOption] = useState('YES');
  const [emailError, setEmailError] = useState('');
  const [client, setClient] = useState({
    name: "",
    email: "",
    password: "",
    phone: "",
    role: 1,
    by: user.id
  });

  const [twilio, setTwilio] = useState({
    businessName: ""
  });

  useEffect(() => {
    const newPassword = generatePassword(12); // Generate a 12-character password
    setClient((prevState) => ({
      ...prevState,
      password: newPassword,
    }));
  }, []);

  const handleChange = e => {
    const { name, value } = e.target;
    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(value)) {
        setEmailError('Invalid email format');
      } else {
        setEmailError(''); // Clear the error message if the email format is valid
      }
    }
    setClient({
      ...client,//spread operator 
      [name]: value
    });
  }

  const twilioHandleChange = e => {
    const { name, value } = e.target;
    setTwilio({
      ...twilio,//spread operator 
      [name]: value
    });
  }

  const generatePassword = (length) => {
    const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
    let password = '';
    for (let i = 0; i < length; i++) {
      const randomIndex = Math.floor(Math.random() * charset.length);
      password += charset[randomIndex];
    }
    return password;
  };

  const createClient = async () => {
    const loadToast = toast.loading('Loading...');
    const { name, email, password } = client;
    const { businessName } = twilio;
    if (name == "") {
      toast.error('Client Name Field is required!');
      return;
    } else if (password == "") {
      toast.error('Client Password Field is required!');
      return;
    } else if (email == "") {
      toast.error('Client Email Field is required!');
      return;
    } else if (emailError != "") {
      toast.error('Please enter a valid email address.');
      return;
    } else if (businessName == "") {
      toast.error('Client Business Name is required!');
      return;
    }

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ user: client, twilio, email: selectedOption }),
    };

    try {
      const response = await fetch(apiUrl + '/api/twilio/client/create', requestOptions);
      const result = await response.json();
      if (result.statusCode === 200) {
        toast.dismiss(loadToast);
        toast.success('Registered Successfully!');
        history.push('/administrator/accounts/new');
      } else if (result.statusCode === 409) {
        toast.dismiss(loadToast);
        toast.error('Email Already Exsists!');
      } else {
        toast.dismiss(loadToast);
        toast.error('Something Went Wrong!');
      }
    } catch (error) {
      console.error('Error creating client:', error);
      toast.dismiss(loadToast);
      toast.error('Error creating client. Please try again later.');
    }
  }
  return (
    <Flex>
        <Card
          w="100%"
          p='20px'
          alignSelf='flex-start'
          justifySelf='flex-start'>
          <CardBody style={{display: 'block'}}><AccountNav current='/administrator/accounts/new' userRole={user.role} /></CardBody>
          <CardBody>
            <Flex direction='column' w='100%'>
              <Stack direction='column' spacing='20px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Personal Information
                </Text>
                <FormControl>
                  <FormLabel variant="ct-label">
                    Client Name
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    value={client.name}
                    name="name"
                    type="text"
                    variant='borderless'
                    placeholder='Enter Client Name'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel variant="ct-label">
                    Client Email
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    value={client.email}
                    name="email"
                    type="email"
                    variant='borderless'
                    placeholder='Enter Client Email'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel variant="ct-label">
                    Client Password
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    value={client.password}
                    name="password"
                    type="password"
                    variant='borderless'
                    placeholder='Enter Client Password'
                  />
                </FormControl>
                <FormControl>
                  <FormLabel variant="ct-label">
                    Client Phone
                  </FormLabel>
                  <Input
                    onChange={handleChange}
                    value={client.phone}
                    name="phone"
                    type="text"
                    variant='borderless'
                    placeholder='+1XXXXXXXXXX'
                  />
                </FormControl>
              </Stack>

              <Stack direction='column' spacing='20px' style={{ marginTop: 50 }}>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Client Twilio Subaccount Information
                </Text>
                <FormControl>
                  <FormLabel variant="ct-label">
                    Enter Client Business Name
                  </FormLabel>
                  <Input
                    onChange={twilioHandleChange}
                    value={twilio.businessName}
                    name="businessName"
                    type="text"
                    variant='borderless'
                    placeholder='Enter Publisher Name'
                  />
                </FormControl>
              </Stack>

              <Stack direction='column' spacing='20px' style={{ marginTop: 50 }}>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Email Settings
                </Text>
                <FormControl>
                  <FormLabel variant="ct-label">
                    Do you want to send Login Details to Client Email?
                  </FormLabel>
                  <RadioGroup defaultValue={selectedOption} onChange={(value) => setSelectedOption(value)}>
                    <Stack direction="row" style={{ color: "#fff" }}>
                      <Radio value="YES">Yes</Radio>
                      <Radio value="NO">No</Radio>
                    </Stack>
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Flex justify='space-between'>
                <Button
                  mt={10}
                  onClick={createClient}
                  variant="formButton"
                >
                  Create
                </Button>
              </Flex>
            </Flex>
          </CardBody>
        </Card>
    </Flex>
  );
}

export default NewUser;
