/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

function NewNumber() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [refersh, refershSet] = React.useState('');
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [number, setNumber] = useState({
    name: "",
    phone: "",
    destination: "",
    timeout: "",
    by: userDetails.id
  })

  useEffect(() => {
    
  }, [refersh]);

  const buyTwilioNumber = async (e) => {
    if (!confirm("Do You want to Buy New Twilio Number?")) {
      return false;
    }
    const loadToast = toast.loading('Please Wait....');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: userDetails.id })
    };
    const response = await fetch(apiUrl + '/api/twilio/forwarding/phonenumber/buy', requestOptions);
    const result = await response.json();
    if (result.status == "success") {
      setNumber({
        ...number,//spread operator 
        phone: result.phoneNumber
      });
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      toast.error("Number Assign to Publisher is failed");
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setNumber({
      ...number,//spread operator 
      [name]: value
    })
  }

  const createNumber = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, phone, timeout, destination } = number;
    if ((name == "") || (phone == "") || (timeout == "") || (destination == "")) {
      toast.dismiss(loadToast);
      toast.error("Please fill the required filled..");
    } else {
      let config = {
        method: 'post',
        url: apiUrl + '/api/number/client/forwarding/number/create',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { number}
      };
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          toast.success(response.msg);
          history.push("/admin/direct-forward/number/lists");
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  return (
    <Flex
      className="buyerEditCustom"
      direction='column'
      minH='100vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel>
            <Card>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Create Number
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Name
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Tag/Name'
                        fontSize='xs'
                        name="name"
                        value={number.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Number
                      </FormLabel>
                      <Flex className="add_number">
                        <Input
                          type='text'
                          bg='#0F1535'
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          borderRadius='15px'
                          placeholder='Enter Twilio Number'
                          fontSize='xs'
                          name="phone"
                          value={number.phone}
                          onChange={handleChange}
                        />
                        <i className="fa-solid fa-plus" onClick={buyTwilioNumber}></i>
                      </Flex>
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Destination
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Destination Number'
                        fontSize='xs'
                        name="destination"
                        value={number.destination}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Timeout
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Ring call Timeout'
                        fontSize='xs'
                        name="timeout"
                        value={number.timeout}
                        onChange={handleChange}
                      />
                    </FormControl>
                  </Stack>
                  <Flex justify='space-between'>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w='100px'
                      h='35px'
                      onClick={createNumber}
                    >
                      <Text fontSize='10px' color='#fff'>
                        Setup Forwarding
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default NewNumber;
