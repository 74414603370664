/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Box,
  Input,
  Stack,
  Text,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  useDisclosure,
} from "@chakra-ui/react";

// Custom components
import React, { useEffect, useState } from "react";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import toast from "react-hot-toast";
import packageJson from "../../../appConfig.js";
import { commonColors } from 'variables/general';
import CTModal from "components/Modal";

const NewRoutingPlan = (props) => {
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const [routePlan, setRoutePlan] = useState({
    name: "",
    routing: [],
    by: user.id,
  });
  const [buyers, setBuyers] = useState([]);
  const [loadingBuyerData, setLoadingBuyerData] = useState(true);
  const [refresh, refreshSet] = React.useState("");
  const [show, setShow] = useState(false);
  const [planRow, setPlanRow] = useState(null);
  const handleClose = () => setShow(false);
  const [updateRouting, setUpdateRouting] = React.useState([]);
  const [edit, setEdit] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [allBuyers, setAllBuyers] = useState([]);
  const [routingPlan, setRoutingPlan] = useState({
    priority: "",
    weight: "",
    callType: 0,
    callLength: "",
    revenue: "",
  });
  
  const [routingPlanLists, setRoutingPlanLists] = useState([]);
  const [loadingRoutingPlans, setLoadingRoutingPlans] = useState(true);

  useEffect(() => {
    if (loadingBuyerData) {
      getBuyers();
    }
    if (loadingRoutingPlans) {
      getRoutingPlanLists();
    }
  }, [refresh]);

  useEffect(() => {
    if (props.plan) {
      setEdit(true);
      setRoutingPlanLists(props.plan.routing);
      setRoutePlan(props.plan);
    }
  }, []);

  useEffect(() => {
    if (show) {
      onOpen();
    } else {
      onClose();
    }
  }, [show])

  const getBuyers = async () => {
    const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "GET",
      headers: { "Content-Type": "application/json" },
    };
    let result = await axios.get(apiUrl + "/api/admin/list-buyers", requestOptions);
    setAllBuyers(result.data);
    const buyersFilterArray = props.plan ? props.plan.routing : routingPlanLists;
    result = result.data.filter(ar => !buyersFilterArray.find(rm => (rm.target === ar._id)));
    setBuyers(result);
    setLoadingBuyerData(false);
    toast.dismiss(loadToast);
  };

  const getRoutingPlanLists = async () => {
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(false);
  };

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>{type}</div>;
  }

  const addRouteModel = (originalRow) => {
    setUpdateRouting(originalRow);
    setShow(true);
  };

  const handleRoutingChange = (e) => {
    const { name, value } = e.target;
    setRoutingPlan({
      ...routingPlan,
      [name]: value,
    });
  };

  const addRoutingPlan = (e) => {
    let targetId = updateRouting._id;
    if (
      routingPlan.priority == "" ||
      routingPlan.weight == "" ||
      routingPlan.revenue == "" ||
      routingPlan.type == "" ||
      routingPlan.callLength == ""
    ) {
      toast.error("Please Fill All Required Fields");
      return false;
    }
    if (planRow !== null) {
      routingPlanLists.splice(planRow, 1);
    }
    routingPlanLists.push({
      target: targetId,
      name: updateRouting.name,
      phone: updateRouting.phoneNumber,
      priority: routingPlan.priority,
      weight: routingPlan.weight,
      callType: routingPlan.callType,
      callLength: routingPlan.callLength,
      revenue: routingPlan.revenue,
      status: updateRouting.status,
    });
    routingPlanLists.sort((a, b) => (a.weight < b.weight ? 1 : -1));
    setRoutingPlan([]);
    setLoadingRoutingPlans(true);
    setLoadingBuyerData(true);
    refreshSet(Math.random());
    handleClose();
    setPlanRow(null);
  };

  function isTargetActivated(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return (
      <div>
        <i
          className="fa-sharp fa-solid fa-circle"
          style={{ color: activated }}
        />
      </div>
    );
  }

  const columnTargetData = [
    {
      Header: "#",
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-solid fa-check" />
        </div>
      ),
      id: "EnableTarget",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: "type",
      accessor: getBuyerType,
    },
    {
      Header: "Destination",
      accessor: "phoneNumber",
    },
    {
      Header: "Status",
      accessor: isTargetActivated,
      id: "EnableTargets",
    },
    {
      Header: "Add",
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              addRouteModel(originalRow);
            }}
            title="Add Routing"
          >
            <i className="fa-solid fa-plus"></i>
          </a>
        </div>
      ),
      id: "addRoute",
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setRoutePlan({
      ...routePlan,
      [name]: value,
    });
  };

  const updateRoutePlan = () => {
    const updatedPlan = { routing: routingPlanLists };
    updatedPlan.name = routePlan.name;
    const loadToast = toast.loading('Please Wait....');
    if (updatedPlan.name && updatedPlan.routing.length > 0) {
      let config = {
        method: "post",
        url: apiUrl + "/api/routingplan/update",
        headers: {
          "Content-Type": "application/json",
        },
        data: { plan: updatedPlan, id: props.plan._id },
      };
      axios
        .request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success("Routing plan has been updated successfully");
            props.onClose();
          }
        })
        .catch((error) => {
          toast.error(error);
          toast.dismiss(loadToast);
        });
    } else {
      toast.error("You have Entered Invalid Input");
      toast.dismiss(loadToast);
    }
  };

  const createRoutePlan = () => {
    routePlan.routing = routingPlanLists;
    const loadToast = toast.loading('Please Wait....');
    const { name, routing } = routePlan;
    if (name && routing.length > 0) {
      let config = {
        method: "post",
        url: apiUrl + "/api/routingplan/create",
        headers: {
          "Content-Type": "application/json",
        },
        data: routePlan,
      };
      axios
        .request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success("Routing Plan has been Created Successfully");
            props.onClose();
          }
        })
        .catch((error) => {
          toast.error(error);
          toast.dismiss(loadToast);
        });
    } else {
      toast.error("You have Entered Invalid Input");
      toast.dismiss(loadToast);
    }
  };

  const deleteRouting = (index) => {
    if (confirm("Are you sure you want to delete this routing plan?")) {
      routingPlanLists.splice(index, 1);
      setRoutingPlanLists(routingPlanLists);
      setLoadingRoutingPlans(true);
      setLoadingBuyerData(true);
      refreshSet(Math.random());
    }
  };

  const editRouting = (index) => {
    setPlanRow(index);
    const currentPlan = routingPlanLists[index];
    setUpdateRouting(allBuyers.find(buyer => buyer._id === currentPlan.target))
    setRoutingPlan({
      priority: currentPlan.priority,
      weight: currentPlan.weight,
      callType: currentPlan.callType,
      callLength: currentPlan.callLength,
      revenue: currentPlan.revenue,
    })
    setShow(true);
  };

  return (
    <Flex flexDirection="column" w="100%" gap={5}>
      <FormControl>
        <FormLabel variant="ct-label" fontWeight="bold" color={"#fff"} fontSize="xs">
          Name
        </FormLabel>
        <Input
          type="text"
          variant="borderless"
          name="name"
          value={routePlan.name}
          onChange={handleChange}
        />
      </FormControl>
      <Flex flexDirection={'column'}>
        <FormControl>
          <FormLabel
            fontWeight="bold"
            color={"#fff"}
            fontSize="xs"
          >
            Choose Targets
          </FormLabel>
          {loadingBuyerData ? (
            <p>Loading Please wait...</p>
          ) : (
            <SearchTable1
              tableData={buyers}
              columnsData={columnTargetData}
            />
          )}
        </FormControl>
        <FormControl>
          <FormLabel variant="ct-label">
            Routing Plan
          </FormLabel>
          <Box overflowX="scroll">
            <Table variant="simple">
              <Thead>
                <Tr>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Priority
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Weight
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Name
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Destination
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Type
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Revenue
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Status
                  </Th>
                  <Th
                    color="gray.400"
                    fontSize="10px"
                    fontFamily="Inter"
                    borderColor="#56577A"
                  >
                    Action
                  </Th>
                </Tr>
              </Thead>
              <Tbody>
                {routingPlanLists.length > 0 ? routingPlanLists.map((item, index) =>
                  <Tr>
                    <Td borderColor='#56577A'>
                      <Stack direction='row' spacing='16px'>
                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                          <Input type="number" placeholder="1" value={item.priority} />
                        </Text>
                      </Stack>
                    </Td>
                    <Td borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        <Input type="number" placeholder="1" value={item.weight} />
                      </Text>
                    </Td>
                    <Td borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        {item.name}
                      </Text>
                    </Td>
                    <Td borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        {item.phone}
                      </Text>
                    </Td>
                    <Td borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        {item.callType == "1" ? "Call Length" : "Dial"}
                      </Text>
                    </Td>
                    <Td borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        ${item.revenue}
                      </Text>
                    </Td>
                    <Td borderColor='#56577A'>
                      {item.status == "1" ? <i className='fa-sharp fa-solid fa-circle' style={{ color: '#22ca0c', fontSize: '12px' }} /> : <i className='fa-sharp fa-solid fa-circle' style={{ color: '#982E2B', fontSize: '12px' }} />}
                    </Td>
                    <Td borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm' fontWeight='bold'>
                        <a href="#" onClick={e => { e.preventDefault(); editRouting(index); }} title="Edit Routing"><i className="fa-solid fa-pen-to-square"></i></a> |&nbsp;
                        <a href="#" onClick={e => { e.preventDefault(); deleteRouting(index); }} title="Delete Routing"><i className="fa-solid fa-trash"></i></a>
                      </Text>
                    </Td>
                  </Tr>
                )
                  : (<Tr>
                    <Td colSpan="8"><h1 color='#fff' fontSize='sm' fontWeight='bold' style={{color:'#fff', fontSize:'12px'}}>There are No Routing Plans Added..</h1></Td>
                  </Tr>
                )}
              </Tbody>
            </Table>
          </Box>
        </FormControl>
      </Flex>
      <Button
        variant="formButton"
        onClick={edit ? updateRoutePlan : createRoutePlan}
      >
        {edit ? "Update" : "Create"}
      </Button>
      <CTModal
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={planRow !== null ? "Edit Routing" : "Add Routing"}
        onCloseComplete={() => { setShow(false); setPlanRow(null); setRoutingPlan({}); }}
        isCentered={true}
      >
        <Flex flexDirection={"column"} bg={"#323b66"} p={"10px"} gap={5}>
          <Flex flexDirection={"row"} justifyContent={"center"} gap={2}>
            <FormLabel textAlign={"right"} alignSelf={"flex-end"} flexBasis={"50%"} variant="ct-label" htmlFor="priority">Enter Priority</FormLabel>
            <Input
              type="number"
              variant="borderless"
              flexBasis={"50%"}
              bg={commonColors.darkNavBg}
              name="priority"
              value={routingPlan.priority}
              onChange={handleRoutingChange}
            />
          </Flex>
          <Flex flexDirection={"row"} justifyContent={"center"} gap={2}>
            <FormLabel textAlign={"right"} alignSelf={"flex-end"} flexBasis={"50%"} variant="ct-label" htmlFor="weight">Enter Weight</FormLabel>
            <Input
              type="number"
              variant="borderless"
              bg={commonColors.darkNavBg}
              flexBasis={"50%"}
              name="weight"
              value={routingPlan.weight}
              onChange={handleRoutingChange}
            />
          </Flex>
          <Flex flexDirection={"row"} justifyContent={"center"} gap={2}>
            <FormLabel textAlign={"right"} alignSelf={"flex-end"} flexBasis={"50%"} variant="ct-label" htmlFor="revenue">Type</FormLabel>
            <Select
              className="form-select"
              variant="borderless"
              bg={commonColors.darkNavBg}
              flexBasis={"50%"}
              aria-label="Default select example"
              name="callType"
              value={routingPlan.callType}
              onChange={handleRoutingChange}
            >
              <option value="0">Choose Option</option>
              <option value="1">Call Length</option>
            </Select>
          </Flex>
          <Flex flexDirection={"row"} justifyContent={"center"} gap={2}>
            <FormLabel textAlign={"right"} alignSelf={"flex-end"} flexBasis={"50%"} variant="ct-label" htmlFor="revenue">Enter Call Length(Sec)</FormLabel>
            <Input
              type="number"
              variant="borderless"
              bg={commonColors.darkNavBg}
              flexBasis={"50%"}
              name="callLength"
              placeholder="30"
              value={routingPlan.callLength}
              onChange={handleRoutingChange}
            />
          </Flex>
          <Flex flexDirection={"row"} justifyContent={"center"} gap={2}>
            <FormLabel textAlign={"right"} alignSelf={"flex-end"} flexBasis={"50%"} variant="ct-label" htmlFor="revenue">Enter Revenue($)</FormLabel>
            <Input
              type="number"
              variant="borderless"
              bg={commonColors.darkNavBg}
              flexBasis={"50%"}
              name="revenue"
              placeholder="30"
              value={routingPlan.revenue}
              onChange={handleRoutingChange}
            />
          </Flex>
          <Flex flexDirection={"row"} justifyContent={"flex-end"} gap={3}>
            <Button
              variant="formButton"
              bg={"red.500"}
              _hover={"red"}
              onClick={() => {setShow(false); setPlanRow(null); setRoutingPlan({}); onClose()}} 
            >
              Cancel
            </Button>
            <Button
              variant="formButton"
              onClick={addRoutingPlan} 
            >
              {planRow !== null ? "Update" : "Add"}
            </Button>
          </Flex>
        </Flex>
      </CTModal>
    </Flex>
  );
};

export default NewRoutingPlan;
