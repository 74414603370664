/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

/*eslint-disable*/
import { HamburgerIcon } from "@chakra-ui/icons";
// chakra imports
import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  HStack,
  Icon,
  Link,
  List,
  ListItem,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  useStyleConfig
} from "@chakra-ui/react";
import {
  renderThumb,
  renderTrack,
  renderView,
} from "components/Scrollbar/Scrollbar";
import { SidebarContext } from "contexts/SidebarContext";
import PropTypes from "prop-types";
import React from "react";
import { Scrollbars } from "react-custom-scrollbars-2";
import { NavLink, useLocation } from "react-router-dom";
import logo from '../../icons/logo.png';
import { commonColors } from 'variables/general';

// FUNCTIONS

const mainWidth = 190;

function Sidebar(props) {
  // this is for the rest of the collapses
  const { sidebarWidth, setSidebarWidth, toggleSidebar } = React.useContext(
    SidebarContext
  );
  const user = JSON.parse(localStorage?.getItem("user"));
  const userRole = user?.role?.toString();

  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
    const hash = location.hash;
    const activeStyle = {
      backgroundColor: commonColors.darkBlue,
      borderRadius: 5,
    };

    routes = routes.filter(route => route?.showInMainNav !== false);

    return routes.map((route, index) => {
      if ('roles' in route && route.roles.indexOf(userRole) === -1) {
        return;
      }
      let isActive = hash == '#' + route.layout + route.path;
      if (!isActive && route.children?.length > 0) {
        isActive = route.children.some(child => hash == '#' + route.layout + child.path);
      }
      return (
        <ListItem key={index} width='100%' my='2px' style={isActive ? activeStyle : {}}>
          <NavLink to={route.layout + route.path} activeClassName="">
            <HStack p='3px' borderRadius='5px' w='100%'>
              <Text color='white' fontSize='md' padding='1'>
                {sidebarWidth === mainWidth ? route.name : route.name[0]}
              </Text>
            </HStack>
          </NavLink>
        </ListItem>
      );
    });
  };

  const { routes, sidebarVariant } = props;
  //let isWindows = navigator.platform.startsWith("Win");
  let links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let sidebarBg = "#2a2d5a";
  let sidebarRadius = "10px";
  let sidebarMargins = "0 10px";
  if (sidebarVariant === "opaque") {
    sidebarMargins = "16px 0px 16px 16px";
  }
  const [isBelow768px] = useMediaQuery("(max-width: 768px)");
  let brand = !isBelow768px ? (
    <Box mt='20px' ml='5px' mr='20px'>
      <Link
        href={`/#/`}
        display='flex'>
        <img src={logo} />
      </Link>
    </Box>
  ) : <Box h='96px' w='0'> </Box>;

  let sidebarContent = (
    <Flex
      justify='space-beetween'
      direction='column'>
      <Flex direction='column' w='100%'>
        <List mt='20px' mb='20px'>{links}</List>
      </Flex>
    </Flex>
  );

  // SIDEBAR
  return (
    <Box
      onMouseEnter={
        toggleSidebar
          ? () => setSidebarWidth(sidebarWidth === 120 ? mainWidth : 120)
          : null
      }
      onMouseLeave={
        toggleSidebar
          ? () => setSidebarWidth(sidebarWidth === mainWidth ? 120 : mainWidth)
          : null
      }
      className="primarySidebar"
      display={{base: "none", md: "block"}}
    >
      <Flex
        justify='space-beetween'
        direction='column'
        h='100%'
        w={sidebarWidth === mainWidth ? "220px" : "80px"}>
        <Flex direction='column'>
          <Box mb='20px'>{brand}</Box>
        </Flex>
      </Flex>

      <Box
        display={{ sm: "none", xl: "block" }}
        position='fixed'
        maxH='calc(100% - 100px)'>
        <Box
          bg={sidebarBg}
          w={`${sidebarWidth}px`}
          px='20px'
          m={sidebarMargins}
          borderRadius={sidebarRadius}>
            <Box id='sidebarScrollRemove' overflowY='scroll' height='calc(100vh - 150px)'>
              {sidebarContent}
            </Box>
        </Box>
      </Box>
    </Box>
  );
}

// FUNCTIONS

export function SidebarResponsive(props) {
  // to check for active links and opened collapses
  let location = useLocation();
  // this is for the rest of the collapses
  let variantChange = "0.2s linear";
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return location.pathname.includes(routeName);
  };
  // this function creates the links and collapses that appear in the sidebar (left menu)
  const createLinks = (routes) => {
     routes = routes.filter(route => route?.showInMainNav !== false);
    return routes.map((route, index) => {
        return (
          <NavLink to={(route.layout || '') + (route.path || '')} key={index}>
                <HStack spacing='22px' py='5px' px='10px'>
                  <Text
                    color='white'>
                    {route.name}
                  </Text>
                </HStack>
          </NavLink>
        );
    });
  };

  const { routes } = props;

  var links = <>{createLinks(routes)}</>;
  //  BRAND
  //  Chakra Color Mode
  let hamburgerColor = "white";
  var brand = (
    <Box pt={"35px"} mb='8px'>
      <Link
        href={`/#/`}
        target='_blank'
        display='flex'
        lineHeight='100%'
        mb='30px'
        fontWeight='700'
        justifyContent='center'
        alignItems='center'
        fontSize='11px'>
        <img src={logo} />
      </Link>
    </Box>
  );

  // SIDEBAR
  const { isOpen, onOpen, onClose } = useDisclosure();
  // Color variables
  return (
    <Box display={props.display}>
      <Box display={{ sm: "block", xl: "none" }}>
        <>
          <HamburgerIcon
            color={hamburgerColor}
            w='18px'
            h='18px'
            me={{base: '16px', lg: '8px', xl: '16px'}}
            mt='10px'
            cursor='pointer'
            onClick={onOpen}
          />
          <Drawer
            placement={"left"}
            isOpen={isOpen}
            onClose={onClose}>
            <DrawerOverlay />
            <DrawerContent
              backdropFilter='blur(10px)'
              bg='linear-gradient(111.84deg, rgba(6, 11, 38, 0.94) 59.3%, rgba(26, 31, 55, 0) 100%); '
              w='250px'
              maxW='250px'
              ms={{
                sm: "16px",
              }}
              my={{
                sm: "16px",
              }}
              borderRadius='16px'>
              <DrawerCloseButton
                _focus={{ boxShadow: "none" }}
                _hover={{ boxShadow: "none" }}
              />
              <DrawerBody maxW='250px' px='1rem'>
                <Box maxW='100%' h='100vh'>
                  <Box mb='20px'>{brand}</Box>
                  <Stack direction='column' mb='40px'>
                    <Box>{links}</Box>
                  </Stack>
                </Box>
              </DrawerBody>
            </DrawerContent>
          </Drawer>
        </>
      </Box>
    </Box>
  );
}
// PROPS

Sidebar.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
  variant: PropTypes.string,
};
SidebarResponsive.propTypes = {
  routes: PropTypes.arrayOf(PropTypes.object),
};

export default Sidebar;
