import React from 'react';
import * as XLSX from 'xlsx';
import {
  Button,
  Text,
  
} from "@chakra-ui/react";
const ExportToExcelButton = ({ columns, data, fileName, sheetName }) => {
  const exportToExcel = () => {
    // Extract column accessors
    const columnAccessors = columns.map((column) => column.accessor);

    // Convert table data to a format compatible with json_to_sheet function
    const formattedData = data.map((row) => {
      const formattedRow = {};
      columnAccessors.forEach((accessor) => {
        formattedRow[accessor] = row.values[accessor];
      });
      return formattedRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(formattedData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, sheetName);
    XLSX.writeFile(workbook, `${fileName}.xlsx`);
  };
  return (
    <Button
    variant="outlineWhite"
    alignSelf="flex-end"onClick={exportToExcel}>
    <Text fontSize="14px" color="#fff">
      Export to Excel</Text>
    </Button>
  );
};

export default ExportToExcelButton;
