/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useState } from "react";
import { Flex, Text, Button, Box, useDisclosure } from "@chakra-ui/react";
import MessageTable from "./MessageTable";
import VoiceMailTable from "./VoiceMailTable";
import ContextSwitcher from 'components/Navbars/ContextSwitcher';
import CTModal from "components/Modal";
import SearchFilter from "./SearchFilterForm";


const MessageList = () => {
  const [activeTab, setActiveTab] = useState(1); // Default to Text Messages
  const { isOpen, onOpen, onClose } = useDisclosure();

  const handleTabClick = (tab) => {
    setActiveTab(tab);
  };

  const contextSwitcher = (
    <ContextSwitcher
      options={[{ label: 'Text Messages' }, { label: 'Voicemail Messages' }]}
      activeTab={activeTab}
      onClick={handleTabClick}
    />);

  return (
    <Box>
      <Flex
        className="addpub"
        direction="column"
      >
        {activeTab === 1 ?<div  style={{
          transform: `translateX(${activeTab === 1 ? "0%" : "-100%"})`,
          transition: "transform 0.3s ease-in-out",
        }}><MessageTable switcher={contextSwitcher} openModal={onOpen}/></div>  : <div  style={{
          transform: `translateX(${activeTab === 1 ? "-100%" : "0%"})`,
          transition: "transform 0.3s ease-in-out",
        }}><VoiceMailTable switcher={contextSwitcher} openModal={onOpen}/></div> }
      </Flex>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={"Advanced Search Filters"}
      >
        {activeTab === 1 &&
          <SearchFilter />
        }
        {activeTab !== 1 &&
          <SearchFilter />
        }
			</CTModal>
    </Box>
  );
};

export default MessageList;
