/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import
// To be changed
// import Tables from "views/Dashboard/Tables.js";
import {
  CartIcon,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  StatsIcon,
} from "components/Icons/Icons";
import Calendar from "views/Applications/Calendar";
import DataTables from "views/Applications/DataTables";
import Kanban from "views/Applications/Kanban.js";
import Wizard from "views/Applications/Wizard.js";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import clientRegistration from "views/Authentication/SignUp/clientRegistration.js";
import clientTwilioDetails from "views/Authentication/SignUp/clientTwilioDetails.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import CRM from "views/Dashboard/CRM.js";
import Default from "views/Dashboard/Default.js";
import ReportDefault from "views/Pages/Reports/Default.js";
import OrderDetails from "views/Ecommerce/Orders/OrderDetails";
import OrderList from "views/Ecommerce/Orders/OrderList";
import EditProduct from "views/Ecommerce/Products/EditProduct";
import NewProduct from "views/Ecommerce/Products/NewProduct";
import ProductPage from "views/Ecommerce/Products/ProductPage";
import Billing from "views/Pages/Account/Billing.js";
import Invoice from "views/Pages/Account/Invoice.js";
import Settings from "views/Pages/Account/Settings.js";
import Alerts from "views/Pages/Alerts";
import Charts from "views/Pages/Charts.js";
import Pricing from "views/Pages/Pricing.js";
import Overview from "views/Pages/Profile/Overview.js";
import Projects from "views/Pages/Profile/Projects.js";
import Teams from "views/Pages/Profile/Teams.js";
import General from "views/Pages/Projects/General.js";
import Timeline from "views/Pages/Projects/Timeline.js";
import RTLPage from "views/Pages/RTLPage.js";
import NewUser from "views/Pages/Users/NewUser.js";
import NewPublisher from "views/Pages/Users/NewPublisher.js";
import EditPublisher from "views/Pages/Users/EditPublisher.js";
import NewBuyer from "views/Pages/Users/NewBuyer.js";
import EditByer from "views/Pages/Users/EditByer.js";
import Reports from "views/Pages/Users/Reports.js";
import Publishers from "views/Pages/Administrator/Publishers.js";
import Buyers from "views/Pages/Administrator/Buyers.js";
import Campaigns from "views/Pages/Administrator/Campaigns.js";
import NewCampaign from "views/Pages/Users/NewCampaign.js";
import EditCampaign from "views/Pages/Users/EditCampaign.js";
import Widgets from "views/Pages/Widgets.js";
import ForgetPassword from "views/Authentication/SignIn/ForgetPassword.js";
import ResetPassword from "views/Authentication/SignIn/ResetPassword.js";
import Numbers from "views/Numbers/Administrator/Numbers.js";
import CreateNumber from "views/Numbers/Admin/CreateNumber.js";
import DirectForwarding from "views/DirectForwarding/Admin/Numbers.js";
import SetupDirectForwarding from "views/DirectForwarding/Admin/CreateNumber.js";
import EditDirectForwarding from "views/DirectForwarding/Admin/EditNumber.js";
import EditNumber from "views/Numbers/Admin/EditNumber.js";
//import Integrations from "views/Pages/Users/EditIntegration.js";
import Integrations from "views/Pages/Users/TwilioIntegration.js";
import routingPlanLists from "views/RoutingPlans/Clients/routingPlanLists.js";
import routingPlanCreate from "views/RoutingPlans/Clients/routingPlanCreate.js";
import routingPlanEdit from "views/RoutingPlans/Clients/routingPlanEdit.js";
import callFlowLists from "views/CallFlows/Clients/callFlowLists.js";
//import callFlowCreate from "views/CallFlows/Clients/callFlowCreate.js";
import callFlowCreate from "views/CallFlows/Clients/callFlowCreateNew.js";
//import callFlowEdit from "views/CallFlows/Clients/callFlowEdit.js";
import livePublishers from "views/LiveCalls/Clients/publishers.js";
import liveBuyers from "views/LiveCalls/Clients/buyers.js";
import liveCampaigns from "views/LiveCalls/Clients/campaigns.js";
import NotifyMe from "views/Pages/Users/NotifyMe.js";
import { PhoneIcon } from "@chakra-ui/icons";
import GroupTarget from "views/Pages/Users/GroupTarget.js";
import EditGroupTarget from "views/Pages/Users/EditGroupTarget.js";
import ViewCampaign from "views/Pages/Users/ViewCampaign.js";
import MessageVoicemail from "views/MessageVoicemail/Admin/MessageList.js";


const dashRoutes = [
  {
    name: "Publishers",
    path: "/publishers",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Lists",
        path: "/publishers/users/lists",
        component: Publishers,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/publishers/users/create",
        component: NewPublisher,
        layout: "/admin",
      },
      {
        name: "Edit Publisher",
        path: "/publishers/users/:id/edit",
        component: EditPublisher,
        layout: "/admin",
      },
      {
        name: "Cover",
        component: SignUpCover,
        path: "/authentication/sign-up/cover",
        layout: "/auth",
      },
      {
        name: "Cover",
        component: clientRegistration,
        path: "/authentication/client/:id/register",
        layout: "/auth",
      },
      {
        name: "Cover",
        component: clientTwilioDetails,
        path: "/authentication/client/:id/twilio",
        layout: "/auth",
      },
      {
        name: "Setup Forwarding",
        path: "/direct-forward/number/create",
        component: SetupDirectForwarding,
        layout: "/admin",
      },
      {
        name: "Edit Forwarding",
        path: "/direct-forward/number/:id/edit",
        component: EditDirectForwarding,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/number/create",
        component: CreateNumber,
        layout: "/admin",
      },
      {
        name: "Edit Number",
        path: "/number/:id/edit",
        component: EditNumber,
        layout: "/admin",
      },
      {
        name: "Create Routing Plans",
        path: "/callflows/routing/create",
        component: routingPlanCreate,
        layout: "/admin",
      },
      {
        name: "Edit Routing Plans",
        path: "/callflows/routing/:id/edit",
        component: routingPlanEdit,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Buyers",
    path: "/buyers",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Lists",
        path: "/buyers/users/lists",
        component: Buyers,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/buyers/users/create",
        component: NewBuyer,
        layout: "/admin",
      },
      {
        name: "Add Group Buyer",
        path: "/buyers/users/group",
        component: GroupTarget,
        layout: "/admin",
      },
      {
        name: "Edit Group Buyer",
        path: "/buyers/users/edit-group/:id/edit",
        component: EditGroupTarget,
        layout: "/admin",
      },
      {
        name: "Edit Buyer",
        path: "/buyers/users/:id/edit",
        component: EditByer,
        layout: "/admin",
      },
      {
        name: "Cover",
        component: SignInCover,
        path: "/authentication/sign-in/cover",
        layout: "/auth",
      },
      {
        name: "Illustration",
        component: SignInIllustration,
        path: "/authentication/sign-in/illustration",
        layout: "/auth",
      },
      {
        name: "Forget Password",
        component: ForgetPassword,
        path: "/authentication/sign-in/forget-password",
        layout: "/auth",
      },
      {
        name: "Reset Password",
        component: ResetPassword,
        path: "/authentication/reset-password/:id/verify",
        layout: "/auth",
      }
    ],
  },
  {
    name: "Campaign",
    path: "/campaigns",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Lists",
        path: "/campaign/lists",
        component: Campaigns,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/campaign/create",
        component: NewCampaign,
        layout: "/admin",
      },
      {
        name: "Edit Campaign",
        path: "/campaign/:id/edit",
        component: EditCampaign,
        layout: "/admin",
      },
      {
        name: "View Campaign",
        path: "/campaign/:id/view",
        component: ViewCampaign,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Dashboard",
    path: "/dashboard/default",
    component: Default,
    layout: "/admin",
    icon: <HomeIcon color='inherit' />,
    authIcon: <HomeIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Main",
        path: "/dashboard/default",
        component: Default,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Live Calls",
    path: "/live-calls",
    icon: <PhoneIcon color='inherit' />,
    authIcon: <PhoneIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Publishers",
        path: "/live-calls/publishers",
        component: livePublishers,
        layout: "/admin",
      },
      {
        name: "Buyers",
        path: "/live-calls/buyers",
        component: liveBuyers,
        layout: "/admin",
      },
      {
        name: "Campaigns",
        path: "/live-calls/campaigns",
        component: liveCampaigns,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Report",
    path: "/reports",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Report",
        path: "/reports/default",
        component: ReportDefault,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Publishers",
    path: "/publishers/users/lists",
    component: Publishers,
    layout: "/admin",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/publishers/users/lists",
        component: Publishers,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/publishers/users/create",
        component: NewPublisher,
        layout: "/admin",
      },
      {
        name: "Edit Publisher",
        path: "/publishers/users/:id/edit",
        component: EditPublisher,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Buyers",
    path: "/buyers/users/lists",
    component: Buyers,
    layout: "/admin",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/buyers/users/lists",
        component: Buyers,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/buyers/users/create",
        component: NewBuyer,
        layout: "/admin",
      },
      {
        name: "Edit Buyer",
        path: "/buyers/users/:id/edit",
        component: EditByer,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Campaign",
    path: "/campaign/lists",
    component: Campaigns,
    layout: "/admin",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/campaign/lists",
        component: Campaigns,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/campaign/create",
        component: NewCampaign,
        layout: "/admin",
      },
      {
        name: "Edit Campaign",
        path: "/campaign/:id/edit",
        component: EditCampaign,
        layout: "/admin",
      }
    ],
  },
  {
    name: "CallFlows",
    path: "/callflows",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: true,
    items: [
      {
        name: "Routing Plans",
        path: "/callflows/routings",
        component: routingPlanLists,
        layout: "/admin",
      },
      {
        name: "ManageCallFlows",
        path: "/callflows/lists",
        component: callFlowLists,
        layout: "/admin",
      },
      {
        name: "CreateCallFlow",
        path: "/callflows/create",
        component: callFlowCreate,
        layout: "/admin",
      },
      {
        name: "ManageMedia",
        path: "/callflows/manage/media",
        component: ReportDefault,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Numbers",
    path: "/number/default",
    component: Numbers,
    layout: "/admin",
    icon: <HomeIcon color='inherit' />,
    authIcon: <HomeIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Manage Numbers",
        path: "/number/default",
        component: Numbers,
        layout: "/admin",
      },
      {
        name: "Add New",
        path: "/number/create",
        component: CreateNumber,
        layout: "/admin",
      },
      {
        name: "Edit Number",
        path: "/number/:id/edit",
        component: EditNumber,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Direct Forward",
    path: "/direct-forward/number/lists",
    component: DirectForwarding,
    layout: "/admin",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/direct-forward/number/lists",
        component: DirectForwarding,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Messages",
    path: "/messages-voicemails/lists",
    component: MessageVoicemail,
    layout: "/admin",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/messages-voicemails/lists",
        component: MessageVoicemail,
        layout: "/admin",
      }
    ],
  },
  {
    name: "NOTIFY ME",
    path: "/notify/me/settings",
    component: NotifyMe,
    layout: "/admin",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Settings",
        path: "/notify/me/settings",
        component: NotifyMe,
        layout: "/admin",
      }
    ],
  },
  {
    name: "Account Informations",
    category: "accounts",
    items: [
      {
        name: "Profile",
        path: "/accounts",
        collapse: true,
        icon: <DocumentIcon color='inherit' />,
        items: [
          {
            name: "Manage Profile",
            path: "/accounts/profile",
            component: Settings,
            layout: "/admin",
          },
          {
            name: "Settings",
            path: "/accounts/settings",
            component: Settings,
            layout: "/admin",
          },
        ],
      },
      /*{
        name: "Integrations",
        path: "/twilio",
        collapse: true,
        icon: <DocumentIcon color='inherit' />,
        items: [
          {
            name: "Manage Twilio",
            path: "/twilio/integration",
            component: Integrations,
            layout: "/admin",
          },
        ],
      },*/
      {
        name: "Billings",
        path: "/billing",
        collapse: true,
        icon: <DocumentIcon color='inherit' />,
        items: [
          {
            name: "Client Billings",
            path: "/billing/clients",
            component: Billing,
            layout: "/admin",
          },
        ],
      },
    ],
  },
  
];

export default dashRoutes;
