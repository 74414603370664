/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
const borderless = {
  field: {
    border: 'none',
    color: '#fff',
    bg: '#323b66',
    fontSize: 'sm',
    h: 7,
    borderRadius: 0,
  },
};

export const inputStyles = {
  components: {
    Input: {
      variants: {
        borderless: {
          field: {
            border: 'none',
            color: '#fff',
            bg: '#323b66',
            fontSize: 'sm',
            h: 7,
            borderRadius: 0,
          },
          element: {
            color: '#fff',
            bg: '#582cff',
            fontSize: 'sm',
            h: 7,
            borderRadius: 0,
            border: 'none',
            fontWeight: 'bold',
          },
          addon: {
            color: '#fff',
            bg: '#582cff',
            fontSize: 'sm',
            h: 7,
            borderRadius: 0,
            border: 'none',
            fontWeight: 'bold',
          }
        },
      },
    },
    Select: {
      variants: {
        borderless: borderless,
        multi: {
          field: {
            ...borderless.field,
            paddingInlineStart: 0,
            webkitPaddingStart: 0,
            paddingInlineEnd: 0,
            webkitPaddingEnd: 0,
          },
          icon: {
            color: '#323b66',
          }
        }
      },
    },
    FormLabel: {
      variants: {
        'ct-label': {
          color: '#fff',
          fontSize: 'xs',
          fontWeight: 'bold',
        },
      },
    },
    Checkbox: {
      variants: {
        'ct-checkbox': {
          label: {
            fontSize: 'xs',
            fontWeight: 'bold',
          },
          control: {
            w: 3,
            h: 3,
            bg: 'white',
            border: 'none',
          }
        },
      },
    },
    Textarea: {
      variants: {
        borderless: {
          border: 'none',
          color: '#fff',
          bg: '#323b66',
          fontSize: 'sm',
          borderRadius: 0,
        }
      },
    },
  },
};
  