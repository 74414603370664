/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';

function NewNumber(props) {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const [publishers, setPublishers] = useState([]);
  const [campaigns, setCampaigns] = useState([]);
  const [loadingPublisher, setloadingPublisher] = useState([]);
  const [loadingCampaign, setloadingCampaign] = useState([]);
  const [loadingNumber, setloadingNumber] = useState([]);
  const [refersh, refershSet] = React.useState('');
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [number, setNumber] = useState({
    _id: "",
    name: "",
    phone: "",
    campaign: "",
    publisher: "",
    offer: "",
    numberpool: ""
  })

  useEffect(() => {
    if (loadingNumber) {
      fetchNumber();
    }
  }, [refersh]);

  const fetchNumber = async () => {
    const loadToast = toast.loading('Loading...');
    let numberId = props.match.params.id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: numberId })
    };
    const response = await fetch(apiUrl + '/api/number/fetch', requestOptions);
    const data = await response.json();
    setNumber(data.data);
    setloadingNumber(false);

    if (loadingCampaign) {
      getCampaigns();
    }
    if (loadingPublisher) {
      getPublishers();
    }
    toast.dismiss(loadToast);
  }

  const getCampaigns = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: token, id: userDetails.id })
    };
    let result = await fetch(apiUrl + "/api/admin/list-campaign", requestOptions);
    result = await result.json();
    setCampaigns(result);
    setloadingCampaign(false);
    toast.dismiss(loadToast);
  }

  const getPublishers = async () => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ token: token, id: userDetails.id })
    };
    let result = await fetch(apiUrl + "/api/admin/publishers", requestOptions);
    result = await result.json();
    setPublishers(result);
    setloadingPublisher(false);
    toast.dismiss(loadToast);
  }

  const buyTwilioNumber = async (e) => {
    if (!confirm("Do You want to Buy New Twilio Number?")) {
      return false;
    }
    const loadToast = toast.loading('Please Wait....');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: userDetails.id })
    };
    const response = await fetch(apiUrl + '/api/twilio/phonenumber/buy', requestOptions);
    const result = await response.json();
    if (result.status == "success") {
      setNumber({
        ...number,//spread operator 
        phone: result.phoneNumber
      });
      toast.dismiss(loadToast);
    } else {
      toast.dismiss(loadToast);
      toast.error("Number Assign to Publisher is failed");
    }
  }

  const handleChange = e => {
    const { name, value } = e.target
    setNumber({
      ...number,//spread operator 
      [name]: value
    })
  }

  const updateNumber = () => {
    const loadToast = toast.loading('Please Wait....');
    const { name, phone, campaign, publisher, offer, numberpool } = number;
    if ((name == "") || (phone == "") || (campaign == "") || (publisher == "")) {
      toast.dismiss(loadToast);
      toast.error("Please fill the required filled..");
    } else {
      let config = {
        method: 'post',
        url: apiUrl + '/api/number/update',
        headers: {
          'Content-Type': 'application/json'
        },
        data: { number, token: token }
      };
      axios.request(config)
        .then((response) => {
          console.log(response);
          if(response.data.statusCode == 200) {
            toast.dismiss(loadToast);
            toast.success("Number has been updated Successfully");
            history.push("/admin/number/default");
          } else {
            toast.dismiss(loadToast);
            toast.error("Not updated due to error");
          }
        })
        .catch((error) => {
          toast.error(error);
        });
    }
  }
  return (
    <Flex
      direction='column'
      minH='100vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel>
            <Card>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Create Number
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Name
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Publisher Name'
                        fontSize='xs'
                        name="name"
                        value={number.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    {/* <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Number
                      </FormLabel>
                      <Flex className="add_number">
                        <Input
                          type='text'
                          bg='#0F1535'
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          borderRadius='15px'
                          placeholder='Enter Twilio Number'
                          fontSize='xs'
                          name="phone"
                          value={number.phone}
                          onChange={handleChange}
                        />
                        <i className="fa-solid fa-plus" onClick={buyTwilioNumber}></i>
                      </Flex>
                    </FormControl> */}
                    <Grid
                      templateColumns={{ sm: "2fr 2fr", lg: "2fr 2fr" }}
                      gap='100px'>
                      <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
                        <FormLabel
                          fontWeight='bold'
                          color={"#fff"}
                          fontSize='xs'>
                          Choose Campaign
                        </FormLabel>
                        <Select
                          onChange={handleChange}
                          name="campaign"
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          bg='#0F1535'
                          borderRadius='15px'
                          color='gray.400'
                          fontSize='sm'
                          fontSize='xs'>
                          <option value=''>Choose Campaign</option>
                          {campaigns.length > 0 ? campaigns.map((item, index) =>
                            <option selected={number.campaign == item._id ? true : false} value={item._id}>{item.name}</option>
                          )
                            : <option value=''>Not Found</option>
                          }
                        </Select>
                      </FormControl>
                      <FormControl gridColumn={{ sm: "1 / 2", lg: "auto" }}>
                        <FormLabel
                          fontWeight='bold'
                          color={"#fff"}
                          fontSize='xs'>
                          Choose Publisher
                        </FormLabel>
                        <Select
                          onChange={handleChange}
                          name="publisher"
                          border='.5px solid'
                          borderColor='rgba(226, 232, 240, 0.3)'
                          _hover='none'
                          color='#fff'
                          bg='#0F1535'
                          borderRadius='15px'
                          color='gray.400'
                          fontSize='sm'
                          fontSize='xs'>
                          <option value=''>Choose Publisher</option>
                          {publishers.length > 0 ? publishers.map((item, index) =>
                            <option selected={number.publisher == item._id ? true : false} value={item._id}>{item.name}</option>
                          )
                            : <option value=''>Not Found</option>
                          }
                        </Select>
                      </FormControl>
                    </Grid>
                    {/* <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Offer
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Offer Code'
                        fontSize='xs'
                        name="offer"
                        value={number.offer}
                        onChange={handleChange}
                      />
                    </FormControl> */}
                    {/* <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Number Pool
                      </FormLabel>
                      <Input
                        type='text'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Number Pool'
                        fontSize='xs'
                        name="numberpool"
                        value={number.numberpool}
                        onChange={handleChange}
                      />
                    </FormControl> */}
                  </Stack>
                  <Flex justify='space-between'>
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w='100px'
                      h='35px'
                      onClick={updateNumber}
                    >
                      <Text fontSize='10px' color='#fff'>
                        Update
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Flex>
  );
}

export default NewNumber;
