/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import toast, { Toaster } from 'react-hot-toast';
import { VSeparator, HSeparator } from "components/Separator/Separator";
import packageJson from '../../../appConfig.js';
import Button1 from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';

function NewUser(props) {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user"));
  const [routePlan, setRoutePlan] = useState({
    name: "",
    routing: []
  });
  const [payout, setPayout] = useState({
    callLength: "",
    concurrencyCap: "",
    dailyCap: "",
    dailyPayoutCap: "",
    duplicatePayout: "disable",
    payoutHoursEnabled: false,
    enableConCurrencyCap: false,
    enableDailyCap: false,
    enableDailyPayoutCap: false,
    enableGlobalCap: false,
    enableGlobalPayoutCap: false,
    enableHourlyCap: false,
    enableHourlyPayoutCap: false,
    enableMonthlyCap: false,
    enableMonthlyPayoutCap: false,
    enablePayoutHours: false,
    globalCap: "",
    globalPayoutCap: "",
    houlyCap: "",
    hourlyPayoutCap: "",
    limitPayoutEnabled: false,
    monthlyCap: "",
    monthlyPayoutCap: "",
    payoutAmount: "",
    payoutType: "",
    type: ""
  });

  const [payoutHours, setPayoutHours] = useState({
    startTime: "09:00",
    endTime: "18:00"
  });
  const [loadingRoutingPlans, setLoadingRoutingPlans] = useState(true);
  const [buyers, setBuyers] = useState([]);
  const [loadingCampaignData, setloadingCampaignData] = useState(true);
  const [loadingBuyerData, setLoadingBuyerData] = useState(true);
  const [refersh, refershSet] = React.useState('');
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [routingPlanLists, setRoutingPlanLists] = useState([]);
  const [selectedRoutes, setSelectedRoutes] = useState([]);
  const [updateRouting, setUpdateRouting] = React.useState([]);
  const [routingPlan, setRoutingPlan] = useState({
    priority: "",
    weight: "",
    callType: 0,
    callLength: "",
    revenue: ""
  });

  useEffect(() => {
    if (loadingCampaignData) {
      fetchCampaign();
    }
  }, [refersh]);

  const fetchCampaign = async () => {
    const loadToast = toast.loading('Loading...');
    let campaignId = props.match.params.id;
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: campaignId })
    };
    const response = await fetch(apiUrl + '/api/routingplan/fetch', requestOptions);
    const data = await response.json();
    let routings = [...data.routing, ...selectedRoutes];
    routings.sort((a, b) => (a.weight < b.weight) ? 1 : -1);
    setRoutePlan(data);
    setRoutingPlanLists(routings);
    setloadingCampaignData(false);
    if (loadingBuyerData) {
      getBuyers(routings);
    }
    toast.dismiss(loadToast);
  }

  const getBuyers = async (routings) => {
    const loadToast = toast.loading('Loading...');
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    let result = await fetch(apiUrl + "/api/admin/list-buyers", requestOptions);
    result = await result.json();
    result = result.filter(ar => !routings.find(rm => (rm.target === ar._id)));
    setBuyers(result);
    setLoadingBuyerData(false);
    toast.dismiss(loadToast);
  }

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>
      {type}
    </div>
  }

  function isTargetActivated(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return <div><i className="fa-sharp fa-solid fa-circle" style={{ color: activated }} /></div>
  }

  const columnTargetData = [
    /*{
      Header: '#',
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-solid fa-check" />
        </div>
      ),
      id: 'EnableTarget',
    },*/
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Type",
      accessor: getBuyerType,
    },
    {
      Header: "Destination",
      accessor: "phoneNumber",
    },
    {
      Header: 'Status',
      accessor: isTargetActivated,
      id: 'EnableTargets',
    },
    {
      Header: 'Add',
      accessor: (originalRow, rowIndex) => (
        <div>
          <a href="#" onClick={e => { e.preventDefault(); addRouteModel(originalRow); }} title="Add Routing"><i className="fa-solid fa-plus"></i></a>
        </div>
      ),
      id: 'addRoute',
    }
  ];

  const handleChange = e => {
    const { name, value } = e.target
    setRoutePlan({
      ...routePlan,//spread operator 
      [name]: value
    })
  }

  const addRouteModel = (originalRow) => {
    setUpdateRouting(originalRow);
    //console.log(originalRow);
    setShow(true);
  }

  const handleRoutingChange = e => {
    const { name, value } = e.target
    setRoutingPlan({
      ...routingPlan,//spread operator 
      [name]: value
    })
  }

  const addRoutingPlan = e => {
    let targetId = updateRouting._id;
    console.log(routingPlan.priority);
    if ((routingPlan.priority == "") || (routingPlan.weight == "") || (routingPlan.revenue == "") || (routingPlan.type == "") || (routingPlan.callLength == "")) {
      toast.error("Please Fill All Required Fields");
      return false;
    }
    selectedRoutes.push({
      target: targetId,
      name: updateRouting.name,
      phone: updateRouting.phoneNumber,
      priority: routingPlan.priority,
      weight: routingPlan.weight,
      callType: routingPlan.callType,
      callLength: routingPlan.callLength,
      revenue: routingPlan.revenue
    });
    setRoutingPlan([]);
    setLoadingRoutingPlans(true);
    setloadingCampaignData(true);
    setLoadingBuyerData(true);
    refershSet(Math.random())
    handleClose();
  }

  const updateCampaign = () => {
    routePlan.routing = routingPlanLists;
    const loadToast = toast.loading('Please Wait....');
    const { name, routing } = routePlan;
    if (name && routing.length > 0) {
      let config = {
        method: 'post',
        url: apiUrl + '/api/routingplan/update',
        headers: {
          'Content-Type': 'application/json'
        },
        data: routePlan
      };
      axios.request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success('Campaign has been Updated successfully');
            history.push("/admin/callflows/routings");
          }
        })
        .catch((error) => {
          toast.error(error);
          toast.dismiss(loadToast);
        });
    }
    else {
      toast.error("You have Entered Invalid Input");
      toast.dismiss(loadToast);
    };
  }

  const deleteRouting = (index) => {
    routingPlanLists.splice(index, 1);
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(true);
    refershSet(Math.random())
  }

  return (
    <Flex
      className="custom_form_style"
      direction='column'
      minH='100vh'
      align='center'
      mt={{ sm: "120px", md: "75px" }}>
      <Tabs variant='unstyled' mt='24px'>
        <TabPanels mt='24px' maxW={{ md: "90%", lg: "100%" }} mx='auto'>
          <TabPanel>
            <Card>
              <CardHeader mb='40px'>
                <Text color={"#fff"} fontSize='lg' fontWeight='bold' mb='3px'>
                  Update Routing Plan
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction='column' w='100%'>
                  <Stack direction='column' spacing='20px'>
                    <FormControl>
                      <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                        Name
                      </FormLabel>
                      <Input
                        type='textarea'
                        bg='#0F1535'
                        border='.5px solid'
                        borderColor='rgba(226, 232, 240, 0.3)'
                        _hover='none'
                        color='#fff'
                        borderRadius='15px'
                        placeholder='Enter Publisher Name'
                        fontSize='xs'
                        name="name"
                        value={routePlan.name}
                        onChange={handleChange}
                      />
                    </FormControl>
                    <VSeparator className="verticleSeparator" />


                    <FormControl>
                      <Flex className="routingTarget">
                        <FormControl>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Choose Targets
                          </FormLabel>
                          {loadingBuyerData ? (
                            <p>Loading Please wait...</p>
                          ) : (
                            <SearchTable1 tableData={buyers} columnsData={columnTargetData} />
                          )}
                        </FormControl>
                        <FormControl>
                          <FormLabel fontWeight='bold' color={"#fff"} fontSize='xs'>
                            Routing Plan
                          </FormLabel>
                          <Card px='0px' style={{ marginTop: '10px' }}>
                            <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                              <Table className="targetTable" variant='simple'>
                                <Thead>
                                  <Tr>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Priority
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Weight
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Name
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Destination
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Type
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Revenue
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Status
                                    </Th>
                                    <Th
                                      color='gray.400'
                                      fontSize='10px'
                                      fontFamily='Inter'
                                      borderColor='#56577A'>
                                      Action
                                    </Th>
                                  </Tr>
                                </Thead>
                                <Tbody>
                                  {routingPlanLists.length > 0 ? routingPlanLists.map((item, index) =>
                                    <Tr>
                                      <Td borderColor='#56577A'>
                                        <Stack direction='row' spacing='16px'>
                                          <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                            <Input type="number" placeholder="1" value={item.priority} />
                                          </Text>
                                        </Stack>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          <Input type="number" placeholder="1" value={item.weight} />
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          {item.name}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          {item.phone}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          {item.callType == "1" ? "Call Length" : "Dial"}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          ${item.revenue}
                                        </Text>
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <i className='fa-sharp fa-solid fa-circle' style={{ color: '#22ca0c', fontSize: '12px' }} />
                                      </Td>
                                      <Td borderColor='#56577A'>
                                        <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                          <i className="fa-solid fa-pen-to-square"></i>
                                          <a href="#" onClick={e => { e.preventDefault(); deleteRouting(index); }} title="Delete Routing"><i className="fa-solid fa-trash"></i></a>
                                        </Text>
                                      </Td>
                                    </Tr>
                                  )
                                    : <Tr>
                                      <Td borderColor='#56577A' colSpan={8}>
                                        <Stack direction='row' spacing='16px'>
                                          <Text color='#fff' fontSize='sm' fontWeight='bold'>
                                            There are No Routing Plans Added..
                                          </Text>
                                        </Stack>
                                      </Td>
                                    </Tr>
                                  }
                                </Tbody>
                              </Table>
                            </CardBody>
                          </Card>
                        </FormControl>
                      </Flex>
                    </FormControl>
                  </Stack>
                  <Flex justify='space-between' className="updateCamp">
                    <Button
                      variant='brand'
                      alignSelf='flex-end'
                      mt='24px'
                      w='100px'
                      h='35px'
                      onClick={updateCampaign}
                    >
                      <Text fontSize='10px' color='#fff'>
                        Update
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal show={show} onHide={handleClose} className="routingPlanModel">
        <Modal.Header closeButton>
          <Modal.Title>Add Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="priority">Enter Priority</label>
            <input type="number" className="form-control" name="priority" value={routingPlan.priority} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="weight">Enter Weight</label>
            <input type="number" className="form-control" name="weight" value={routingPlan.weight} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Type</label>
            <select className="form-select" aria-label="Default select example" name="callType" onChange={handleRoutingChange}>
              <option value="0">Choose Option</option>
              <option value="1">Call Length</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Call Length(Sec)</label>
            <input type="number" className="form-control" name="callLength" placeholder="30" value={routingPlan.callLength} onChange={handleRoutingChange} />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Revenue($)</label>
            <input type="number" className="form-control" name="revenue" placeholder="30" value={routingPlan.revenue} onChange={handleRoutingChange} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button1 variant="secondary" onClick={handleClose}>
            Close
          </Button1>
          <Button1 variant="primary" onClick={addRoutingPlan}>
            Create
          </Button1>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
}

export default NewUser;
