import ContextSwitcher from './ContextSwitcher';

const LiveCalls = ({ current }) => {
  const menu = [
    {
      label: 'Publishers',
      href: '/administrator/live-calls/publishers',
    },
    {
      label: 'Buyers',
      href: '/administrator/live-calls/buyers',
    },
    {
      label: 'Campaigns',
      href: '/administrator/live-calls/campaigns',
    },
  ];

  const activeTab = (menu.findIndex(x => x.href == current) || 0) + 1;

  return (
    <ContextSwitcher
      options={menu}
      activeTab={activeTab}
    />
  );
};

export default LiveCalls;
