import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  Stack,
  Select,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  useDisclosure,
  Box,
  FormControl,
  FormLabel,
  Input,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import toast from "react-hot-toast";
import axios from "axios";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useState } from "react";
import { useEffect } from "react";
import SearchTable1 from 'components/Tables/SearchTable1.js'
import Loading from "components/Loading.js";

const VoiceMailTable = (props) => {
  const [voicemails, setVoicemails] = useState([]);
  const [loadingData, setLoadingData] = useState([]);

  useEffect(() => {
    getVoicemails();
  }, []);

  const getVoicemails = async () => {
    setLoadingData(true)
    const loadToast = toast.loading("Loading...");
    await axios.get("api/voicemails")
      .then(({ status, data}) => {
        if (data.success) {
          setVoicemails(data.voicemails);
          toast.dismiss(loadToast);
        } else {
          toast.dismiss(loadToast);
          toast.error(data.error)
        }
      }).catch(error => {
        toast.dismiss(loadToast);
        console.log(error.message);
      });
    setLoadingData(false)
  };

  const voicemailColumns = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Creation Date",
      accessor: row => row.dateCreated.split("T")[0],
    },
    {
      Header: "Phone Number",
      accessor: "to",
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "Recording",
      accessor: row => {
        return (
          <audio src={row.mediaUrl} controls/>
        );
      }
    },
    {
      Header: "Duration",
      accessor: row => row.duration + " sec",
    }
  ];

  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedText, setSelectedText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const user = JSON.parse(localStorage?.getItem("user"));
  const allItems = ["+1 2406508761", "+1 2406508762", "+1 2406508763", "+1 24065087654","+1 24065088767","+1 2406508717"];
  const totalRows = voicemails.length;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = voicemails.slice(startIndex, endIndex);
  const [showFilter, setShowFilter] = useState(false);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const switcher = props.switcher;

  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when rowsPerPage changes
  }, [rowsPerPage]);

  const handleRowClick = (from) => {
    setSelectedText(from);
    onOpen();
  };

  // checkbox
  const handleCheckboxChange = (checkedItems) => {
    setSelectedItems(checkedItems);
  };

  const handleSelectAll = () => {
    setSelectedItems(allItems);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const isAllSelected = selectedItems.length === allItems.length;
  return (
    <Card>
      <CardBody>{switcher}</CardBody>
      <CardHeader px="22px" mb="2px">
        <Flex direction="column">
          <Text fontSize="lg" mb="5px" color="#fff">
            Voicemail Messages
          </Text>
        </Flex>
      </CardHeader>

      <CardBody>
        {/* <Flex
          justify="space-between"
          align="center"
          w="100%"
          wrap="wrap"
        >
          <Stack direction="row" spacing="12px" align="center" my="30px">
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              size="sm"
              maxW="75px"
              cursor="pointer"
            >
              {[5, 10, 15, 20, 25].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
            <Text fontSize="xs" color="gray.400" fontWeight="500" width="200px">
              entries per page
            </Text>
          </Stack>
          <Button
            variant="outlineWhite"
            w="auto"
            h="35px"
            onClick={props.openModal}
          >
            <Text fontSize="10px" color="#fff">
              Advanced Search Filters
            </Text>
          </Button>
        </Flex> */}

        {showFilter && (
          <div
            className="new_target_form"
            style={{
              margin: " 0px 22px 40px 22px",
              boxShadow: "0px 0px 3px #fff",
            }}
          >
            <Box>
              <Text fontSize="xl" color="#fff">
                Advanced Search Filters
              </Text>
              <Text fontSize="sm" color="#fff">
                Filter your search results by selecting numbers of your choice
                in a specific date range
              </Text>
            </Box>
            <Stack direction="column" spacing="20px">
              <FormControl mt="22px">
                <FormLabel fontWeight="bold" fontSize="xs" color="#fff">
                  Number
                </FormLabel>
                <Flex align="center">
                  <Input
                    name="Number"
                    type="text"
                    bg="#0F1535"
                    border=".5px solid"
                    borderColor="rgba(226, 232, 240, 0.3)"
                    _hover="none"
                    color="#fff"
                    borderRadius="15px"
                    fontSize="xs"
                    placeholder="search"
                  />
                </Flex>
              </FormControl>
              <FormControl mt="22px">
                <Flex align="center"></Flex>
                <FormLabel fontWeight="bold" fontSize="xs" color="#fff">
                  Date range
                </FormLabel>
                <Flex justify="space-between" align="center" gap="2">
                  <Input
                    name="startDate"
                    type="date"
                    placeholder="start date"
                    bg="#0F1535"
                    border=".5px solid"
                    borderColor="rgba(226, 232, 240, 0.3)"
                    _hover="none"
                    color="#fff"
                    borderRadius="15px"
                    fontSize="xs"
                  />
                  <Input
                    name="endDate"
                    type="date"
                    bg="#0F1535"
                    border=".5px solid"
                    borderColor="rgba(226, 232, 240, 0.3)"
                    _hover="none"
                    color="#fff"
                    borderRadius="15px"
                    fontSize="xs"
                    placeholder="end date"
                  />
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bold" fontSize="xs" color="#fff">
                  Messages Direction
                </FormLabel>
                <Select
                  name=" Messages directions"
                  border=".5px solid"
                  borderColor="rgba(226, 232, 240, 0.3)"
                  _hover="none"
                  bg="#0F1535"
                  borderRadius="15px"
                  color="gray.400"
                  fontSize="sm"
                >
                  <option value="Conversion">Show All Direction</option>
                  <option value="Conversion">Inbound Only</option>
                  <option value="Conversion">Outbound Only</option>
                </Select>
              </FormControl>
              <FormControl mt="22px" spacing={4}>
                <Checkbox
                  isChecked={isAllSelected}
                  onChange={(e) =>
                    e.target.checked ? handleSelectAll() : handleDeselectAll()
                  }
                  borderColor="rgba(226, 232, 240, 0.3)"
                  color="#fff"
                >
                  Select All / Deselect All
                </Checkbox>
                <Flex gap="2" mt="15px" flexWrap="wrap" mx="10px">
                  <CheckboxGroup
                    value={selectedItems}
                    onChange={handleCheckboxChange}
                    borderColor="rgba(226, 232, 240, 0.3)"
                    color="#fff"
                  >
                    {allItems.map((item, index) => (
                      <Checkbox
                        borderColor="rgba(226, 232, 240, 0.3)"
                        key={index}
                        color="#fff"
                        value={item}
                      >
                        <Text>{item}</Text>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Flex>
              </FormControl>
            </Stack>
            <Flex wrap="wrap" align="center" justify="center" gap="2" mt="15px">
              <Button variant="brand" onClick={() => setShowFilter(false)}>
                Close
              </Button>
              <Button variant="brand">Search</Button>
              <Button variant="brand">Export</Button>
            </Flex>
          </div>
        )}

        {!loadingData &&
          <SearchTable1 tableData={currentRows} columnsData={voicemailColumns} />
        }
        {loadingData &&
          <Loading />
        }
      </CardBody>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent textAlign="center" style={{ top: "20%" }}>
          <ModalHeader>Voicemails Message</ModalHeader>
          <ModalBody
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Text>
              Call from this
              {selectedText}
            </Text>
            <audio controls>
              <source src="horse.ogg" type="audio/ogg" />
              <source src="horse.mp3" type="audio/mpeg" />
              Your browser does not support the audio element.
            </audio>
            <a href="horse.mp3" download style={{ marginTop: "10px" }}>
              Download Audio
            </a>
          </ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} width="100%" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default VoiceMailTable;
