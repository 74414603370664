/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import axios from 'axios';

// Chakra imports
import {
  Box,
  Flex,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import LineChart from "components/Charts/LineChart";
import Loading from "components/Loading";
import packageJson from '../../appConfig.js';
import {
  lineChartOptionsDashboard,
} from "variables/charts";

const user = JSON.parse(localStorage?.getItem("user"));

export default function Default() {
  const apiUrl = packageJson.apiurl;
  const [statics, setStatics] = useState([]);
  const [chartData, setChartData] = useState(false);
  /*
  const [publishers, setPublishers] = useState([]);
  const [targets, setTargets] = useState([]);
  const [campaign, setCampaign] = useState([]);
  */

  useEffect(() => {
    getStaticsDetails();
    /*
    getTopPublisherDetails();
    getTopTargetDetails();
    getTopCampaigntDetails();
    */
  }, []);

  const getStaticsDetails = async () => {
    try {
      const response = await axios.get(apiUrl + "/api/report/dashboard/statics");
      const result = response.data;

      setChartData([{ name: "Call Counts", data: result.chart }]);

      const today = (new Date()).toISOString().slice(0, 10);
      const resp2 = await axios.post(apiUrl + '/api/report/date/details', { startDate: today, endDate: today });

      let result2 = resp2.data;
      if (result2.length === 0) {
        result2 = [{
          revenue: 0,
          payout: 0,
          incoming: 0,
          converted: 0,
          noConnect: 0,
          acl: '00:00:00',
        }];
      }
      setStatics({ callList: result.callList, info: result2[0] });
    } catch (error) {
      console.error('Failed to fetch statics details', error);
    }
  };

/*
  const getTopPublisherDetails = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    let result = await fetch(apiUrl + "/api/admin/dashboard/top/publishers", requestOptions);
    result = await result.json();
    setPublishers(result);
  }

  const getTopTargetDetails = async () => {
    let result = await fetch(apiUrl + "/api/report/top/targets");
    result = await result.json();
    setTargets(result);
  }

  const getTopCampaigntDetails = async () => {
    let result = await fetch(apiUrl + "/api/report/top/campaigns");
    result = await result.json();
    setCampaign(result);
  }
*/

  return (
    <Flex flexDirection='column' >
      <Card>
        <CardHeader>
          <Text fontSize='lg' color='#fff' mb='6px' ml='10px'>
            Today's Performance Summary
          </Text>
        </CardHeader>
        <Box w='100%'>
          {statics.info ?
            <Flex
              id="dashStats"
              my="10px"
              direction={['column', 'row']}
              wrap={['nowrap', 'wrap']}
              gap="4"
            >
              <div className="dashStat">
                <label>Revenue:</label>
                <span className="statValue">${statics.info.revenue}</span>
              </div>
              <div className="dashStat">
                <label>Payout:</label>
                <span className="statValue">${statics.info.payout || 0}</span>
              </div>
              <div className="dashStat">
                <label>Incoming:</label>
                <span className="statValue">{statics.info.incoming}</span>
              </div>
              <div className="dashStat">
                <label>Converted:</label>
                <span className="statValue">{statics.info.converted}</span>
              </div>
              <div className="dashStat">
                <label>No Answer:</label>
                <span className="statValue">{statics.info.noConnect}</span>
              </div>
              <div className="dashStat" title="Average Call Length">
                <label>ACL:</label>
                <span className="statValue">{statics.info.acl}</span>
              </div>
            </Flex>
          : <></>}
        </Box>
        <Box w='100%' minH={{ sm: "300px" }}>
          {chartData ? <LineChart
            chartData={chartData}
            chartOptions={lineChartOptionsDashboard}
          /> : <Loading />}
        </Box>
        <Box w='100%' mt={"25px"} overflowX={{ base: "scroll", lg: "hidden" }}>
          <Table variant='simple' className="report_custom_table" mb='20px'>
            <Thead>
              <Tr>
                <Th>
                  Date
                </Th>
                <Th>
                  Missed Calls
                </Th>
                <Th>
                  Incoming
                </Th>
                <Th>
                  Connected
                </Th>
                <Th>
                  Converted
                </Th>
                <Th>
                  Total Call Time
                </Th>
                <Th>
                  Revenue
                </Th>
                <Th>
                  Payout
                </Th>
              </Tr>
            </Thead>
            <Tbody>
              {statics.callList?.length > 0 ? statics.callList?.map((item, index) =>
                <Tr key={index}>
                  <Td minW='100px' borderColor='#56577A'>
                      <Text color='#fff' fontSize='sm'>
                        {item.date}
                      </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      {item.impresssion}
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      {item.incoming}
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      {item.connected}
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      {item.converted}
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      {item.anylengthCall}
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      ${item.revenue}
                    </Text>
                  </Td>
                  <Td borderColor='#56577A'>
                    <Text color='#fff' fontSize='sm' fontWeight='bold'>
                      ${item.payout || 0}
                    </Text>
                  </Td>
                </Tr>
              )
                : <Tr><Td borderBottom="none"><Text fontSize="xl" color="white">No Result Found</Text></Td></Tr>
              }
            </Tbody>
          </Table>
        </Box>
      </Card>
    </Flex>
  );
}
