/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";
import axios from 'axios';

// Chakra imports
import { Flex, Text } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import SearchTable1 from "components/Tables/SearchTable1";
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import LiveCallsNav from 'components/Navbars/LiveCalls';
import Loading from 'components/Loading';

function LiveCallsCampaign() {
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const [campaigns, setCampaigns] = useState([]);
  const [loadingData, setLoadingData] = useState(true);

  useEffect(() => {
    if (loadingData) {
      getData();
    }
    let intervalid = setInterval(() => {
      setLoadingData(true);
      if (loadingData) {
        getData();
      }
    }, 5000);

    return function cleanup() {
      clearInterval(intervalid);
    };
  }, []);

  const getData = async () => {
    const loadToast = toast.loading('Fetching Live Calls ....');
    try {
      const result = await axios.post(apiUrl + "/api/twilio/live/call/campaign", { id: user.id });
      const data = result.data;
      if (data.status === "success") {
        setCampaigns(data.data);
      } else {
        setCampaigns([]);
      }

      setLoadingData(false);
      toast.dismiss(loadToast);
    } catch (error) {
      console.error("Error fetching data:", error);
      setCampaigns([]);
      setLoadingData(false);
      toast.dismiss(loadToast);
      toast.error('Error fetching data. Please try again later.');
    }
  }

  function callStatus(originalRow, rowIndex) {
    let StatusTxt = "LIVE";
    let StatusColor = "#3c993f";
    return <div
      style={{ background: StatusColor, width: "fit-content", padding: "0 10px", borderRadius: 4, margin: "0 auto", fontWeight: 600 }}>{StatusTxt}</div>
  }

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Status",
      accessor: callStatus,
      disableSortBy: true,
    },
    {
      Header: "Campaign",
      accessor: "campaignName",
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "To",
      accessor: "to",
    },
    {
      Header: "Through",
      accessor: "through",
    }
  ];

  return (
    <Flex className='addpub' direction='column'>
      <Card>
        <CardBody>
          <LiveCallsNav current='/administrator/live-calls/campaigns' />
        </CardBody>
        <CardHeader px='22px' mb='2px'>
          <Flex direction='column'>
            <Text fontSize='lg' mb='5px' color='#fff'>
              Campaign Live Call Details
            </Text>
          </Flex>
        </CardHeader>

        <CardBody>
          {loadingData ? (
            <Loading />
          ) : (
            <SearchTable1 tableData={campaigns} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default LiveCallsCampaign;
