/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import BasicTable from "components/Tables/BasicTable";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import { useHistory } from 'react-router-dom';
import toast, { Toaster } from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
// Data
//import { columnsData1 } from "variables/columnsData";
//import tableData1 from "variables/tableData1.json";

function DataTables() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const userDetails = JSON.parse(localStorage?.getItem("user"));
  const [publishers, setPublishers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [refersh, refershSet] = React.useState('');

  useEffect(() => {
    if (loadingData) {
      getData();
    }
  }, [refersh]);

  const getData = async () => {
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: userDetails.id, token: token })
    };
    let result = await fetch(apiUrl + '/api/number/v2/lists', requestOptions);
    result = await result.json();
    if (result.status == "success") {
      setPublishers(result.data);
      setLoadingData(false);
    } else {
      toast.error("Invalid Token");
      localStorage.removeItem("user");
      localStorage.removeItem("token");
      window.location.reload();
    }
  }

  const handleEdit = (originalRow) => {
    alert("In Progress");
    return false;
    history.push('/admin/publishers/users/' + originalRow._id + '/edit');
  };

  const addNumber = (originalRow) => {
    alert("In Progress");
    return false;
    history.push('/admin/publishers/users/' + originalRow._id + '/edit');
  };

  const deleteNumber = (originalRow) => {
    alert("In Progress");
    return false;
    history.push('/admin/publishers/users/' + originalRow._id + '/edit');
  };

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Number",
      accessor: "phone",
    },
    {
      Header: "Country",
      accessor: "country",
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Campaign",
      accessor: "campaign.name",
    },
    {
      Header: "Number Pool",
      accessor: "numberpool",
    },
    {
      Header: 'Status',
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-sharp fa-solid fa-circle" style={{ color: '#22ca0c' }} />
        </div>
      ),
      id: 'status',
    }
  ];

  return (
    <Flex className='addpub' direction='column'>
      <Card>
        <CardHeader px='22px' mb='24px'>
          <Flex direction='column'>
            <Text fontSize='lg' mb='5px' color='#fff' fontWeight='bold'>
              Numbers List
            </Text>

          </Flex>
        </CardHeader>

        <CardBody>
          {loadingData ? (
            <p>Loading Please wait...</p>
          ) : (
            <SearchTable1 tableData={publishers} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
    </Flex>
  );
}

export default DataTables;
