import ContextSwitcher from './ContextSwitcher';

const Routing = ({ current }) => {
  const menu = [
    {
      label: 'Routing Scheme',
      href: '/administrator/callflows/routings',
    },
    {
      label: 'Routing Flow',
      href: '/administrator/callflows/lists',
    },
    // {
    //   label: "Buyer's Group",
    // },
    // {
    //   label: 'Media Files',
    //   href: '/administrator/callflows/manage/media',
    // },
    // {
    //   label: 'Template',
    // },
  ];

  const activeTab = (menu.findIndex(x => x.href == current) || 0) + 1;

  return (
    <ContextSwitcher
      options={menu}
      activeTab={activeTab}
    />
  );
};

export default Routing;
