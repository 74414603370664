import { Flex, Text } from "@chakra-ui/react";
import { commonColors } from "variables/general";

export default function ContextSwitcher(props) {
  const { options, activeTab } = props;
  const onClick = props.onClick || (() => {});
  const getSwitcherColor = (isActive) => {
    return isActive ? commonColors.highlightBlue : commonColors.darkBlue;
  };

  return (
    <Flex
      my="20px"
      wrap={{ sm: "wrap", md: "nowrap" }}
      gap="4"
    >
    {options.map((option, i) => (
        <a
          href={'/#' + option.href || ''}
          onClick={e => {
            if (!option.href) {
              e.preventDefault();
            }
            onClick(i + 1);
          }}
          className="contextSwitchButton"
          style={{
            backgroundColor: getSwitcherColor(activeTab === (i + 1)),
            flex: 1,
            maxWidth: 250,
            height: 45,
            whiteSpace: 'nowrap',
          }}
          key={i}
        >
          <Text fontSize="16px" color="#fff">
            {option.label || ''}
          </Text>
        </a>)
    )}
    </Flex>
  );
};
