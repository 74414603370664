/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";
import { jwtDecode } from 'jwt-decode';

import AuthLayout from "layouts/Auth.js";
import SuperAdminLayout from "layouts/SuperAdminLayout.js";

export const isTokenValid = (token) => {
  try {
    const { exp } = jwtDecode(token);
    return (exp >= Date.now() / 1000);
  } catch (error) {
    return false;
  }
};

const token = localStorage?.getItem("token");
const isValidToken = token ? isTokenValid(token) : false;

const user = JSON.parse(localStorage?.getItem("user"));
const userRole = user?.role?.toString();
const paths = {
  '0': "/administrator",
  '1': "/administrator",
  '2': "/publisher",
};
const path = userRole in paths ? paths[user?.role] : '/buyer';
let toPath = `${path}/dashboard/default`;
if (userRole != 0 && userRole != 1) {
  toPath = `${path}/publishers/users/lists`;
} 

ReactDOM.render(
  <HashRouter>
    <Switch>
      {isValidToken
        ? <>
          <Route path={path} component={SuperAdminLayout} />
          <Redirect from={`/`} to={toPath} />
        </>
        : <>
          <Route path={`/auth`} component={AuthLayout} />
          <Redirect from={`/`} to='/auth/authentication/sign-in/cover' />
        </>
      }
    </Switch>
  </HashRouter>,
  document.getElementById("root")
);
