/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

export const buttonStyles = {
  components: {
    Button: {
      // 3. We can add a new visual variant
      variants: {
        "with-shadow": {
          boxShadow: "0 0 2px 2px #efdfde",
        },
        "no-hover": {
          _hover: {
            boxShadow: "none",
          },
        },
        "transparent-with-icon": {
          bg: "transparent",
          fontWeight: "bold",
          borderRadius: "inherit",
          cursor: "pointer",
          _hover: "none",
          _active: {
            bg: "transparent",
            transform: "none",
            borderColor: "transparent",
          },
          _focus: {
            boxShadow: "none",
          },
          _hover: {
            boxShadow: "none",
          },
        },
        brand: {
          bg: "brand.200",
          color: "#fff",
          _hover: {
            bg: "brand.300",
          },
          _active: {
            bg: "brand.400",
          },
          _focus: "none",
        },
        outlineWhite: {
          bg: "transparent",
          color: "#fff",
          borderColor: "white",
          borderWidth: 1,
          padding: '20px 30px',
          _hover: {
            borderColor: 'blue.500',
            color: 'blue.600',
          },
          _active: {
            bg: "whiteAlpha.200",
          },
          _focus: "none",
        },
        formButton: {
          w: '100px',
          bg: 'blue.500',
          _hover: {
            bg: "blue.600",
          },
          fontSize: 'sm',
          h: 8,
        },
        blueOutline: {
          bg: "transparent",
          borderWidth: "1px",
          borderStyle: "solid",
          borderColor: "blue.500",
          borderRadius: "2px",
          color: "blue.500",
          _hover: {
            bg: "blue.500",
            border: "none",
            color: "white",
          }

        }
      },
      baseStyle: {
        _focus: {
          boxShadow: "none",
        },
      },
    },
  },
};
