/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra Imports
import { HamburgerIcon, BellIcon } from "@chakra-ui/icons";
import {
  Box,
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  Button,
  Flex,
  Icon,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  useMediaQuery,
  Link,
} from "@chakra-ui/react";
import axios from "axios";
import { SidebarContext } from "contexts/SidebarContext";
import PropTypes from "prop-types";
import { SidebarResponsive } from "components/Sidebar/Sidebar";
import React, { useContext, useEffect, useState } from "react";
import { CgMenuRight } from "react-icons/cg";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks";
import { ItemContent } from "components/Menu/ItemContent";
import { SettingsIcon } from "components/Icons/Icons";
import { useHistory } from "react-router-dom";
// Assets
import avatar1 from "assets/img/avatars/avatar1.png";
import avatar2 from "assets/img/avatars/avatar2.png";
import avatar3 from "assets/img/avatars/avatar3.png";

import logo from "../../icons/logo.png";
import { commonColors } from 'variables/general';

const mainWidth = 190;

export function AdminNavbar(props) {
  const [scrolled, setScrolled] = useState(false);
  const [profile, setProfile] = useState({
    name: "",
    email: "",
    role: 0
  });
  const history = useHistory();

  const logout = async () => {
    const user = JSON.parse(localStorage?.getItem("user"));
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    const res = await axios.post("/api/auth/logout", { id: user.id });
    window.location.reload();
  };

  const profileView = () => {
    const slug = document.documentElement.layout || 'administrator';
    history.push('/' + slug + '/accounts/profile');
  };

  const {
    sidebarWidth,
    setSidebarWidth,
    toggleSidebar,
    setToggleSidebar,
  } = useContext(SidebarContext);
  const {
    variant,
    children,
    fixed,
    secondary,
    routes,
    ...rest
  } = props;

  useEffect(() => {
    const user = JSON.parse(localStorage?.getItem("user"));
    setProfile(user);
  }, []);

  // Here are all the props that may change depending on navbar's type or state.(secondary, variant, scrolled)
  let mainText = "white";
  let secondaryText = "gray.400";
  let navbarPosition = "absolute";
  let navbarFilter = "none";
  let navbarBackdrop = "unset";
  let navbarShadow = "none";
  let navbarBg = commonColors.darkNavBg;
  let navbarBorder = "transparent";
  let secondaryMargin = "0px";
  let paddingS = "15px";
  let paddingX = "15px";
  if (props.secondary) {
    navbarBackdrop = "none";
  }
  if (props.fixed === true)
    if (scrolled === true) {
      navbarPosition = "fixed";
      navbarShadow = "0px 7px 23px rgba(0, 0, 0, 0.05)";
      navbarBorder = "rgba(226, 232, 240, 0.3)";
    }
  const changeNavbar = () => {
    if (window.scrollY > 1) {
      setScrolled(true);
    } else {
      setScrolled(false);
    }
  };
  window.addEventListener("scroll", changeNavbar);
  const [isBelow768px] = useMediaQuery("(max-width: 768px)");

  return (
    <Flex
      position={navbarPosition}
      boxShadow={navbarShadow}
      bg={navbarBg}
      borderColor={navbarBorder}
      filter={navbarFilter}
      backdropFilter={navbarBackdrop}
      borderWidth="1.5px"
      borderStyle="solid"
      transitionDelay="0s, 0s, 0s, 0s"
      transitionDuration=" 0.25s, 0.25s, 0.25s, 0s"
      transition-property="box-shadow, background-color, filter, border"
      transitionTimingFunction="linear, linear, linear, linear"
      alignItems={{ xl: "left" }}
      borderRadius="8px"
      display="flex"
      minH="45px"
      justifyContent={{ xl: "left" }}
      top={{ base: '1px', md: '20px', lg: '35px' }}
      left={{base: 'unset', md: `calc(${sidebarWidth}px + 30px)` }}
      right={{base: 'unset', md: "25px"}}
      lineHeight="25.6px"
      mx="0"
      mt={secondaryMargin}
      px={{
        base: paddingX,
        md: "30px",
      }}
      ps={{
        base: paddingS,
        md: "20px",
      }}
      w={{
        base: "100%",
        md: `calc(100% - 50px - ${sidebarWidth}px)`,
      }}
    >
      <Flex
        flexDirection={'row'}
        justifyContent={"space-between"}
        alignItems={"center"}
        w={"100%"}
      >
        <Box flexBasis={{base: "30%", md: "0"}}>
          <Link
            href={`/#/`}
            display='flex'>
            <img src={logo} />
          </Link>
        </Box>
        <Flex
          w="100%"
          flexBasis={{base: "70%", md: "100%"}}
          flexDirection={{
            base: "column",
            lg: "row",
          }}
          justifyContent={"space-between"}
          alignItems={"center"}
        >
          {toggleSidebar && (
            <Icon
              as={CgMenuRight}
              w="20px"
              h="20px"
              color={props.fixed ? (scrolled ? "#333" : "#fff") : "#fff"}
              cursor="pointer"
              display={{ sm: "none", xl: "block" }}
              onClick={() => {
                setSidebarWidth(sidebarWidth === mainWidth ? 120 : mainWidth);
                setToggleSidebar(!toggleSidebar);
              }}
            />
          )}
          {!isBelow768px && <Box style={{marginLeft: 15}} display={{base: "none", lg: "block"}}>
            <Breadcrumb sx={{ ol: { display: "flex", alignItems: "center" } }}>
              <BreadcrumbItem color={mainText}>
                  <BreadcrumbLink href="#" color={secondaryText}>
                    Home
                  </BreadcrumbLink>
              </BreadcrumbItem>

              {props?.breadcrumbs?.map((crumb, k) =>
              <BreadcrumbItem color={mainText} key={k}>
                <BreadcrumbLink href={crumb.href} color={mainText}>
                  {crumb.text}
                </BreadcrumbLink>
              </BreadcrumbItem>
              )}

              {!props.breadcrumbs && 
              <BreadcrumbItem color={mainText}>
                <BreadcrumbLink href='#' color={mainText}>
                  {brandText}
                </BreadcrumbLink>
              </BreadcrumbItem>}
            </Breadcrumb>
          </Box>}
          <Box>
            <AdminNavbarLinks
              logoText={props.logoText}
              secondary={props.secondary}
              fixed={props.fixed}
            />
          </Box>
          <Box
            style={{ display: 'flex' }}
            justifyContent={{base: "flex-start", md: "center"}}
          >
            <Menu>
              <MenuButton as={Button} pl={0} className="headerMenu" fontSize={{base: '12px', md: '14px', xl: '16px'}}>{profile.name} <i className="fa-solid fa-caret-down" /></MenuButton>
              <MenuList>
                <MenuItem onClick={profileView}>My Profile</MenuItem>
                <MenuItem onClick={logout}>Log Out</MenuItem>
              </MenuList>
            </Menu>
            <SidebarResponsive
              secondary={props.secondary}
              routes={routes}
              {...rest}
            />

            <SettingsIcon
              cursor='pointer'
              me={{base: '16px', lg: '8px', xl: '16px'}}
              mt='10px'
              onClick={profileView}
              color='white'
              w='18px'
              h='18px'
            />
            <Menu>
              <MenuButton>
                <BellIcon color='white' w='18px' h='18px' />
              </MenuButton>
              <MenuList
                border='transparent'
                bg={commonColors.darkNavBg}
                borderRadius='20px'
                p='16px 8px'>
                <Flex flexDirection='column'>
                  <MenuItem
                    _hover={{
                      bg: "transparent",
                    }}
                    _active={{
                      bg: "transparent",
                    }}
                    _focus={{
                      bg: "transparent",
                    }}
                    borderRadius='8px'
                    mb='10px'>
                    <ItemContent
                      time='13 minutes ago'
                      info='from Alicia'
                      boldInfo='New Message'
                      aName='Alicia'
                      aSrc={avatar1}
                    />
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      bg: "transparent",
                    }}
                    _active={{
                      bg: "transparent",
                    }}
                    _focus={{
                      bg: "transparent",
                    }}
                    borderRadius='8px'
                    mb='10px'>
                    <ItemContent
                      time='2 days ago'
                      info='by Josh Henry'
                      boldInfo='New Album'
                      aName='Josh Henry'
                      aSrc={avatar2}
                    />
                  </MenuItem>
                  <MenuItem
                    _hover={{
                      bg: "transparent",
                    }}
                    _active={{
                      bg: "transparent",
                    }}
                    _focus={{
                      bg: "transparent",
                    }}
                    borderRadius='8px'>
                    <ItemContent
                      time='3 days ago'
                      info='Payment succesfully completed!'
                      boldInfo=''
                      aName='Kara'
                      aSrc={avatar3}
                    />
                  </MenuItem>
                </Flex>
              </MenuList>
            </Menu>
          </Box>
        </Flex>
      </Flex>
    </Flex>
  );
}

AdminNavbar.propTypes = {
  brandText: PropTypes.string,
  breadcrumbs: PropTypes.array,
  variant: PropTypes.string,
  secondary: PropTypes.bool,
  fixed: PropTypes.bool,
};
 export default AdminNavbar;
