/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React, { useEffect, useState } from "react";

// Chakra imports
import { Flex, Text, Button,Switch, useDisclosure } from "@chakra-ui/react";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import SearchTable1 from "components/Tables/SearchTable1";
import StrategyNav from 'components/Navbars/Strategy';
import CTModal from "components/Modal.js";
import NewNumber from './NewNumber';

import axios from "axios";
import toast from 'react-hot-toast';
import packageJson from '../../../appConfig.js';
import Loading from 'components/Loading';
import { caseInsensitiveSort, simpleSort } from 'variables/general';

function DataTables() {
  const apiUrl = packageJson.apiurl;
  const [numbers, setNumbers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [formType, setFormType] = React.useState('');
  const [editRow, setEditRow] = React.useState(null);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const user = JSON.parse(localStorage?.getItem("user"));

  useEffect(() => {
      getData();
  }, []);

const isCheckedNumber = (originalRow, rowIndex) => {
  const numberChecked = (originalRow.status == 1);

  return <Switch id="client-switch" onChange={enableNumber} name="number" value={originalRow._id} isChecked={numberChecked}
      visibility={originalRow.source === 'number' ? 'visible' : 'hidden' }
    />
};

const enableNumber = async (event) => {
  setLoadingData(true);
  const loadToast = toast.loading('Loading...');
  let numberId = event.target.value;
  let status = 1;
  if (event.target.checked) {
    status = 1;
  } else {
    status = 2;
  }

  const requestOptions = {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({ id: numberId, status: status })
  };
  const response = await fetch(apiUrl+'/api/number/status-change', requestOptions);
  const result = await response.json();
  if(result.statusCode == 200) {
    toast.success(result.msg);
    getData();
  } else {
    toast.error(result.msg);
  }

  toast.dismiss(loadToast);
  setLoadingData(false);
  getData();
}

  const getData = async () => {
    setLoadingData(true);
    let dataUrl = apiUrl + "/api/number/lists";
    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id: user.id })
    };
    const loadToast = toast.loading('Loading...');
    let result = await fetch(dataUrl, requestOptions);
    result = await result.json();
    setNumbers(result.data);
    toast.dismiss(loadToast);
    setLoadingData(false);
  }

  const handleEdit = (originalRow) => {
    setFormType("Edit Number");
    setEditRow(originalRow);
    onOpen();
  };

  const addNumber = () => {
    setFormType("Create Number");
    setEditRow(null);
    onOpen();
  };

  const deleteNumber = (originalRow) => {
    if (!confirm("Do You want to Delete This Number?")) {
      return false;
    }
    setLoadingData(true);

    let config = {
      method: 'post',
      url: apiUrl + '/api/number/delete',
      headers: {
        'Content-Type': 'application/json'
      },
      data: { "id": originalRow._id }
    };
    axios.request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error);
        } else {
          toast.success('Number has been Deleted Successfully');
          getData();
        }
      })
      .catch((error) => {
        toast.error(error);
      });
    setLoadingData(false);
  };
  function numberStatus(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return <div><i className="fa-sharp fa-solid fa-circle" style={{ color: activated }} /></div>
  }

  const columnsData = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header:"Creation Date",
      accessor:row => row.createdAt.split("T")[0],
    },
    {
      Header: "#",
      accessor: isCheckedNumber,
      sortType: (p, c, i) => {
        return simpleSort(p, c, 'status');
      },
      disableSortBy: true,
    },
    {
      Header: "Number",
      accessor: "phone",
    },
    {
      Header: "Name",
      accessor: "name",
      sortType: (prev, curr, colId) => {
        return caseInsensitiveSort(prev, curr, colId);
      },
    },
    {
      Header: "Campaign",
      accessor: "campaignName",
    },
    {
      Header: "Publisher",
      accessor: "publisherName",
    },
    {
      Header: 'Status',
      accessor: numberStatus,
      sortType: (p, c, i) => {
        return simpleSort(p, c, i);
      },
      id: 'status',
    },
    {
      Header: 'Action',
      accessor: (originalRow) => (
        <div>
          {originalRow.source === 'number' && originalRow.publisherName && <>
            <a href="#" onClick={e => { e.preventDefault(); handleEdit(originalRow); }} ><i className="fa-solid fa-pen-to-square"></i></a>
            {' | '}
            <a href="#" onClick={e => { e.preventDefault(); deleteNumber(originalRow); }} ><i className="fa-solid fa-trash"></i></a>
          </>}
        </div>
      ),
      id: 'action',
      disableSortBy: true,
    },
  ];

  const onCloseForm = () => {
    getData();
    onClose();
  };

  return (
    <Flex className='addpub' direction='column'>
      <Card>
        <CardBody>
          {(user.role == 0 || user.role == 1)
            ? <StrategyNav current="/administrator/number/default" />
            : <Text fontSize='lg' mb={6} color='#fff' fontWeight='bold'>
              Number Lists
            </Text>
          }

          <Button
            variant='outlineWhite'
            alignSelf='flex-end'
            onClick={addNumber}
            fontSize='16px'
            color='#fff'
          >
            + Add Number
          </Button>
          {loadingData ? (
            <Loading />
          ) : (
            <SearchTable1 tableData={numbers} columnsData={columnsData} />
          )}
        </CardBody>
      </Card>
      <CTModal 
        isOpen={isOpen}
        onClose={onClose}
        showClose={false}
        title={formType}
      >
        <NewNumber
          onClose={onCloseForm}
          number={editRow}
        />
			</CTModal>
    </Flex>
  );
}

export default DataTables;
