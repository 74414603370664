import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Button,
} from '@chakra-ui/react'

import { commonColors } from 'variables/general';

const CTModal = (props) => {

  return (
    <Modal
      isOpen={props.isOpen}
      onClose={props.onClose}
      size={props.size}
      onCloseComplete={props.onCloseComplete}
      isCentered={props.isCentered ? true : false}
    >
    <ModalOverlay />
    <ModalContent bg={commonColors.darkNavBg} color={'white'}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalCloseButton />
      <ModalBody>
        {props.children}
      </ModalBody>

      <ModalFooter>
      {props.showClose &&
        <Button colorScheme='blue' mr={3} onClick={props.onClose}>
          Close
        </Button>
      }
      </ModalFooter>
    </ModalContent>
    </Modal>
  );
};

CTModal.defaultProps = {
  onClose: () => {},
  isOpen: false,
  showClose: true,
  title: '',
  size: 'md',
};

export default CTModal
