/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
// import
// To be changed
// import Tables from "views/Dashboard/Tables.js";
import {
  CartIcon,
  DocumentIcon,
  HomeIcon,
  PersonIcon,
  StatsIcon,
} from "components/Icons/Icons";
import Calendar from "views/Applications/Calendar";
import DataTables from "views/Applications/DataTables";
import Kanban from "views/Applications/Kanban.js";
import Wizard from "views/Applications/Wizard.js";
import SignInBasic from "views/Authentication/SignIn/SignInBasic.js";
import SignInCover from "views/Authentication/SignIn/SignInCover.js";
import SignInIllustration from "views/Authentication/SignIn/SignInIllustration.js";
import SignUpBasic from "views/Authentication/SignUp/SignUpBasic.js";
import SignUpCover from "views/Authentication/SignUp/SignUpCover.js";
import SignUpIllustration from "views/Authentication/SignUp/SignUpIllustration.js";
import CRM from "views/Dashboard/CRM.js";
import Default from "views/Dashboard/Default.js";
import ReportDefault from "views/Pages/PublisherReports/Default.js";
import OrderDetails from "views/Ecommerce/Orders/OrderDetails";
import OrderList from "views/Ecommerce/Orders/OrderList";
import EditProduct from "views/Ecommerce/Products/EditProduct";
import NewProduct from "views/Ecommerce/Products/NewProduct";
import ProductPage from "views/Ecommerce/Products/ProductPage";
import Billing from "views/Pages/Account/Billing.js";
import Invoice from "views/Pages/Account/Invoice.js";
import Settings from "views/Pages/Account/Settings.js";
import Alerts from "views/Pages/Alerts";
import Charts from "views/Pages/Charts.js";
import Pricing from "views/Pages/Pricing.js";
import Overview from "views/Pages/Profile/Overview.js";
import Projects from "views/Pages/Profile/Projects.js";
import Teams from "views/Pages/Profile/Teams.js";
import General from "views/Pages/Projects/General.js";
import Timeline from "views/Pages/Projects/Timeline.js";
import RTLPage from "views/Pages/RTLPage.js";
import NewUser from "views/Pages/Users/NewUser.js";
import NewPublisher from "views/Pages/Users/NewPublisher.js";
import EditPublisher from "views/Pages/Users/EditPublisher.js";
import NewBuyer from "views/Pages/Users/NewBuyer.js";
import EditByer from "views/Pages/Users/EditByer.js";
import Reports from "views/Pages/Users/Reports.js";
import Publishers from "views/Pages/Administrator/Publishers.js";
import Buyers from "views/Pages/Administrator/Buyers.js";
import Campaigns from "views/Pages/Administrator/Campaigns.js";
import NewCampaign from "views/Pages/Users/NewCampaign.js";
import EditCampaign from "views/Pages/Users/EditCampaign.js";
import ViewCampaign from "views/Pages/Publisher/ViewCampaign.js";
import Widgets from "views/Pages/Widgets.js";
import ForgetPassword from "views/Authentication/SignIn/ForgetPassword.js";
import ResetPassword from "views/Authentication/SignIn/ResetPassword.js";
import Numbers from "views/Numbers/Publisher/Numbers.js";

const dashRoutes = [
  {
    name: "Publishers",
    path: "/publishers/users/lists",
    component: Publishers,
    layout: "/publisher",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/publishers/users/lists",
        component: Publishers,
        layout: "/publisher",
      },
      {
        name: "Add New",
        path: "/publishers/users/create",
        component: NewPublisher,
        layout: "/publisher",
      },
      {
        name: "Edit Publisher",
        path: "/publishers/users/:id/edit",
        component: EditPublisher,
        layout: "/publisher",
      }
    ],
  },
  {
    name: "Buyers",
    path: "/buyers/users/lists",
    component: Buyers,
    layout: "/publisher",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/buyers/users/lists",
        component: Buyers,
        layout: "/publisher",
      },
      {
        name: "Add New",
        path: "/buyers/users/create",
        component: NewBuyer,
        layout: "/publisher",
      },
      {
        name: "Edit Buyer",
        path: "/buyers/users/:id/edit",
        component: EditByer,
        layout: "/publisher",
      }
    ],
  },
  {
    name: "Campaign",
    path: "/campaign/lists",
    component: Campaigns,
    layout: "/publisher",
    icon: <DocumentIcon color='inherit' />,
    authIcon: <DocumentIcon color='inherit' />,
    collapse: false,
    items: [
      {
        name: "Lists",
        path: "/campaign/lists",
        component: Campaigns,
        layout: "/publisher",
      },
      {
        name: "View Campaign",
        path: "/campaign/:id/view",
        component: ViewCampaign,
        layout: "/publisher",
      }
    ],
  },
  {
    name: "Report",
    path: "/reports/default",
    component: ReportDefault,
    layout: "/publisher",
  },
  {
    name: "Numbers",
    path: "/number/default",
    component: Numbers,
    layout: "/publisher",
  },
];

export default dashRoutes;
