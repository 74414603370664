/*!

=========================================================
* Vision UI PRO Chakra - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/vision-ui-dashboard-pro-chakra
* Copyright 2021 Creative Tim (https://www.creative-tim.com/)

* Design and Coded by Simmmple & Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

// Chakra imports
import {
  Button,
  Flex,
  FormControl,
  FormLabel,
  Grid,
  Icon,
  Input,
  Stack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text,
  Textarea,
  Switch,
  Select,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  background,
} from "@chakra-ui/react";

// Custom components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import { BsCircleFill } from "react-icons/bs";
import SearchTable1 from "components/Tables/SearchTable1";
import axios from "axios";
import toast, { Toaster } from "react-hot-toast";
import { VSeparator, HSeparator } from "components/Separator/Separator";
import packageJson from "../../../appConfig.js";
import Button1 from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { QuestionIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";

function NewUser() {
  const history = useHistory();
  const apiUrl = packageJson.apiurl;
  const token = localStorage?.getItem("token");
  const user = JSON.parse(localStorage?.getItem("user")); //
  const [campaign, setCampaign] = useState({
    name: "",
    defaultPayout: [],
    payoutHours: [],
    publishers: [],
    callrouting: "",
    routing: [],
    by: user.id,
  });
  const [payout, setPayout] = useState({
    callLength: "",
    concurrencyCap: "",
    dailyCap: "",
    dailyPayoutCap: "",
    duplicatePayout: "disable",
    enableConCurrencyCap: false,
    enableDailyCap: false,
    enableDailyPayoutCap: false,
    enableGlobalCap: false,
    enableGlobalPayoutCap: false,
    enableHourlyCap: false,
    enableHourlyPayoutCap: false,
    enableMonthlyCap: false,
    enableMonthlyPayoutCap: false,
    enablePayoutHours: false,
    globalCap: "",
    globalPayoutCap: "",
    houlyCap: "",
    hourlyPayoutCap: "",
    limitPayoutEnabled: false,
    monthlyCap: "",
    monthlyPayoutCap: "",
    payoutAmount: "",
    payoutType: "1",
    type: "",
    // numberFormat: "",
    reportDuplicateCallsOn: "Correct",
    callLengthGreaterThanSeconds: "",
    toNormal: "",
    toOriginal: "",
    toDifferent: "",
    strict: false,
    // handleAnonymousCallsAsDuplicate: false,
    // payoutOncePerCaller: false,
    // recordCalls: false,
    // waitForAnswer: false,
    // trimSilence: false,
    // targetDialAttempts: "",
    filterRepeatCallers: "",
    // filterAnonymousCallersAsSpam: false,
    duplicateTimePeriod: "",
  });

  const [viewReportDuplicateCallsOn, setViewReportDuplicateCallsOn] = useState(
    "hide"
  );

  const [buttonColor1, setButtonColor1] = useState("#0d6efd");
  const [buttonColor2, setButtonColor2] = useState("#0d6efd");
  const [buttonColor3, setButtonColor3] = useState("#0d6efd");

  const handleButtonClick1 = () => {
    setPayout({
      ...payout,
      toNormal: "toNormal",
      toOriginal: "",
      toDifferent: "",
      strict: false,
    });
    setButtonColor1("#63B3ED");
    setButtonColor2("#0d6efd");
    setButtonColor3("#0d6efd");
  };
  const handleButtonClick2 = () => {
    setPayout({
      ...payout,
      toOriginal: "toOriginal",
      toNormal: "",
      toDifferent: "",
      strict: false,
    });
    setButtonColor2("#63B3ED");
    setButtonColor1("#0d6efd");
    setButtonColor3("#0d6efd");
  };
  const handleButtonClick3 = () => {
    setPayout({
      ...payout,
      toDifferent: "toDifferent",
      toNormal: "",
      toOriginal: "",
    });
    setButtonColor3("#63B3ED");
    setButtonColor1("#0d6efd");
    setButtonColor2("#0d6efd");
  };

  const onViewReportDuplicatesCallsOnChange = (e) => {
    const { name, value } = e.target;

    if (e.target.value == "callLengthGreaterThan") {
      setViewReportDuplicateCallsOn("show");
    } else {
      setViewReportDuplicateCallsOn("hide");
    }
    setPayout({
      ...payout, //spread operator
      [name]: value,
    });
  };

  const [payoutHours, setPayoutHours] = useState({
    startTime: "09:00",
    endTime: "18:00",
    breakTime: [],
  });

  // const [breakHours, setBreakHours] = useState([
  //   {
  //     startTime: "",
  //     duration: 0,
  //   },
  // ]);

  const [publishers, setPublishers] = useState([]);
  const [breakHours, setBreakHours] = useState([
    {
      startTime: "",
      duration: 0,
    },
  ]);
  //const [payout, setPayout] = useState([]);
  const [buyers, setBuyers] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [loadingBuyerData, setLoadingBuyerData] = useState(true);
  const [refersh, refershSet] = React.useState("");
  const [loadingCampaignData, setloadingCampaignData] = useState(true);
  const [payoutHoursEnabled, setPayoutHoursEnabled] = useState(false);
  const [limitPayoutEnabled, setLimitPayoutEnabled] = useState(false);
  const [callLengthShow, setCallLengthShow] = useState("hide");
  const [limitPayoutShow, setLimitPayoutShow] = useState("hide");
  const [enableGlobalCap, setEnableGlobalCap] = useState(false);
  const [enableMonthlyCap, setEnableMonthlyCap] = useState(false);
  const [enableDailyCap, setEnableDailyCap] = useState(false);
  const [enableHourlyCap, setEnableHourlyCap] = useState(false);
  const [enableConCurrencyCap, setEnableConCurrencyCap] = useState(false);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const [updateRouting, setUpdateRouting] = React.useState([]);
  const [addBreakShow, setAddBreakShow] = useState("hide");
  const [routingPlan, setRoutingPlan] = useState({
    priority: "",
    weight: "",
    callType: 0,
    callLength: "",
    revenue: "",
  });

  const [routingPlanLists, setRoutingPlanLists] = useState([]);
  const [loadingRoutingPlans, setLoadingRoutingPlans] = useState(true);

  const [publishersSlack, setPublishersSlack] = useState([]);
  const [loadingPublishersData, setLoadingPublishersData] = useState(true);

  const [publisherFormToggle, setpublisherFormToggle] = useState("hide");
  const [targetFormToggle, setTargetFormToggle] = useState("hide");
  const [publisher, setPublisher] = useState({
    name: "",
    email: "",
    password: "",
    by: user.id,
  });

  const [buyer, setBuyer] = useState({
    name: "",
    email: "",
    password: "",
    subid: "",
    phonetype: "",
    phoneNumber: "",
    connectiontimeout: "",
    cccap: "",
    by: user.id,
    timezone: "",
  });
  const [createNewUsNumberEnabled, setCreateNewUsNumberEnabled] = useState(
    true
  );
  const [existingNumberShow, setExistingNumberShow] = useState(false);
  const [addExistingNumberShow, setAddExistingNumberShow] = useState(false);
  const [numberLists, setNumberLists] = useState([]);
  const [numberListsLoading, setNumberListsLoading] = useState(true);
  const [
    addNewNumberPublisherIndex,
    setAddNewNumberPublisherIndex,
  ] = useState();
  const [addPhoneFormShow, setAddPhoneFormShow] = useState();
  const [addNewUsNumberEnabled, setAddNewUsNumberEnabled] = useState(true);

  const [billCardEnabled, setbillCardEnabled] = useState(true);
  const [newNumberBillCardEnabled, setNewNumberBillCardEnabled] = useState(
    true
  );
  const [publisherLists, setPublisherLists] = useState([]);

  const [addNewNumber, setAddNewNumber] = useState({
    phone: [],
    numberType: 1,
    prefix: "",
    billCard: "",
  });
  const [newPublisher, setNewPublisher] = useState({
    publisherId: "",
    publisherName: "",
    phone: [],
    numberType: 1,
    prefix: "",
    billCard: "",
  });

  const [targetgroup, setTargetgroup] = useState([]);

  // handle change for toggle
  // const [
  //   handleAnonymousCallsAsDuplicate,
  //   setHandleAnonymousCallsAsDuplicate,
  // ] = useState(false);
  // const onhandleAnonymousCallsAsDuplicate = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setHandleAnonymousCallsAsDuplicate(true);
  //     enabled = true;
  //   } else {
  //     setHandleAnonymousCallsAsDuplicate(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  // const [payoutOncePerCaller, setPayoutOncePerCaller] = useState(false);
  // const onpayoutOncePerCaller = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setPayoutOncePerCaller(true);
  //     enabled = true;
  //   } else {
  //     setPayoutOncePerCaller(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  // const [recordCalls, setRecordCalls] = useState(false);
  // const onrecordCalls = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setRecordCalls(true);
  //     enabled = true;
  //   } else {
  //     setRecordCalls(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  // const [waitForAnswer, setWaitForAnswer] = useState(false);
  // const onwaitForAnswer = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setWaitForAnswer(true);
  //     enabled = true;
  //   } else {
  //     setWaitForAnswer(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  // const [trimSilence, setTrimSilence] = useState(false);
  // const ontrimSilence = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setTrimSilence(true);
  //     enabled = true;
  //   } else {
  //     setTrimSilence(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  // const [
  //   filterAnonymousCallersAsSpam,
  //   setFilterAnonymousCallersAsSpam,
  // ] = useState(false);
  // const onfilterAnonymousCallersAsSpam = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setFilterAnonymousCallersAsSpam(true);
  //     enabled = true;
  //   } else {
  //     setFilterAnonymousCallersAsSpam(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  // const [strict, setStrict] = useState(false);
  // const onstrict = (e) => {
  //   const { name, value } = e.target;
  //   let enabled = false;
  //   if (e.target.value == "false") {
  //     setStrict(true);
  //     enabled = true;
  //   } else {
  //     setStrict(false);
  //     enabled = false;
  //   }
  //   setPayout({
  //     ...payout, //spread operator
  //     [name]: enabled,
  //   });
  // };

  useEffect(() => {
    // const loadToast = toast.loading("Loading...");
    if (loadingData) {
      //getPublishers();
      getPublisherLists();
    }
    if (numberListsLoading) {
      getNumberLists();
    }
    if (loadingBuyerData) {
      getBuyers();
    }
    if (loadingRoutingPlans) {
      getRoutingPlanLists();
    }
    // toast.dismiss(loadToast);
  }, [refersh]);

  useEffect(() => {
    GetGroupBuyerListing();
  }, []);

  const getPublisherLists = async () => {
    // const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    let result = await fetch(apiUrl + "/api/admin/publishers", requestOptions);
    result = await result.json();
    setPublisherLists(result);
    setLoadingData(false);
    // toast.dismiss(loadToast);
  };

  const getNumberLists = async () => {
    // const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ by: user.id }),
    };
    let result = await fetch(apiUrl + "/api/number/lists", requestOptions);
    result = await result.json();
    if (result.statusCode == 200) {
      result.data = result.data.filter(
        (ar) => !newPublisher.phone.find((rm) => rm === ar.phone)
      );
      result.data = result.data.filter(
        (ar) => !addNewNumber.phone.find((rm) => rm === ar.phone)
      );
      setNumberLists(result.data);
      setNumberListsLoading(false);
    }
    // toast.dismiss(loadToast);
  };

  const handleAddTarget = (e) => {
    const { name, value } = e.target;
    setBuyer({
      ...buyer, //spread operator
      [name]: value,
    });
  };

  const createNewbuyer = () => {
    const loadToast = toast.loading("Please Wait....");
    const {
      name,
      email,
      password,
      subid,
      phonetype,
      phoneNumber,
      connectiontimeout,
      timezone,
    } = buyer;
    if (name == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Name is required!");
      return false;
    } else if (email == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Email is required!");
      return false;
    } else if (password == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Password is required!");
      return false;
    } else if (subid == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer SubID is required!");
      return false;
    } else if (phonetype == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Phone Type is required!");
      return false;
    }else if (phoneNumber == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Phone is required!");
      return false;
    }else if (phoneNumber.length !== 12) {
      toast.dismiss(loadToast);
      toast.error("Phone number must be exactly 12 digit long!");
      return false;
    } else if (connectiontimeout == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Connection Timeout is required!");
      return false;
    } else if (timezone == "") {
      toast.dismiss(loadToast);
      toast.error("Buyer Timezone is required!");
      return false;
    }

    let config = {
      method: "post",
      url: apiUrl + "/api/buyers",
      headers: {
        "Content-Type": "application/json",
      },
      data: buyer,
    };
    axios
      .request(config)
      .then((response) => {
        if (response.data.error) {
          toast.error(response.data.error[0].message);
          toast.dismiss(loadToast);
        } else {
          toast.success("Buyer has been  Successfully added");
          setLoadingData(true);
          setloadingCampaignData(true);
          setLoadingBuyerData(true);
          refershSet(Math.random());
          setTargetFormToggle("hide");
          toast.dismiss(loadToast);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.dismiss(loadToast);
      });
  };

  const showPublisherForm = (e) => {
    setpublisherFormToggle("show");
  };

  const cancelPublisher = (e) => {
    setpublisherFormToggle("hide");
  };

  const showTargetForm = (e) => {
    setTargetFormToggle("show");
  };
  const cancelTarget = (e) => {
    setTargetFormToggle("hide");
  };

  const handleEdit = (originalRow) => {
    history.push("/admin/buyers/users/" + originalRow._id + "/edit");
    window.scrollTo(0, 0);
  };

  const handleEditPublisher = (originalRow) => {
    history.push("/admin/publishers/users/" + originalRow + "/edit");
    window.scrollTo(0, 0);
  };

  const getBuyers = async () => {
    // const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    let result = await fetch(apiUrl + "/api/admin/list-buyers", requestOptions);
    result = await result.json();
    result = result.filter(
      (ar) => !routingPlanLists.find((rm) => rm.target === ar._id)
    );
    setBuyers(result);
    setLoadingBuyerData(false);
    // toast.dismiss(loadToast);
  };

  const getRoutingPlanLists = async () => {
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(false);
  };

  function getBuyerType(originalRow, rowIndex) {
    let type = "SIP";
    if (originalRow.phonetype == 1) {
      type = "NUMBER";
    }
    return <div>{type}</div>;
  }

  const addRouteModel = (originalRow) => {
    setUpdateRouting(originalRow);
    //setUpdateRouting
    setShow(true);
  };

  const handleRoutingChange = (e) => {
    const { name, value } = e.target;
    setRoutingPlan({
      ...routingPlan, //spread operator
      [name]: value,
    });
  };

  const addRoutingPlan = (e) => {
    let targetId = updateRouting._id;
    console.log(updateRouting, "updateRouting");
    if (
      routingPlan.priority == "" ||
      routingPlan.weight == "" ||
      routingPlan.revenue == "" ||
      routingPlan.type == "" ||
      routingPlan.callLength == ""
    ) {
      toast.error("Please Fill All Required Fields");
      return false;
    }

    if (updateRouting.hasOwnProperty("simuldial")) {
      routingPlanLists.push({
        targetGroup: targetId,
        name: updateRouting.name,
        phone: updateRouting.phoneNumber,
        priority: routingPlan.priority,
        weight: routingPlan.weight,
        callType: routingPlan.callType,
        callLength: routingPlan.callLength,
        revenue: routingPlan.revenue,
        status: updateRouting.status,
      });
    } else {
      routingPlanLists.push({
        target: targetId,
        name: updateRouting.name,
        phone: updateRouting.phoneNumber,
        priority: routingPlan.priority,
        weight: routingPlan.weight,
        callType: routingPlan.callType,
        callLength: routingPlan.callLength,
        revenue: routingPlan.revenue,
        status: updateRouting.status,
      });
    }
    console.log(routingPlanLists, "routingPlanLists");
    routingPlanLists.sort((a, b) => (a.weight < b.weight ? 1 : -1));
    setRoutingPlan([]);
    setLoadingRoutingPlans(true);
    setLoadingBuyerData(true);
    // setTargetListLoading(true);
    refershSet(Math.random());
    handleClose();
  };

  function isTargetActivated(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return (
      <div>
        <i
          className="fa-sharp fa-solid fa-circle"
          style={{ color: activated }}
        />
      </div>
    );
  }
  const columnTargetData = [
    {
      Header: "#",
      accessor: (originalRow, rowIndex) => (
        <div>
          <i className="fa-solid fa-check" />
          {/*<Switch id="email-alerts" onChange={addTarget} name="publisher" value={originalRow._id} />*/}
        </div>
      ),
      id: "EnableTarget",
    },
    {
      Header: "Name",
      accessor: "name",
      Cell: ({ row }) => (
        <div>
          {row.original.name} |{" "}
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              handleEdit(row.original);
            }}
          >
            <i className="fa-solid fa-pen-to-square"></i>
          </a>
        </div>
      ),
    },
    {
      Header: "Type",
      accessor: "type",
      accessor: getBuyerType,
    },
    {
      Header: "Destination",
      accessor: "phoneNumber",
    },
    {
      Header: "Status",
      accessor: isTargetActivated,
      id: "EnableTargets",
    },
    {
      Header: "Add",
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              addRouteModel(originalRow);
            }}
            title="Add Routing"
          >
            <i className="fa-solid fa-plus"></i>
          </a>
        </div>
      ),
      id: "addRoute",
    },
  ];

  let targetData = [
    {
      name: "Target First",
      type: "Phone",
      destination: "8888888888",
      status: 1,
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCampaign({
      ...campaign, //spread operator
      [name]: value,
    });
  };

  const onPayoutChange = (e) => {
    const { name, value } = e.target;
    if (name == "type" && value == 1) {
      setCallLengthShow("show");
    } else if (name == "type" && value != 1) {
      setCallLengthShow("hide");
    }
    setPayout({
      ...payout, //spread operator
      [name]: value,
    });
  };
  const onPayoutHours = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setPayoutHoursEnabled(true);
      enabled = true;
    } else {
      setPayoutHoursEnabled(false);
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };
  const onChangeLimitPayout = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setLimitPayoutEnabled(true);
      setLimitPayoutShow("show");
      enabled = true;
    } else {
      setLimitPayoutEnabled(false);
      setLimitPayoutShow("hide");
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };

  const onGlobalCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableGlobalCap(true);
      enabled = true;
    } else {
      setEnableGlobalCap(false);
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };

  const onMonthlyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableMonthlyCap(true);
      enabled = true;
    } else {
      setEnableMonthlyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };

  const onDailyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableDailyCap(true);
      enabled = true;
    } else {
      setEnableDailyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };

  const onHourlyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableHourlyCap(true);
      enabled = true;
    } else {
      setEnableHourlyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };

  const onConCurrencyCapChange = (e) => {
    const { name, value } = e.target;
    let enabled = false;
    if (e.target.value == "false") {
      setEnableConCurrencyCap(true);
      enabled = true;
    } else {
      setEnableConCurrencyCap(false);
      enabled = false;
    }
    setPayout({
      ...payout, //spread operator
      [name]: enabled,
    });
  };

  const onChangeHandle = (e) => {
    const { name, value } = e.target;
    setPayoutHours({
      ...payoutHours, //spread operator
      [name]: value,
    });
  };
  const onChangeHandleDuplicate = (e) => {
    const { name, value } = e.target;
    setPayout((prevPayout) => ({
      ...prevPayout,
      [name]: value,
    }));
  };

  const createCampaign = () => {
    campaign.routing = routingPlanLists;
    campaign.defaultPayout = payout;
    campaign.payoutHours = payoutHours;
    campaign.publishers = publishersSlack;
    const loadToast = toast.loading("Please Wait....");
    const {
      name,
      description,
      defaultPayout,
      publishers,
      callrouting,
      routing,
    } = campaign;
    console.log(campaign, "campaign");
    if (name && publishers.length > 0 && callrouting && routing.length > 0) {
      let config = {
        method: "post",
        url: apiUrl + "/api/campaign/",
        headers: {
          "Content-Type": "application/json",
        },
        data: campaign,
      };
      axios
        .request(config)
        .then((response) => {
          toast.dismiss(loadToast);
          if (response.data.error) {
            toast.error(response.data.error);
          } else {
            toast.success("Campaign has been Created Successfully");
            history.push("/admin/campaign/lists");
          }
        })
        .catch((error) => {
          toast.error(error);
          toast.dismiss(loadToast);
        });
    } else {
      toast.error("You have Entered Invalid Input");
      toast.dismiss(loadToast);
    }
  };

  const deleteRouting = (index) => {
    routingPlanLists.splice(index, 1);
    setRoutingPlanLists(routingPlanLists);
    setLoadingRoutingPlans(true);
    refershSet(Math.random());
  };

  const showBreak = (e) => {
    setAddBreakShow("show");
  };

  const cancelBreak = (e) => {
    setAddBreakShow("hide");
  };

  const onBreakChangeHandle = (e) => {
    const { name, value } = e.target;
    setBreakHours({
      ...breakHours, //spread operator
      [name]: value,
    });
  };

  const addBreakHandle = (e) => {
    toast.error("Add Break Part is in Progess..");
  };

  const addPhoneToggle = (e) => {
    addNewNumber.phone = [];
    addNewNumber.numberType = 1;
    addNewNumber.prefix = "";
    setAddNewNumber(addNewNumber);
    setAddNewUsNumberEnabled(false);
    setAddPhoneFormShow(e.target.getAttribute("data-editPhoneId"));
    setAddNewNumberPublisherIndex(e.target.getAttribute("data-editPhoneId"));
  };
  const closeAddPhoneForm = (e) => {
    setAddPhoneFormShow();
    setAddNewNumberPublisherIndex();
  };

  const newUsNumberStatusChange = (event) => {
    if (event.target.checked) {
      setCreateNewUsNumberEnabled(true);
      setExistingNumberShow(false);
    } else {
      setCreateNewUsNumberEnabled(false);
      setExistingNumberShow(true);
    }
  };

  const onChangeNewPublisherDropdown = (e) => {
    const { name, value } = e.target;
    var index = e.target.selectedIndex;
    let publisherName = "publisherName";
    let publisherNameValue = e.target[index].text;
    setNewPublisher({
      ...newPublisher, //spread operator
      [name]: value,
      [publisherName]: publisherNameValue,
    });
  };

  const onChangeNewPublisherHandle = (e) => {
    const { name, value } = e.target;
    setNewPublisher({
      ...newPublisher, //spread operator
      [name]: value,
    });
  };

  const changeNumberType = (e) => {
    const name = "numberType";
    const value = e.target.getAttribute("data-id");
    setNewPublisher({
      ...newPublisher, //spread operator
      [name]: value,
    });
  };

  const createNewPublisher = async (e) => {
    const loadToast = toast.loading("Please Wait..");
    newPublisher.phone = [];
    let assignedPhone = "";
    const { publisherId } = newPublisher;
    if (publisherId == "") {
      toast.error("You have to select Publisher");
      toast.dismiss(loadToast);
      return false;
    }
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    let result = await fetch(
      apiUrl + "/api/twilio/phonenumber/buy",
      requestOptions
    );
    result = await result.json();
    if (result.status == "success") {
      assignedPhone = result.phoneNumber;
      newPublisher.phone.push(assignedPhone);
      publishersSlack.push(newPublisher);
    }
    setPublishersSlack(publishersSlack);
    setLoadingPublishersData(true);
    setpublisherFormToggle("hide");
    refershSet(Math.random());
    toast.dismiss(loadToast);
  };

  const addUsNumberStatusChange = (event) => {
    if (event.target.checked) {
      setAddNewUsNumberEnabled(true);
      setAddExistingNumberShow(false);
    } else {
      setAddNewUsNumberEnabled(false);
      setAddExistingNumberShow(true);
    }
  };

  const onChangeNewNumbertHandle = (e) => {
    const { name, value } = e.target;
    setAddNewNumber({
      ...addNewNumber, //spread operator
      [name]: value,
    });
  };

  const addNumberToExistingList = (e) => {
    addNewNumber.phone.push(e.target.getAttribute("data-phone"));
    setAddNewNumber({
      ...addNewNumber, //spread operator
      addNewNumber,
    });
    setNumberListsLoading(true);
    refershSet(Math.random());
  };

  const changeExistingNumberType = (e) => {
    const name = "numberType";
    const value = e.target.getAttribute("data-id");
    setAddNewNumber({
      ...addNewNumber, //spread operator
      [name]: value,
    });
  };

  const addNewNumberBtn = async (e) => {
    const loadToast = toast.loading("Please Wait..");
    let assignedPhone = "";
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({ id: user.id }),
    };
    if (addNewUsNumberEnabled == true) {
      let result = await fetch(
        apiUrl + "/api/twilio/phonenumber/buy",
        requestOptions
      );
      result = await result.json();
      if (result.status == "success") {
        assignedPhone = result.phoneNumber;
        addNewNumber.phone.push(assignedPhone);
        if (addNewNumber.phone.length > 0) {
          for (let i = 0; i < addNewNumber.phone.length; i++) {
            publishersSlack[addNewNumberPublisherIndex].phone.push(
              addNewNumber.phone[i]
            );
          }
          publishersSlack[addNewNumberPublisherIndex].numberType =
            addNewNumber.numberType;
          publishersSlack[addNewNumberPublisherIndex].prefix =
            addNewNumber.prefix;
        }
        setPublishersSlack(publishersSlack);
        setAddPhoneFormShow();
        setAddNewNumberPublisherIndex();
        setLoadingPublishersData(true);
        refershSet(Math.random());
        toast.dismiss(loadToast);
      }
    } else {
      if (addNewNumber.phone.length > 0) {
        for (let i = 0; i < addNewNumber.phone.length; i++) {
          publishersSlack[addNewNumberPublisherIndex].phone.push(
            addNewNumber.phone[i]
          );
        }
        publishersSlack[addNewNumberPublisherIndex].numberType =
          addNewNumber.numberType;
        publishersSlack[addNewNumberPublisherIndex].prefix =
          addNewNumber.prefix;
      }
      setPublishersSlack(publishersSlack);
      setAddPhoneFormShow();
      setAddNewNumberPublisherIndex();
      setLoadingPublishersData(true);
      refershSet(Math.random());
      toast.dismiss(loadToast);
    }
  };

  const deletePublisher = (e) => {
    let removeKey = e.target.getAttribute("data-editPhoneId");
    publishersSlack.splice(removeKey, 1);
    setPublishersSlack(publishersSlack);
    setLoadingPublishersData(true);
    refershSet(Math.random());
  };

  const deletePublisherPhone = (e) => {
    let publisherKey = e.target.getAttribute("data-publisher-key");
    let removeKey = e.target.getAttribute("data-phone-key");
    publishersSlack[publisherKey].phone.splice(removeKey, 1);
    setPublishersSlack(publishersSlack);
    refershSet(Math.random());
  };

  const getCampaignPublishers = async () => {
    //setPublishersSlack(publishersSlack);
    setLoadingPublishersData(false);
  };

  // for tab
  const [tab1, setTab1] = useState("show");
  const [tab2, setTab2] = useState("hide");
  const [tabMenu1, setTabMenu1] = useState("active");
  const [tabMenu2, setTabMenu2] = useState("");
  const tabHandle = (e) => {
    let tab = e.target.getAttribute("data-tab");
    if (tab == "tab-1") {
      setTab1("show");
      setTab2("hide");
      setTabMenu1("active");
      setTabMenu2("");
    } else {
      setTab1("hide");
      setTab2("show");
      setTabMenu1("");
      setTabMenu2("active");
    }
  };

  // for Target Group
  //  const [groupTargetListLoading, setTargetListLoading] = useState(true);
  useEffect(() => {
    // const group = targetgroup
    //   const res=group.filter((data)=>{
    //     for(let i=0;i<routingPlanLists?.length;i++){
    //     if(data?.targets?.indexOf(routingPlanLists[i]?.target)!=-1){
    //         return false
    //     }
    //     }
    //     return true
    // })

    setTargetgroup((group) =>
      group.filter((data) => {
        for (let i = 0; i < routingPlanLists?.length; i++) {
          // console.log("routin plan list",routingPlanLists[i].target)
          if (data?.targets?.indexOf(routingPlanLists[i]?.target) != -1) {
            return false;
          }
        }
        return true;
      })
    );
  }, [routingPlanLists?.length]);

  useEffect(() => {
    console.log(routingPlanLists,"routingPlanLists")
  }, [targetgroup])

  const GetGroupBuyerListing = async () => {
    // const loadToast = toast.loading("Loading...");
    const requestOptions = {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        by: user.id,
      }),
    };
    let result = await fetch(
      apiUrl + "/api/targetgroup/admin/all",
      requestOptions
    );
    result = await result.json();
    // result = result.filter(
    //   (ar) => !routingPlanLists.find((rm) => rm.target === ar._id)
    // );
    setTargetgroup(result);
    // setTargetListLoading(false);
    // toast.dismiss(loadToast);
  };

  // list Column for Target group
  function buyerGroupStatus(originalRow, rowIndex) {
    let activated = "#982E2B";
    if (originalRow.status == 1) {
      activated = "#22ca0c";
    }
    return (
      <div>
        <i
          className="fa-sharp fa-solid fa-circle"
          style={{ color: activated }}
        />
      </div>
    );
  }

  const TargetGroupCheck = (originalRow) => {
    // console.log(originalRow.targets,"routing check");
    // console.log(routingPlanLists,"routing check33333")
  };

  const ColumnGroup_list = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Name",
      accessor: "name",
    },
    {
      Header: "Simuldial",
      accessor: "simuldial",
      Cell: ({ value }) => (value ? "On" : "Off"),
    },
    {
      Header: "Target",
      accessor: "count",
    },
    {
      Header: "Status",
      accessor: buyerGroupStatus,
      id: "status",
    },
    {
      Header: "Add",
      accessor: (originalRow, rowIndex) => (
        <div>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              // TargetGroupCheck(originalRow)
              addRouteModel(originalRow);
            }}
            title="Add Routing"
          >
            <i className="fa-solid fa-plus"></i>
          </a>
        </div>
      ),
      id: "addRoute",
    },
  ];

  const addBuyerGroup = async () => {
    history.push("/admin/buyers/users/group");
    window.scrollTo(0, 0);
  };

  return (
    <Flex
      className="custom_form_style"
      direction="column"
      minH="100vh"
      align="center"
      mt={{ sm: "120px", md: "75px" }}
    >
      <Tabs variant="unstyled" mt="24px">
        <TabPanels mt="24px" maxW={{ md: "90%", lg: "100%" }} mx="auto">
          <TabPanel>
            <Card>
              <CardHeader mb="0px">
                <Text color={"#fff"} fontSize="lg" fontWeight="bold" mb="3px">
                  Create Campaign
                </Text>
              </CardHeader>
              <CardBody>
                <Flex direction="column" w="100%">
                  <Stack direction="column" spacing="20px">
                    <VSeparator className="verticleSeparator" />
                    <CardHeader mb="40px">
                      <Text
                        color={"#fff"}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        General Info
                      </Text>
                    </CardHeader>
                    <FormControl
                      className="payOut"
                      style={{ maxWidth: "780px" }}
                    >
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Campaign ID
                          </FormLabel>
                          <div className="inputPrice">
                            <Input
                              type="name"
                              className="payOutAmount"
                              placeholder="Campaign ID"
                              name="campaignID"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Campaign Name
                          </FormLabel>
                          <div className="inputPrice">
                            <Input
                              type="textarea"
                              bg="#0F1535"
                              border=".5px solid"
                              borderColor="rgba(226, 232, 240, 0.3)"
                              _hover="none"
                              color="#fff"
                              borderRadius="15px"
                              placeholder="Enter Campaign Name"
                              fontSize="xs"
                              name="name"
                              value={campaign.name}
                              onChange={handleChange}
                            />
                          </div>
                        </Flex>
                      </FormControl>
                      {/* <FormControl>
                        <Flex>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Number Format
                          </FormLabel>
                          <div className="inputPrice">
                            <Input
                              type="name"
                              className="payOutAmount"
                              placeholder="(nnn) nnn-nnnn"
                              name="numberFormat"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Report Duplicate Calls On
                          </FormLabel>
                          <div className="inputPrice">
                            {/* <Input
                              type="name"
                              className="payOutAmount"
                              placeholder="(nnn) nnn-nnnn"
                              name="numberFormat"
                              onChange={onPayoutChange}
                            /> */}
                            <div
                              style={{
                                display: "flex",
                                flexDirection: "row",
                              }}
                            >
                              <Select
                                onChange={onViewReportDuplicatesCallsOnChange}
                                value={payout.reportDuplicateCallsOn}
                                name="reportDuplicateCallsOn"
                                border=".5px solid"
                                borderColor="rgba(226, 232, 240, 0.3)"
                                _hover="none"
                                color="#fff"
                                bg="#0F1535"
                                borderRadius="15px"
                                color="gray.400"
                                fontSize="xs"
                              >
                                <option value="connect">Connect</option>
                                <option value="incoming">Incoming</option>
                                <option value="callLengthGreaterThan">
                                  Call Length Greater Than
                                </option>
                              </Select>
                              {viewReportDuplicateCallsOn === "show" ? (
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "5px",
                                  }}
                                >
                                  <Input
                                    onChange={onPayoutChange}
                                    value={payout.callLengthGreaterThanSeconds}
                                    name="callLengthGreaterThanSeconds"
                                    type="number"
                                    bg="#0F1535"
                                    border=".5px solid"
                                    borderColor="rgba(226, 232, 240, 0.3)"
                                    _hover="none"
                                    color="#fff"
                                    borderRadius="15px"
                                    placeholder="0"
                                    fontSize="xs"
                                    width="50%"
                                    ml="3%"
                                  />{" "}
                                  <span
                                    style={{
                                      fontWeight: "400",
                                      color: "#fff",
                                      fontSize: "14px",
                                    }}
                                  >
                                    seconds
                                  </span>
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </Flex>
                      </FormControl>
                      <FormControl>
                        <Flex>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Select Duplicate Time Period
                          </FormLabel>
                          <Select
                            onChange={onChangeHandleDuplicate}
                            value={payout.duplicateTimePeriod}
                            name="duplicateTimePeriod"
                            border=".5px solid"
                            borderColor="rgba(226, 232, 240, 0.3)"
                            _hover="none"
                            color="#fff"
                            bg="#0F1535"
                            borderRadius="15px"
                            color="gray.400"
                            fontSize="sm"
                            fontSize="xs"
                          >
                            <option value="">
                              Select duplicate time Period
                            </option>
                            <option value="day">1 day</option>
                            <option value="week">1 week</option>
                            <option value="month">1 month</option>
                          </Select>
                        </Flex>
                      </FormControl>
                      <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Route Previously Connected Calls
                          </FormLabel>
                          <div className="inputPrice">
                            <Button
                              variant="primary"
                              onClick={handleButtonClick1}
                              style={{
                                borderRadius: "0px",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                              backgroundColor={buttonColor1}
                            >
                              Normally
                            </Button>
                            <Button
                              onClick={handleButtonClick2}
                              style={{
                                borderRadius: "0px",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                              backgroundColor={buttonColor2}
                            >
                              To Original
                            </Button>
                            <Button
                              onClick={handleButtonClick3}
                              style={{
                                borderRadius: "0px",
                                color: "#fff",
                                fontWeight: "500",
                              }}
                              backgroundColor={buttonColor3}
                            >
                              To Different
                            </Button>
                          </div>
                        </Flex>
                        {payout.toDifferent !== "" ? (
                          <Flex>
                            <FormLabel htmlFor="email-alerts" mb="0">
                              Stricts
                            </FormLabel>
                            <div>
                              <Switch
                                style={{ flexBasis: "100%" }}
                                onChange={onstrict}
                                name="strict"
                                value={payout.strict}
                                defaultIsChecked={payout.strict}
                              />
                            </div>
                          </Flex>
                        ) : null}
                      </FormControl>
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Handle Anonymous Calls As Duplicate
                            <Tooltip
                              label="Handle Anonymous Calls As Duplicate"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onhandleAnonymousCallsAsDuplicate}
                              name="handleAnonymousCallsAsDuplicate"
                              value={payout.handleAnonymousCallsAsDuplicate}
                              defaultIsChecked={
                                payout.handleAnonymousCallsAsDuplicate
                              }
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Payout Once Per Caller{" "}
                            <Tooltip
                              label="Payout Once Per Caller"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onpayoutOncePerCaller}
                              name="payoutOncePerCaller"
                              value={payout.payoutOncePerCaller}
                              defaultIsChecked={payout.payoutOncePerCaller}
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Record Calls{" "}
                            <Tooltip
                              label="Record Calls"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onrecordCalls}
                              name="recordCalls"
                              value={payout.recordCalls}
                              defaultIsChecked={payout.recordCalls}
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Wait For Answer{" "}
                            <Tooltip
                              label="Wait For Answer"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onwaitForAnswer}
                              name="waitForAnswer"
                              value={payout.waitForAnswer}
                              defaultIsChecked={payout.waitForAnswer}
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Trim Silence{" "}
                            <Tooltip
                              label="Trim Silence"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={ontrimSilence}
                              name="trimSilence"
                              value={payout.trimSilence}
                              defaultIsChecked={payout.trimSilence}
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      {/* <FormControl>
                        <Flex>
                          <FormLabel
                            htmlFor="email-alerts"
                            mb="0"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Filter Anonymous Callers As Spam{" "}
                            <Tooltip
                              label="Filter Anonymous Callers As Spam"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onfilterAnonymousCallersAsSpam}
                              name="filterAnonymousCallersAsSpam"
                              value={payout.filterAnonymousCallersAsSpam}
                              defaultIsChecked={
                                payout.filterAnonymousCallersAsSpam
                              }
                            />
                          </div>
                        </Flex>
                      </FormControl> */}
                      {/* <FormControl style={{ margin: "20px 0px" }}>
                        <Flex>
                          <FormLabel htmlFor="email-alerts" mb="0">
                            Target Dial Attempts{" "}
                            <Tooltip
                              label="Target Dial Attempts"
                              placement="right"
                              hasArrow
                              fontSize="11px"
                              color="black"
                              bg="white"
                              arrowSize={7}
                            >
                              <QuestionIcon marginLeft="5px" />
                            </Tooltip>
                          </FormLabel>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <Input
                              onChange={onPayoutChange}
                              value={payout.targetDialAttempts}
                              name="targetDialAttempts"
                              type="number"
                              bg="#0F1535"
                              border=".5px solid"
                              borderColor="rgba(226, 232, 240, 0.3)"
                              _hover="none"
                              color="#fff"
                              borderRadius="15px"
                              placeholder="0"
                              fontSize="xs"
                              width="50%"
                              ml="3%"
                            />{" "}
                          </div>
                        </Flex>
                      </FormControl> */}
                      <FormControl>
                        <Flex>
                          <FormLabel
                            htmlFor="email-alerts"
                            mb="0"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Filter Repeat Callers
                          </FormLabel>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "5px",
                            }}
                          >
                            <Input
                              onChange={onPayoutChange}
                              value={payout.filterRepeatCallers}
                              name="filterRepeatCallers"
                              type="number"
                              bg="#0F1535"
                              border=".5px solid"
                              borderColor="rgba(226, 232, 240, 0.3)"
                              _hover="none"
                              color="#fff"
                              borderRadius="15px"
                              placeholder="0"
                              fontSize="xs"
                              width="50%"
                              ml="3%"
                            />{" "}
                            <span
                              style={{
                                fontWeight: "400",
                                color: "#fff",
                                fontSize: "14px",
                              }}
                            >
                              seconds
                            </span>
                          </div>
                        </Flex>
                      </FormControl>
                    </FormControl>

                    {/* <CardHeader mb="40px">
                      <Text
                        color={"#fff"}
                        fontSize="lg"
                        fontWeight="bold"
                        mb="3px"
                      >
                        Spam Filter
                      </Text>
                    </CardHeader> */}

                    <VSeparator className="verticleSeparator" />
                    <Text
                      align="center"
                      color={"#fff"}
                      fontSize="lg"
                      fontWeight="bold"
                    >
                      DEFAULT CAMPAIGN PAYOUT SETTINGS
                    </Text>
                    <FormControl className="payOut">
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Payout Type
                        </FormLabel>
                        <Flex style={{ margin: "0px" }}>
                          <input
                            type="radio"
                            name="payoutType"
                            value="1"
                            onChange={onPayoutChange}
                            defaultChecked
                          />
                          &nbsp;{" "}
                          <label htmlFor="fixedAmount">Fixed Amount</label>
                          <br />
                          &nbsp;{" "}
                          {/* <input
                            type="radio"
                            name="payoutType"
                            value="2"
                            onChange={onPayoutChange}
                          />
                          &nbsp;{" "}
                          <label htmlFor="Percentage">
                            Revshare Percentage
                          </label> */}
                          {/* <br /> */}
                        </Flex>
                      </Flex>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Payout On
                        </FormLabel>
                        <Select
                          onChange={onPayoutChange}
                          name="type"
                          border=".5px solid"
                          borderColor="rgba(226, 232, 240, 0.3)"
                          _hover="none"
                          color="#fff"
                          bg="#0F1535"
                          borderRadius="15px"
                          color="gray.400"
                          fontSize="sm"
                          fontSize="xs"
                        >
                          <option value="">Choose Type</option>
                          <option value="1">Call Length</option>
                        </Select>
                      </Flex>
                      <Flex className={callLengthShow}>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Call Length
                        </FormLabel>
                        <div className="inputPrice">
                          <Input
                            type="number"
                            className="payOutAmount"
                            name="callLength"
                            onChange={onPayoutChange}
                          />
                        </div>
                      </Flex>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Payout Amount
                        </FormLabel>
                        <div className="inputPrice">
                          <Input
                            type="number"
                            className="payOutAmount"
                            placeholder="$"
                            name="payoutAmount"
                            onChange={onPayoutChange}
                          />
                        </div>
                      </Flex>
                      <Flex>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Duplicate Payout
                        </FormLabel>
                        <Flex
                          className="duplicatePayouts"
                          style={{ margin: "0px" }}
                        >
                          <Flex>
                            <input
                              type="radio"
                              name="duplicatePayout"
                              value="disable"
                              onChange={onPayoutChange}
                            />
                            &nbsp; <label htmlFor="html">Disable</label>
                            &nbsp;{" "}
                            <input
                              type="radio"
                              name="duplicatePayout"
                              value="enable"
                              onChange={onPayoutChange}
                            />
                            &nbsp; <label htmlFor="css">Enable</label>
                            &nbsp;{" "}
                            <input
                              type="radio"
                              name="duplicatePayout"
                              value="timelimit"
                              onChange={onPayoutChange}
                            />
                            &nbsp;{" "}
                            <label htmlFor="javascript">Time Limit </label>
                          </Flex>
                        </Flex>
                      </Flex>
                      <Flex
                        className="payoutHours"
                        display="flex"
                        alignItems="center"
                      >
                        <FormLabel htmlFor="email-alerts" mb="0">
                          Campaign Hours
                        </FormLabel>
                        <div>
                          <Switch
                            style={{ flexBasis: "100%" }}
                            onChange={onPayoutHours}
                            name="enablePayoutHours"
                            value={payoutHoursEnabled}
                            defaultIsChecked={payoutHoursEnabled}
                          />
                          <div className="openClose hide">
                            <table>
                              <tr>
                                <th></th>
                                <th>Open</th>
                                <th>Close</th>
                                <th>Breaks</th>
                              </tr>
                              <tr>
                                <td>Monday-Sunday</td>
                                <td>
                                  {" "}
                                  <input
                                    type="time"
                                    name="startTime"
                                    value={payoutHours.startTime}
                                    onChange={onChangeHandle}
                                  />{" "}
                                </td>
                                <td>
                                  {" "}
                                  <input
                                    type="time"
                                    name="endTime"
                                    value={payoutHours.endTime}
                                    onChange={onChangeHandle}
                                  />{" "}
                                </td>
                                <td>
                                  <span>
                                    12:00pm <i>for</i>45min{" "}
                                    <i className="fa-solid fa-x"></i>
                                  </span>
                                  <button onClick={showBreak}>
                                    Add New Break
                                  </button>
                                </td>
                              </tr>
                            </table>
                            <div className={`addBreak ${addBreakShow}`}>
                              <table>
                                <tr>
                                  <td> Break Start Time : </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="time"
                                      name="startTime"
                                      onChange={onBreakChangeHandle}
                                    />{" "}
                                  </td>
                                </tr>
                                <tr>
                                  <td> Break Duration : </td>
                                  <td>
                                    {" "}
                                    <input
                                      type="number"
                                      name="duration"
                                      onChange={onBreakChangeHandle}
                                    />{" "}
                                    minutes
                                  </td>
                                </tr>
                                <tr>
                                  <td></td>
                                  <td>
                                    <button onClick={addBreakHandle}>
                                      Add Break
                                    </button>
                                    <button onClick={cancelBreak}>
                                      Cancel
                                    </button>
                                  </td>
                                </tr>
                              </table>
                            </div>
                          </div>
                        </div>
                      </Flex>

                      <Flex display="flex" alignItems="center">
                        <FormLabel htmlFor="email-alerts" mb="0">
                          Enable Campaign Caps
                        </FormLabel>
                        <Switch
                          style={{ flexBasis: "100%" }}
                          name="limitPayoutEnabled"
                          value={limitPayoutEnabled}
                          defaultIsChecked={limitPayoutEnabled}
                          onChange={onChangeLimitPayout}
                        />
                      </Flex>
                      <div className={limitPayoutShow}>
                        <Flex className="commomSwitch">
                          <Flex>
                            <FormLabel htmlFor="email-alerts" mb="0">
                              Global Cap{" "}
                              <Tooltip
                                label="Global Cap"
                                placement="right"
                                hasArrow
                                fontSize="11px"
                                color="black"
                                bg="white"
                                arrowSize={7}
                              >
                                <QuestionIcon marginLeft="5px" />
                              </Tooltip>
                            </FormLabel>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onGlobalCapChange}
                              name="enableGlobalCap"
                              value={enableGlobalCap}
                              defaultIsChecked={enableGlobalCap}
                            />
                          </Flex>
                          <div>
                            <Input
                              type="number"
                              className="payOutAmount"
                              placeholder=""
                              name="globalCap"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                        <Flex className="commomSwitch">
                          <Flex>
                            <FormLabel htmlFor="email-alerts" mb="0">
                              Monthly Cap{" "}
                              <Tooltip
                                label="Monthly Cap"
                                placement="right"
                                hasArrow
                                fontSize="11px"
                                color="black"
                                bg="white"
                                arrowSize={7}
                              >
                                <QuestionIcon marginLeft="5px" />
                              </Tooltip>
                            </FormLabel>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onMonthlyCapChange}
                              name="enableMonthlyCap"
                              value={enableMonthlyCap}
                              defaultIsChecked={enableMonthlyCap}
                            />
                          </Flex>
                          <div>
                            <Input
                              type="number"
                              className="payOutAmount"
                              placeholder=""
                              name="monthlyCap"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                        <Flex className="commomSwitch">
                          <Flex>
                            <FormLabel htmlFor="email-alerts" mb="0">
                              Daily Cap{" "}
                              <Tooltip
                                label="Daily Cap"
                                placement="right"
                                hasArrow
                                fontSize="11px"
                                color="black"
                                bg="white"
                                arrowSize={7}
                              >
                                <QuestionIcon marginLeft="5px" />
                              </Tooltip>
                            </FormLabel>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onDailyCapChange}
                              name="enableDailyCap"
                              value={enableDailyCap}
                              defaultIsChecked={enableDailyCap}
                            />
                          </Flex>
                          <div>
                            <Input
                              type="number"
                              className="payOutAmount"
                              placeholder=""
                              name="dailyCap"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                        <Flex className="commomSwitch">
                          <Flex>
                            <FormLabel htmlFor="email-alerts" mb="0">
                              Hourly Cap{" "}
                              <Tooltip
                                label="Hourly Cap"
                                placement="right"
                                hasArrow
                                fontSize="11px"
                                color="black"
                                bg="white"
                                arrowSize={7}
                              >
                                <QuestionIcon marginLeft="5px" />
                              </Tooltip>
                            </FormLabel>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onHourlyCapChange}
                              name="enableHourlyCap"
                              value={enableHourlyCap}
                              defaultIsChecked={enableHourlyCap}
                            />
                          </Flex>
                          <div>
                            <Input
                              type="number"
                              className="payOutAmount"
                              placeholder=""
                              name="houlyCap"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                        <Flex className="commomSwitch">
                          <Flex>
                            <FormLabel htmlFor="email-alerts" mb="0">
                              Concurrency Cap{" "}
                              <Tooltip
                                label=" Concurrency Cap"
                                placement="right"
                                hasArrow
                                fontSize="11px"
                                color="black"
                                bg="white"
                                arrowSize={7}
                              >
                                <QuestionIcon marginLeft="5px" />
                              </Tooltip>
                            </FormLabel>
                            <Switch
                              style={{ flexBasis: "100%" }}
                              onChange={onConCurrencyCapChange}
                              name="enableConCurrencyCap"
                              value={enableConCurrencyCap}
                              defaultIsChecked={enableConCurrencyCap}
                            />
                          </Flex>
                          <div>
                            <Input
                              type="number"
                              className="payOutAmount"
                              placeholder=""
                              name="concurrencyCap"
                              onChange={onPayoutChange}
                            />
                          </div>
                        </Flex>
                      </div>
                    </FormControl>
                    <VSeparator className="verticleSeparator" />
                    <Text
                      align="center"
                      color={"#fff"}
                      fontSize="xl"
                      fontWeight="bold"
                    >
                      Publisher's Settings
                    </Text>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Enable Publishers For This Campaign
                      </FormLabel>
                      <button
                        onClick={() => showPublisherForm()}
                        className="add_publisher_btn"
                        style={{
                          color: "#fff",
                          borderColor: " #fff",
                          marginBottom: "20px",
                          border: "1px solid",
                          padding: "5px 10px",
                          borderRadius: "20px",
                          fontSize: "12px",
                          marginTop: "15px",
                        }}
                      >
                        {" "}
                        Add Publishers{" "}
                      </button>
                      <Card px="0px">
                        <CardHeader px="22px" mb="32px">
                          <Text color="#fff" fontSize="lg" fontWeight="bold">
                            Number
                          </Text>
                        </CardHeader>
                        <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                          <Table
                            className="report_custom_table newpublishTable"
                            variant="simple"
                          >
                            <Thead>
                              <Tr>
                                <Th
                                  className="publisherName"
                                  color="gray.400"
                                  fontSize="10px"
                                  fontFamily="Inter"
                                  borderColor="#56577A"
                                >
                                  ENABLED
                                </Th>
                                <Th
                                  color="gray.400"
                                  fontSize="10px"
                                  fontFamily="Inter"
                                  borderColor="#56577A"
                                >
                                  PUBLISHER
                                </Th>
                                <Th
                                  color="gray.400"
                                  fontSize="10px"
                                  fontFamily="Inter"
                                  borderColor="#56577A"
                                >
                                  PHONE NUMBERS
                                </Th>
                                <Th
                                  color="gray.400"
                                  fontSize="10px"
                                  fontFamily="Inter"
                                  borderColor="#56577A"
                                >
                                  CONFIGURATION OVERRIDE
                                </Th>
                                <Th
                                  color="gray.400"
                                  fontSize="10px"
                                  fontFamily="Inter"
                                  borderColor="#56577A"
                                >
                                  STATUS
                                </Th>
                              </Tr>
                            </Thead>
                            <Tbody>
                              {/*************Add Publisher Form*********** */}
                              <Tr>
                                <td></td>
                                <td colspan="4">
                                  <div
                                    className={`add_publisher_block ${publisherFormToggle}`}
                                  >
                                    <div className="new_publisher_form">
                                      <CardBody>
                                        <Table>
                                          <Tr>
                                            <Td color="gray.400">
                                              {" "}
                                              Publisher{" "}
                                            </Td>
                                            <Td>
                                              {loadingData ? (
                                                <p>Loading Please wait...</p>
                                              ) : (
                                                <select
                                                  id="select_user_option"
                                                  name="publisherId"
                                                  onChange={
                                                    onChangeNewPublisherDropdown
                                                  }
                                                >
                                                  <option value="">
                                                    Choose User Options
                                                  </option>
                                                  {publisherLists.length > 0 ? (
                                                    publisherLists.map(
                                                      (item, index) => (
                                                        <>
                                                          <option
                                                            value={item._id}
                                                          >
                                                            {item.name}
                                                          </option>
                                                        </>
                                                      )
                                                    )
                                                  ) : (
                                                    <></>
                                                  )}
                                                </select>
                                              )}
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td color="gray.400">
                                              {" "}
                                              Create new united state number{" "}
                                            </Td>
                                            <Td>
                                              {" "}
                                              <Switch
                                                style={{ flexBasis: "100%" }}
                                                name="createNewNumber"
                                                defaultIsChecked={
                                                  createNewUsNumberEnabled
                                                }
                                                onChange={
                                                  newUsNumberStatusChange
                                                }
                                                isDisabled={true}
                                              />{" "}
                                            </Td>
                                          </Tr>
                                          <Tr
                                            className={
                                              existingNumberShow
                                                ? "show"
                                                : "hide"
                                            }
                                          >
                                            <Td
                                              color="gray.400"
                                              vertical-align="top"
                                            >
                                              {" "}
                                              Add Existing number{" "}
                                            </Td>
                                            <Td>
                                              <Flex className="searchNumber">
                                                <div className="exixtingNumber">
                                                  <input
                                                    type="text"
                                                    placeholder="Search number"
                                                    color="gray.400"
                                                    background="transparent"
                                                  />
                                                  <div className="dragNumber">
                                                    <ul>
                                                      {numberListsLoading ? (
                                                        <p>
                                                          Loading Please wait...
                                                        </p>
                                                      ) : (
                                                        <>
                                                          {numberLists.length >
                                                          0 ? (
                                                            numberLists.map(
                                                              (item, index) => (
                                                                <>
                                                                  <li
                                                                    data-id={
                                                                      item._id
                                                                    }
                                                                    data-phone={
                                                                      item.phone
                                                                    }
                                                                  >
                                                                    {" "}
                                                                    {
                                                                      item.phone
                                                                    }{" "}
                                                                  </li>
                                                                </>
                                                              )
                                                            )
                                                          ) : (
                                                            <h1>
                                                              Phone Number Found
                                                            </h1>
                                                          )}
                                                        </>
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                                <i className="fa-solid fa-arrow-right-arrow-left"></i>
                                                <div className="exixtingNumber">
                                                  <input
                                                    type="text"
                                                    placeholder="Search number"
                                                    color="gray.400"
                                                    background="transparent"
                                                  />
                                                  <div className="dragNumber">
                                                    <ul>
                                                      {newPublisher.phone
                                                        .length > 0 ? (
                                                        newPublisher.phone.map(
                                                          (item, index) => (
                                                            <>
                                                              <li
                                                                data-phone={
                                                                  item
                                                                }
                                                              >
                                                                {" "}
                                                                {item}{" "}
                                                              </li>
                                                            </>
                                                          )
                                                        )
                                                      ) : (
                                                        <h1>No Result Found</h1>
                                                      )}
                                                    </ul>
                                                  </div>
                                                </div>
                                              </Flex>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td color="gray.400">
                                              Number Type{" "}
                                            </Td>
                                            <Td>
                                              <Flex>
                                                <button
                                                  data-id="1"
                                                  onClick={changeNumberType}
                                                >
                                                  Toll Free
                                                </button>{" "}
                                                <button
                                                  data-id="2"
                                                  onClick={changeNumberType}
                                                >
                                                  Local
                                                </button>
                                              </Flex>
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td color="gray.400"> Prefix </Td>
                                            <Td>
                                              {" "}
                                              <input
                                                type="text"
                                                name="prefix"
                                                placeholder="Optional"
                                                onChange={
                                                  onChangeNewPublisherHandle
                                                }
                                              />{" "}
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td color="gray.400">
                                              {" "}
                                              Bill Card{" "}
                                            </Td>
                                            <Td>
                                              {" "}
                                              <Switch
                                                style={{ flexBasis: "100%" }}
                                                name="enabledBillCard"
                                                defaultIsChecked={
                                                  billCardEnabled
                                                }
                                              />{" "}
                                            </Td>
                                          </Tr>
                                          <Tr>
                                            <Td color="gray0.40"> </Td>
                                            <Td>
                                              {" "}
                                              <Flex>
                                                <button
                                                  onClick={createNewPublisher}
                                                >
                                                  Add
                                                </button>{" "}
                                                <button
                                                  className="cancel"
                                                  onClick={cancelPublisher}
                                                >
                                                  Cancel
                                                </button>
                                              </Flex>{" "}
                                            </Td>
                                          </Tr>
                                        </Table>
                                      </CardBody>
                                    </div>
                                  </div>
                                </td>
                              </Tr>
                              {/*************Table Rows*********** */}
                              {publishersSlack.length > 0 ? (
                                publishersSlack.map((item, index) => (
                                  <>
                                    <Tr>
                                      <Td style={{ textAlign: "left" }}>
                                        <i className="fa-solid fa-check"></i>
                                      </Td>
                                      <Td minW="50px" borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="10px"
                                          fontWeight="bold"
                                        >
                                          {item.publisherName}{" "}
                                          <i
                                            className="fa-solid fa-pen-to-square"
                                            onClick={() =>
                                              handleEditPublisher(
                                                item.publisherId
                                              )
                                            }
                                          ></i>
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        {item.phone.length > 0 ? (
                                          item.phone.map((item, indx) => (
                                            <>
                                              <Text
                                                className="multipleNumber"
                                                color="#fff"
                                                fontSize="10px"
                                                fontWeight="bold"
                                              >
                                                {item}
                                                <i
                                                  className="fa-solid fa-xmark"
                                                  data-publisher-key={index}
                                                  data-phone-key={indx}
                                                  onClick={deletePublisherPhone}
                                                ></i>
                                              </Text>
                                            </>
                                          ))
                                        ) : (
                                          <Text
                                            className="multipleNumber"
                                            color="#fff"
                                            fontSize="10px"
                                            fontWeight="bold"
                                          >
                                            +XXXXXXXXXXX
                                          </Text>
                                        )}
                                      </Td>
                                      <Td minW="50px" borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="10px"
                                          fontWeight="bold"
                                        >
                                          Using Campaign Payout Setting
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Flex color="#fff" className="icons">
                                          <i className="fa-solid fa-pen-to-square"></i>
                                          <i
                                            className="fa-solid fa-plus"
                                            data-editPhoneId={index}
                                            onClick={addPhoneToggle}
                                          ></i>
                                          <i
                                            className="fa-solid fa-trash"
                                            data-editPhoneId={index}
                                            onClick={deletePublisher}
                                          ></i>
                                        </Flex>
                                      </Td>
                                    </Tr>

                                    <Tr
                                      className={
                                        addPhoneFormShow == index
                                          ? "show"
                                          : "hide"
                                      }
                                    >
                                      <Td colspan="5">
                                        <div className="plusData">
                                          <span
                                            className="closeButton"
                                            onClick={closeAddPhoneForm}
                                          >
                                            {" "}
                                            Close{" "}
                                            <i className="fa-solid fa-xmark"></i>{" "}
                                          </span>
                                          <Table>
                                            <Tr>
                                              <Td color="gray.400">
                                                {" "}
                                                Create new united state number{" "}
                                              </Td>
                                              <Td>
                                                {" "}
                                                <Switch
                                                  style={{ flexBasis: "100%" }}
                                                  name="enableNewUsNumber"
                                                  defaultIsChecked={
                                                    addNewUsNumberEnabled
                                                  }
                                                  onChange={
                                                    addUsNumberStatusChange
                                                  }
                                                />{" "}
                                              </Td>
                                            </Tr>

                                            <Tr
                                              className={
                                                addExistingNumberShow
                                                  ? "show"
                                                  : "hide"
                                              }
                                            >
                                              <Td
                                                color="gray.400"
                                                vertical-align="top"
                                              >
                                                {" "}
                                                Add Existing number{" "}
                                              </Td>
                                              <Td>
                                                <Flex className="searchNumber">
                                                  <div className="exixtingNumber">
                                                    <input
                                                      type="text"
                                                      placeholder="Search number"
                                                      color="gray.400"
                                                      background="transparent"
                                                    />
                                                    <div className="dragNumber">
                                                      <ul>
                                                        {numberListsLoading ? (
                                                          <p>
                                                            Loading Please
                                                            wait...
                                                          </p>
                                                        ) : (
                                                          <>
                                                            {numberLists.length >
                                                            0 ? (
                                                              numberLists.map(
                                                                (
                                                                  item,
                                                                  index
                                                                ) => (
                                                                  <>
                                                                    <li
                                                                      data-id={
                                                                        item._id
                                                                      }
                                                                      data-phone={
                                                                        item.phone
                                                                      }
                                                                      onClick={
                                                                        addNumberToExistingList
                                                                      }
                                                                    >
                                                                      {" "}
                                                                      {
                                                                        item.phone
                                                                      }{" "}
                                                                    </li>
                                                                  </>
                                                                )
                                                              )
                                                            ) : (
                                                              <h1>
                                                                Phone Number
                                                                Found
                                                              </h1>
                                                            )}
                                                          </>
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                  <i className="fa-solid fa-arrow-right-arrow-left"></i>
                                                  <div className="exixtingNumber">
                                                    <input
                                                      type="text"
                                                      placeholder="Search number"
                                                      color="gray.400"
                                                      background="transparent"
                                                    />
                                                    <div className="dragNumber">
                                                      <ul>
                                                        {addNewNumber.phone
                                                          .length > 0 ? (
                                                          addNewNumber.phone.map(
                                                            (item, index) => (
                                                              <>
                                                                <li
                                                                  data-phone={
                                                                    item
                                                                  }
                                                                  onClick={
                                                                    addNumberToExistingList
                                                                  }
                                                                >
                                                                  {" "}
                                                                  {item}{" "}
                                                                </li>
                                                              </>
                                                            )
                                                          )
                                                        ) : (
                                                          <h1>
                                                            No Result Found
                                                          </h1>
                                                        )}
                                                      </ul>
                                                    </div>
                                                  </div>
                                                </Flex>
                                              </Td>
                                            </Tr>
                                            <Tr>
                                              <Td color="gray.400">
                                                Number Type{" "}
                                              </Td>
                                              <Td>
                                                <Flex className="bottomButtons">
                                                  <button
                                                    data-id="1"
                                                    onClick={
                                                      changeExistingNumberType
                                                    }
                                                  >
                                                    Toll Free
                                                  </button>{" "}
                                                  <button
                                                    data-id="2"
                                                    onClick={
                                                      changeExistingNumberType
                                                    }
                                                  >
                                                    Local
                                                  </button>
                                                </Flex>
                                              </Td>
                                            </Tr>
                                            <Tr>
                                              <Td color="gray.400"> Prefix </Td>
                                              <Td>
                                                {" "}
                                                <input
                                                  type="text"
                                                  name="prefix"
                                                  placeholder="Optional"
                                                  onChange={
                                                    onChangeNewNumbertHandle
                                                  }
                                                />{" "}
                                              </Td>
                                            </Tr>
                                            <Tr>
                                              <Td color="gray.400">
                                                {" "}
                                                Bill Card{" "}
                                              </Td>
                                              <Td>
                                                {" "}
                                                <Switch
                                                  style={{ flexBasis: "100%" }}
                                                  name="enabledBillCard"
                                                  defaultIsChecked={
                                                    newNumberBillCardEnabled
                                                  }
                                                />{" "}
                                              </Td>
                                            </Tr>
                                            <Tr>
                                              <Td color="gray0.40"> </Td>
                                              <Td>
                                                {" "}
                                                <Flex className="bottomButtons">
                                                  <button
                                                    onClick={addNewNumberBtn}
                                                  >
                                                    Add
                                                  </button>{" "}
                                                  <button
                                                    className="cancel"
                                                    onClick={closeAddPhoneForm}
                                                  >
                                                    Cancel
                                                  </button>
                                                </Flex>{" "}
                                              </Td>
                                            </Tr>
                                          </Table>
                                        </div>
                                      </Td>
                                    </Tr>
                                  </>
                                ))
                              ) : (
                                <Tr>
                                  <Td colspan="5">
                                    <h1 style={{ color: "#fff" }}>
                                      No Publisher added yet.
                                    </h1>
                                  </Td>
                                </Tr>
                              )}

                              {/*************Table Rows Update Number*********** */}
                            </Tbody>
                          </Table>
                        </CardBody>
                      </Card>

                      {/* {loadingData ? (
                        <p>Loading Please wait...</p>
                      ) : (
                        <SearchTable1 tableData={publishers} columnsData={columnsData} />
                      )} */}
                    </FormControl>
                    <VSeparator />
                    <Text
                      align="center"
                      color={"#fff"}
                      fontSize="xl"
                      fontWeight="bold"
                    >
                      Routing Settings
                    </Text>
                    <FormControl>
                      <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                        Call Routing
                      </FormLabel>
                      <Select
                        onChange={handleChange}
                        value={campaign.phonetype}
                        name="callrouting"
                        border=".5px solid"
                        borderColor="rgba(226, 232, 240, 0.3)"
                        _hover="none"
                        color="#fff"
                        bg="#0F1535"
                        borderRadius="15px"
                        color="gray.400"
                        fontSize="sm"
                        fontSize="xs"
                      >
                        <option value="">Choose Routing Option</option>
                        <option value="1">Priority(Standard)</option>
                        <option value="2">Call Flow</option>
                      </Select>
                    </FormControl>

                    <FormLabel fontWeight="bold" color={"#fff"} fontSize="xs">
                      Choose Targets
                    </FormLabel>
                    <button
                      onClick={() => showTargetForm()}
                      className="add_publisher_btn"
                      style={{
                        color: "#fff",
                        borderColor: " #fff",
                        width: "max-content",
                        marginBottom: "20px",
                        border: "1px solid",
                        padding: "5px 10px",
                        borderRadius: "20px",
                        fontSize: "12px",
                        marginTop: "15px",
                      }}
                    >
                      {" "}
                      Add Target{" "}
                    </button>
                    <div className={`add_publisher_block ${targetFormToggle}`}>
                      <div className="new_target_form">
                        <CardBody>
                          <Flex direction="column" w="100%">
                            <Stack direction="column" spacing="20px">
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Name
                                </FormLabel>
                                <Input
                                  type="text"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter Publisher Name"
                                  fontSize="xs"
                                  name="name"
                                  value={buyer.name}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Email
                                </FormLabel>
                                <Input
                                  name="email"
                                  type="text"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter Buyer Email"
                                  fontSize="xs"
                                  value={buyer.email}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Password
                                </FormLabel>
                                <Input
                                  name="password"
                                  type="password"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter Buyer Password"
                                  fontSize="xs"
                                  value={buyer.password}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  SubID
                                </FormLabel>
                                <Input
                                  type="number"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter SubID"
                                  fontSize="xs"
                                  name="subid"
                                  value={buyer.subid}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Type
                                </FormLabel>
                                <Select
                                  onChange={handleAddTarget}
                                  name="phonetype"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  bg="#0F1535"
                                  borderRadius="15px"
                                  color="gray.400"
                                  fontSize="sm"
                                  fontSize="xs"
                                >
                                  <option value="">Select Phone Type</option>
                                  <option value="1">Number</option>
                                  <option value="2">SIP</option>
                                </Select>
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Phone Number
                                </FormLabel>
                                <Input
                                  type="text"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter Target Phone"
                                  fontSize="xs"
                                  name="phoneNumber"
                                  value={buyer.phoneNumber}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Connection Timeout
                                </FormLabel>
                                <Input
                                  type="number"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter Connection timeout"
                                  fontSize="xs"
                                  name="connectiontimeout"
                                  value={buyer.connectiontimeout}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  CC CAP
                                </FormLabel>
                                <Input
                                  type="number"
                                  bg="#0F1535"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  borderRadius="15px"
                                  placeholder="Enter CCCAP"
                                  fontSize="xs"
                                  name="cccap"
                                  value={buyer.cccap}
                                  onChange={handleAddTarget}
                                />
                              </FormControl>
                              <FormControl>
                                <FormLabel
                                  fontWeight="bold"
                                  color={"#fff"}
                                  fontSize="xs"
                                >
                                  Timezone
                                </FormLabel>
                                <Select
                                  onChange={handleAddTarget}
                                  value={buyer.timezone}
                                  name="timezone"
                                  border=".5px solid"
                                  borderColor="rgba(226, 232, 240, 0.3)"
                                  _hover="none"
                                  color="#fff"
                                  bg="#0F1535"
                                  borderRadius="15px"
                                  color="gray.400"
                                  fontSize="xs"
                                >
                                  <option value="">Select Time Zone</option>
                                  <option value="(UTC-05:00) Eastern Time(US & Canada))">
                                    (UTC-05:00) Eastern Time(US & Canada))
                                  </option>
                                  <option value="UTC+10:30 — Lord Howe Island">
                                    UTC+10:30 — Lord Howe Island
                                  </option>
                                </Select>
                              </FormControl>
                            </Stack>
                            <Flex justify="space-between">
                              <Button
                                variant="brand"
                                alignSelf="flex-end"
                                mt="24px"
                                w="100px"
                                h="35px"
                                onClick={createNewbuyer}
                              >
                                <Text fontSize="10px" color="#fff">
                                  Add
                                </Text>
                              </Button>
                              <Button
                                variant="brand"
                                alignSelf="flex-end"
                                mt="24px"
                                w="100px"
                                h="35px"
                                onClick={cancelTarget}
                              >
                                <Text fontSize="10px" color="#fff">
                                  Cancel
                                </Text>
                              </Button>
                            </Flex>
                          </Flex>
                        </CardBody>
                      </div>
                    </div>

                    <div className="tabs_new">
                      <div className="w3-bar w3-black">
                        <button
                          data-tab="tab-1"
                          className={`w3-bar-item w3-button ${tabMenu1}`}
                          onClick={tabHandle}
                        >
                          Target
                        </button>
                        <button
                          data-tab="tab-2"
                          className={`w3-bar-item w3-button ${tabMenu2}`}
                          onClick={tabHandle}
                        >
                          Group
                        </button>
                      </div>
                    </div>

                    <Flex className="routingTarget">
                      {/* **********tab*********** */}

                      <FormControl>
                        <div id="tab-1" className={`w3-container city ${tab1}`}>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                          >
                            Choose Targets
                          </FormLabel>

                          {loadingBuyerData ? (
                            <p>Loading Please wait...</p>
                          ) : (
                            <SearchTable1
                              tableData={buyers}
                              columnsData={columnTargetData}
                            />
                          )}
                        </div>
                        <div id="tab-2" className={`w3-container city ${tab2}`}>
                          <FormLabel
                            fontWeight="bold"
                            color={"#fff"}
                            fontSize="xs"
                            style={{
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            <Text>Choose Targets Group</Text>
                            <button
                              className="add_publisher_btn"
                              style={{
                                color: "#fff",
                                borderColor: " #fff",
                                width: "max-content",
                                border: "1px solid",
                                padding: "5px 10px",
                                borderRadius: "20px",
                                fontSize: "12px",
                                marginRight: ".5rem",
                              }}
                              onClick={addBuyerGroup}
                            >
                              {" "}
                              New
                            </button>
                          </FormLabel>

                          {targetgroup.length == 0 ? (
                            <p>Loading Please wait...</p>
                          ) : (
                            <SearchTable1
                              tableData={targetgroup}
                              columnsData={ColumnGroup_list}
                            />
                          )}
                        </div>
                      </FormControl>
                      {/* **********tab*********** */}
                      <FormControl>
                        <FormLabel
                          fontWeight="bold"
                          color={"#fff"}
                          fontSize="xs"
                        >
                          Routing Plan
                        </FormLabel>
                        <Card px="0px" style={{ marginTop: "10px" }}>
                          <CardBody overflowX={{ sm: "scroll", md: "hidden" }}>
                            <Table className="targetTable" variant="simple">
                              <Thead>
                                <Tr>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Priority
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Weight
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Name
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Destination
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Type
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Revenue
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Status
                                  </Th>
                                  <Th
                                    color="gray.400"
                                    fontSize="10px"
                                    fontFamily="Inter"
                                    borderColor="#56577A"
                                  >
                                    Action
                                  </Th>
                                </Tr>
                              </Thead>
                              <Tbody>
                                {routingPlanLists.length > 0 ? (
                                  routingPlanLists.map((item, index) => (
                                    <Tr>
                                      <Td borderColor="#56577A">
                                        <Stack direction="row" spacing="16px">
                                          <Text
                                            color="#fff"
                                            fontSize="sm"
                                            fontWeight="bold"
                                          >
                                            <Input
                                              type="number"
                                              placeholder="1"
                                              value={item.priority}
                                            />
                                          </Text>
                                        </Stack>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="sm"
                                          fontWeight="bold"
                                        >
                                          <Input
                                            type="number"
                                            placeholder="1"
                                            value={item.weight}
                                          />
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="sm"
                                          fontWeight="bold"
                                        >
                                          {item.name}
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="sm"
                                          fontWeight="bold"
                                        >
                                          {item.phone}
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="sm"
                                          fontWeight="bold"
                                        >
                                          {item.callType == "1"
                                            ? "Call Length"
                                            : "Dial"}
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="sm"
                                          fontWeight="bold"
                                        >
                                          ${item.revenue}
                                        </Text>
                                      </Td>
                                      <Td borderColor="#56577A">
                                        {item.status == "1" ? (
                                          <i
                                            className="fa-sharp fa-solid fa-circle"
                                            style={{
                                              color: "#22ca0c",
                                              fontSize: "12px",
                                            }}
                                          />
                                        ) : (
                                          <i
                                            className="fa-sharp fa-solid fa-circle"
                                            style={{
                                              color: "#982E2B",
                                              fontSize: "12px",
                                            }}
                                          />
                                        )}
                                      </Td>
                                      <Td borderColor="#56577A">
                                        <Text
                                          color="#fff"
                                          fontSize="sm"
                                          fontWeight="bold"
                                        >
                                          <i className="fa-solid fa-pen-to-square"></i>
                                          <a
                                            href="#"
                                            onClick={(e) => {
                                              e.preventDefault();
                                              deleteRouting(index);
                                            }}
                                            title="Delete Routing"
                                          >
                                            <i className="fa-solid fa-trash"></i>
                                          </a>
                                        </Text>
                                      </Td>
                                    </Tr>
                                  ))
                                ) : (
                                  <Tr>
                                    <Td colspan="8">
                                      <h1
                                        color="#fff"
                                        fontSize="sm"
                                        fontWeight="bold"
                                        style={{
                                          color: "#fff",
                                          fontSize: "12px",
                                        }}
                                      >
                                        There are No Routing Plans Added..
                                      </h1>
                                    </Td>
                                  </Tr>
                                )}
                              </Tbody>
                            </Table>
                          </CardBody>
                        </Card>
                      </FormControl>
                    </Flex>
                  </Stack>
                  <Flex justify="space-between">
                    <Button
                      variant="brand"
                      alignSelf="flex-end"
                      mt="24px"
                      w="100px"
                      h="35px"
                      onClick={createCampaign}
                    >
                      <Text fontSize="10px" color="#fff">
                        Create
                      </Text>
                    </Button>
                  </Flex>
                </Flex>
              </CardBody>
            </Card>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Modal show={show} onHide={handleClose} className="routingPlanModel">
        <Modal.Header closeButton>
          <Modal.Title>Add Routing</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="form-group">
            <label htmlFor="priority">Enter Priority</label>
            <input
              type="number"
              className="form-control"
              name="priority"
              value={routingPlan.priority}
              onChange={handleRoutingChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="weight">Enter Weight</label>
            <input
              type="number"
              className="form-control"
              name="weight"
              value={routingPlan.weight}
              onChange={handleRoutingChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Type</label>
            <select
              className="form-select"
              aria-label="Default select example"
              name="callType"
              onChange={handleRoutingChange}
            >
              <option value="0">Choose Option</option>
              <option value="1">Call Length</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Call Length(Sec)</label>
            <input
              type="number"
              className="form-control"
              name="callLength"
              placeholder="30"
              value={routingPlan.callLength}
              onChange={handleRoutingChange}
            />
          </div>
          <div className="form-group">
            <label htmlFor="revenue">Enter Revenue($)</label>
            <input
              type="number"
              className="form-control"
              name="revenue"
              placeholder="30"
              value={routingPlan.revenue}
              onChange={handleRoutingChange}
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button1 variant="secondary" onClick={handleClose}>
            Close
          </Button1>
          <Button1 variant="primary" onClick={addRoutingPlan}>
            Create
          </Button1>
        </Modal.Footer>
      </Modal>
    </Flex>
  );
}

export default NewUser;
