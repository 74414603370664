import React from "react";

// Chakra imports
import {
  Flex,
  Text,
  Table,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Stack,
  Select,
  Icon,
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Box,
  FormControl,
  FormLabel,
  Input,
  VStack,
  Checkbox,
  CheckboxGroup,
} from "@chakra-ui/react";
import { GrFormNext, GrFormPrevious } from "react-icons/gr";
import axios from "axios";

// Custom Components
import Card from "components/Card/Card";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { useState, useEffect } from "react";
import packageJson from "../../../appConfig.js";
import SearchTable1 from 'components/Tables/SearchTable1.js'
import Loading from "components/Loading.js";

const MessageTable = (props) => {
  const [getMessage, setGetMessage] = useState([]);
  const [loadingData, setLoadingData] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [selectedText, setSelectedText] = useState("");
  const [selectedItems, setSelectedItems] = useState([]);
  const [showFilter, setShowFilter] = useState(false);
  const apiUrl = packageJson.apiurl;
  const user = JSON.parse(localStorage?.getItem("user"));
  const allItems = [
    "+1 2406508761",
    "+1 2406508762",
    "+1 2406508763",
    "+1 24065087654",
    "+1 24065088767",
    "+1 2406508717",
  ];
  const totalRows = getMessage?.length ?? 0;
  const totalPages = Math.ceil(totalRows / rowsPerPage);
  const startIndex = (currentPage - 1) * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const currentRows = getMessage?.slice(startIndex, endIndex);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const switcher = props.switcher;

  useEffect(() => {
    const getData = async () => {
      const requestOptions = {
        method: "POST",
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({ by: user.id }),
      };
      let result = await fetch(
        apiUrl + "/api/logs/admin/message",
        requestOptions
      );
      result = await result.json();
      setGetMessage(result?.messages);
      setLoadingData(false);
    };
    if (loadingData) {
      // if the result is not ready so you make the axios call
      getData();
    }
  }, []);



  const handlePageChange = (newPage) => {
    setCurrentPage(newPage);
  };

  const handleRowsPerPageChange = (event) => {
    const newRowsPerPage = parseInt(event.target.value);
    setRowsPerPage(newRowsPerPage);
    setCurrentPage(1); // Reset to the first page
  };

  useEffect(() => {
    setCurrentPage(1); // Reset to the first page when rowsPerPage changes
  }, [rowsPerPage]);

  const handleRowClick = (text) => {
    setSelectedText(text);
    onOpen();
  };

  // checkbox
  const handleCheckboxChange = (checkedItems) => {
    setSelectedItems(checkedItems);
  };

  const handleSelectAll = () => {
    setSelectedItems(allItems);
  };

  const handleDeselectAll = () => {
    setSelectedItems([]);
  };

  const isAllSelected = selectedItems.length === allItems.length;
  function formatDateString(dateString) {
    const options = {
      year: 'numeric',
      month: 'short',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
  
    const formattedDate = new Date(dateString).toLocaleString('en-US', options);
    return formattedDate;
  }

  const messageColumns = [
    {
      Header: "S.No",
      accessor: (row, index) => index + 1,
    },
    {
      Header: "Creation Date",
      accessor: row => row.date.split("T")[0],
    },
    {
      Header: "Phone Number",
      accessor: "to",
    },
    {
      Header: "From",
      accessor: "from",
    },
    {
      Header: "Text",
      accessor: (row, index) => {
        return (<Text
          color="#fff"
          fontSize="10px"
          onClick={() => handleRowClick(row.body)}
          my="10px"
          sx={{
            cursor: "pointer",
          }}
        >
          <i className="fa-solid fa-eye"></i>
        </Text>)
      },
    }
  ];
  
  return (
    <Card>
    <CardBody>{switcher}</CardBody>
      <CardHeader px="22px" mb="2px">
        <Flex direction="column">
          <Text fontSize="lg" mb="5px" color="#fff">
            Text Messages
          </Text>
        </Flex>
      </CardHeader>
      <CardBody>
        {/* <Flex
          justify="space-between"
          align="center"
          w="100%"
          wrap="wrap"
        >
          <Stack direction="row" spacing="12px" align="center" my="30px">
            <Select
              value={rowsPerPage}
              onChange={handleRowsPerPageChange}
              color="gray.400"
              bg="#0F1535"
              border="0.5px solid"
              borderColor="#E2E8F04D"
              borderRadius="15px"
              size="sm"
              maxW="75px"
              cursor="pointer"
            >
              {[5, 10, 15, 20, 25].map((value) => (
                <option key={value} value={value}>
                  {value}
                </option>
              ))}
            </Select>
            <Text fontSize="xs" color="gray.400" fontWeight="500" width="200px">
              entries per page
            </Text>
          </Stack>
          <Button
            variant="outlineWhite"
            w="auto"
            h="35px"
            onClick={props.openModal}
          >
            <Text fontSize="10px" color="#fff">
              Advanced Search Filters
            </Text>
          </Button>
        </Flex> */}
        {showFilter && (
          <div
            className="new_target_form"
            style={{
              margin: " 0px 22px 40px 22px",
              boxShadow: "0px 0px 3px #fff",
            }}
          >
            <Box>
              <Text fontSize="xl" color="#fff">
                Advanced Search Filters
              </Text>
              <Text fontSize="sm" color="#fff">
                Filter your search results by selecting numbers of your choice
                in a specific date range
              </Text>
            </Box>
            <Stack direction="column" spacing="20px">
              <FormControl mt="22px">
                <FormLabel fontWeight="bold" fontSize="xs" color="#fff">
                  Number
                </FormLabel>
                <Flex align="center">
                  <Input
                    name="Number"
                    type="text"
                    bg="#0F1535"
                    border=".5px solid"
                    borderColor="rgba(226, 232, 240, 0.3)"
                    _hover="none"
                    color="#fff"
                    borderRadius="15px"
                    fontSize="xs"
                    placeholder="search"
                  />
                </Flex>
              </FormControl>
              <FormControl mt="22px">
                <Flex align="center"></Flex>
                <FormLabel fontWeight="bold" fontSize="xs" color="#fff">
                  Date range
                </FormLabel>
                <Flex justify="space-between" align="center" gap="2">
                  <Input
                    name="startDate"
                    type="date"
                    placeholder="start date"
                    bg="#0F1535"
                    border=".5px solid"
                    borderColor="rgba(226, 232, 240, 0.3)"
                    _hover="none"
                    color="#fff"
                    borderRadius="15px"
                    fontSize="xs"
                  />
                  <Input
                    name="endDate"
                    type="date"
                    bg="#0F1535"
                    border=".5px solid"
                    borderColor="rgba(226, 232, 240, 0.3)"
                    _hover="none"
                    color="#fff"
                    borderRadius="15px"
                    fontSize="xs"
                    placeholder="end date"
                  />
                </Flex>
              </FormControl>
              <FormControl>
                <FormLabel fontWeight="bold" fontSize="xs" color="#fff">
                  Messages Direction
                </FormLabel>
                <Select
                  name=" Messages directions"
                  border=".5px solid"
                  borderColor="rgba(226, 232, 240, 0.3)"
                  _hover="none"
                  bg="#0F1535"
                  borderRadius="15px"
                  color="gray.400"
                  fontSize="sm"
                >
                  <option value="Conversion">Show All Direction</option>
                  <option value="Conversion">Inbound Only</option>
                  <option value="Conversion">Outbound Only</option>
                </Select>
              </FormControl>
              <FormControl mt="22px" spacing={4}>
                <Checkbox
                  isChecked={isAllSelected}
                  onChange={(e) =>
                    e.target.checked ? handleSelectAll() : handleDeselectAll()
                  }
                  borderColor="rgba(226, 232, 240, 0.3)"
                  color="#fff"
                >
                  Select All / Deselect All
                </Checkbox>
                <Flex gap="2" mt="15px" flexWrap="wrap" mx="10px">
                  <CheckboxGroup
                    value={selectedItems}
                    onChange={handleCheckboxChange}
                    borderColor="rgba(226, 232, 240, 0.3)"
                    color="#fff"
                  >
                    {allItems.map((item, index) => (
                      <Checkbox
                        borderColor="rgba(226, 232, 240, 0.3)"
                        key={index}
                        color="#fff"
                        value={item}
                      >
                        <Text>{item}</Text>
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Flex>
              </FormControl>
            </Stack>
            <Flex wrap="wrap" align="center" justify="center" gap="2" mt="15px">
              <Button variant="brand" onClick={() => setShowFilter(false)}>
                Close
              </Button>
              <Button variant="brand">Search</Button>
              <Button variant="brand">Export</Button>
            </Flex>
          </div>
        )}
        {!loadingData &&
          <SearchTable1 tableData={currentRows} columnsData={messageColumns} />
        }
        {loadingData &&
          <Loading />
        }
      </CardBody>

      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent textAlign="center" style={{ top: "20%" }}>
          <ModalHeader>Text Message</ModalHeader>
          <ModalBody>{selectedText}</ModalBody>
          <ModalFooter>
            <Button colorScheme="blue" mr={3} width="100%" onClick={onClose}>
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Card>
  );
};

export default MessageTable;
